


import React from 'react';
import { SampleFileID } from 'src/store/identifiers/identifiers';
import { Client, Provider, createClient } from 'urql';
import * as GraphQL from "src/generated/graphqlTypes"
import { gState } from 'src/store';



const getAPIURL = () => {
    if (process.env.REACT_APP_LOOPNFT_API_URL !== undefined) {
        return process.env.REACT_APP_LOOPNFT_API_URL
    }
    return 'http://localhost:4000/'
}

const client: Client = createClient({
    url: getAPIURL(),
    fetchOptions: () => {
        const headers: Record<string, string> = {}
        const connectionState = gState.connect.state

        if (connectionState?.kind === "Connected") {
            headers['public-key'] = connectionState.publicKey,
                headers['signature'] = connectionState.signature
        }

        return {
            headers
        }
    }
});

client.subscribeToDebugTarget?.(event => {
    if (event.source === 'dedupExchange')
        return;
    console.log(event); // { type, message, operation, data, source, timestamp }
});

console.log(`API URL: ${getAPIURL()}`)
const URQLProvider: React.FC = ({ children }) => (
    (<Provider value={client} >
        {children}
    </Provider>)
);

export {
    client as graphQLCLient
}

export default URQLProvider

export class GraphQLFetch {

    public static async sampleFile(props: SampleFileID): Promise<GraphQL.SampleFileFragment> {
        const result = await client
            .query<GraphQL.FetchSampleQuery,
                GraphQL.FetchSampleQueryVariables>(
                    GraphQL.FetchSampleDocument,
                    {
                        sampleHashID: props.sampleID
                    }
                )
            .toPromise()

        if (result.error) {
            throw result.error
        }

        const entry = result.data?.samples?.fetchSample
        if (entry === undefined) {
            throw new Error("Failed to find data in result")
        }

        return entry
    }
}