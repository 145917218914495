import * as React from 'react';
import { ListItemText } from '@mui/material';
import { SampleCardPackFileRowProps } from './SampleCard';
import { IdentifierFactory, SampleFileID } from 'src/store/identifiers/identifiers';
import { AuditionState } from "src/store/AuditionState";
import { useAppContext } from 'src/store/hooks';
import { observer } from 'mobx-react-lite';
import { Handle } from '../DragCore/Item/components';
import AudionButton from '../Audition/AuditionButton';





export const SampleCardPackFileRow: React.FC<SampleCardPackFileRowProps> = observer(({
    listeners,
    setNodeRef,
    attributes,
    samplePackFile
}) => {

    const audition: AuditionState = useAppContext().audition
    const auditionState: AuditionState["sampleAudition"] = audition.sampleAudition
    const packEntryID: SampleFileID = IdentifierFactory.makeSampleFileIDFromRaw(samplePackFile)

    let isAuditioning = false
    let isQueued = false
    if (auditionState.kind !== "stopped") {
        if (auditionState.currentAudition.compositeKey === packEntryID.compositeKey) {
            isAuditioning = auditionState.kind === "playing"
            isQueued = auditionState.kind === "queued"
        }
    }

    return (<div
        ref={setNodeRef}
        {...attributes}
        // className={classNames(style.SampleCardPackFileRow)}
        className="flex flex-row w-full justify-start border rounded-2xl bg-slate-100 mt-1 pr-1"
    >
        <Handle  {...listeners} />
        <div style={{ width: "100%" }}>
            <ListItemText primary={samplePackFile.name} sx={{ fontSize: 5 }} />
        </div>

        <AudionButton sample={samplePackFile} />

    </div >
    );
})
