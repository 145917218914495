import * as BABYLON from "@babylonjs/core";
import { ForgroundModelLayer } from "src/store/cinema";
import { deserializeVector3, serializeVector3 } from "src/store/cinema/utils";
import { Disposable, combineDisposables } from "src/utils/disposable";

import { MaterialRenderParams, MeshFactory, OnRenderClosure } from ".";
import materialShaderFactory from "./materialShaderFactory";

export enum MeshType {
    SPHERE = 0,
    TORUS = 1,
    TORUS_KNOT = 2,
    GROUND = 3,
    BOX = 4,
    PLANE = 5
}

export function MeshTypeFromString(str: string): MeshType {
    switch (str) {
        case "SPHERE":
            return MeshType.SPHERE
        case "TORUS":
            return MeshType.TORUS
        case "TORUS_KNOT":
            return MeshType.TORUS_KNOT
        case "GROUND":
            return MeshType.GROUND
        case "BOX":
            return MeshType.BOX
        case "PLANE":
            return MeshType.PLANE
        default:
            throw new Error(`Upexpected Mesh type ${str}`)
    }
}

export const meshFactory = (
    layer: ForgroundModelLayer): MeshFactory => {
    return {
        make: async (props) => {
            const { scene, camera } = props
            const { model, materialShader, meshParameters, camera: cameraParmeters } = layer

            if (model === undefined) {
                return {
                    dispose: () => { //
                    }
                }
            }

            //camera._getViewMatrix
            //camera.getViewMatrix().copyFrom(BABYLON.Matrix.FromArray())


            const mesh = selectMesh(model.meshType, scene)

            let materialDispose: Disposable | undefined = undefined
            let onMaterialRender: OnRenderClosure<MaterialRenderParams> | undefined = undefined

            if (materialShader) {
                const materialFactory = materialShaderFactory(layer, materialShader.summary)
                const {
                    material,
                    dispose,
                    onRender
                } = await materialFactory.make(props)
                materialDispose = { dispose }
                onMaterialRender = onRender

                // Assign material to Mesh.
                mesh.material = material
            }

            mesh.position = deserializeVector3(meshParameters.position)
            mesh.rotation = meshParameters.rotation ? deserializeVector3(meshParameters.rotation) : BABYLON.Vector3.Zero()
            mesh.scaling = meshParameters.scaling ? deserializeVector3(meshParameters.scaling) : BABYLON.Vector3.One()

            return {
                onBindGizmos: (gizmosType, callback) => {
                    const utilLayer = new BABYLON.UtilityLayerRenderer(scene);
                    const gizmo = new BABYLON.RotationGizmo(utilLayer,undefined, undefined, 3);


                    gizmo.onDragEndObservable.add(() => {
                        callback({
                            position: serializeVector3(mesh.position),
                            rotation: serializeVector3(mesh.rotation),
                            scaling: serializeVector3(mesh.scaling),
                        })
                    })
                    gizmo.attachedMesh = mesh

                    return {
                        dispose: () => {
                            utilLayer.dispose()
                            gizmo.dispose
                        }
                    }
                },
                ...combineDisposables(mesh, materialDispose),
                onRender: (renderParams) => {
                    onMaterialRender?.({
                        ...renderParams,
                        mesh
                    })
                }
            }
        }
    }
}

function selectMesh(type: MeshType, scene: BABYLON.Scene) {
    switch (type) {
        case MeshType.BOX:
            // Creating sphere
            //return Mesh.CreateSphere("mesh", 16, 5, scene);
            const mesh = BABYLON.MeshBuilder.CreateBox("mesh", {
                width: 8,
                height: 8,
                depth: 8
            }, scene);


            mesh.position.y = -1
            mesh.rotation.y = Math.PI / 4

            return mesh

        case MeshType.SPHERE:
            // Creating sphere
            //return Mesh.CreateSphere("mesh", 16, 5, scene);
            return BABYLON.MeshBuilder.CreateSphere("mesh", {
                segments: 16,
                diameter: 5
            }, scene);


        case MeshType.TORUS:
            // Creating Torus
            //return Mesh.CreateTorus("mesh", 5, 1, 32, scene)
            return BABYLON.MeshBuilder.CreateTorus("mesh", {
                diameter: 5,
                thickness: 1,
                tessellation: 32
            }, scene)
        case MeshType.TORUS_KNOT:
            // Creating Torus knot
            //return Mesh.CreateTorusKnot("mesh", 2, 0.5, 128, 64, 2, 3, scene)
            return BABYLON.MeshBuilder.CreateTorusKnot("mesh", {
                radius: 2,
                tube: 0.5,
                radialSegments: 128,
                tubularSegments: 64,
                p: 2,
                q: 3
            }, scene)

        case MeshType.PLANE:
            const ground = BABYLON.MeshBuilder.CreateGround("ground", {
                height: 15,
                width: 15,
            }, scene);
            ground.rotation.y = Math.PI;
            return ground
        default:
        case MeshType.GROUND:
            //return Mesh.CreateGroundFromHeightMap("mesh", "test_samples/heightMap.png", 8, 8, 100, 0, 3, scene, false)
            return BABYLON.MeshBuilder.CreateGroundFromHeightMap("mesh", "test_samples/heightMap.png", {
                width: 8,
                height: 8,
                subdivisions: 100,
                minHeight: 0,
                maxHeight: 3,
                updatable: false
            }, scene)
    }
}