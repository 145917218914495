import { Effect, Matrix, ShaderMaterial, Vector3 } from "@babylonjs/core"
import { MaterialFactory, MaterialRenderParams, OnRenderClosure } from "../../factory"
import { fetchShader } from "../shaderToy/fetchShader"
import * as GraphQL from "src/generated/graphqlTypes"
import { PrepUniformProps, applyPrimitiveUniformsToMaterial, createPrimitiveUniforms, prepareUniforms } from "../uniforms"
import { LayerMaterialID } from "src/store/identifiers/identifiers"

const STANDARDUNIFORMS = ["world", "worldView", "worldViewProjection", "view", "projection", "time"]
const SKIPUNIFORMS = ["cameraPosition"]
const SHADER_FROG_S3 = "https://s3-us-west-1.amazonaws.com/shader-frog/{image_name}"




export function loadShaderFrogMaterial(
    layerID: LayerMaterialID,
    shaderID: string): MaterialFactory {

    return {
        make: async ({ scene, context }) => {
            const shader = await fetchShader(shaderID, GraphQL.ShaderType.Material)

            if (shader.shaderPass.length > 1) {
                throw new Error("Cant handle multi pass material shaders")
            }
            const shaderPass = shader.shaderPass[0]

            if (shaderPass.vertexGLSL === undefined) {
                throw new Error("Expected Vertex GLSL")
            }

            Effect.ShadersStore[`${shaderID}FragmentShader`] = shaderPass.fragmentGLSL
            Effect.ShadersStore[`${shaderID}VertexShader`] = shaderPass.vertexGLSL

            const prepareUniformProps: PrepUniformProps = {
                layerID,
                shaderPass,
                postProcesses: {},
                scene,
                context
            }

              const {
                primitiveUniforms: _primitiveUniforms,
                textureUniformMap,
                uniformNames
            } = prepareUniforms(prepareUniformProps)
            let primitiveUniforms = _primitiveUniforms

            const shaderMaterial = new ShaderMaterial("shader", scene, {
                vertex: `${shaderID}`,
                fragment: `${shaderID}`,
            },
                {
                    attributes: ["position", "normal", "uv"],
                    //uniforms: standardUniforms//["world", "worldView", "worldViewProjection", "view", "projection"]
                    uniforms: [...STANDARDUNIFORMS, ...Object.keys(uniformNames)],
                });


            shaderMaterial.setFloat("time", 0.0)
            //applyTextureUniforms(shaderMaterial, scene, textureUniformMap)
            Object.entries(textureUniformMap).forEach(([key, value]) => {
                shaderMaterial.setTexture(key, value.texture())
            })
            applyPrimitiveUniformsToMaterial(shaderMaterial, primitiveUniforms)

            const bindingDispoable = context.binding.onBindingChanged.add(() => {
                primitiveUniforms = createPrimitiveUniforms(prepareUniformProps)
            })

            //shaderMaterial.setVector3("color", new Vector3(.6, .8, .2))
            shaderMaterial.setMatrix("projectionMatrix", Matrix.Identity())
            shaderMaterial.setMatrix("modelViewMatrix", Matrix.Identity())


            // // Use a setter
            shaderMaterial.setVector3(
                // This name becomes the Uniform name you can reference in the shader.
                "cameraPosition",
                // This is the value getting passed in.
                new Vector3(
                    1, 1, 1
                )
            )

            //world (scene.getTransformMatrix()

            shaderMaterial.onEffectCreatedObservable.addOnce(({ effect }) => {
                effect.getUniform("worldViewProjection")

                console.log("here")
            })


            const onRender: OnRenderClosure<MaterialRenderParams> = ({ scene, time, mesh }) => {



                // // = object.matrixWorld
                // uniform mat4 modelMatrix;
                const modelMatrix = mesh?.worldMatrixFromCache || Matrix.Identity()
                shaderMaterial.setMatrix("modelMatrix", modelMatrix)

                // // = camera.matrixWorldInverse * object.matrixWorld
                // uniform mat4 modelViewMatrix;
                const _camera_matrixWorldInverse = (scene.activeCamera?.worldMatrixFromCache || Matrix.Identity()).invert()
                const modelViewMatrix = _camera_matrixWorldInverse.multiply(modelMatrix)
                //shaderMaterial.setMatrix("modelViewMatrix", modelViewMatrix)

                // // = camera.projectionMatrix
                // uniform mat4 projectionMatrix;
                // const projectionMatrix = (scene.activeCamera?.getProjectionMatrix() || Matrix.Identity())
                // shaderMaterial.setMatrix("projectionMatrix", projectionMatrix)

                // TODO HACK!!!!!!!
                const worldViewProjectionMatrix = (shaderMaterial as any)._cachedWorldViewProjectionMatrix as Matrix
                shaderMaterial.setMatrix("projectionMatrix", worldViewProjectionMatrix)


                // // = camera.matrixWorldInverse
                // uniform mat4 viewMatrix;
                const viewMatrix = _camera_matrixWorldInverse
                shaderMaterial.setMatrix("viewMatrix", viewMatrix)

                // // = inverse transpose of modelViewMatrix
                // uniform mat3 normalMatrix;
                const _normalMatrix = new Matrix()
                modelViewMatrix.toNormalMatrix(_normalMatrix)
                const normalMatrix = Matrix.GetAsMatrix3x3(_normalMatrix)
                shaderMaterial.setMatrix3x3("normalMatrix", normalMatrix)

                // // = camera position in world space
                // uniform vec3 cameraPosition;
                const cameraPosition = scene.activeCamera?.globalPosition || new Vector3(1, 2, 3)
                shaderMaterial.setVector3("cameraPosition", cameraPosition)

                shaderMaterial.setFloat("time", time)
                applyPrimitiveUniformsToMaterial(shaderMaterial, primitiveUniforms)
                // TODO HACK!!!!!!!
                // const worldViewProjectionMatrix = (shaderMaterial as any)._cachedWorldViewProjectionMatrix as Matrix
                // shaderMaterial.setMatrix("projectionMatrix", worldViewProjectionMatrix)


                // for (const [uniform, value] of Object.entries(threeJSFile.uniforms)) {
                //     switch (uniform) {
                //         case "time":
                //             //shaderMaterial.setFloat("time", time)
                //             continue
                //         case "cameraPosition":
                //             //scene.activeCamera?.position
                //             continue
                //         default:
                //         // throw new Error("Uniform")
                //     }
                // }
            }


            return {
                material: shaderMaterial,
                dispose: () => {
                    shaderMaterial.dispose()
                    bindingDispoable.dispose()
                },
                onRender
            }
        }
    }
}