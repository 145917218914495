
import React, { useState } from "react"
import { ADSRRatios, ARRatios, Ratios } from "src/store/sound/Track"
import Envelope from "./Envelope"
import NFTKnob from "./NFTKnob"




interface Props {
    ratios: Ratios
    onChange?: (ratio: Ratios) => void
    style?: React.CSSProperties
}

// {
//     xp: 0.0,
//     xa: 0.5,
//     xd: 0.5,
//     xr: 0.5,
//     ys: 0.5
// }


export const ADSREnvelope: React.FC<Props> = ({ ratios: _ratios, onChange, style }) => {

    const [ratios, setRatios] = useState<Ratios>(_ratios)
    const [firstMount, setFirstMount] = useState(true)

    const handleADSRChange = () => {
        switch (ratios.kind) {
            case "adsr":
                const ARRatio: ARRatios = {
                    kind: "ar",
                    xp: ratios.xp,
                    xr: ratios.xr,
                    xa: ratios.xa,
                }
                setRatios(ARRatio)
                break
            case "ar":
                const ADSRRatios: ADSRRatios = {
                    kind: "adsr",
                    xp: ratios.xp,
                    xr: ratios.xr,
                    xd: 0.25,
                    ys: 0.25,
                    xa: ratios.xa,
                }
                setRatios(ADSRRatios)
                break
        }
    }

    React.useEffect(() => {
        if (firstMount) {
            setFirstMount(false)
            return
        }

        if (onChange) {
            onChange(ratios)
        }
    }, [ratios])

    const bind = <K extends keyof Omit<ADSRRatios, "kind">>(key: K, value: number) => {
        const newRatios = Object.assign({}, ratios)

        switch (newRatios.kind) {
            case "adsr":
                newRatios[key] = value
                break
            case "ar":
                (newRatios as any)[key] = value! //Just do it
                break;
        }
        setRatios(newRatios)
    }

    return <div className="flex items-center gap-1">
        <Envelope style={{
            // width: "100%",
            border: "2px solid black",
            borderRadius: "5px",
            height: "80px",
            backgroundColor: "grey"
        }}
            mode='adsr'
            ratio={ratios}
            onChange={(_ratios) => {
                setRatios(Object.assign({}, _ratios))
            }}
        />


        <div className="flex flex-col">
            <ADSRKnob
                label="A"
                value={() => ratios.xa}
                onChange={(v: number) => bind("xa", v)}
                size={"small"}
            />
            {/* {
                    ratios.kind == "adsr" ? [
                        <ADSRKnob key="d" label="D" value={() => ratios.xd} onChange={(v: number) => bind("xd", v)} />,
                        <ADSRKnob key="s" label="S" value={() => ratios.ys} onChange={(v: number) => bind("ys", v)} />
                    ] : null
                } */}

            <ADSRKnob
                label="R"
                value={() => ratios.xr}
                onChange={(v: number) => bind("xr", v)}
                size={"small"}
            />
        </div>
    </div>
}


interface ADSRKnob {
    value: () => number,
    label: string,
    onChange: (value: number) => void
    size?: "small" | "normal"
}
const ADSRKnob = (props: ADSRKnob) => {
    return <NFTKnob
        value={props.value() * 100}
        label={props.label}
        onChange={(v) => { props.onChange(v / 100) }}
        skin={props.size ?? "normal"}
        max={100}
        min={0}
    />
}