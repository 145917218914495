import { runInAction } from "mobx";
import * as GraphQL from "src/generated/graphqlTypes"
import { LayerMaterialID, LayerShaderID } from "../identifiers/identifiers";
import { AudioUniform, PrimitiveUniform, UniformTypeMap } from "./types";
import { isTime } from "./utils";


export function readUniformsFromShader(
    layerID: LayerMaterialID | LayerShaderID,
    shader: GraphQL.ShaderFragment): UniformTypeMap {
    return shader.shaderPass?.reduce((acc, next) => {
        next.uniforms?.map(uniform => {
            switch (uniform.__typename) {
                case "UniformPrimitive": {
                    const primitiveUniform = runInAction(() => {

                        const primitiveUniform = new PrimitiveUniform(
                            layerID,
                            {
                                name: uniform.name,
                                friendlyName: uniform.friendlyName,
                                type: uniform.type,
                                value: uniform.value,
                                rangeMax: uniform.rangeMax ?? Math.max(uniform.value * 5, 1),
                                rangeMin: uniform.rangeMin ?? 0
                            })

                        if (uniform.type === GraphQL.UniformPrimitiveType.F &&
                            isTime(uniform.name)) {
                            if (uniform.value === 0) {
                                primitiveUniform.value = 1
                                primitiveUniform.rangeMax = 2
                                primitiveUniform.rangeMin = 0
                            }
                        }
                        return primitiveUniform
                    })

                    acc[uniform.name] = primitiveUniform

                    handleSpecialCaseUniforms(primitiveUniform)
                }
                    break
                case "UniformAudio":
                    acc[uniform.name] = new AudioUniform(uniform.name)
                    break
            }
        })
        return acc
    }, {} as UniformTypeMap) ?? []
}

function handleSpecialCaseUniforms(uniform: PrimitiveUniform) {
    if (uniform.isTime) {
        uniform.modulatable = "required"
    }

}
