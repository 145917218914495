
import React, { CSSProperties, useState } from 'react';
import { BindableProps } from './BindableItem';
import { FrogColor3 } from 'src/render/visual/shaders/shaderFrog/model';
import { ensure } from 'src/utils';
import { Color3 } from '@babylonjs/core';
import { classItemBindable } from './styles';
import Tooltip from 'rc-tooltip';


export const ColorPicker: React.FC<BindableProps<FrogColor3>> = (props) => {

    const [isVisibile, setIsVisible] = useState(false)
    const popover: CSSProperties = {
        position: 'absolute',
        zIndex: '2',
    }

    const cover: CSSProperties = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    const initialColor = `${new Color3(props.value.r,
        props.value.g,
        props.value.b).toHexString()}`

    return (<div className={classItemBindable({ pinned: false })} >
        <div className='flex flex-col items-start'>
            <p className='text-base'>C</p>
            <div className='flex flex-row w-full pr-1 pb-1'>
                <Tooltip overlay={props.name}>
                    <p className='text-base text-left truncate w-5/6'>{props.name}</p>
                </Tooltip>
                <input
                    className='w-1/6'
                    type="color"
                    id="head"
                    name="head"
                    value={initialColor}
                    onChange={(event) => {
                        const hexString = event.target.value
                        const color = Color3.FromHexString(hexString)
                        props.onChange(ensure<FrogColor3>(color))
                    }}
                />
                {/* <button onClick={() => { setIsVisible(true) }}>Pick Color</button>

            {
                isVisibile &&
                <div style={popover}>
                    <div style={cover} onClick={() => setIsVisible(false)} />
                    <PhotoshopPicker
                        color={props.value}
                        onChangeComplete={(color) =>
                            props.onChange(ensure<FrogColor3>(color.rgb))
                        }
                    />
                </div>
            } */}
            </div>
        </div>

    </div>)
}
