import React from "react"
import { GraphQL } from "src/generated"
import { useLoopContext } from "src/render/hooks"
import { AuditionState } from "src/store/AuditionState"
import { useAppContext } from "src/store/hooks"
import { IdentifierFactory, SampleFileID } from "src/store/identifiers/identifiers"
import { Headphones } from "src/svg"

const AudionButton: React.FC<{ sample: GraphQL.SampleFileFragment }> = ({ sample }) => {

    const { audition } = useAppContext()
    const { transport } = useLoopContext()

    const auditionState: AuditionState["sampleAudition"] = audition.sampleAudition
    const packEntryID: SampleFileID = IdentifierFactory.makeSampleFileIDFromRaw(sample)

    let isAuditioning = false
    let isQueued = false
    if (auditionState.kind !== "stopped") {
        if (auditionState.currentAudition.compositeKey === packEntryID.compositeKey) {
            isAuditioning = auditionState.kind === "playing"
            isQueued = auditionState.kind === "queued"
        }
    }


    return <button className="btn-blue self-center w-fit mr-3" onClick={() => {
        onClickAudition({
            isQueued,
            isAuditioning,
            audition,
            packEntryID
        })
    }} ><Headphones className="text-white"></Headphones>
    </button>
}


const onClickAudition = ({ isQueued, isAuditioning, audition, packEntryID }: {
    isQueued: boolean,
    isAuditioning: boolean,
    audition: AuditionState,
    packEntryID: SampleFileID
}) => {

    const auditionState: AuditionState["sampleAudition"] = audition.sampleAudition

    const fireAudition = () => {
        audition.queueAudition(packEntryID)
    }

    switch (auditionState.kind) {
        case "queued":
            break

        case "playing":
            audition.stopAuditionPlaying()

            // If its not us playing, then queue another
            if (!isQueued && !isAuditioning) {
                fireAudition()
            }

            break

        case "stopped":
            fireAudition()
            break
    }

}

export default AudionButton