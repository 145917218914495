import React, { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Knob } from "react-rotary-knob";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as skins from 'react-rotary-knob-skin-pack';

type KnobProps = {
    value: number,
    label?: string
    valueLabel?: boolean
    valueFormat?: (value: number) => string
    clampMin?: number,
    clampMax?: number,
    rotateDegrees?: number,
    min: number,
    max: number,

    limit?: boolean

    skin?: "normal" | "small"
    enabled?: boolean
    //   skin: Skin,
    // format: (val: number) => string,
    onChange?: (val: number) => void,
    // onStart: () => void,
    // onEnd: () => void,
    style?: any,
    // preciseMode: boolean,
    // unlockDistance: number,
    // step: number
};

const Knob2 = (props: KnobProps) => {
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    let size: string
    let skin: string


    switch (props.skin) {
        case "small":
            skin = skins.s9
            size = "30px"

            break
        case "normal":
        default:
            skin = skins.s15
            size = "50px"

    }

    return <div >
        {props.label ? (<p style={{ fontSize: 10, margin: 0, padding: 0 }}>{props.label}</p>) : null}
        <Knob skin={skin}
            value={value}
            rotateDegrees={props.rotateDegrees ?? 0}
            onChange={(v: number) => {

                if (Math.abs(v - value) > .2 * (props.max - props.min)) {
                    if (value < v) {
                        v = props.min
                    } else {
                        v = props.max
                    }
                }

                setValue(v);
                props.onChange ? props.onChange(v) : null
            }}
            min={props.min}
            max={props.max}

            //unlockDistance={100}
            preciseMode={false}
            style={{
                width: size,
                height: size,
                opacity: props.enabled ?? true ? 1.0 : 0.5
            }}

        />
        {props.valueLabel ? (<p style={{ fontSize: 10, margin: 0, padding: 0 }}>{props.valueFormat?.(value) ?? value}</p>) : null}
    </div>
};

export default Knob2
