import React from "react"
import { registerLocalStoreEvents } from "./localStore";
import { registerSideEffects } from "./sideEffects";
import { PagesState } from "./store";

export const gState = new PagesState()

registerSideEffects(gState)
registerLocalStoreEvents(gState)


export const StateContext = React.createContext(gState)

export const PagesProvider = ({ children }: any) => {

    console.log("Creating Store")

    return (
        <StateContext.Provider value={gState}>
            {children}
        </StateContext.Provider>
    )
}

