import { Nullable } from "@babylonjs/core";
import React, { useEffect, useRef, useState } from "react";
import { AsyncStateLoader, asyncStateNone } from "src/utils/async";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  style: React.CSSProperties
  color?: string
  fetchPeaks: () => Promise<Peaks>
  maxStep?: number
  barToShow: number
}

type Peaks = {
  data: Float32Array
  numberOfBars: number
}

const Waveform: React.FC<Props> = (props: Props) => {
  const { color, fetchPeaks, maxStep = 1000, style } = props

  const [peaksState, setPeaksState] = useState<AsyncStateLoader<unknown, Peaks>>(asyncStateNone)
  const [barToShow, setBarToShow] = useState(props.barToShow)

  useEffect(() => {
    if (barToShow != props.barToShow) {
      setBarToShow(props.barToShow)
    }
  }, [props.barToShow])

  switch (peaksState.kind) {
    case "none":
      fetchPeaks()
        .then(peaks => {
          setPeaksState({
            kind: "loaded",
            props: peaks
          })
        })
        .catch(err => {
          setPeaksState({
            kind: "failed",
            error: err
          })
        })
      setPeaksState({
        kind: "loading",
        props: {}
      })
  }

  const peaks = peaksState.kind == "loaded" ? peaksState.props : null

  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current as Nullable<HTMLCanvasElement>
    if (!canvas) {
      return
    }
    const context = canvas.getContext('2d')
    if (!context) {
      return
    }
    if (!peaks) {
      return
    }

    if (color) {
      context.fillStyle = color
    }

    const width = canvas.width
    const height = canvas.height
    context.clearRect(0, 0, width, height)
    context.strokeStyle = "solid"

    const audioData = peaks.data.slice(barToShow * peaks.data.length / peaks.numberOfBars,
      barToShow === peaks.numberOfBars ? undefined : (barToShow + 1) * peaks.data.length / peaks.numberOfBars)

    const step = Math.ceil(audioData.length / width)
    const amp = height / 2
    for (let i = 0; i < width; i++) {
      let neg = 0
      let pos = 0
      const max = Math.min(step, maxStep)
      for (let j = 0; j < max; j++) {
        const val = audioData[(i * step) + j]
        if (val < 0) neg += val
        else pos += val
      }
      neg = neg / max
      pos = pos / max
      context.fillRect(i, amp - pos * amp, 1, amp * (pos - neg))
    }

    // //Our first draw
    // context.fillStyle = '#FF0000'
    // context.fillRect(0, 0, context.canvas.width, context.canvas.height)
  }, [peaksState, barToShow])


  return <canvas ref={canvasRef} style={style} />
}

export default Waveform;