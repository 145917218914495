import * as BABYLON from "@babylonjs/core";
import * as GraphQL from "src/generated/graphqlTypes";
import { FrequencyAnalyserTexture } from "src/render/sequencing/FreqAnalyser";
import { GlobalRenderContext } from "src/render/visual/GlobalRenderContext";
import { BufferModel, RenderTextureOutput, ShaderPassMap } from "./shaderToy/index";
import { ensure } from "src/utils";
import { LayerMaterialID, LayerShaderID } from "src/store/identifiers/identifiers";
import { BindingTargetResult } from "src/render/sequencing/types";
import { FrogColor3 } from "./shaderFrog/model";
import { BindableTimeData } from "src/render/sequencing/binding";
import { Effect, ShaderMaterial } from "@babylonjs/core";
import { isTime } from "src/store/cinema/utils";


export function applyPrimitiveUniformsToMaterial(
    material: ShaderMaterial,
    uniforms: UniformPrimitiveMap) {

    Object.values(uniforms).forEach((uniform) => {
        uniform.bindMaterial(material)
    })
}

export function applyPrimitiveUniformsToEffect(
    effect: Effect,
    uniforms: UniformPrimitiveMap
) {
    Object.values(uniforms).forEach((uniform) => {
        uniform.bindEffect(effect)
    })
}


export type UniformPrimitiveMap = {
    [uniformName: string]: UniformPrimitiveBinding
}

export type UniformTextureMap = {
    [uniform: string]: UniformTextureTypes
}

export type UniformTextureTypes =
    {
        kind: "texture"
        texture: () => BABYLON.Texture
    } |
    {
        kind: "cube",
        texture: () => BABYLON.CubeTexture,
    } |
    {
        kind: "volume",
        texture: () => BABYLON.CubeTexture
    } |
    {
        kind: "audio",
        texture: () => FrequencyAnalyserTexture
    } |
    {
        kind: "buffer",
        texture: () => BABYLON.RenderTargetTexture
    }

export type UniformPrimitiveBinding = {
    bindEffect: (effect: BABYLON.Effect) => void
    bindMaterial: (material: BABYLON.ShaderMaterial) => void
}


function makeBindingToPrimitive(
    uniform: GraphQL.UniformPrimitive,
    binding: BindingTargetResult<any>): UniformPrimitiveBinding {

    const name = uniform.name
    switch (uniform.type) {
        case GraphQL.UniformPrimitiveType.F: {
            return {
                bindEffect: (effect) => {
                    effect.setFloat(name, binding.getData())
                },
                bindMaterial: (material) => {
                    material.setFloat(name, binding.getData())
                }
            }
        }

        case GraphQL.UniformPrimitiveType.Color: {

            return {
                bindEffect: (effect) => {
                    const colors = ensure<FrogColor3>(binding.getData())
                    effect.setColor3(name, colors)
                },

                bindMaterial: (material) => {
                    //const colors = ensure<FrogColor3>(binding.getData())
                    material.setColor3(name, binding.getData())
                }
            }
        }

        case GraphQL.UniformPrimitiveType.Vector2: {
            return {
                bindEffect: (effect) => { effect.setVector2(name, binding.getData()) },
                bindMaterial: (material) => { material.setVector2(name, binding.getData()) }
            }
        }

        case GraphQL.UniformPrimitiveType.Vector3: {
            return {
                bindEffect: (effect) => { effect.setVector3(name, binding.getData()) },
                bindMaterial: (material) => { material.setVector3(name, binding.getData()) }
            }
        }

        case GraphQL.UniformPrimitiveType.Vector4: {
            return {
                bindEffect: (effect) => { effect.setVector4(name, binding.getData()) },
                bindMaterial: (material) => { material.setVector4(name, binding.getData()) }
            }
        }

        default:
            throw new Error("Incomplete")
    }
}

export type PrepUniformProps = {
    layerID: LayerShaderID | LayerMaterialID,
    scene: BABYLON.Scene
    context: GlobalRenderContext
    shaderPass: GraphQL.ShaderPassFragment
    postProcesses: ShaderPassMap
    currentTextureOutput?: RenderTextureOutput
}

interface UniformBindingMap {
    textureUniformMap: UniformTextureMap,
    primitiveUniforms: UniformPrimitiveMap,
    uniformNames: {
        primitives: string[],
        samplers: string[]
    }
}

export function prepareUniforms(props: PrepUniformProps): UniformBindingMap {
    const textureUniformMap = createTextureUniforms(props)
    const primitiveUniforms = createPrimitiveUniforms(props)

    return {
        textureUniformMap,
        primitiveUniforms,
        uniformNames: {
            primitives: Object.keys(primitiveUniforms),
            samplers: Object.keys(textureUniformMap)
        }

    }
}


export function createTextureUniforms(props: PrepUniformProps): UniformTextureMap {
    const {
        scene,
        context,
        shaderPass,
        postProcesses,
        currentTextureOutput } = props

    const map: UniformTextureMap = {};
    if (!shaderPass.uniforms) {
        return map;
    }

    shaderPass.uniforms.forEach(input => {
        switch (input.__typename) {
            case "UniformTexture":
                const textureURL = input.textureURL;
                let result: UniformTextureTypes;
                switch (input.textureType) {
                    case GraphQL.TextureType["2D"]: {

                        const texture = new BABYLON.Texture(textureURL, scene);
                        result = {
                            kind: "texture",
                            texture: () => texture
                        };
                    }
                        break;
                    case GraphQL.TextureType["Cube"]: {
                        const texture = new BABYLON.CubeTexture(textureURL, scene);
                        result =
                        {
                            kind: "cube",
                            texture: () => texture
                        };
                    }
                        break;
                    case GraphQL.TextureType["Volume"]: {
                        const texture = new BABYLON.CubeTexture(textureURL, scene);
                        result = {
                            kind: "volume",
                            texture: () => texture
                        };
                    }
                        break;
                    default:
                        throw new Error(`Unrecognized Texture Type ${input.textureType}`);
                }

                map[input.name] = result;

                break;
            case "UniformBuffer":
                const bufferID = input.bufferID;

                if (bufferID === shaderPass.bufferID) {
                    if (currentTextureOutput === undefined) {
                        throw new Error("Expected a render texture target to be available for self buffers");
                    }
                    map[input.name] = {
                        kind: "buffer",
                        texture: () => currentTextureOutput("read")
                    };
                } else {
                    const buffer = Object
                        .values(postProcesses)
                        .find(x => x.kind === "buffer" &&
                            x.bufferID === bufferID) as BufferModel;
                    if (!buffer) {
                        console.warn(`Failed to find buffer ${bufferID} as input texture`);
                        return;
                    }
                    map[input.name] = buffer.renderTarget.get;
                }
                break;

            case "UniformAudio": {
                const texture = new FrequencyAnalyserTexture(scene);
                map[input.name] = {
                    kind: "audio",
                    texture: () => {
                        texture.updateAnalyser(context.update.get().frame);
                        return texture;
                    }
                };
                break;
            }
            default:
                break;
        }

    });
    return map;
}

export function createPrimitiveUniforms(props: PrepUniformProps): UniformPrimitiveMap {
    const map = props.shaderPass.uniforms?.reduce((acc, uniform) => {
        switch (uniform.__typename) {
            case "UniformPrimitive":

                let binding = props.context.binding.bindTargetPrimitive<any>(
                    props.layerID,
                    uniform
                )

                if (isTime(uniform.name)) {
                    const modulationBinding = binding
                    let time = 0

                    binding = {
                        getData: () => {
                            const deltaTime = props.context.update.get().deltaTime
                            const bindingData = modulationBinding.getData() as BindableTimeData

                            let returnedTime: number
                            if (typeof bindingData === "number") {
                                time += deltaTime * bindingData
                                returnedTime = time
                            } else {
                                switch (bindingData.behavior) {
                                    default:
                                    case "offset":
                                        time += deltaTime * bindingData.preset
                                        returnedTime = time + (bindingData.mod ?? 0)
                                        break
                                    case "scale":
                                        time += deltaTime * (bindingData.preset + (bindingData.mod ?? 0))
                                        returnedTime = time
                                        break
                                }
                                //console.log(`time:${returnedTime} deltaTime:${deltaTime} preset:${bindingData.preset} mod:${bindingData.mod ?? 0} behavior:${bindingData.behavior}`)
                                // console.log(returnedTime)


                            }

                            return returnedTime
                        }
                    }
                }

                acc[uniform.name] = makeBindingToPrimitive(uniform, binding)



        }

        return acc
    }, ensure<UniformPrimitiveMap>({})) ?? {}
    return map
}

// function makeBindingToPrimitive(uniform: GraphQL.UniformPrimitive): UniformPrimitiveBinding {

//     const name = uniform.name
//     switch (uniform.type) {
//         case GraphQL.UniformPrimitiveType.F: {
//             const v = parsePrimitiveFloat(uniform.value)

//             return {
//                 bindEffect: (effect) => { effect.setFloat(name, v) },
//                 bindMaterial: (material) => { material.setFloat(name, v) }
//             }
//         }

//         case GraphQL.UniformPrimitiveType.Color: {
//             const v = parsePrimitiveColor3(uniform.value)

//             return {
//                 bindEffect: (effect) => { effect.setColor3(name, v) },
//                 bindMaterial: (material) => { material.setColor3(name, v) }
//             }

//         }

//         case GraphQL.UniformPrimitiveType.Vector2: {
//             const v = parsePrimitiveVector2(uniform.value)

//             return {
//                 bindEffect: (effect) => { effect.setVector2(name, v) },
//                 bindMaterial: (material) => { material.setVector2(name, v) }
//             }

//         }

//         case GraphQL.UniformPrimitiveType.Vector3: {
//             const v = parsePrimitiveVector3(uniform.value)

//             return {
//                 bindEffect: (effect) => { effect.setVector3(name, v) },
//                 bindMaterial: (material) => { material.setVector3(name, v) }
//             }

//         }

//         case GraphQL.UniformPrimitiveType.Vector4: {
//             const v = parsePrimitiveVector4(uniform.value)

//             return {
//                 bindEffect: (effect) => { effect.setVector4(name, v) },
//                 bindMaterial: (material) => { material.setVector4(name, v) }
//             }

//         }

//         default:
//             throw new Error("Incomplete")

//     }
// }




export function parsePrimitiveVector3(value: GraphQL.Scalars["UniformPrimitiveValue"]): BABYLON.Vector3 {

    if (typeof value !== "object" ||
        value.x === undefined ||
        value.y === undefined ||
        value.z === undefined) {
        throw new Error("Unexpected scalar type for vec3 primitive:" + typeof value)
    }
    return new BABYLON.Vector3(value.x, value.y, value.z)
}


export function parsePrimitiveVector4(value: GraphQL.Scalars["UniformPrimitiveValue"]): BABYLON.Vector4 {

    if (typeof value !== "object" ||
        value.x === undefined ||
        value.y === undefined ||
        value.z === undefined ||
        value.w === undefined) {
        throw new Error("Unexpected scalar type for vec3 primitive:" + typeof value)
    }
    return new BABYLON.Vector4(value.x, value.y, value.z, value.w)
}


export function parsePrimitiveVector2(value: GraphQL.Scalars["UniformPrimitiveValue"]): BABYLON.Vector2 {

    if (typeof value !== "object" ||
        value.x === undefined ||
        value.y === undefined) {
        throw new Error("Unexpected scalar type for vec2 primitive:" + typeof value)
    }

    return new BABYLON.Vector2(value.x, value.y)
}

export function parsePrimitiveColor3(value: GraphQL.Scalars["UniformPrimitiveValue"]): BABYLON.Color3 {

    if (typeof value !== "object" ||
        value.r === undefined ||
        value.g === undefined ||
        value.b === undefined) {
        throw new Error("Unexpected scalar type for color3 primitive:" + typeof value)
    }

    return new BABYLON.Color3(value.r, value.g, value.b)
}


