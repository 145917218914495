import classNames from 'classnames';
import React from 'react';
import styles from "../Material.module.scss";
import Button from '@mui/material/Button';



type Props = {
    name: string
    onSelected: () => void
};

export const ModelPeakPreview: React.FC<Props> = ({ name, onSelected }) => {
    return (<div className={classNames(styles.ModelPeakView)} onClick={onSelected}>
        <Button variant='text' >
            {name}
        </Button>
    </div>)
};

