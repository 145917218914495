import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface AlertDialogProps {
    open: boolean,
    title: string,
    body: string,
    close: () => void,
    okay?: () => void

}

export const AlertDialog = (props: AlertDialogProps) => {

    const [isOpen, setIsOpen] = React.useState(props.open)

    return (
        <Dialog
            open={isOpen}
            // onClose={() => {
            //     setIsOpen(false)
            //     props.close()
            // }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setIsOpen(false)
                    props.close()
                }} autoFocus>Close</Button>
                {props.okay ? <Button onClick={() => {
                    setIsOpen(false)
                    props.okay?.()
                }} autoFocus>
                    Okay
                </Button> : null}
            </DialogActions>
        </Dialog>
    );
}