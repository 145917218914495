
import * as BABYLON from "@babylonjs/core"

//import { Renderpass, ShaderToyModel } from "./model"
import { MaterialFactory, OnRenderClosure, PostProcessFactory, PostProcessFactoryResult } from "../../factory"
import * as GraphQL from "src/generated/graphqlTypes"
import { Disposable } from "src/utils/disposable"
import { GlobalRenderContext } from "src/render/visual/GlobalRenderContext"
import { PrepUniformProps, UniformTextureMap, applyPrimitiveUniformsToEffect, createPrimitiveUniforms, prepareUniforms } from "../uniforms"
import { fetchShader } from "./fetchShader"
import { IdentifierFactory, LayerShaderID } from "src/store/identifiers/identifiers"
/* eslint-disable */

const STANDARDUNIFORMS = ["world", "worldView", "worldViewProjection", "view", "projection", "time"]

const SHADERTOY_UNIFORMS = ["iTime", "iResolution",
    "iMouse", "iFrame",
    "iChannel0", "iChannel1", "iChannel2", "iChannel3",
    "iChannelTime"]

const SHADER_TOY_SAMPLERS = ["iChannel0", "iChannel1", "iChannel2", "iChannel3"]


export type ShaderPassMap = Record<string, ModelType>

export interface BufferModel extends Disposable {
    kind: "buffer"
    bufferID: string,
    effect: BABYLON.EffectWrapper,
    renderer: BABYLON.EffectRenderer
    renderTarget: {
        dispose: () => void,
        get: {
            kind: "buffer",
            texture: () => BABYLON.RenderTargetTexture
        }
    }
}

interface PrimaryModal extends Disposable {
    kind: "primary",
    postProcess: BABYLON.PostProcess
}

type ModelType = PrimaryModal | BufferModel

let sharedTime = 0
export function loadShaderToyPostProcess(
    shaderID: string,
    shaderType: GraphQL.ShaderType,
    layerID: LayerShaderID): PostProcessFactory {

    return {
        make: async ({
            scene,
            camera,
            context }): Promise<PostProcessFactoryResult> => {

            const shader = await fetchShader(shaderID, shaderType)


            const postProcesses = shader.shaderPass
                .reduce((postProcesses, shaderPass: GraphQL.ShaderPassFragment) => {

                    switch (shaderPass.type) {
                        case GraphQL.ShaderPassType.Buffer:
                            postProcesses[shaderPass.name] = makeBuffer(
                                layerID,
                                shaderPass,
                                postProcesses,
                                camera,
                                scene,
                                context)
                            break
                        case GraphQL.ShaderPassType.Primary:
                            postProcesses[shaderPass.name] = makePrimary({
                                camera,
                                context,
                                scene,
                                shaderID,
                                shaderType,
                                layerID,
                                shaderPass,
                                postProcesses
                            })
                    }

                    return postProcesses

                }, {} as ShaderPassMap)

            const onRender: OnRenderClosure = ({
                deltaTime }) => {

            }

            return {
                postProcesses,
                dispose: () => {
                    Object.values(postProcesses).forEach(x => x.dispose())
                },
                onRender
            }
        }
    }
}


type ShaderAsInput = {
    process: BABYLON.PostProcess,
    name: string
    inputID: string
}
// function createBufferAsInput(
//     currentShaderPass: ShaderPass,
//     allShaderPasses: ShaderPass[],
//     postProcesses: Record<string, BABYLON.PostProcess>): ShaderAsInput | undefined {

//     const inputIDForBuffer = textureFromPostProcess(currentShaderPass)
//     if (inputIDForBuffer) {

//         const passAsInput = allShaderPasses.find(x => x.outputID === inputIDForBuffer)
//         // if (inputIDForBuffer === currentShaderPass.outputID) {
//         //     return
//         // }
//         if (!passAsInput) {
//             throw new Error(`Failed to find shaderPass ${inputIDForBuffer} to be used as input to ${currentShaderPass.name}`)
//         }

//         return {
//             process: postProcesses[passAsInput.name],
//             name: 'iChannel0',
//             inputID: inputIDForBuffer
//         }
//     }

//     return undefined
// }

export function loadShadeToyMaterial(shaderID: string): MaterialFactory {

    // https://shaderfrog.com/api/shadersById/\?ids\[\]\=1932

    return {
        make: async ({ scene }) => {

            throw new Error("Incomplete")
            //const { shaderData: shaderData, fragmentShader } = await fetchShaderToy(shaderID, "material")
            // const { shaderPasses: [imagePass] } = await fetchShaderToy(shaderID)

            // BABYLON.Effect.ShadersStore[`${shaderID}FragmentShader`] = imagePass.fragmentShader
            // BABYLON.Effect.ShadersStore["shaderToyVertexShader"] = VERTEX_SHADER

            // const shaderMaterial = new BABYLON.ShaderMaterial("shader", scene, {
            //     vertex: "shaderToy",
            //     fragment: shaderID,
            // },
            //     {
            //         attributes: ["position", "normal", "uv"],
            //         //defines: ["precision highp float;"],
            //         uniforms: [
            //             ...STANDARDUNIFORMS,
            //             ...SHADERTOY_UNIFORMS,
            //         ]
            //     }
            // );


            // bindTextureUniformsToShader(createTextureUniforms(imagePass, scene), shaderMaterial)
            // applyVideoTextureUniforms(shaderID, imagePass, shaderMaterial, scene)

            // const onRender: OnRenderClosure = ({ scene, time }) => {

            //     shaderMaterial.setFloat("iTime", time)
            //     shaderMaterial.setVector3("iResolution", new BABYLON.Vector3(
            //         scene.getEngine().getRenderWidth(),
            //         scene.getEngine().getRenderHeight()
            //     ))

            //     shaderMaterial.setVector4("iMouse", BABYLON.Vector4.Zero())
            // }


            // return {
            //     material: shaderMaterial,
            //     dispose: () => {
            //         shaderMaterial.dispose()
            //     },
            //     onRender
            // }

        }
    }
}

// function textureFromPostProcess(shaderPass: ShaderPass): string | undefined {

//     return shaderPass.uniforms
//         .reduce<string | undefined>((foundID, next) => {
//             switch (next.__typename) {
//                 case "UniformBuffer":
//                     if (foundID) {
//                         throw new Error("Expected to find only one texture for input.")
//                     }
//                     return next.bufferID
//             }
//             return undefined
//         }, undefined)
// }

export type RenderTextureOutput = (method: "read" | "write") => BABYLON.RenderTargetTexture

function makeBuffer(
    layerID: LayerShaderID,
    shaderPass: GraphQL.ShaderPassFragment,
    postProcesses: Record<string, ModelType>,
    camera: BABYLON.Camera,
    scene: BABYLON.Scene,
    context: GlobalRenderContext
): BufferModel {

    if (shaderPass.bufferID === undefined) {
        throw new Error("Expected Buffer to have a bufferID")
    }


    var {
        outputTexture,
        dispose: disposeRenderTargetTextures,
        swap: swapTextureTargets } = makeBufferRenderTargetTexture(scene)


    const {
        primitiveUniforms: _primitiveUniforms,
        textureUniformMap } = prepareUniforms({
            layerID,
            shaderPass,
            currentTextureOutput: outputTexture,
            postProcesses,
            scene,
            context
        })
    let primitiveUniforms = _primitiveUniforms

    const effectWrapper = new BABYLON.EffectWrapper({
        engine: scene.getEngine(),
        fragmentShader: shaderPass.fragmentGLSL,
        uniformNames: SHADERTOY_UNIFORMS,
        samplerNames: SHADER_TOY_SAMPLERS,
        name: "effect wrapper"
    });


    const prepareUniformProps: PrepUniformProps = {
        layerID,
        shaderPass,
        postProcesses,
        scene,
        context
    }

    const bindingDispoable = context.binding.onBindingChanged.add(() => {
        bindTextureUniformsToEffect(
            layerID,
            textureUniformMap,
            context)

        primitiveUniforms = createPrimitiveUniforms(prepareUniformProps)

    })

    //Feed the last frame into the Wrapper being rendered this frame
    effectWrapper.onApplyObservable.add(() => {
        const { frame } = context.update.get()

        //effectWrapper.effect.setTexture("iChannel0", outputTexture("read"));
        effectWrapper.effect.setInt("iFrame", frame)
        //effectWrapper.effect.setFloat("iTime", sharedTime)
        effectWrapper.effect.setVector3("iResolution", new BABYLON.Vector3(
            scene.getEngine().getRenderWidth(),
            scene.getEngine().getRenderHeight()
        ))

        effectWrapper.effect.setVector4("iMouse", BABYLON.Vector4.Zero())

        Object.entries(textureUniformMap).forEach(([key, value]) => {
            //   console.log(`readBuffer:${value().name}`)
            effectWrapper.effect.setTexture(key, value.texture())
        })

        applyPrimitiveUniformsToEffect(effectWrapper.effect, primitiveUniforms)
    });

    const renderer = new BABYLON.EffectRenderer(scene.getEngine());

    const observer = scene.onBeforeCameraRenderObservable.add(() => {
        renderer.render(effectWrapper, outputTexture("write"));
        // console.log(`writeing:${outputTexture("write").name}`)
        swapTextureTargets()

    });

    return {
        kind: "buffer",
        bufferID: shaderPass.bufferID,
        effect: effectWrapper,
        renderTarget: {
            dispose: disposeRenderTargetTextures,
            get: {
                kind: "buffer",
                texture: () => outputTexture("read")
            }
        },
        renderer,
        dispose() {
            bindingDispoable.dispose()
            this.effect.dispose()
            this.renderTarget.dispose()
            this.renderer.dispose()
            scene.onBeforeCameraRenderObservable.remove(observer)
        }
    }
}



function makeBufferRenderTargetTexture(scene: BABYLON.Scene) {
    let pingpong = 0

    const width = scene.getEngine().getRenderWidth()
    const height = scene.getEngine().getRenderHeight()

    //Create the two textures to write to
    const rttA = new BABYLON.RenderTargetTexture("a", { width, height }, scene, false, undefined, BABYLON.Constants.TEXTURETYPE_FLOAT)
    const rttB = new BABYLON.RenderTargetTexture("b", { width, height }, scene, false, undefined, BABYLON.Constants.TEXTURETYPE_FLOAT)

    const outputTexture: RenderTextureOutput = (method) => {
        if (pingpong) {
            if (method === "read")
                return rttA
            else
                return rttB
            //&& pingpong) || (method === "write" && !pingpong) ? rttA : rttB;
        } else {
            if (method === "read")
                return rttB
            else
                return rttA
        }
    }
    return {
        outputTexture,
        swap: () => { pingpong ^= 1 },
        dispose: () => {
            rttA.dispose()
            rttB.dispose()
        }
    }
}

function makePrimary(props: {
    shaderID: string,
    shaderType: GraphQL.ShaderType,
    layerID: LayerShaderID,
    shaderPass: GraphQL.ShaderPassFragment,
    postProcesses: ShaderPassMap,
    camera: BABYLON.Camera,
    scene: BABYLON.Scene,
    context: GlobalRenderContext,
}): PrimaryModal {

    const {
        shaderID,
        shaderPass,
        postProcesses,
        camera,
        scene,
        context,
        layerID
    } = props

    const fragmentID = `${shaderID}_${shaderPass.name}_`


    const prepareUniformProps: PrepUniformProps = {
        layerID,
        shaderPass,
        postProcesses,
        scene,
        context
    }

    const {
        primitiveUniforms: _primitiveUniforms,
        textureUniformMap,
        uniformNames: names
    } = prepareUniforms(prepareUniformProps)
    let primitiveUniforms = _primitiveUniforms

    BABYLON.Effect.ShadersStore[`${fragmentID}FragmentShader`] = shaderPass.fragmentGLSL
    const ratio = 0.5

    const postProcess = new BABYLON.PostProcess(`${layerID.compositeKey}_${fragmentID}`,
        fragmentID,
        [
            ...STANDARDUNIFORMS,
            ...SHADERTOY_UNIFORMS,
        ],
        SHADER_TOY_SAMPLERS,
        ratio,
        camera);

    //applyTextureUniforms(shaderID, pass, shaderMaterial, scene)
    //applyBufferUniforms()
    //postProcess.uni


    const bindingDispoable = context.binding.onBindingChanged.add(() => {
        bindTextureUniformsToEffect(
            layerID,
            textureUniformMap,
            context)

        primitiveUniforms = createPrimitiveUniforms(prepareUniformProps)
    })

    const onCommonApply = (effect: BABYLON.Effect) => {
        // effect.setTexture("iChannel0", texture)
        const { frame, time } = context.update.get()
        effect.setInt("iFrame", frame)

        //effect.setFloat("iTime", sharedTime)
        effect.setVector3("iResolution", new BABYLON.Vector3(
            scene.getEngine().getRenderWidth(),
            scene.getEngine().getRenderHeight()
        ))

        effect.setVector4("iMouse", BABYLON.Vector4.Zero())
        effect.setFloatArray("iChannelTime", [time, time, time, time])

        Object.entries(textureUniformMap).forEach(([key, value]) => {
            effect.setTexture(key, value.texture())
        })

        // Bind
        applyPrimitiveUniformsToEffect(effect, primitiveUniforms)
    }

    // let id = 0
    // let postProcessSelf: BABYLON.PostProcess | undefined
    // if (shaderAsInput && shaderAsInput.inputID === shaderPass.outputID) {
    //     postProcessSelf = makePostProcess("self")

    //     postProcessSelf.onApply = (effect) => {
    //         // bindTextureUniformsToEffect(textureUniformMap, effect)

    //         effect.setTextureFromPostProcess(shaderAsInput.name, postProcess);
    //         if (postProcessSelf) {
    //             if (id == 0) {
    //                 shaderAsInput.process = postProcessSelf
    //                 postProcesses["temp:" + shaderPass.name] = postProcess
    //                 postProcesses[shaderPass.name] = postProcessSelf

    //             } else {
    //                 shaderAsInput.process = postProcess
    //                 postProcesses["temp:" + shaderPass.name] = postProcessSelf
    //                 postProcesses[shaderPass.name] = postProcess
    //             }
    //             id = id == 0 ? 1 : 0
    //         }


    //         onCommonApply(effect)
    //     }

    //     postProcesses["temp:" + shaderPass.name] = postProcessSelf
    // }

    postProcess.onApply = (effect) => {
        sharedTime += 0.015
        // bindTextureUniformsToEffect(textureUniformMap, effect)

        // if (shaderAsInput) {
        //     if (shaderAsInput.inputID === shaderPass.outputID && postProcessSelf) {
        //         effect.setTextureFromPostProcess(shaderAsInput.name, postProcessSelf);
        //     } else {
        //         const fresh = createBufferAsInput(shaderPass, shaderPasses, postProcesses)
        //         // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        //         effect.setTextureFromPostProcess(shaderAsInput.name, fresh!.process);
        //     }
        // }

        onCommonApply(effect)
    }

    postProcess.getEffect().onErrorObservable.add((error) => {
        console.error(error)
    })

    postProcess.getEffect().onError = (effect, errors) => {
        console.error("Shader Error", errors)
    }

    return {
        kind: "primary",
        postProcess: postProcess,
        dispose() {
            this.postProcess.getEffect().dispose()
            this.postProcess.dispose()
            bindingDispoable.dispose()
        }
    }

}


function bindTextureUniformsToEffect(
    layerID: LayerShaderID,
    textureUniformMap: UniformTextureMap,
    context: GlobalRenderContext) {
    Object.entries(textureUniformMap).forEach(([uniformName, texture]) => {
        switch (texture.kind) {
            case "audio": {
                const b = context.binding.bindTargetToAudio({
                    compositeID: IdentifierFactory.makeUniformIdentifier(layerID, uniformName).compositeKey,
                    prepare: texture.texture
                })
            }
        }
    })
}

