import { DefaultNodeModel } from "@projectstorm/react-diagrams";
import { generateBindableTargets } from "src/components/Cinema/Bindable/models";
import { CinemaState } from "src/store/cinema";
import { NodeEditorContext } from "../NodeEditorContext";
import { CinemaNodeModel } from "../factories/CinemaNode";
import { AudioTargetPortModel, UniformTargetPortModel } from "../factories/PortModels";

export function makeTargets(cinemaState: CinemaState,
    context: NodeEditorContext): DefaultNodeModel[] {
    const rows = generateBindableTargets(cinemaState)
    return rows.map((row, index) => {

        const node = new CinemaNodeModel(row.layer.info.layerName, 'rgb(0,192,255)');

        row.uniforms.forEach(uniform => {
            switch (uniform.kind) {
                case "audio":
                    node.addPort(new AudioTargetPortModel(
                        uniform.layerUniform,
                        uniform.uniformName,
                        context))
                    break
                default:
                    node.addPort(new UniformTargetPortModel(
                        uniform.layerUniform,
                        uniform.uniformName,
                        context))
                    break
            }

        })

        node.setPosition(600, 25 + 100 * index);
        return node
        // return ensure<Node<BindableTargetData>>({
        //     id: row.layer.layerName,
        //     type: 'shader',
        //     data: row,
        //     position: { x: 400, y: 100 * index },
        // });
    });

}