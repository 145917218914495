
import { Slider } from '@mui/material';
import React, { useState } from 'react';

interface Props {
    value: number,
    rangeMin: number,
    rangeMax: number

    onChange: (value: number) => void
}


export const ModulatingSlider: React.FC<Props> = (props) => {

    const [defaultValue] = useState(props.value)

    return <Slider
        size="small"
        defaultValue={defaultValue}
        max={props.rangeMax}
        min={props.rangeMin}
        step={(props.rangeMax - props.rangeMin) / 100}
        aria-label="Small"
        valueLabelDisplay="auto"
        onChange={(evt, value) => {
            evt.stopPropagation()
            if (typeof value === 'number') {
                props.onChange(value)
            }
        }}

        // style={{ width: "100px" }}
    />

}
