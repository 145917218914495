import './styles/globals.css'
import React from "react";
import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './Home';
import { getNavigation } from 'src/components/SampleRow/lib/content';
//require( 'console-stamp' )( console );


function MyApp({ }: any) {
  // React.useEffect(() => {
  //   // Remove the server-side injected CSS.
  //   const jssStyles = document.querySelector('#jss-server-side');
  //   if (jssStyles) {
  //     jssStyles.parentElement!.removeChild(jssStyles);
  //   }
  // }, []);
  return <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    {/* <Component {...pageProps} /> */}
    <Home categories={getNavigation()} />
  </ThemeProvider>
}

export default MyApp
