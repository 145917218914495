import React from 'react';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import { DragDescriptions, DragTrackDescription } from '../dragTypes';


/* eslint-disable no-use-before-define */  // --> OFF
/* eslint-disable react/prop-types */  // --> OFF
/* eslint-disable react/display-name */  // --> OFF

export type NFTRenderItemArgs = {
  dragOverlay: boolean;
  dragging: boolean;
  sorting: boolean;
  index: number | undefined;
  fadeIn: boolean;
  listeners: DraggableSyntheticListeners;
  ref: React.ForwardedRef<HTMLLIElement>;
  style: React.CSSProperties | undefined;
  transform: Props['transform'];
  transition: Props['transition'];
  value: Props['value'];
  dragData?: DragDescriptions
  removeItem?(trackId:string): void
  payload: DragTrackDescription
}

export interface Props {
  dragOverlay?: boolean;
  disabled?: boolean;
  dragging?: boolean;
  index?: number;
  fadeIn?: boolean;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  transform?: Transform | null;
  value: React.ReactNode;
  renderItem?(args: NFTRenderItemArgs): React.ReactElement;
  dragData?: DragDescriptions,
  removeItem?: any
  payload?: any
}


export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        dragOverlay,
        dragging,
        fadeIn,
        index,
        listeners,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        dragData,
        removeItem,
        payload
      },
      ref
    ) => {
      // useEffect(() => {
      //   if (!dragOverlay) {
      //     return;
      //   }

      //   document.body.style.cursor = 'grabbing';

      //   return () => {
      //     document.body.style.cursor = '';
      //   };
      // }, [dragOverlay]);

      //console.log("value:" + value)

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
          dragData,
          removeItem,
          payload
        })
      ) : null
    }
  )
);

/* eslint no-use-before-define: 2 */  // --> ON