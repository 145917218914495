import * as React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { assert, ensure } from 'src/utils';
import { DragSampleDescription, DragType } from '../DragCore/dragTypes';
import { SampleCardPackFileRow } from './SampleCardPackFileRow';
import * as GraphQL from "src/generated/graphqlTypes"
import { SamplePackID } from 'src/store/identifiers/identifiers';
import { BusyView } from '../Busy';

type Props = {
  samplePackID: SamplePackID
}

export const SampleCardLoader: React.FC<Props> = ({ samplePackID }) => {

  const [result] = GraphQL.useFetchSamplePackQuery({ variables: { samplePackID: samplePackID.packID } })

  if (result.fetching) {
    return (
      // <Card className={classNames(style.EmptyContainer)}>
      //   <Spinner />
      // </Card >)
      <div className="relative w-[275] h-44 flex flex-col bg-slate-300 rounded-2xl items-start justify-center">
        <BusyView />
      </div>)
  }

  const samplePack = result.data?.samples?.fetchSamplePack
  assert(samplePack !== undefined, "Failed to find sample pack")

  return <SampleCard samplePack={samplePack} />
}

export const SampleCard: React.FC<{ samplePack: GraphQL.SamplePackFragment }> = ({ samplePack }) => {
  return (
    <div className="w-72 max-w-xs h-fit flex flex-col bg-slate-300 rounded-3xl"  >
      <img
        className='h-[120px] rounded-t-2xl'
        src={samplePack.imageURL} />
      <div >
        <p className='text-base pl-1'>{samplePack.name}</p>
        <div className="flex p-1 justify-left">
          {samplePack.bpm ? (
            <p className='text-sm'>{`${samplePack.bpm} BPM`}&nbsp;</p>
          ) : null}
          <p className='text-sm'>
            {samplePack.samples.length} stems
          </p>
        </div>
      </div>
      {samplePack.samples.map(samplePackFile => {
        return (<SamplePackCardRowEntry
          key={samplePackFile.audioURL}
          samplePackFile={samplePackFile} />)
      })}
    </div>
  )
}

export type SampleCardPackFileRowProps = {
  samplePackFile: GraphQL.SampleFileFragment,
  listeners?: any,
  attributes?: any,
  setNodeRef?: (element: HTMLElement) => void
}

const SamplePackCardRowEntry: React.FC<{
  samplePackFile: GraphQL.SampleFileFragment
}> = ({ samplePackFile }) => {

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: samplePackFile.id,

    data: ensure<DragSampleDescription>({
      type: DragType.RAW_SAMPLE,
      id: samplePackFile.id,
      payload: {
        sampleFile: samplePackFile
      }
    })
  });

  return (
    <SampleCardPackFileRow
      samplePackFile={samplePackFile}
      listeners={listeners}
      attributes={attributes}
      setNodeRef={setNodeRef}
    />
  )
}
