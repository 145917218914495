import classNames from 'classnames';
import React from 'react';
import { CharacterModel } from 'src/store/cinema';
import styles from "../../Cinema.module.scss";
import { Typography } from '@mui/material';


type ModelBodyViewProps = {
    model: CharacterModel
    onSelect: () => void
    selected: boolean
};
export const ModelBodyView: React.FC<ModelBodyViewProps> = ({ model, selected, onSelect }) => {

    return (
        <div className={classNames(styles.ShaderSmallView, selected && styles.Selected)} onClick={onSelect}>
            <Typography>Model</Typography>
            <div className={classNames(styles.ShaderImageEdit)}>
                <img
                    style={{
                        aspectRatio: "1",
                        height: "60px"
                    }}
                    src={"/icons/3d-cube.svg"} />
                {/* <IconButton color="primary" component="span" size='small'
                    onClick={() => {
                        //
                    }}>
                    <EditIcon />
                </IconButton> */}
            </div>
        </div>);
};
