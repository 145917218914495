import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react"
import { useFetchMySamplesQuery } from "src/generated/graphqlTypes";
import { useAppContext } from "src/store/hooks";
import { BusyView } from "../Busy";
import { flattenSamples } from "./models";
import SampleCardUser from "./SampleCardUser";
import { DragAndDropSample } from "./UploadSample/DragAndDropSample";



type SamplesUserProps = {
    //
}


const SamplesUser: React.FC<SamplesUserProps> = observer((props) => {

    const [data, request] = useFetchMySamplesQuery()
    const { uiState, connect } = useAppContext()
    const dirty = uiState.mySampleDirty

    React.useEffect(() => {
        if (dirty) {
            request({
                requestPolicy: "network-only"
            })
            runInAction(() => {
                uiState.mySampleDirty = false
            })
        }
    }, [dirty])

    if (connect.isConnected === false) {
        return <div>Not connected</div>
    }

    if (data.fetching) {
        return <div>Loading...</div>
    }

    if (data.error) {
        return <div>Oh no... {data.error.message}</div>
    }
    const samples = data.data?.samples.fetchMySamples?.samples

    if (!samples) {
        return <div>No samples</div>
    }


    const sampleModes = flattenSamples(samples)

    return (
        <div className="w-full h-full overflow-clip pt-1">
            <div className="relative w-full h-full overflow-auto">
                <div className="h-fit w-full mb-3 justify-center flex">
                    <DragAndDropSample />
                </div>
                <div className="grid grid-cols-3 gap-4 h-fit">
                    {sampleModes.map((sampleMode, idx) => {
                        return (<SampleCardUser
                            key={idx}
                            sample={sampleMode}
                        />)
                    })}
                </div>
                <BusyView enabled={uiState.uploadSample !== undefined} />
            </div >

        </div>)
})



export default SamplesUser;

