import * as GraphQL from 'src/generated/graphqlTypes';

export type PrimitiveTypes =
    "Color" |
    "F" |
    "Matrix4" |
    "Vector2" |
    "Matrix3" |
    "Vector3" |
    "Vector4"


export type BindableTypes = Extract<PrimitiveTypes, "F" | "Color"> | "Audio"
export type ModulatableType = Extract<BindableTypes, "F" | "Audio">

export function mapUniformPrimitiveType(graphQLPrimitive: GraphQL.UniformPrimitiveType): PrimitiveTypes {
    return graphQLPrimitive
}

export function isModulatableType(primitive: PrimitiveTypes) {
    switch (primitive) {
        case "F":
        case "Color":
            return true
    }

    return false
}