import { graphQLCLient } from "src/core/client";
import { PagesState } from "./store";
import * as GraphQL from 'src/generated/graphqlTypes';
import { deserializeSong } from "./serialization/serialization";

export async function createNewUser(pageState: PagesState) {

    const demoSongResult = await graphQLCLient.query<
        GraphQL.DemoSongQuery>(GraphQL.DemoSongDocument).toPromise()

    const demoSongContents = demoSongResult.data?.serialization.demoSaveFile?.contents
    if (demoSongContents === undefined) {
        return
    }

    deserializeSong(demoSongContents, pageState, { connect: false })
}

