import * as React from 'react';
import styled from '@emotion/styled';
import { DefaultPortModel, DiagramEngine, PortWidget } from '@projectstorm/react-diagrams';

export interface DefaultPortLabelProps {
	port: DefaultPortModel;
	engine: DiagramEngine;
	color?: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace S {
	export const PortLabel = styled.div`
		display: flex;
		margin-top: 1px;
		align-items: center;
	`;

	export const Label = styled.div`
		padding: 0 5px;
		flex-grow: 1;
	`;

	
    export const Port = styled.div<{ color: string }>`
		width: 15px;
		height: 15px;
		background: ${(p) => p.color};

		&:hover {
			background: rgb(192, 255, 0);
		}
	`
}

export const DefaultPortLabel: React.FC<DefaultPortLabelProps> = (props) => {
	const _color = props.color || `rgba(255, 192, 0, 0.1)`
	const port = (
		<PortWidget engine={props.engine} port={props.port}>
			<S.Port color={_color} />
		</PortWidget>
	);

	const label = <S.Label>{props.port.getOptions().label}</S.Label>;

	return (
		<S.PortLabel>
			{props.port.getOptions().in ? port : label}
			{props.port.getOptions().in ? label : port}
		</S.PortLabel>
	);
}
