
import * as GraphQL from "src/generated/graphqlTypes"
import { search } from "../cinema/search";
import { LayerUniformID, PrimitiveModulationID, TrackEnvelopeID, TrackID } from "../identifiers/identifiers";
import { FrogValueTypes } from "src/render/visual/shaders/shaderFrog/model";
import { BindingLoader } from "src/render/sequencing/types";
import { PagesState } from "../store";
import { makeAutoObservable } from "mobx";
import { list, serializable } from "serializr";
import { union } from "../utils/serializrExt"

export type ModulationKinds = (AudioModulation | PrimitiveModulation)["kind"];

export type AudioModulation = {
    kind: "AudioBinding";
    source: TrackID,
    target: LayerUniformID
}

export type PrimitiveModulation = {
    kind: "PrimitiveBinding";
    source: TrackEnvelopeID,
    target: LayerUniformID,
    modScale: number,
    behavior?: "offset" | "scale"
}

export type ModulationTypes =
    AudioModulation |
    PrimitiveModulation;

export const modulationTypesEquals = (l: ModulationTypes, r: ModulationTypes): boolean => {
    return l.kind === r.kind &&
        l.source.compositeKey === r.source.compositeKey &&
        l.target.compositeKey === r.target.compositeKey;
};


export class ModulationState {
    constructor() {
        makeAutoObservable(this)
    }

    @serializable(list(union<ModulationTypes, "kind">("kind")))
    modulations: ModulationTypes[] = []

    removeModulation(mod: ModulationTypes) {
        const newModulations = this.modulations.filter(x => !modulationTypesEquals(x, mod))
        if (newModulations.length === this.modulations.length) {
            throw new Error(`Failed to remove any modulations at all`)
        }
        this.modulations = newModulations
    }


    addModulation(mod: ModulationTypes) {
        this.modulations.push(mod)
    }

}


export function makeBindingLoader(getState: () => PagesState): BindingLoader {
    return {
        getPrimitive(id, uniform: GraphQL.UniformPrimitive) {
            switch (id.kind) {
                case "LayerMaterialIdentifier":
                case "LayerShaderIdentifier":
                    const shader = search.findShaderOnLayer(id, getState().cinemaState)
                    if (shader === undefined) {
                        throw new Error("Failed to find shader on layer " + id.compositeKey)
                    }

                    const localUniform = shader.uniforms[uniform.name]
                    if (localUniform.kind !== "PrimitiveUniform") {
                        throw new Error(`Expected uniform to be of kind Primitive. Found ${localUniform.kind}`)
                    }

                    return localUniform.value as FrogValueTypes
                default:
                    throw new Error("Don't support layerID " + id.compositeKey)
            }
        },
        getModValue(id: PrimitiveModulationID): number {
            const mod = search.findPrimitiveModulationRequired(id, getState().modulationState)
            return mod.modScale
        }
    }
}

export function parsePrimitiveFloat(value: GraphQL.Scalars["UniformPrimitiveValue"]): number {
    let v: number
    if (typeof value === "string") {
        v = Number.parseFloat(value)
    } else if (typeof value === "number") {
        v = value
    } else {
        throw new Error("Unexpected scalar type for float primitive:" + typeof value)
    }
    return v
}