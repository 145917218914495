import React, { } from "react"
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams';

import { AudioModSourcePortModel } from './PortModels';
import { BindableTargetIdentifier } from "../NodeEditorContext"
import { useAppContext } from "src/store/hooks";
import { observer } from "mobx-react-lite";
import { S } from "./CommonCSS";
import { WaveGrid } from "src/components/SampleRow/WaveGrid/WaveGrid";
import TrackADSRWindow from "src/components/SampleRow/TrackADSRWindow";

export interface DefaultPortLabelProps {
	port: AudioModSourcePortModel;
	portID: BindableTargetIdentifier
	engine: DiagramEngine;
	color?: string
}



const ModulationOutputPortLabel: React.FC<DefaultPortLabelProps> = observer(({ ...props }) => {

	const state = useAppContext()

	const trackInfo = state.sounds.findTrack(props.port.identifier)


	const _color = props.color || `#340`
	const port = (
		<PortWidget engine={props.engine} port={props.port}>
			<S.Port color={_color} />
		</PortWidget>
	);

	const label = <S.Label>{props.port.getOptions().label}</S.Label>;

	return (
		<div className="flex flex-row items-center">
			{/* <S.VerticalFlex style={{ width: "100%" }}> */}

			<div className="flex flex-col gap-1 mb-1 mr-1">
				<div className="self-center h-px bg-slate-600 w-full" />
				<div className="self-center">{label}</div>
				<WaveGrid barToShow={0} trackInfo={trackInfo} />
				<div className="self-center">
					<TrackADSRWindow trackInfo={trackInfo} />
				</div>
			</div>
			{/* </S.VerticalFlex> */}
			{port}
		</div>
	);
})




export default ModulationOutputPortLabel