import React from "react"
import { useEffect, useState } from "react"
import { Spinner } from "src/svg"



export const BusyView: React.FC<{ enabled?: boolean }> = ({ enabled = true }) => {

    const [visible, setVisible] = useState(enabled)

    useEffect(() => {
        if (enabled != visible) {
            setVisible(enabled)
        }
    }, [enabled])

    if(visible == false) return null

    return <div className="absolute flex w-full h-full justify-center items-center bg-slate-500 opacity-25 top-0">
        <Spinner className="h-12 w-12" />
    </div>

}