import React from 'react';
import { SamplePackList } from './SamplePackList';
import { SelectedSamplePack } from './SelectedSamplePack';



export const SampleSearchView: React.FC = () => {

    //return (<SamplePackList />)
    return (
        <div className='flex flex-row h-full w-full overflow-clip pt-1'>
            <SelectedSamplePack />
            <SamplePackList />
        </div>
    )
}