import * as BABYLON from "@babylonjs/core";
import { Disposable } from "src/utils/disposable";
import { GizmosUpdateState } from "./RenderManager";

export class GizmosState implements Disposable {
    public observable = new BABYLON.Observable<GizmosUpdateState>();
    public gizmoDisposable?: Disposable;
    constructor() {
        //
    }
    dispose() {
        this.gizmoDisposable?.dispose();
        this.observable.clear();
    }
}
