import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { DefaultNodeModel, DefaultNodeWidget } from '@projectstorm/react-diagrams';

import { LoopBasePortModel } from './PortModels';
import CinemaPortLabel from './CinemaPortLabelWidget';

class CinemaNodeWidget extends DefaultNodeWidget {

    generatePort = (port: LoopBasePortModel) => {
        return <CinemaPortLabel
            engine={this.props.engine}
            port={port}
            portID={port.identifier as any}
            key={port.getID()}
        />
    }


}

export class CinemaNodeModel extends DefaultNodeModel {
    constructor(name?: string, color?: string) {
        super({
            color,
            name,
            type: "cinema"
        })
    }
}

export class CinemaNodeFactory extends AbstractReactFactory<CinemaNodeModel, DiagramEngine> {
    constructor() {
        super('cinema');
    }

    generateReactWidget(event: any): JSX.Element {
        return <CinemaNodeWidget engine={this.engine} node={event.model} />;
    }

    generateModel(event: any) {
        return new CinemaNodeModel();
    }


}
