
import * as React from 'react';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import { DemoCanvasWidget } from './DemoCanvasWidget';
import { makeEngine } from './models/Engine';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { useAppContext } from 'src/store/hooks';


export const NodeEditor = () => {


	const state = useAppContext()

	const [engine, setEngine] = React.useState<DiagramEngine | undefined>(undefined)

	React.useEffect(() => {
		if (!engine) {
			setEngine(makeEngine(state))
		}
	}, [engine])

	if (!engine) {
		return null
	}

	//6) render the diagram!
	return (
		<div style={{ height: "100%", width: "100%" }}
			onMouseUp={(event) => {
				//
			}}
		>
			<DemoCanvasWidget>
				<CanvasWidget engine={engine}

				/>
			</DemoCanvasWidget>
		</div>
	);
};

