import { action } from "mobx";
import { PagesState } from "../store";



export class ConnectionActions {

    constructor(
        private state: PagesState
    ) {

    }

    disconnect = action(() => {
        this.state.reset()
        this.state.connect.disconnect()
    })
}


