import React from 'react';
import { AnimateLayoutChanges, NewIndexGetter, useSortable } from '@dnd-kit/sortable';
import { RenderItemArgs } from './NFTItem';
import { Data } from '@dnd-kit/core/dist/store';
import { DragTrackDescription } from './dragTypes';


export interface SortableItemProps {
  animateLayoutChanges?: AnimateLayoutChanges;
  disabled?: boolean;
  getNewIndex?: NewIndexGetter;
  id: string;
  data?: Data,
  index: number;
  handle?: boolean;
  useDragOverlay?: boolean;
  removeItem?: (trackID: string)=> void;
  //style?: (values: any) => React.CSSProperties;
  renderItem?: (args: RenderItemArgs) => React.ReactElement;
  payload: DragTrackDescription
  // wrapperStyle?: ({
  //   index,
  //   isDragging,
  //   id,
  // }: {
  //   index: number;
  //   isDragging: boolean;
  //   id: string;
  // }) => React.CSSProperties;
}


export function NFTSortableItem({
  disabled,
  animateLayoutChanges,
  getNewIndex,
  id,
  data,
  index,
  renderItem,
  useDragOverlay,
  removeItem,
  payload,
}: SortableItemProps) {
  const {
    isDragging, isSorting, listeners, setNodeRef, transform, transition,
  } = useSortable({
    id,
    data,
    animateLayoutChanges,
    disabled,
    getNewIndex,
  });

  return (renderItem ? renderItem({
    ref: setNodeRef,
    value: id,
    payload: payload,
    fadeIn: true,
    dragging: isDragging,
    sorting: isSorting,
    index,
    style: undefined,
    transform,
    transition: (!useDragOverlay && isDragging ? 'none' : transition),
    listeners,
    dragOverlay: (!useDragOverlay && isDragging),
    removeItem,
  }) : null
  );
}
