
import classNames from 'classnames';
import React, { useState } from 'react';
import { LayerNameKeys, allCinemaLayerNames } from 'src/store/cinema';
import { LayerNameToKindMap } from 'src/store/cinema/utils';
import { IdentifierFactory, LayerID } from 'src/store/identifiers/identifiers';
import styles from "../Cinema.module.scss"
import { CinemaLayer } from './CinemaLayer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CinemaLayerProps {
    //
}

export type EditorLayerDetails = {
    layerID: LayerID
}


export const CinemaLayerLane: React.FC<CinemaLayerProps> = ({ }) => {

    console.log("Redraw")

    const layers: EditorLayerDetails[] = allCinemaLayerNames.map((layerName): EditorLayerDetails => {
        return {
            layerID: IdentifierFactory.makeLayerID({
                layerKind: LayerNameToKindMap[layerName],
                layerName,
            })
        }
    })

    const [selectedID, setSelectedID] = useState<LayerNameKeys | undefined>(undefined)

    
    return (
        <div className={classNames(styles.LayerLane)} >
            {layers.map((layer, index: number) => {
                return makeLayer({
                    layer,
                    index
                })
            })}
        </div>
    )
}

interface MakeLayerProps {
    layer: EditorLayerDetails
    index: number
}

function makeLayer(props: MakeLayerProps) {

    const { layer, index } = props

    return (<CinemaLayer
        layer={layer}
        key={index}
        //layer={layer.layerInfo}
        currentID={layer.layerID.layerName}

    />)
}


// const factory = (layer: Layers) => {
//     switch (layer.kind) {
//         case "empty":
//             return <CinemaEmptyLayer
//                 layerType={layer.layerType}
//                 layerName={layer.layerName}
//             />
//         case "layer":
//             switch (layer.layerInfo.kind) {
//                 case "postProcessLayer":
//                     return (<PostProcessLayerView layer={layer.layerInfo} />)
//                 case "backgroundLayer":
//                     return (<BackgroundProcessLayerView layer={layer.layerInfo} />)
//                 case "forgroundModelLayer":
//                     return (<ForegroundModelLayerView layer={layer.layerInfo} />)
//                 default:
//                     return null
//             }
//     }
// }
