
interface Props {
    pinned: boolean;
}

export const classItemBindable = (props: Props) => {
    return `
    flex
    flex-col
    mb-1
    pl-1
    ${props.pinned ? "bg-selected" : "bg-control"}`
}