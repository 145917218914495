import createEngine, { DefaultDiagramState, DiagramModel } from '@projectstorm/react-diagrams';

import { makeSources } from './Sources';
import { makeTargets } from './Targets';
import { AudioLinkFactory } from '../factories/AudioLinkModel';
import { TrackNodeFactory } from '../factories/TrackNode';
import { makeLinks } from './Links';
import { makeContext } from '../NodeEditorContext';
import { CinemaNodeFactory } from '../factories/CinemaNode';
import { PagesState } from 'src/store/store';


export function makeEngine(
	state: PagesState
) {

	const engine = createEngine({
		registerDefaultZoomCanvasAction: false,
		//registerDefaultDeleteItemsAction:false
	});

	const stateMachine = engine.getStateMachine().getCurrentState();
	engine.setMaxNumberPointsPerLink(0)

	if (!(stateMachine instanceof DefaultDiagramState)) {
		throw new Error("Expected state to be of type DefaultDiagramState")
	}

	stateMachine.dragNewLink.config.allowLooseLinks = false;

	const context = makeContext({
		onDragEnabled: (enabled) => {
			stateMachine.dragCanvas.config.allowDrag = enabled
		},
		state
	})

	engine.getLinkFactories().registerFactory(new AudioLinkFactory(context));
	engine.getNodeFactories().registerFactory(new TrackNodeFactory());
	engine.getNodeFactories().registerFactory(new CinemaNodeFactory());

	engine.getPortFactories();

	const model = new DiagramModel();

	context.enableEvents(false)
	model.addAll(...makeSources(state.sounds.tracks, context));
	model.addAll(...makeTargets(state.cinemaState, context));
	model.addAll(...makeLinks(model, state.modulationState, context));
	context.enableEvents(true)


	//5) load model into engine
	engine.setModel(model);
	return engine;
}



