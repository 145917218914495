import React, { useCallback } from "react";
import { useDropzone } from 'react-dropzone'
import { useLoopActions } from "src/render/hooks";

const MAX_FILE_SIZE = 1024 * 1024 * 16 //4 megs

export const DragAndDropSample: React.FC = () => {

    const { uiActions } = useLoopActions()

    const uiStateAction = useLoopActions().uiActions

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const tooLargeFiles = acceptedFiles.find((file: File) => file.size > MAX_FILE_SIZE)
        // Do something with the files
        if (tooLargeFiles) {
            uiActions.postAlert("File size too large", "The file you selected is too large. Please select a file less than 4MB in size.")
            return
        }

        console.log("start uploading")
        uiStateAction.uploadSamples(acceptedFiles).then(() => {
            console.log("done uploading")
        }).catch((err) => {
            console.log("error uploading", err)
        })

    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    console.log("rendering drag and drop: " + isDragActive)
    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={`w-96 h-16 bg-slate-400 p-2 cursor-pointer hover:bg-green-700 ${isDragActive ? "bg-green-700" : ""}`}>
                <div className="flex flex-row w-full h-full border border-dashed border-black bg-slate-7 items-center justify-center">
                    <img className="w-12 h-12 opacity-30 mr-1" src={"/icons/download.svg"} />
                    <p className="font-bold text-base">Choose a file&nbsp;</p><p className="text-base">or drag it here</p>
                </div>
            </div>
        </div>)
}

