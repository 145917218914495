import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useAppContext } from "src/store/hooks";

import { GizmosRowView } from "../Cinema/Gizmos/GizmosRowView";
import SceneComponent from "./SceneComponent";


const AnimationWindow: React.FC<{ style?: React.CSSProperties }> = observer(({ }) => {

    const uiState = useAppContext().uiState
    const selectedElement = uiState.selectedElements.selectedElement
    const isModelSelected = selectedElement?.kind === "LayerModelIdentifier"

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                //height: "100%",
                width: "100%",
                marginBottom: "5px",
                border: "1px solid black",
            }}>
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
                <SceneComponent
                    id="my-canvas"
                />
                {/* <canvas
                    style={{
                        height: "100%", width: "100%",
                        backgroundColor: "orange",
                    }}
                /> */}
            </div>

            <GizmosRowView
                enabled={isModelSelected}
                onToggleGizmos={action((gizmosType) => {
                    uiState.selectedElements.gizmosState = gizmosType
                })}
                selectedGizmo={uiState.selectedElements.gizmosState}
            />
        </div>
    )
})

export default AnimationWindow