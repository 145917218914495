
import * as BABYLON from "@babylonjs/core";
import { Disposable } from "./disposable";


export interface Observable<T> {
    add(callback: (data: T) => void): Disposable
    addOnce(callback: (data: T) => void): Disposable
}

export interface Emitter<T> extends Disposable {
    clear(): void
    notify(value: T): void
}


export function makeObservable<T>(): [Observable<T>, Emitter<T>] {
    const obs = new ObservableImplementation<T>()
    return [obs, obs]
}

class ObservableImplementation<T> implements Observable<T>, Emitter<T> {
    private observable = new BABYLON.Observable<T>()

    add(callback: (data: T) => void): Disposable {

        const observer = this.observable.add((eventData) => {
            callback(eventData)
        })

        return {
            dispose: () => {
                this.observable.remove(observer)
            }
        }
    }

    addOnce(callback: (data: T) => void): Disposable {
        const observer = this.observable.addOnce((eventData) => {
            callback(eventData)
        })

        return {
            dispose: () => {
                this.observable.remove(observer)
            }
        }
    }

    notify(value: T): void {
        this.observable.notifyObservers(value)
    }

    clear() {
        this.observable.clear()
    }

    dispose() {
        this.clear()
    }
}



