import React from 'react';
import { Chip, Grid, Link, Typography } from '@mui/material';
import * as GraphQL from 'src/generated/graphqlTypes';

import VisibilityIcon from '@mui/icons-material/Visibility';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ShaderSummary } from 'src/store/cinema';
import { useAppContext } from 'src/store/hooks';
import { observer } from 'mobx-react-lite';
import { BookmarksControl } from './Bookmarks';
import { graphQLCLient } from 'src/core/client';
import { action } from 'mobx';


type Props = {
    //
};

const EmptyPreview = () => {
    return <div className="relative w-96 min-w-24 h-full p-5 border-r border-black" />
}

export const ShaderPreview: React.FC<Props> = observer(() => {

    const previewShader = useAppContext().uiState.selectedElements.selectShaderForPreview

    if (previewShader === undefined) {
        return (<EmptyPreview />)
    } else {
        return <ShaderPreviewConcrete previewShader={previewShader} />
    }
})

const ShaderPreviewConcrete: React.FC<{
    previewShader: ShaderSummary
}> = observer(({ previewShader }) => {

    const [result] = GraphQL.useFetchShaderSummaryQuery({
        variables: {
            shaderID: previewShader.id,
            type: previewShader.type
        }
    })

    if (!result.data) {
        return (<EmptyPreview />)
    }

    const previewShaderGQL = result.data.shader.fetchShader?.summary

    const { userState } = useAppContext()
    const isBookmarked = userState.bookmarkedShaders.has(previewShader.id)
    const isReported = userState.reportedShaders.has(previewShader.id)

    if (!previewShaderGQL) {
        return (<EmptyPreview />)
    }

    return (<div className="relative w-96 min-w-24 h-full p-5 border-r border-black">
        <div className="absolute left-1 top-1 flex flex-col w-full h-full items-start">
            <Typography variant="body2">
                {previewShader.name}
            </Typography>

            <Typography variant="body2">
                #{previewShader.id}
            </Typography>
            {
                previewShaderGQL.author &&
                <Link
                    href={previewShaderGQL.authorURL}
                    variant="body2"
                    underline="always"
                    target="_blank"
                    rel="noreferrer"
                >
                    {previewShaderGQL.author}
                </Link>
            }

            {
                previewShaderGQL.externalURL &&
                (<div style={{ margin: "0px", padding: "0px", display: "flex" }}>
                    <Link href={previewShaderGQL.externalURL}
                        variant="body2"
                        underline="always"
                        target="_blank"
                        rel="noreferrer"
                    >

                        {previewShader.source === GraphQL.ShaderSource.St ? "ShaderToy" : "Original"}
                    </Link>
                    &nbsp;
                    {
                        previewShader.source === GraphQL.ShaderSource.St && (
                            <Typography variant="body2">
                                #{previewShader.id}
                            </Typography>
                        )
                    }
                </div>)
            }

            {
                previewShaderGQL.likes && <RenderKPI type='likes' value={previewShaderGQL.likes} />
            }

            {
                previewShaderGQL.views && <RenderKPI type='views' value={previewShaderGQL.views} />
            }

            {
                previewShaderGQL.mints && <RenderKPI type='mints' value={previewShaderGQL.mints} />
            }

            {
                <Grid container spacing={0} sx={{ backgroundColor: "" }} >
                    {
                        previewShaderGQL.tags && previewShaderGQL.tags.map((t, i) =>
                        (
                            <Grid item key={i} >
                                <Chip label={t} />
                            </Grid>)
                        )
                    }
                </Grid>
            }
        </div>

        <div className="absolute right-1 top-1">
            <BookmarksControl
                bookmarked={isBookmarked}
                onBookmarked={action(() => {

                    if (isBookmarked) {
                        userState.bookmarkedShaders.delete(previewShader.id)
                    } else {
                        userState.bookmarkedShaders.add(previewShader.id)
                    }

                    graphQLCLient
                        .mutation<
                            GraphQL.BookmarkShaderMutation,
                            GraphQL.BookmarkShaderMutationVariables>(GraphQL.BookmarkShaderDocument, {
                                shaderType: previewShaderGQL.type,
                                update: {
                                    bookmarked: !isBookmarked
                                },
                                updateShaderId: previewShaderGQL.id
                            })
                        .toPromise()
                        .then((r) => {
                            console.log(r.data?.shader?.updateShader?.status)
                        })
                })}

                reported={isReported}
                onReported={action(() => {

                    if (isReported) {
                        userState.reportedShaders.delete(previewShader.id)
                    } else {
                        userState.reportedShaders.add(previewShader.id)
                    }

                    graphQLCLient
                        .mutation<
                            GraphQL.ReportShaderMutation,
                            GraphQL.ReportShaderMutationVariables>(GraphQL.ReportShaderDocument, {
                                shaderType: previewShaderGQL.type,
                                reportShaderId: previewShaderGQL.id,
                                report: {
                                    reason: GraphQL.ShaderReportTypes.Crashing
                                }

                            })
                        .toPromise()
                        .then((r) => {
                            console.log(r.data?.shader?.reportShader?.status)
                        })
                })}
            />
        </div>
    </div >);
})


interface KPIProps {
    type: "views" | "mints" | "likes"
    value: number
}

const RenderKPI: React.FC<KPIProps> = ({ type, value }) => {

    type Info = {
        text: string,
        image: ReactJSXElement
    }

    let info: Info


    const makeKPI = (type: KPIProps["type"]): Info => {
        switch (type) {
            case "likes":
                return {
                    text: `${value} likes`,
                    image: (<ThumbUpIcon fontSize='small' />)
                }
            case "mints":
                return {
                    text: `${value} mints`,
                    image: (<MonetizationOnIcon fontSize='small' />)
                }
            case "views":
                return {
                    text: `${value} views`,
                    image: (<VisibilityIcon fontSize='small' />)
                }
        }
    }

    const { image, text } = makeKPI(type)
    return (
        <div
            style={
                {
                    display: "flex"
                }
            }>
            {image}
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
                {text}
            </Typography>
        </div>)
}