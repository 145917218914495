import { isNotNull } from "src/utils";
import { LayerType } from "../cinema/types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Visit {
    export function allShaders(layers: LayerType[]) {
        return layers
            .map(layer => {
                switch (layer.kind) {
                    case "backgroundLayer":
                    case "postProcessLayer":
                        if (layer.shader) {
                            return layer.shader
                        }
                        break
                    case "forgroundModelLayer":
                        if (layer.materialShader) {
                            return layer.materialShader
                        }
                        break
                }
                return undefined
            })
            .filter(isNotNull)
    }
}