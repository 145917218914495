import gql from "graphql-tag"
import * as React from "react"
import * as Urql from "urql"
export type Maybe<T> = T | undefined
export type InputMaybe<T> = T | undefined
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Cursor: any
  DateTime: any
  GSLSShader: any
  SaveFileJSON: any
  UniformPrimitiveValue: any
  Upload: any
}

export type AuthenticationMutations = {
  __typename: "AuthenticationMutations"
  authorize?: Maybe<AuthorizeStatus>
  updateUserName?: Maybe<UpdateUsernameResponse>
}

export type AuthenticationMutationsAuthorizeArgs = {
  publicKey: Scalars["String"]
  signature: Scalars["String"]
}

export type AuthenticationMutationsUpdateUserNameArgs = {
  userName?: InputMaybe<Scalars["String"]>
}

export type AuthorizeFailure = {
  __typename: "AuthorizeFailure"
  errorCode: Scalars["String"]
  publicKey: Scalars["String"]
  signature: Scalars["String"]
}

export type AuthorizeStatus = AuthorizeFailure | AuthorizeSuccess

export type AuthorizeSuccess = {
  __typename: "AuthorizeSuccess"
  publicKey: Scalars["String"]
  signature: Scalars["String"]
}

export type DeleteSamplesResponse = {
  __typename: "DeleteSamplesResponse"
  sampleIDs: Array<Scalars["ID"]>
}

export type DeleteSaveFileResponse =
  | DeleteSaveFileSuccessResponse
  | ErrorResponse

export type DeleteSaveFileSuccessResponse = {
  __typename: "DeleteSaveFileSuccessResponse"
  identifier: Scalars["String"]
}

export type DownloadSampleResponse = {
  __typename: "DownloadSampleResponse"
  oneTimeURL: Scalars["String"]
}

export type ErrorResponse = {
  __typename: "ErrorResponse"
  errorCode?: Maybe<Scalars["String"]>
  errorMessage: Scalars["String"]
  errorType?: Maybe<Scalars["String"]>
}

export type FetchMySamplesResponse = {
  __typename: "FetchMySamplesResponse"
  samples: Array<SampleFile>
}

export type FetchSamplePackResponse = {
  __typename: "FetchSamplePackResponse"
  packs: Array<SamplePack>
  pageInfo: PageInfo
}

export type FetchSampleResponse = {
  __typename: "FetchSampleResponse"
  samples: Array<SampleFile>
}

export type FetchSampleSearchField = {
  mySamples?: InputMaybe<Scalars["Boolean"]>
}

export type GetSaveFileResponse = {
  __typename: "GetSaveFileResponse"
  saveFiles?: Maybe<Array<SaveFile>>
}

export type Mutation = {
  __typename: "Mutation"
  auth: AuthenticationMutations
  samples: SamplesMutations
  serialization: SerializationMutations
  shader: ShaderMutations
}

export type PageInfo = {
  __typename: "PageInfo"
  endCursor?: Maybe<Scalars["Cursor"]>
  hasNextPage?: Maybe<Scalars["Boolean"]>
}

export type PendingUploadResponse = {
  __typename: "PendingUploadResponse"
  confirmationKey: Scalars["String"]
  signedURL: Scalars["String"]
}

export type PushSaveFileResponse = ErrorResponse | PushSaveFileSuccessResponse

export type PushSaveFileSuccessResponse = {
  __typename: "PushSaveFileSuccessResponse"
  created: Scalars["Boolean"]
  identifier: Scalars["String"]
}

export type Query = {
  __typename: "Query"
  samples: Samples
  serialization: Serialization
  shader: Shaders
  user: UserQuery
}

export enum Role {
  Admin = "ADMIN",
  Mod = "MOD",
  User = "USER",
}

export type SampleFile = {
  __typename: "SampleFile"
  audioURL: Scalars["String"]
  beats16ths: Scalars["Int"]
  bpm: Scalars["Float"]
  durationS: Scalars["Float"]
  hashID: Scalars["String"]
  id: Scalars["ID"]
  name: Scalars["String"]
  stemParentID?: Maybe<Scalars["ID"]>
  stemType?: Maybe<StemType>
  stemsIDs?: Maybe<Array<Scalars["ID"]>>
}

export type SamplePack = {
  __typename: "SamplePack"
  bpm?: Maybe<Scalars["Float"]>
  id: Scalars["ID"]
  imageURL: Scalars["String"]
  name: Scalars["String"]
  samples: Array<SampleFile>
}

export type SampleUpdateInfo = {
  name?: InputMaybe<Scalars["String"]>
  numberOfBeatsIn16ths?: InputMaybe<Scalars["Int"]>
}

export type Samples = {
  __typename: "Samples"
  allSplitStatus?: Maybe<Array<SplitSampleResponse>>
  downloadSample?: Maybe<DownloadSampleResponse>
  fetchMySamples?: Maybe<FetchMySamplesResponse>
  fetchSample?: Maybe<SampleFile>
  fetchSamplePack?: Maybe<SamplePack>
  fetchSamplePacks?: Maybe<FetchSamplePackResponse>
  fetchSamples?: Maybe<FetchSampleResponse>
  waitForSplit?: Maybe<SplitSampleResponse>
}

export type SamplesDownloadSampleArgs = {
  sampleID: Scalars["ID"]
}

export type SamplesFetchSampleArgs = {
  sampleHashID: Scalars["String"]
}

export type SamplesFetchSamplePackArgs = {
  samplePackID: Scalars["String"]
}

export type SamplesFetchSamplePacksArgs = {
  after?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  search?: InputMaybe<SearchField>
}

export type SamplesFetchSamplesArgs = {
  search?: InputMaybe<FetchSampleSearchField>
}

export type SamplesWaitForSplitArgs = {
  sampleID: Scalars["ID"]
}

export type SamplesMutations = {
  __typename: "SamplesMutations"
  confirmUpload?: Maybe<UploadSampleResponse>
  deleteSamples?: Maybe<DeleteSamplesResponse>
  pendingUpload?: Maybe<PendingUploadResponse>
  split?: Maybe<SplitSampleResponse>
  updateSample?: Maybe<SampleFile>
}

export type SamplesMutationsConfirmUploadArgs = {
  confirmationKey: Scalars["String"]
}

export type SamplesMutationsDeleteSamplesArgs = {
  samples: Array<Scalars["ID"]>
}

export type SamplesMutationsPendingUploadArgs = {
  filename: Scalars["String"]
}

export type SamplesMutationsSplitArgs = {
  force?: InputMaybe<Scalars["Boolean"]>
  sampleID: Scalars["ID"]
}

export type SamplesMutationsUpdateSampleArgs = {
  info: SampleUpdateInfo
  sampleID: Scalars["ID"]
}

export type SaveFile = {
  __typename: "SaveFile"
  contents: Scalars["SaveFileJSON"]
  identifier: Scalars["ID"]
  name: Scalars["String"]
  saveDate: Scalars["DateTime"]
  version: Scalars["String"]
}

export type SaveFileHistoryResponse = {
  __typename: "SaveFileHistoryResponse"
  saveFiles?: Maybe<Array<SaveFile>>
}

export type SaveFileInput = {
  clientFileVersion: Scalars["String"]
  contents: Scalars["SaveFileJSON"]
  identifier?: InputMaybe<Scalars["ID"]>
  name: Scalars["String"]
}

export type SearchField = {
  term?: InputMaybe<Scalars["String"]>
  type?: InputMaybe<ShaderSearchType>
}

export type Serialization = {
  __typename: "Serialization"
  demoSaveFile?: Maybe<SaveFile>
  getSaveFile?: Maybe<SaveFile>
  getSaveFiles?: Maybe<GetSaveFileResponse>
}

export type SerializationGetSaveFileArgs = {
  identifier: Scalars["ID"]
}

export type SerializationMutations = {
  __typename: "SerializationMutations"
  deleteSave?: Maybe<DeleteSaveFileResponse>
  pushSave?: Maybe<PushSaveFileResponse>
}

export type SerializationMutationsDeleteSaveArgs = {
  identifier: Scalars["ID"]
}

export type SerializationMutationsPushSaveArgs = {
  saveFile: SaveFileInput
}

export type Shader = {
  __typename: "Shader"
  shaderPass: Array<ShaderPass>
  summary: ShaderSummary
}

export type ShaderFetchSummaryResponse = {
  __typename: "ShaderFetchSummaryResponse"
  pageInfo: PageInfo
  summaries: Array<ShaderSummary>
}

export type ShaderMutations = {
  __typename: "ShaderMutations"
  reportShader?: Maybe<ShaderUpdatedResponse>
  updateShader?: Maybe<ShaderUpdatedResponse>
}

export type ShaderMutationsReportShaderArgs = {
  id: Scalars["String"]
  report: ShaderReport
  shaderType: ShaderType
}

export type ShaderMutationsUpdateShaderArgs = {
  id: Scalars["String"]
  shaderType: ShaderType
  update: ShaderUpdate
}

export type ShaderPass = {
  __typename: "ShaderPass"
  bufferID?: Maybe<Scalars["String"]>
  fragmentGLSL: Scalars["GSLSShader"]
  name: Scalars["String"]
  type: ShaderPassType
  uniforms?: Maybe<Array<Uniform>>
  vertexGLSL?: Maybe<Scalars["GSLSShader"]>
}

export enum ShaderPassType {
  Buffer = "Buffer",
  Primary = "Primary",
}

export type ShaderReport = {
  message?: InputMaybe<Scalars["String"]>
  reason?: InputMaybe<ShaderReportTypes>
}

export enum ShaderReportTypes {
  Crashing = "Crashing",
  IncorrectlyRendering = "IncorrectlyRendering",
  NotInteresting = "NotInteresting",
}

export enum ShaderSearchType {
  Bookmarked = "Bookmarked",
  Random = "Random",
  Reported = "Reported",
}

export enum ShaderSource {
  Node = "NODE",
  Sf = "SF",
  St = "ST",
}

export type ShaderSummary = {
  __typename: "ShaderSummary"
  author?: Maybe<Scalars["String"]>
  authorURL?: Maybe<Scalars["String"]>
  bookmarked: Scalars["Boolean"]
  date?: Maybe<Scalars["DateTime"]>
  externalURL?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  isReported: Scalars["Boolean"]
  likes: Scalars["Int"]
  mints: Scalars["Int"]
  name: Scalars["String"]
  screenshotURL?: Maybe<Scalars["String"]>
  source: ShaderSource
  tags?: Maybe<Array<Scalars["String"]>>
  type: ShaderType
  views: Scalars["Int"]
}

export enum ShaderType {
  Material = "Material",
  Shader = "Shader",
}

export type ShaderUpdate = {
  bookmarked?: InputMaybe<Scalars["Boolean"]>
  like?: InputMaybe<Scalars["Boolean"]>
}

export type ShaderUpdatedResponse = {
  __typename: "ShaderUpdatedResponse"
  errorMessage?: Maybe<Scalars["String"]>
  status: Scalars["Boolean"]
}

export type Shaders = {
  __typename: "Shaders"
  fetchShader?: Maybe<Shader>
  fetchSummary?: Maybe<ShaderFetchSummaryResponse>
}

export type ShadersFetchShaderArgs = {
  id: Scalars["String"]
  shaderType: ShaderType
}

export type ShadersFetchSummaryArgs = {
  after?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  search?: InputMaybe<SearchField>
  shaderType: ShaderType
}

export type SplitSampleError = {
  __typename: "SplitSampleError"
  message: Scalars["String"]
  sampleID: Scalars["ID"]
}

export type SplitSamplePending = {
  __typename: "SplitSamplePending"
  confirmationKey: Scalars["String"]
  sampleID: Scalars["ID"]
}

export type SplitSampleResponse =
  | SplitSampleError
  | SplitSamplePending
  | SplitSampleSuccess

export type SplitSampleSuccess = {
  __typename: "SplitSampleSuccess"
  sampleID: Scalars["ID"]
  stemSampleIDs: Array<Scalars["ID"]>
}

export enum StemType {
  Bass = "BASS",
  Drums = "DRUMS",
  Other = "OTHER",
  Vocal = "VOCAL",
}

export type SuccessResponse = {
  __typename: "SuccessResponse"
  status?: Maybe<Scalars["String"]>
}

export enum TextureType {
  Cube = "CUBE",
  Volume = "VOLUME",
  "2D" = "_2D",
}

export type Uniform =
  | UniformAudio
  | UniformBuffer
  | UniformPrimitive
  | UniformTexture
  | UniformVideo

export type UniformAudio = {
  __typename: "UniformAudio"
  channel?: Maybe<Scalars["Int"]>
  name: Scalars["String"]
  originalURL: Scalars["String"]
}

export type UniformBuffer = {
  __typename: "UniformBuffer"
  bufferID: Scalars["String"]
  channel?: Maybe<Scalars["Int"]>
  name: Scalars["String"]
}

export type UniformPrimitive = {
  __typename: "UniformPrimitive"
  friendlyName?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  rangeMax?: Maybe<Scalars["UniformPrimitiveValue"]>
  rangeMin?: Maybe<Scalars["UniformPrimitiveValue"]>
  type: UniformPrimitiveType
  value: Scalars["UniformPrimitiveValue"]
}

export enum UniformPrimitiveType {
  Color = "Color",
  F = "F",
  Matrix3 = "Matrix3",
  Matrix4 = "Matrix4",
  Vector2 = "Vector2",
  Vector3 = "Vector3",
  Vector4 = "Vector4",
}

export type UniformTexture = {
  __typename: "UniformTexture"
  channel?: Maybe<Scalars["Int"]>
  name: Scalars["String"]
  textureThumbnailURL?: Maybe<Scalars["String"]>
  textureType: TextureType
  textureURL: Scalars["String"]
}

export type UniformVideo = {
  __typename: "UniformVideo"
  channel?: Maybe<Scalars["Int"]>
  name: Scalars["String"]
  videoURL: Scalars["String"]
}

export type UpdateUsernameResponse = ErrorResponse | SuccessResponse

export type UploadSampleResponse = {
  __typename: "UploadSampleResponse"
  sample: SampleFile
}

export type UserQuery = {
  __typename: "UserQuery"
  userSaveData?: Maybe<UserSaveData>
}

export type UserSaveData = {
  __typename: "UserSaveData"
  bookmarks?: Maybe<UserSavedBookmarks>
  reportedShaders?: Maybe<Array<Scalars["String"]>>
}

export type UserSavedBookmarks = {
  __typename: "UserSavedBookmarks"
  shaders?: Maybe<Array<Scalars["String"]>>
}

export type DeleteSamplesMutationVariables = Exact<{
  samples: Array<Scalars["ID"]> | Scalars["ID"]
}>

export type DeleteSamplesMutation = {
  __typename: "Mutation"
  samples: {
    __typename: "SamplesMutations"
    deleteSamples?:
      | { __typename: "DeleteSamplesResponse"; sampleIDs: Array<string> }
      | undefined
  }
}

export type DownloadSampleQueryVariables = Exact<{
  sampleId: Scalars["ID"]
}>

export type DownloadSampleQuery = {
  __typename: "Query"
  samples: {
    __typename: "Samples"
    downloadSample?:
      | { __typename: "DownloadSampleResponse"; oneTimeURL: string }
      | undefined
  }
}

export type FetchMySamplesQueryVariables = Exact<{ [key: string]: never }>

export type FetchMySamplesQuery = {
  __typename: "Query"
  samples: {
    __typename: "Samples"
    fetchMySamples?:
      | {
          __typename: "FetchMySamplesResponse"
          samples: Array<{
            __typename: "SampleFile"
            name: string
            id: string
            audioURL: string
            durationS: number
            beats16ths: number
            bpm: number
            stemsIDs?: Array<string> | undefined
            stemParentID?: string | undefined
            stemType?: StemType | undefined
          }>
        }
      | undefined
  }
}

export type FetchSamplePackQueryVariables = Exact<{
  samplePackID: Scalars["String"]
}>

export type FetchSamplePackQuery = {
  __typename: "Query"
  samples: {
    __typename: "Samples"
    fetchSamplePack?:
      | {
          __typename: "SamplePack"
          bpm?: number | undefined
          id: string
          imageURL: string
          name: string
          samples: Array<{
            __typename: "SampleFile"
            name: string
            id: string
            audioURL: string
            durationS: number
            beats16ths: number
            bpm: number
            stemsIDs?: Array<string> | undefined
            stemParentID?: string | undefined
            stemType?: StemType | undefined
          }>
        }
      | undefined
  }
}

export type FetchSampleQueryVariables = Exact<{
  sampleHashID: Scalars["String"]
}>

export type FetchSampleQuery = {
  __typename: "Query"
  samples: {
    __typename: "Samples"
    fetchSample?:
      | {
          __typename: "SampleFile"
          name: string
          id: string
          audioURL: string
          durationS: number
          beats16ths: number
          bpm: number
          stemsIDs?: Array<string> | undefined
          stemParentID?: string | undefined
          stemType?: StemType | undefined
        }
      | undefined
  }
}

export type FetchSamplePacksQueryVariables = Exact<{
  search?: InputMaybe<SearchField>
  after?: InputMaybe<Scalars["String"]>
}>

export type FetchSamplePacksQuery = {
  __typename: "Query"
  samples: {
    __typename: "Samples"
    fetchSamplePacks?:
      | {
          __typename: "FetchSamplePackResponse"
          pageInfo: {
            __typename: "PageInfo"
            endCursor?: any | undefined
            hasNextPage?: boolean | undefined
          }
          packs: Array<{
            __typename: "SamplePack"
            bpm?: number | undefined
            id: string
            imageURL: string
            name: string
            samples: Array<{
              __typename: "SampleFile"
              name: string
              id: string
              audioURL: string
              durationS: number
              beats16ths: number
              bpm: number
              stemsIDs?: Array<string> | undefined
              stemParentID?: string | undefined
              stemType?: StemType | undefined
            }>
          }>
        }
      | undefined
  }
}

type SplitSampleResponse_SplitSampleError_Fragment = {
  __typename: "SplitSampleError"
  sampleID: string
  message: string
}

type SplitSampleResponse_SplitSamplePending_Fragment = {
  __typename: "SplitSamplePending"
  sampleID: string
  confirmationKey: string
}

type SplitSampleResponse_SplitSampleSuccess_Fragment = {
  __typename: "SplitSampleSuccess"
  sampleID: string
  stemSampleIDs: Array<string>
}

export type SplitSampleResponseFragment =
  | SplitSampleResponse_SplitSampleError_Fragment
  | SplitSampleResponse_SplitSamplePending_Fragment
  | SplitSampleResponse_SplitSampleSuccess_Fragment

export type SamplePackFragment = {
  __typename: "SamplePack"
  bpm?: number | undefined
  id: string
  imageURL: string
  name: string
  samples: Array<{
    __typename: "SampleFile"
    name: string
    id: string
    audioURL: string
    durationS: number
    beats16ths: number
    bpm: number
    stemsIDs?: Array<string> | undefined
    stemParentID?: string | undefined
    stemType?: StemType | undefined
  }>
}

export type SampleFileFragment = {
  __typename: "SampleFile"
  name: string
  id: string
  audioURL: string
  durationS: number
  beats16ths: number
  bpm: number
  stemsIDs?: Array<string> | undefined
  stemParentID?: string | undefined
  stemType?: StemType | undefined
}

export type SplitSampleMutationVariables = Exact<{
  sampleID: Scalars["ID"]
  force?: InputMaybe<Scalars["Boolean"]>
}>

export type SplitSampleMutation = {
  __typename: "Mutation"
  samples: {
    __typename: "SamplesMutations"
    split?:
      | { __typename: "SplitSampleError"; sampleID: string; message: string }
      | {
          __typename: "SplitSamplePending"
          sampleID: string
          confirmationKey: string
        }
      | {
          __typename: "SplitSampleSuccess"
          sampleID: string
          stemSampleIDs: Array<string>
        }
      | undefined
  }
}

export type WaitForSampleToSplitQueryVariables = Exact<{
  sampleId: Scalars["ID"]
}>

export type WaitForSampleToSplitQuery = {
  __typename: "Query"
  samples: {
    __typename: "Samples"
    waitForSplit?:
      | { __typename: "SplitSampleError"; sampleID: string; message: string }
      | {
          __typename: "SplitSamplePending"
          sampleID: string
          confirmationKey: string
        }
      | {
          __typename: "SplitSampleSuccess"
          sampleID: string
          stemSampleIDs: Array<string>
        }
      | undefined
  }
}

export type AllPendingSplitsQueryVariables = Exact<{ [key: string]: never }>

export type AllPendingSplitsQuery = {
  __typename: "Query"
  samples: {
    __typename: "Samples"
    allSplitStatus?:
      | Array<
          | {
              __typename: "SplitSampleError"
              sampleID: string
              message: string
            }
          | {
              __typename: "SplitSamplePending"
              sampleID: string
              confirmationKey: string
            }
          | {
              __typename: "SplitSampleSuccess"
              sampleID: string
              stemSampleIDs: Array<string>
            }
        >
      | undefined
  }
}

export type UpdateSampleMutationVariables = Exact<{
  sampleId: Scalars["ID"]
  info: SampleUpdateInfo
}>

export type UpdateSampleMutation = {
  __typename: "Mutation"
  samples: {
    __typename: "SamplesMutations"
    updateSample?:
      | {
          __typename: "SampleFile"
          name: string
          id: string
          audioURL: string
          durationS: number
          beats16ths: number
          bpm: number
          stemsIDs?: Array<string> | undefined
          stemParentID?: string | undefined
          stemType?: StemType | undefined
        }
      | undefined
  }
}

export type CreatePendingUploadMutationVariables = Exact<{
  filename: Scalars["String"]
}>

export type CreatePendingUploadMutation = {
  __typename: "Mutation"
  samples: {
    __typename: "SamplesMutations"
    pendingUpload?:
      | {
          __typename: "PendingUploadResponse"
          confirmationKey: string
          signedURL: string
        }
      | undefined
  }
}

export type ConfirmSampleMutationVariables = Exact<{
  confirmationKey: Scalars["String"]
}>

export type ConfirmSampleMutation = {
  __typename: "Mutation"
  samples: {
    __typename: "SamplesMutations"
    confirmUpload?:
      | {
          __typename: "UploadSampleResponse"
          sample: { __typename: "SampleFile"; audioURL: string }
        }
      | undefined
  }
}

export type FetchShaderQueryVariables = Exact<{
  type: ShaderType
  shaderID: Scalars["String"]
}>

export type FetchShaderQuery = {
  __typename: "Query"
  shader: {
    __typename: "Shaders"
    fetchShader?:
      | {
          __typename: "Shader"
          summary: {
            __typename: "ShaderSummary"
            id: string
            name: string
            screenshotURL?: string | undefined
            type: ShaderType
            source: ShaderSource
            bookmarked: boolean
            isReported: boolean
            date?: any | undefined
            likes: number
            views: number
            mints: number
            tags?: Array<string> | undefined
            author?: string | undefined
            authorURL?: string | undefined
            externalURL?: string | undefined
          }
          shaderPass: Array<{
            __typename: "ShaderPass"
            name: string
            type: ShaderPassType
            fragmentGLSL: any
            vertexGLSL?: any | undefined
            bufferID?: string | undefined
            uniforms?:
              | Array<
                  | {
                      __typename: "UniformAudio"
                      name: string
                      channel?: number | undefined
                    }
                  | {
                      __typename: "UniformBuffer"
                      name: string
                      bufferID: string
                      channel?: number | undefined
                    }
                  | {
                      __typename: "UniformPrimitive"
                      name: string
                      friendlyName?: string | undefined
                      rangeMin?: any | undefined
                      rangeMax?: any | undefined
                      type: UniformPrimitiveType
                      value: any
                    }
                  | {
                      __typename: "UniformTexture"
                      name: string
                      textureURL: string
                      textureType: TextureType
                      textureThumbnailURL?: string | undefined
                      channel?: number | undefined
                    }
                  | {
                      __typename: "UniformVideo"
                      name: string
                      videoURL: string
                      channel?: number | undefined
                    }
                >
              | undefined
          }>
        }
      | undefined
  }
}

export type ShaderFragment = {
  __typename: "Shader"
  summary: {
    __typename: "ShaderSummary"
    id: string
    name: string
    screenshotURL?: string | undefined
    type: ShaderType
    source: ShaderSource
    bookmarked: boolean
    isReported: boolean
    date?: any | undefined
    likes: number
    views: number
    mints: number
    tags?: Array<string> | undefined
    author?: string | undefined
    authorURL?: string | undefined
    externalURL?: string | undefined
  }
  shaderPass: Array<{
    __typename: "ShaderPass"
    name: string
    type: ShaderPassType
    fragmentGLSL: any
    vertexGLSL?: any | undefined
    bufferID?: string | undefined
    uniforms?:
      | Array<
          | {
              __typename: "UniformAudio"
              name: string
              channel?: number | undefined
            }
          | {
              __typename: "UniformBuffer"
              name: string
              bufferID: string
              channel?: number | undefined
            }
          | {
              __typename: "UniformPrimitive"
              name: string
              friendlyName?: string | undefined
              rangeMin?: any | undefined
              rangeMax?: any | undefined
              type: UniformPrimitiveType
              value: any
            }
          | {
              __typename: "UniformTexture"
              name: string
              textureURL: string
              textureType: TextureType
              textureThumbnailURL?: string | undefined
              channel?: number | undefined
            }
          | {
              __typename: "UniformVideo"
              name: string
              videoURL: string
              channel?: number | undefined
            }
        >
      | undefined
  }>
}

export type ShaderPassFragment = {
  __typename: "ShaderPass"
  name: string
  type: ShaderPassType
  fragmentGLSL: any
  vertexGLSL?: any | undefined
  bufferID?: string | undefined
  uniforms?:
    | Array<
        | {
            __typename: "UniformAudio"
            name: string
            channel?: number | undefined
          }
        | {
            __typename: "UniformBuffer"
            name: string
            bufferID: string
            channel?: number | undefined
          }
        | {
            __typename: "UniformPrimitive"
            name: string
            friendlyName?: string | undefined
            rangeMin?: any | undefined
            rangeMax?: any | undefined
            type: UniformPrimitiveType
            value: any
          }
        | {
            __typename: "UniformTexture"
            name: string
            textureURL: string
            textureType: TextureType
            textureThumbnailURL?: string | undefined
            channel?: number | undefined
          }
        | {
            __typename: "UniformVideo"
            name: string
            videoURL: string
            channel?: number | undefined
          }
      >
    | undefined
}

type Uniform_UniformAudio_Fragment = {
  __typename: "UniformAudio"
  name: string
  channel?: number | undefined
}

type Uniform_UniformBuffer_Fragment = {
  __typename: "UniformBuffer"
  name: string
  bufferID: string
  channel?: number | undefined
}

type Uniform_UniformPrimitive_Fragment = {
  __typename: "UniformPrimitive"
  name: string
  friendlyName?: string | undefined
  rangeMin?: any | undefined
  rangeMax?: any | undefined
  type: UniformPrimitiveType
  value: any
}

type Uniform_UniformTexture_Fragment = {
  __typename: "UniformTexture"
  name: string
  textureURL: string
  textureType: TextureType
  textureThumbnailURL?: string | undefined
  channel?: number | undefined
}

type Uniform_UniformVideo_Fragment = {
  __typename: "UniformVideo"
  name: string
  videoURL: string
  channel?: number | undefined
}

export type UniformFragment =
  | Uniform_UniformAudio_Fragment
  | Uniform_UniformBuffer_Fragment
  | Uniform_UniformPrimitive_Fragment
  | Uniform_UniformTexture_Fragment
  | Uniform_UniformVideo_Fragment

export type FetchShaderSummariesQueryVariables = Exact<{
  type: ShaderType
  search?: InputMaybe<SearchField>
  afterPage?: InputMaybe<Scalars["String"]>
}>

export type FetchShaderSummariesQuery = {
  __typename: "Query"
  shader: {
    __typename: "Shaders"
    fetchSummary?:
      | {
          __typename: "ShaderFetchSummaryResponse"
          pageInfo: {
            __typename: "PageInfo"
            endCursor?: any | undefined
            hasNextPage?: boolean | undefined
          }
          summaries: Array<{
            __typename: "ShaderSummary"
            id: string
            name: string
            screenshotURL?: string | undefined
            type: ShaderType
            source: ShaderSource
            bookmarked: boolean
            isReported: boolean
            date?: any | undefined
            likes: number
            views: number
            mints: number
            tags?: Array<string> | undefined
            author?: string | undefined
            authorURL?: string | undefined
            externalURL?: string | undefined
          }>
        }
      | undefined
  }
}

export type FetchShaderSummaryQueryVariables = Exact<{
  type: ShaderType
  shaderID: Scalars["String"]
}>

export type FetchShaderSummaryQuery = {
  __typename: "Query"
  shader: {
    __typename: "Shaders"
    fetchShader?:
      | {
          __typename: "Shader"
          summary: {
            __typename: "ShaderSummary"
            id: string
            name: string
            screenshotURL?: string | undefined
            type: ShaderType
            source: ShaderSource
            bookmarked: boolean
            isReported: boolean
            date?: any | undefined
            likes: number
            views: number
            mints: number
            tags?: Array<string> | undefined
            author?: string | undefined
            authorURL?: string | undefined
            externalURL?: string | undefined
          }
        }
      | undefined
  }
}

export type ShaderSummaryFragment = {
  __typename: "ShaderSummary"
  id: string
  name: string
  screenshotURL?: string | undefined
  type: ShaderType
  source: ShaderSource
  bookmarked: boolean
  isReported: boolean
  date?: any | undefined
  likes: number
  views: number
  mints: number
  tags?: Array<string> | undefined
  author?: string | undefined
  authorURL?: string | undefined
  externalURL?: string | undefined
}

export type AuthenticateMutationVariables = Exact<{
  publicKey: Scalars["String"]
  signature: Scalars["String"]
}>

export type AuthenticateMutation = {
  __typename: "Mutation"
  auth: {
    __typename: "AuthenticationMutations"
    authorize?:
      | { __typename: "AuthorizeFailure"; errorCode: string }
      | { __typename: "AuthorizeSuccess"; publicKey: string; signature: string }
      | undefined
  }
}

export type BookmarkShaderMutationVariables = Exact<{
  updateShaderId: Scalars["String"]
  shaderType: ShaderType
  update: ShaderUpdate
}>

export type BookmarkShaderMutation = {
  __typename: "Mutation"
  shader: {
    __typename: "ShaderMutations"
    updateShader?:
      | {
          __typename: "ShaderUpdatedResponse"
          status: boolean
          errorMessage?: string | undefined
        }
      | undefined
  }
}

export type DeleteSaveFileMutationVariables = Exact<{
  identifier: Scalars["ID"]
}>

export type DeleteSaveFileMutation = {
  __typename: "Mutation"
  serialization: {
    __typename: "SerializationMutations"
    deleteSave?:
      | { __typename: "DeleteSaveFileSuccessResponse"; identifier: string }
      | { __typename: "ErrorResponse"; errorMessage: string }
      | undefined
  }
}

export type DemoSongQueryVariables = Exact<{ [key: string]: never }>

export type DemoSongQuery = {
  __typename: "Query"
  serialization: {
    __typename: "Serialization"
    demoSaveFile?:
      | {
          __typename: "SaveFile"
          contents: any
          identifier: string
          name: string
          saveDate: any
          version: string
        }
      | undefined
  }
}

export type SaveFileFragment = {
  __typename: "SaveFile"
  contents: any
  identifier: string
  name: string
  saveDate: any
  version: string
}

export type GetSaveFileQueryVariables = Exact<{
  identifier: Scalars["ID"]
}>

export type GetSaveFileQuery = {
  __typename: "Query"
  serialization: {
    __typename: "Serialization"
    getSaveFile?:
      | {
          __typename: "SaveFile"
          contents: any
          identifier: string
          name: string
          saveDate: any
          version: string
        }
      | undefined
  }
}

export type GetSaveFilesQueryVariables = Exact<{ [key: string]: never }>

export type GetSaveFilesQuery = {
  __typename: "Query"
  serialization: {
    __typename: "Serialization"
    getSaveFiles?:
      | {
          __typename: "GetSaveFileResponse"
          saveFiles?:
            | Array<{
                __typename: "SaveFile"
                identifier: string
                name: string
                saveDate: any
                version: string
              }>
            | undefined
        }
      | undefined
  }
}

export type SaveFileLiteFragment = {
  __typename: "SaveFile"
  identifier: string
  name: string
  saveDate: any
  version: string
}

export type ReportShaderMutationVariables = Exact<{
  reportShaderId: Scalars["String"]
  shaderType: ShaderType
  report: ShaderReport
}>

export type ReportShaderMutation = {
  __typename: "Mutation"
  shader: {
    __typename: "ShaderMutations"
    reportShader?:
      | {
          __typename: "ShaderUpdatedResponse"
          errorMessage?: string | undefined
          status: boolean
        }
      | undefined
  }
}

export type SaveFileMutationVariables = Exact<{
  saveFile: SaveFileInput
}>

export type SaveFileMutation = {
  __typename: "Mutation"
  serialization: {
    __typename: "SerializationMutations"
    pushSave?:
      | {
          __typename: "ErrorResponse"
          errorCode?: string | undefined
          errorMessage: string
          errorType?: string | undefined
        }
      | {
          __typename: "PushSaveFileSuccessResponse"
          created: boolean
          identifier: string
        }
      | undefined
  }
}

export type UserDataQueryVariables = Exact<{ [key: string]: never }>

export type UserDataQuery = {
  __typename: "Query"
  user: {
    __typename: "UserQuery"
    userSaveData?:
      | {
          __typename: "UserSaveData"
          reportedShaders?: Array<string> | undefined
          bookmarks?:
            | {
                __typename: "UserSavedBookmarks"
                shaders?: Array<string> | undefined
              }
            | undefined
        }
      | undefined
  }
  samples: {
    __typename: "Samples"
    allSplitStatus?:
      | Array<
          | {
              __typename: "SplitSampleError"
              sampleID: string
              message: string
            }
          | {
              __typename: "SplitSamplePending"
              sampleID: string
              confirmationKey: string
            }
          | {
              __typename: "SplitSampleSuccess"
              sampleID: string
              stemSampleIDs: Array<string>
            }
        >
      | undefined
  }
}

export const SplitSampleResponseFragmentDoc = gql`
  fragment SplitSampleResponse on SplitSampleResponse {
    ... on SplitSampleError {
      sampleID
      message
    }
    ... on SplitSamplePending {
      sampleID
      confirmationKey
    }
    ... on SplitSampleSuccess {
      sampleID
      stemSampleIDs
    }
  }
`
export const SampleFileFragmentDoc = gql`
  fragment SampleFile on SampleFile {
    name
    id
    audioURL
    durationS
    beats16ths
    bpm
    stemsIDs
    stemParentID
    stemType
  }
`
export const SamplePackFragmentDoc = gql`
  fragment SamplePack on SamplePack {
    bpm
    id
    imageURL
    name
    samples {
      ...SampleFile
    }
  }
  ${SampleFileFragmentDoc}
`
export const ShaderSummaryFragmentDoc = gql`
  fragment ShaderSummary on ShaderSummary {
    id
    name
    screenshotURL
    type
    source
    bookmarked
    isReported
    date
    likes
    views
    mints
    tags
    author
    authorURL
    externalURL
  }
`
export const UniformFragmentDoc = gql`
  fragment Uniform on Uniform {
    __typename
    ... on UniformTexture {
      name
      textureURL
      textureType
      textureThumbnailURL
      channel
    }
    ... on UniformVideo {
      name
      videoURL
      channel
    }
    ... on UniformAudio {
      name
      channel
    }
    ... on UniformBuffer {
      name
      bufferID
      channel
    }
    ... on UniformPrimitive {
      name
      friendlyName
      rangeMin
      rangeMax
      type
      value
    }
  }
`
export const ShaderPassFragmentDoc = gql`
  fragment ShaderPass on ShaderPass {
    name
    type
    fragmentGLSL
    vertexGLSL
    uniforms {
      ...Uniform
    }
    bufferID
  }
  ${UniformFragmentDoc}
`
export const ShaderFragmentDoc = gql`
  fragment Shader on Shader {
    summary {
      ...ShaderSummary
    }
    shaderPass {
      ...ShaderPass
    }
  }
  ${ShaderSummaryFragmentDoc}
  ${ShaderPassFragmentDoc}
`
export const SaveFileFragmentDoc = gql`
  fragment SaveFile on SaveFile {
    contents
    identifier
    name
    saveDate
    version
  }
`
export const SaveFileLiteFragmentDoc = gql`
  fragment SaveFileLite on SaveFile {
    identifier
    name
    saveDate
    version
  }
`
export const DeleteSamplesDocument = gql`
  mutation DeleteSamples($samples: [ID!]!) {
    samples {
      deleteSamples(samples: $samples) {
        sampleIDs
      }
    }
  }
`

export const DeleteSamplesComponent = (
  props: Omit<
    Urql.MutationProps<DeleteSamplesMutation, DeleteSamplesMutationVariables>,
    "query"
  > & { variables?: DeleteSamplesMutationVariables }
) => <Urql.Mutation {...props} query={DeleteSamplesDocument} />

export function useDeleteSamplesMutation() {
  return Urql.useMutation<
    DeleteSamplesMutation,
    DeleteSamplesMutationVariables
  >(DeleteSamplesDocument)
}
export const DownloadSampleDocument = gql`
  query DownloadSample($sampleId: ID!) {
    samples {
      downloadSample(sampleID: $sampleId) {
        oneTimeURL
      }
    }
  }
`

export const DownloadSampleComponent = (
  props: Omit<
    Urql.QueryProps<DownloadSampleQuery, DownloadSampleQueryVariables>,
    "query"
  > & { variables: DownloadSampleQueryVariables }
) => <Urql.Query {...props} query={DownloadSampleDocument} />

export function useDownloadSampleQuery(
  options: Omit<Urql.UseQueryArgs<DownloadSampleQueryVariables>, "query">
) {
  return Urql.useQuery<DownloadSampleQuery, DownloadSampleQueryVariables>({
    query: DownloadSampleDocument,
    ...options,
  })
}
export const FetchMySamplesDocument = gql`
  query FetchMySamples {
    samples {
      fetchMySamples {
        samples {
          ...SampleFile
        }
      }
    }
  }
  ${SampleFileFragmentDoc}
`

export const FetchMySamplesComponent = (
  props: Omit<
    Urql.QueryProps<FetchMySamplesQuery, FetchMySamplesQueryVariables>,
    "query"
  > & { variables?: FetchMySamplesQueryVariables }
) => <Urql.Query {...props} query={FetchMySamplesDocument} />

export function useFetchMySamplesQuery(
  options?: Omit<Urql.UseQueryArgs<FetchMySamplesQueryVariables>, "query">
) {
  return Urql.useQuery<FetchMySamplesQuery, FetchMySamplesQueryVariables>({
    query: FetchMySamplesDocument,
    ...options,
  })
}
export const FetchSamplePackDocument = gql`
  query FetchSamplePack($samplePackID: String!) {
    samples {
      fetchSamplePack(samplePackID: $samplePackID) {
        ...SamplePack
      }
    }
  }
  ${SamplePackFragmentDoc}
`

export const FetchSamplePackComponent = (
  props: Omit<
    Urql.QueryProps<FetchSamplePackQuery, FetchSamplePackQueryVariables>,
    "query"
  > & { variables: FetchSamplePackQueryVariables }
) => <Urql.Query {...props} query={FetchSamplePackDocument} />

export function useFetchSamplePackQuery(
  options: Omit<Urql.UseQueryArgs<FetchSamplePackQueryVariables>, "query">
) {
  return Urql.useQuery<FetchSamplePackQuery, FetchSamplePackQueryVariables>({
    query: FetchSamplePackDocument,
    ...options,
  })
}
export const FetchSampleDocument = gql`
  query FetchSample($sampleHashID: String!) {
    samples {
      fetchSample(sampleHashID: $sampleHashID) {
        ...SampleFile
      }
    }
  }
  ${SampleFileFragmentDoc}
`

export const FetchSampleComponent = (
  props: Omit<
    Urql.QueryProps<FetchSampleQuery, FetchSampleQueryVariables>,
    "query"
  > & { variables: FetchSampleQueryVariables }
) => <Urql.Query {...props} query={FetchSampleDocument} />

export function useFetchSampleQuery(
  options: Omit<Urql.UseQueryArgs<FetchSampleQueryVariables>, "query">
) {
  return Urql.useQuery<FetchSampleQuery, FetchSampleQueryVariables>({
    query: FetchSampleDocument,
    ...options,
  })
}
export const FetchSamplePacksDocument = gql`
  query FetchSamplePacks($search: SearchField, $after: String) {
    samples {
      fetchSamplePacks(search: $search, after: $after, first: 200) {
        pageInfo {
          endCursor
          hasNextPage
        }
        packs {
          ...SamplePack
        }
      }
    }
  }
  ${SamplePackFragmentDoc}
`

export const FetchSamplePacksComponent = (
  props: Omit<
    Urql.QueryProps<FetchSamplePacksQuery, FetchSamplePacksQueryVariables>,
    "query"
  > & { variables?: FetchSamplePacksQueryVariables }
) => <Urql.Query {...props} query={FetchSamplePacksDocument} />

export function useFetchSamplePacksQuery(
  options?: Omit<Urql.UseQueryArgs<FetchSamplePacksQueryVariables>, "query">
) {
  return Urql.useQuery<FetchSamplePacksQuery, FetchSamplePacksQueryVariables>({
    query: FetchSamplePacksDocument,
    ...options,
  })
}
export const SplitSampleDocument = gql`
  mutation SplitSample($sampleID: ID!, $force: Boolean) {
    samples {
      split(sampleID: $sampleID, force: $force) {
        ...SplitSampleResponse
      }
    }
  }
  ${SplitSampleResponseFragmentDoc}
`

export const SplitSampleComponent = (
  props: Omit<
    Urql.MutationProps<SplitSampleMutation, SplitSampleMutationVariables>,
    "query"
  > & { variables?: SplitSampleMutationVariables }
) => <Urql.Mutation {...props} query={SplitSampleDocument} />

export function useSplitSampleMutation() {
  return Urql.useMutation<SplitSampleMutation, SplitSampleMutationVariables>(
    SplitSampleDocument
  )
}
export const WaitForSampleToSplitDocument = gql`
  query WaitForSampleToSplit($sampleId: ID!) {
    samples {
      waitForSplit(sampleID: $sampleId) {
        ...SplitSampleResponse
      }
    }
  }
  ${SplitSampleResponseFragmentDoc}
`

export const WaitForSampleToSplitComponent = (
  props: Omit<
    Urql.QueryProps<
      WaitForSampleToSplitQuery,
      WaitForSampleToSplitQueryVariables
    >,
    "query"
  > & { variables: WaitForSampleToSplitQueryVariables }
) => <Urql.Query {...props} query={WaitForSampleToSplitDocument} />

export function useWaitForSampleToSplitQuery(
  options: Omit<Urql.UseQueryArgs<WaitForSampleToSplitQueryVariables>, "query">
) {
  return Urql.useQuery<
    WaitForSampleToSplitQuery,
    WaitForSampleToSplitQueryVariables
  >({ query: WaitForSampleToSplitDocument, ...options })
}
export const AllPendingSplitsDocument = gql`
  query AllPendingSplits {
    samples {
      allSplitStatus {
        ...SplitSampleResponse
      }
    }
  }
  ${SplitSampleResponseFragmentDoc}
`

export const AllPendingSplitsComponent = (
  props: Omit<
    Urql.QueryProps<AllPendingSplitsQuery, AllPendingSplitsQueryVariables>,
    "query"
  > & { variables?: AllPendingSplitsQueryVariables }
) => <Urql.Query {...props} query={AllPendingSplitsDocument} />

export function useAllPendingSplitsQuery(
  options?: Omit<Urql.UseQueryArgs<AllPendingSplitsQueryVariables>, "query">
) {
  return Urql.useQuery<AllPendingSplitsQuery, AllPendingSplitsQueryVariables>({
    query: AllPendingSplitsDocument,
    ...options,
  })
}
export const UpdateSampleDocument = gql`
  mutation UpdateSample($sampleId: ID!, $info: SampleUpdateInfo!) {
    samples {
      updateSample(sampleID: $sampleId, info: $info) {
        ...SampleFile
      }
    }
  }
  ${SampleFileFragmentDoc}
`

export const UpdateSampleComponent = (
  props: Omit<
    Urql.MutationProps<UpdateSampleMutation, UpdateSampleMutationVariables>,
    "query"
  > & { variables?: UpdateSampleMutationVariables }
) => <Urql.Mutation {...props} query={UpdateSampleDocument} />

export function useUpdateSampleMutation() {
  return Urql.useMutation<UpdateSampleMutation, UpdateSampleMutationVariables>(
    UpdateSampleDocument
  )
}
export const CreatePendingUploadDocument = gql`
  mutation CreatePendingUpload($filename: String!) {
    samples {
      pendingUpload(filename: $filename) {
        confirmationKey
        signedURL
      }
    }
  }
`

export const CreatePendingUploadComponent = (
  props: Omit<
    Urql.MutationProps<
      CreatePendingUploadMutation,
      CreatePendingUploadMutationVariables
    >,
    "query"
  > & { variables?: CreatePendingUploadMutationVariables }
) => <Urql.Mutation {...props} query={CreatePendingUploadDocument} />

export function useCreatePendingUploadMutation() {
  return Urql.useMutation<
    CreatePendingUploadMutation,
    CreatePendingUploadMutationVariables
  >(CreatePendingUploadDocument)
}
export const ConfirmSampleDocument = gql`
  mutation ConfirmSample($confirmationKey: String!) {
    samples {
      confirmUpload(confirmationKey: $confirmationKey) {
        sample {
          audioURL
        }
      }
    }
  }
`

export const ConfirmSampleComponent = (
  props: Omit<
    Urql.MutationProps<ConfirmSampleMutation, ConfirmSampleMutationVariables>,
    "query"
  > & { variables?: ConfirmSampleMutationVariables }
) => <Urql.Mutation {...props} query={ConfirmSampleDocument} />

export function useConfirmSampleMutation() {
  return Urql.useMutation<
    ConfirmSampleMutation,
    ConfirmSampleMutationVariables
  >(ConfirmSampleDocument)
}
export const FetchShaderDocument = gql`
  query FetchShader($type: ShaderType!, $shaderID: String!) {
    shader {
      fetchShader(shaderType: $type, id: $shaderID) {
        ...Shader
      }
    }
  }
  ${ShaderFragmentDoc}
`

export const FetchShaderComponent = (
  props: Omit<
    Urql.QueryProps<FetchShaderQuery, FetchShaderQueryVariables>,
    "query"
  > & { variables: FetchShaderQueryVariables }
) => <Urql.Query {...props} query={FetchShaderDocument} />

export function useFetchShaderQuery(
  options: Omit<Urql.UseQueryArgs<FetchShaderQueryVariables>, "query">
) {
  return Urql.useQuery<FetchShaderQuery, FetchShaderQueryVariables>({
    query: FetchShaderDocument,
    ...options,
  })
}
export const FetchShaderSummariesDocument = gql`
  query FetchShaderSummaries(
    $type: ShaderType!
    $search: SearchField
    $afterPage: String
  ) {
    shader {
      fetchSummary(
        shaderType: $type
        search: $search
        first: 50
        after: $afterPage
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        summaries {
          ...ShaderSummary
        }
      }
    }
  }
  ${ShaderSummaryFragmentDoc}
`

export const FetchShaderSummariesComponent = (
  props: Omit<
    Urql.QueryProps<
      FetchShaderSummariesQuery,
      FetchShaderSummariesQueryVariables
    >,
    "query"
  > & { variables: FetchShaderSummariesQueryVariables }
) => <Urql.Query {...props} query={FetchShaderSummariesDocument} />

export function useFetchShaderSummariesQuery(
  options: Omit<Urql.UseQueryArgs<FetchShaderSummariesQueryVariables>, "query">
) {
  return Urql.useQuery<
    FetchShaderSummariesQuery,
    FetchShaderSummariesQueryVariables
  >({ query: FetchShaderSummariesDocument, ...options })
}
export const FetchShaderSummaryDocument = gql`
  query FetchShaderSummary($type: ShaderType!, $shaderID: String!) {
    shader {
      fetchShader(shaderType: $type, id: $shaderID) {
        summary {
          ...ShaderSummary
        }
      }
    }
  }
  ${ShaderSummaryFragmentDoc}
`

export const FetchShaderSummaryComponent = (
  props: Omit<
    Urql.QueryProps<FetchShaderSummaryQuery, FetchShaderSummaryQueryVariables>,
    "query"
  > & { variables: FetchShaderSummaryQueryVariables }
) => <Urql.Query {...props} query={FetchShaderSummaryDocument} />

export function useFetchShaderSummaryQuery(
  options: Omit<Urql.UseQueryArgs<FetchShaderSummaryQueryVariables>, "query">
) {
  return Urql.useQuery<
    FetchShaderSummaryQuery,
    FetchShaderSummaryQueryVariables
  >({ query: FetchShaderSummaryDocument, ...options })
}
export const AuthenticateDocument = gql`
  mutation Authenticate($publicKey: String!, $signature: String!) {
    auth {
      authorize(publicKey: $publicKey, signature: $signature) {
        ... on AuthorizeFailure {
          errorCode
        }
        ... on AuthorizeSuccess {
          publicKey
          signature
        }
      }
    }
  }
`

export const AuthenticateComponent = (
  props: Omit<
    Urql.MutationProps<AuthenticateMutation, AuthenticateMutationVariables>,
    "query"
  > & { variables?: AuthenticateMutationVariables }
) => <Urql.Mutation {...props} query={AuthenticateDocument} />

export function useAuthenticateMutation() {
  return Urql.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(
    AuthenticateDocument
  )
}
export const BookmarkShaderDocument = gql`
  mutation bookmarkShader(
    $updateShaderId: String!
    $shaderType: ShaderType!
    $update: ShaderUpdate!
  ) {
    shader {
      updateShader(
        id: $updateShaderId
        shaderType: $shaderType
        update: $update
      ) {
        status
        errorMessage
      }
    }
  }
`

export const BookmarkShaderComponent = (
  props: Omit<
    Urql.MutationProps<BookmarkShaderMutation, BookmarkShaderMutationVariables>,
    "query"
  > & { variables?: BookmarkShaderMutationVariables }
) => <Urql.Mutation {...props} query={BookmarkShaderDocument} />

export function useBookmarkShaderMutation() {
  return Urql.useMutation<
    BookmarkShaderMutation,
    BookmarkShaderMutationVariables
  >(BookmarkShaderDocument)
}
export const DeleteSaveFileDocument = gql`
  mutation DeleteSaveFile($identifier: ID!) {
    serialization {
      deleteSave(identifier: $identifier) {
        ... on DeleteSaveFileSuccessResponse {
          identifier
        }
        ... on ErrorResponse {
          errorMessage
        }
      }
    }
  }
`

export const DeleteSaveFileComponent = (
  props: Omit<
    Urql.MutationProps<DeleteSaveFileMutation, DeleteSaveFileMutationVariables>,
    "query"
  > & { variables?: DeleteSaveFileMutationVariables }
) => <Urql.Mutation {...props} query={DeleteSaveFileDocument} />

export function useDeleteSaveFileMutation() {
  return Urql.useMutation<
    DeleteSaveFileMutation,
    DeleteSaveFileMutationVariables
  >(DeleteSaveFileDocument)
}
export const DemoSongDocument = gql`
  query DemoSong {
    serialization {
      demoSaveFile {
        ...SaveFile
      }
    }
  }
  ${SaveFileFragmentDoc}
`

export const DemoSongComponent = (
  props: Omit<
    Urql.QueryProps<DemoSongQuery, DemoSongQueryVariables>,
    "query"
  > & { variables?: DemoSongQueryVariables }
) => <Urql.Query {...props} query={DemoSongDocument} />

export function useDemoSongQuery(
  options?: Omit<Urql.UseQueryArgs<DemoSongQueryVariables>, "query">
) {
  return Urql.useQuery<DemoSongQuery, DemoSongQueryVariables>({
    query: DemoSongDocument,
    ...options,
  })
}
export const GetSaveFileDocument = gql`
  query GetSaveFile($identifier: ID!) {
    serialization {
      getSaveFile(identifier: $identifier) {
        ...SaveFile
      }
    }
  }
  ${SaveFileFragmentDoc}
`

export const GetSaveFileComponent = (
  props: Omit<
    Urql.QueryProps<GetSaveFileQuery, GetSaveFileQueryVariables>,
    "query"
  > & { variables: GetSaveFileQueryVariables }
) => <Urql.Query {...props} query={GetSaveFileDocument} />

export function useGetSaveFileQuery(
  options: Omit<Urql.UseQueryArgs<GetSaveFileQueryVariables>, "query">
) {
  return Urql.useQuery<GetSaveFileQuery, GetSaveFileQueryVariables>({
    query: GetSaveFileDocument,
    ...options,
  })
}
export const GetSaveFilesDocument = gql`
  query GetSaveFiles {
    serialization {
      getSaveFiles {
        saveFiles {
          ...SaveFileLite
        }
      }
    }
  }
  ${SaveFileLiteFragmentDoc}
`

export const GetSaveFilesComponent = (
  props: Omit<
    Urql.QueryProps<GetSaveFilesQuery, GetSaveFilesQueryVariables>,
    "query"
  > & { variables?: GetSaveFilesQueryVariables }
) => <Urql.Query {...props} query={GetSaveFilesDocument} />

export function useGetSaveFilesQuery(
  options?: Omit<Urql.UseQueryArgs<GetSaveFilesQueryVariables>, "query">
) {
  return Urql.useQuery<GetSaveFilesQuery, GetSaveFilesQueryVariables>({
    query: GetSaveFilesDocument,
    ...options,
  })
}
export const ReportShaderDocument = gql`
  mutation ReportShader(
    $reportShaderId: String!
    $shaderType: ShaderType!
    $report: ShaderReport!
  ) {
    shader {
      reportShader(
        id: $reportShaderId
        shaderType: $shaderType
        report: $report
      ) {
        errorMessage
        status
      }
    }
  }
`

export const ReportShaderComponent = (
  props: Omit<
    Urql.MutationProps<ReportShaderMutation, ReportShaderMutationVariables>,
    "query"
  > & { variables?: ReportShaderMutationVariables }
) => <Urql.Mutation {...props} query={ReportShaderDocument} />

export function useReportShaderMutation() {
  return Urql.useMutation<ReportShaderMutation, ReportShaderMutationVariables>(
    ReportShaderDocument
  )
}
export const SaveFileDocument = gql`
  mutation SaveFile($saveFile: SaveFileInput!) {
    serialization {
      pushSave(saveFile: $saveFile) {
        ... on ErrorResponse {
          errorCode
          errorMessage
          errorType
        }
        ... on PushSaveFileSuccessResponse {
          created
          identifier
        }
      }
    }
  }
`

export const SaveFileComponent = (
  props: Omit<
    Urql.MutationProps<SaveFileMutation, SaveFileMutationVariables>,
    "query"
  > & { variables?: SaveFileMutationVariables }
) => <Urql.Mutation {...props} query={SaveFileDocument} />

export function useSaveFileMutation() {
  return Urql.useMutation<SaveFileMutation, SaveFileMutationVariables>(
    SaveFileDocument
  )
}
export const UserDataDocument = gql`
  query UserData {
    user {
      userSaveData {
        bookmarks {
          shaders
        }
        reportedShaders
      }
    }
    samples {
      allSplitStatus {
        ...SplitSampleResponse
      }
    }
  }
  ${SplitSampleResponseFragmentDoc}
`

export const UserDataComponent = (
  props: Omit<
    Urql.QueryProps<UserDataQuery, UserDataQueryVariables>,
    "query"
  > & { variables?: UserDataQueryVariables }
) => <Urql.Query {...props} query={UserDataDocument} />

export function useUserDataQuery(
  options?: Omit<Urql.UseQueryArgs<UserDataQueryVariables>, "query">
) {
  return Urql.useQuery<UserDataQuery, UserDataQueryVariables>({
    query: UserDataDocument,
    ...options,
  })
}
