import _ from "lodash";
import * as GraphQL from "src/generated/graphqlTypes"

import { ModulationTypes, PrimitiveModulation } from "src/store/bindings";
import { isTime } from "src/store/cinema/utils";
import { IdentifierFactory, LayerMaterialID, LayerShaderID, LayerUniformID } from "src/store/identifiers/identifiers";
import { assert } from "src/utils";
import { Emitter, Observable, makeObservable } from "src/utils/observer";
import AnimationSequence from "./AnimationSequence";

import { FrequencyAnalyserTexture } from "./FreqAnalyser";
import { BindableSource, BindableTarget, BindingLoader, BindingTargetResult } from "./types";


type BindablePairActions<Source, Target, Input, Output> = {
    prepare: (
        source: Source,
        target: Target,
        preset?: BindingFixedValues<Input>) => () => Output;
};

type BindingFixedValues<Data> = {
    getValue: () => Data
    getModScaler: () => Data
}


// An instance of a binding
interface ActiveModulationConnection<Target = any, Source = object,> {
    modulation: ModulationTypes
    source?: BindableSource<Source>
    target?: BindableTarget<Target>,

}
interface UniformPresetEntry<P> {
    compositeID: string,
    value: P
}

export class BindingContext {

    public onBindingChanged: Observable<ModulationTypes[]>
    private notifyBindingChanged: Emitter<ModulationTypes[]>

    private uniformPresetStore: { [uniformCompositeKey: string]: UniformPresetEntry<any> } = {}

    modulations: ModulationTypes[] = []
    liveConnections: ActiveModulationConnection<any, any>[] = []

    constructor(private loader: BindingLoader) {
        [this.onBindingChanged, this.notifyBindingChanged] = makeObservable()
    }


    removePreset(uniformID: LayerUniformID) {
        delete this.uniformPresetStore[uniformID.compositeKey]
    }

    updateUniformPreset<P>(uniformCompositeKey: string, value: P): UniformPresetEntry<P> {
        const entry = this.uniformPresetStore[uniformCompositeKey] ?? { compositeID: uniformCompositeKey }
        entry.value = value
        this.uniformPresetStore[uniformCompositeKey] = entry
        return entry
    }

    load(bindings: ModulationTypes[]) {
        this.modulations = bindings
        this.liveConnections = []
        this.notifyBindingChanged.notify(bindings)
    }

    clearAll() {
        this.modulations = []
        this.liveConnections = []
    }



    bindSource<Source>(source: BindableSource<Source>) {
        const foundModulations: ModulationTypes[] = this.modulations.filter(modulation => {
            return modulation.source.compositeKey === source.compositeID
        })

        if (foundModulations.length === 0) {
            return
        }


        let updatedConnection = false
        foundModulations.forEach(modulation => {
            const foundModulationID = IdentifierFactory.makeModulationID(modulation)
            const liveConnection = _.find(this.liveConnections, connection =>
                IdentifierFactory.makeModulationID(connection.modulation).compositeKey === foundModulationID.compositeKey)

            if (!liveConnection || !liveConnection.target) {
                this.liveConnections.push({
                    modulation: modulation,
                    source: source as any
                })
                return
            }

            updatedConnection = true
            liveConnection.source = source as any
        })

        if (updatedConnection) {
            this.notifyBindingChanged.notify(this.modulations)
        }
    }



    bindTargetPrimitive<P>(
        layer: LayerShaderID | LayerMaterialID,
        uniform: GraphQL.UniformPrimitive): BindingTargetResult<P> {


        const uniformID = IdentifierFactory.makeUniformIdentifier(layer, uniform.name)
        const uniformCompositeKey = uniformID.compositeKey

        // Looks for overridden parameters
        const valueEntry: UniformPresetEntry<any> = this.getValueEntry(uniformCompositeKey, layer, uniform);


        const foundModulation = this.modulations.find(mod => {
            return mod.target.compositeKey === uniformCompositeKey
        })

        // If nothing found, use fixed value
        if (!foundModulation) {
            return {
                getData: () => valueEntry.value
            }
        }
        assert(foundModulation.kind !== "AudioBinding", "Should not be audio binding")

        const target: BindableTarget<P> = {
            compositeID: uniformCompositeKey,
            prepare: () => valueEntry.value
        }

        const foundModulationID = IdentifierFactory.makeModulationID(foundModulation)
        const foundLiveConnection: ActiveModulationConnection | undefined = _.find(this.liveConnections, connection => IdentifierFactory.makeModulationID(connection.modulation).compositeKey === foundModulationID.compositeKey)

        if (!foundLiveConnection || !foundLiveConnection.source) {
            this.liveConnections.push({
                ...foundLiveConnection,
                modulation: foundModulation,
                target
            })
            return {
                getData: target.prepare,
            }
        }

        foundLiveConnection.target = target
        const modEntry: UniformPresetEntry<any> = this.getModEntry(foundModulation)

        return {
            getData: prepareBinding(foundModulation,
                foundLiveConnection.source,
                foundLiveConnection.target,
                {
                    getModScaler: () => modEntry.value,
                    getValue: () => valueEntry.value
                })
        }
    }



    private getValueEntry(uniformCompositeKey: string, layer: LayerShaderID | LayerMaterialID, uniform: GraphQL.UniformPrimitive) {
        if (this.uniformPresetStore[uniformCompositeKey]) {
            return this.uniformPresetStore[uniformCompositeKey];
        } else {
            // Look for primitives in the Shader files.
            return this.updateUniformPreset<any>(uniformCompositeKey, this.loader.getPrimitive(layer, uniform));
        }
    }

    private getModEntry(primitive: PrimitiveModulation) {
        const identifier = IdentifierFactory.makePrimitiveModulationID(primitive)
        const compositeKey = identifier.compositeKey
        if (this.uniformPresetStore[compositeKey]) {
            return this.uniformPresetStore[compositeKey];
        } else {
            // Look for primitives in the Shader files.
            return this.updateUniformPreset<any>(compositeKey, this.loader.getModValue(identifier));
        }
    }

    bindTargetToAudio<T>(target: BindableTarget<T>): BindingTargetResult<T> | undefined {
        const foundBinding = this.modulations.find(binding => {
            return binding.target.compositeKey === target.compositeID
        })

        if (!foundBinding) {
            return
        }

        const foundConnect = this.liveConnections.find(connection => connection.modulation === foundBinding)

        if (!foundConnect || !foundConnect.source) {
            this.liveConnections.push({
                ...foundConnect,
                modulation: foundBinding,
                target
            })
            return
        }

        foundConnect.target = target

        return {
            getData: prepareBinding(
                foundBinding,
                foundConnect.source,
                foundConnect.target)
        }
    }
}

function prepareBinding<P>(rawBinding: ModulationTypes,
    source: BindableSource<any>,
    target: BindableTarget<any>,
    preset?: BindingFixedValues<any>
): () => P {
    const prepareSource = source.prepare()
    const prepareTarget = target.prepare()

    let bindingPair: BindablePairActions<any, any, any, any>

    switch (rawBinding.kind) {
        case "AudioBinding":
            bindingPair = BindableTypes.Audio.action
            break

        case "PrimitiveBinding":
            if (isTime(rawBinding.target.uniformName)) {
                bindingPair = BindableTypes.Modulation.time(rawBinding)
            } else {
                bindingPair = BindableTypes.Modulation.action
            }
            break
    }

    return bindingPair.prepare(prepareSource, prepareTarget, preset)
}



const AudioPairActions: BindablePairActions<GainNode, FrequencyAnalyserTexture, any, FrequencyAnalyserTexture> = {
    prepare: (source, target) => {
        target.bindToSource(source)
        return () => target
    }
}


export type BindableTimeData = {
    kind: "BindableTimeData"
    mod?: number
    preset: number
    behavior: PrimitiveModulation["behavior"]
} | number

const ModulationOfTime = (modulation: PrimitiveModulation): BindablePairActions<AnimationSequence, void, number, BindableTimeData> => {
    return {
        prepare: (source, target, preset) => {
            //target.bindToSource(source)
            return () => {
                const presetValue = preset?.getValue() ?? 1
                const modScalar = preset?.getModScaler() ?? 1
                const mod = source.onRenderTick().lerp

                return {
                    kind: "BindableTimeData",
                    mod: mod * modScalar,
                    preset: presetValue,
                    behavior: modulation.behavior ?? "offset"
                }
            }
        }
    }
}

const ModulationOfPrimitive: BindablePairActions<AnimationSequence, void, number, number> = {
    prepare: (source, target, preset) => {
        //target.bindToSource(source)
        return () => {
            const presetValue = preset?.getValue() ?? 0
            const modScalar = preset?.getModScaler() ?? 1
            const mod = source.onRenderTick().lerp

            return presetValue + mod * modScalar
        }
    }
}

const BindableTypes = {
    Audio: {
        action: AudioPairActions
    },
    Modulation: {
        action: ModulationOfPrimitive,
        time: ModulationOfTime
    }
}
