
import React, { } from 'react';
import { ModulatingSlider } from 'src/components/Controls/Slider';
import { BindableTypes } from 'src/store/cinema/primitives';
import { BindableTopRow } from './BindableTopRow';
import { PopupOverSettingsButton } from './PopoverSetting';
import { classItemBindable } from './styles';


export interface BindableProps<Value = number> {
    name: string
    value: Value
    typeName: BindableTypes
    onChange: (newValue: any) => void
    onChangeRange?: (type: "min" | "max", newValue: any) => void
    onChangeModulatable?: (newValue: boolean) => void
}


export type BindableItemProps = {
    modulatable?: boolean
    requiredModulatable?: boolean
    rangeMin: any
    rangeMax: any
} & BindableProps

export const BindableItem: React.FC<BindableItemProps> = (props) => {

    return (<div className={classItemBindable({ pinned: (props.modulatable || props.requiredModulatable) ?? false })}>
        <BindableTopRow {...props} />

        <div className="flex flex-col items-start">
            <p className='text-base'>
                {props.name}
            </p>
            <div className='flex flex-row w-full pl-1 justify-between'>
                <ModulatingSlider {...props} />
                <PopupOverSettingsButton {...props} />
            </div>
        </div>
    </div >)
}

