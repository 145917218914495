import { AllIdentifiers, LayerUniformID, TrackIdentifers } from "src/store/identifiers/identifiers";

import { ModulationTypes } from "src/store/bindings";
import { search } from "src/store/cinema/search";
import { PagesState } from "src/store/store";


export type BindableSourceIdentifiers = TrackIdentifers
export type BindableTargetIdentifier = LayerUniformID

export interface NodeEditorContext {

    enableEvents(enable: boolean): void

    setDragEnabled(enabled: boolean): void

    onLinkCreated(
        source: BindableSourceIdentifiers,
        target: BindableTargetIdentifier): void

    onLinkRemoved(
        source: BindableSourceIdentifiers,
        target: BindableTargetIdentifier): void

    getLinkData<ID extends AllIdentifiers, Data extends object>(id: ID): Data
}


interface ContextProps {
    onDragEnabled: (enabled: boolean) => void,
    state: PagesState
}


export function makeContext({ onDragEnabled, state }: ContextProps): NodeEditorContext {

    let isEnabled = true;
    return {
        setDragEnabled(enabled) {
            onDragEnabled(enabled)
        },
        enableEvents(enable: boolean) {
            isEnabled = enable;
        },
        onLinkCreated: (source, target) => {
            if (isEnabled)
                onLinkCreated(source, target, state);
        },
        onLinkRemoved: (source, target) => {
            if (isEnabled)
                onLinkRemoved(source, target, state);
        },
        getLinkData<ID extends AllIdentifiers, Data extends object>(id: ID): Data {
            switch (id.kind) {
                case "UniformID":
                    return search.findUniformRequired(id, state.cinemaState) as Data
            }

            throw new Error("Incomplete: getLinkData")
        }
    }
}



function makeModulationType(source: BindableSourceIdentifiers,
    target: BindableTargetIdentifier): ModulationTypes {
    switch (source.kind) {
        case "TrackID":

            switch (target.kind) {
                case "UniformID":
                    return {
                        "kind": "AudioBinding",
                        source,
                        target
                    }
            }

        case "TrackEnvelopeID":
            switch (target.kind) {
                case "UniformID":
                    return {
                        "kind": "PrimitiveBinding",
                        source,
                        target,
                        modScale: 1
                    }
            }

    }

    throw new Error("Unhandled Link creation")
}

function onLinkRemoved(
    source: BindableSourceIdentifiers,
    target: BindableTargetIdentifier,
    state: PagesState) {

    const mod = makeModulationType(source, target)

    state.modulationState.removeModulation(mod)
}
function onLinkCreated(
    source: BindableSourceIdentifiers,
    target: BindableTargetIdentifier,
    state: PagesState) {

    const mod = makeModulationType(source, target)

    state.modulationState.addModulation(mod)
}