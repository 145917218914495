import { useDraggable } from "@dnd-kit/core";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import Tooltip from "rc-tooltip";
import React, { useEffect } from "react"
import { GraphQL } from "src/generated";
import { useLoopActions } from "src/render/hooks";
import { useAppContext } from "src/store/hooks";
import { Download, Gear, Headphones, Info, InfoFilled, Spinner, Splitter } from "src/svg";
import { ensure } from "src/utils";
import AudionButton from "../Audition/AuditionButton";
import { DragSampleDescription, DragType } from "../DragCore/dragTypes";
//import { Action } from "./Action";
import { Handle } from "../DragCore/Item/components/Handle"
import PopupButton from "../Popup";
import { SampleModes } from "./models";
import EditSampleProperties from "./Properties/EditSampleProperties";



const solutions = [
    {
        name: 'Insights',
        description: 'Measure actions your users take',
        href: '##',
        icon: Gear,
    },
    {
        name: 'Automations',
        description: 'Create your own targeted content',
        href: '##',
        icon: Gear,
    },
    {
        name: 'Reports',
        description: 'Keep track of your growth',
        href: '##',
        icon: Gear,
    },
]


type SampleCardUserProps = {
    sample: SampleModes
}


const SampleCardUser: React.FC<SampleCardUserProps> = observer(({ sample }) => {

    const root = sample.root

    const { attributes, listeners, setNodeRef } = useDraggable({
        id: root.id,
        data: ensure<DragSampleDescription>({
            type: DragType.RAW_SAMPLE,
            id: "-1",
            payload: {
                sampleFile: root
            }
        })
    });

    const { trackActions, uiActions } = useLoopActions()
    const { uiState, userState } = useAppContext()

    const [_splitBusy, setSplitBusy] = React.useState(false)
    const [_splitError, setSplitError] = React.useState<string | undefined>(undefined)

    const pendingStatusState = userState.pendingSampleSplitStatus.get(root.id)

    let splitBusy = _splitBusy
    let splitError: string | undefined = _splitError
    let splitIsPending = false

    switch (pendingStatusState?.__typename) {
        case "SplitSamplePending":
            splitBusy = true
            splitIsPending = true
            break

        case "SplitSampleError":
            splitError = _splitError ?? pendingStatusState.message
            break

        default: //fallthrough
        case "SplitSampleSuccess":
            break
    }


    useEffect(() => {
        if (splitBusy) {
            trackActions.waitForSplit(root).then(() => runInAction(() => {
                trackActions.updateAllPendingSplits()
                uiState.mySampleDirty = true
            }))
        }
    }, [splitIsPending])

    return (
        <div ref={setNodeRef} className="bg-slate-300 rounded-3xl h-fit flex flex-col pb-2" >
            <div className="h-fit flex flex-row">
                <Handle {...listeners} />
                <div className="flex flex-col my-1 mr-2">
                    <p className="font-bold text-base text-left">{root.name}</p>
                    <div className="flex flex-row items-center gap-1">
                        <p className="text-base">BPM:{root.bpm?.toFixed(1)} bars:{(root.beats16ths ?? 16) / 16}</p >

                    </div>

                </div>

                <div className="grow" />

                <div className="grid grid-cols-2 gap-1 mr-1 my-1">
                    <PopupButton
                        button={(<Gear />)} >
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <EditSampleProperties sampleFile={root} />
                        </div>
                    </PopupButton>
                    <button onClick={() => {
                        uiActions.postAlert("Auto BPM not yet implemented", "There is no automatic BPM detection yet. Enter number of bars manually.")
                    }}>
                        <Info />
                    </button>
                    <button onClick={() => trackActions.downloadSample(root)}>
                        <Download />
                    </button>
                </div>

                <button className="btn-blue self-center w-fit mr-3" onClick={() => {
                    //
                }} ><Headphones className="text-white"></Headphones>
                </button>

                {sample.kind === "SampleSingle" &&
                    < Tooltip
                        placement="bottom"
                        trigger={['hover']}
                        overlay={(<div>
                            <span>
                                Split sample into stems
                            </span>
                            <br />
                            {splitError && <span className="text-red-600">{splitError}</span>}
                        </div>)}>
                        <button className="btn-blue self-center w-fit mr-3 bg-red-600 relative" onClick={() => {
                            function runSplit(force = false) {
                                setSplitBusy(true)
                                setSplitError(undefined)
                                trackActions.splitTrack(root, force)
                                    .then((result) => {
                                        switch (result.__typename) {
                                            case "SplitSampleSuccess":
                                                runInAction(() => {
                                                    uiState.mySampleDirty = true
                                                })
                                                break
                                            case "SplitSamplePending":
                                                uiActions.postAlert("Splitting sample", "Splitting sample still in progress. Its can take awhile. Check back in a few minutes by refreshing.")
                                                break
                                            case "SplitSampleError":
                                                setSplitError(result.message)
                                                uiActions.postAlert("Splitting sample failed ", "Error splitting sample:" + result.message)
                                                break
                                        }

                                    }).finally(() => {
                                        setSplitBusy(false)
                                        trackActions.updateAllPendingSplits()
                                    })
                            }

                            if (splitError) {
                                uiActions.postAlert("Splitting sample failed previously", "Splitting sample failed last time. Would you like to try again?", () => { runSplit(true) })
                            } else {
                                runSplit()
                            }
                        }}>
                            {splitError && <InfoFilled className="text-red-500 absolute right-0 top-0 translate-x-1/2 -translate-y-1/2" />}
                            {splitBusy ? <Spinner /> : <Splitter className="text-white" />}

                        </button>
                    </Tooltip>
                }


            </div >
            {sample.kind === "SampleSplit" &&
                sample.children.map((s, idx) => (<StemChildRow key={idx} sample={s} />))
            }

        </div>
    )
});


const StemChildRow: React.FC<{ sample: GraphQL.SampleFileFragment }> = ({ sample }) => {

    const { trackActions } = useLoopActions()
    const { attributes, listeners, setNodeRef } = useDraggable({
        id: sample.id,
        data: ensure<DragSampleDescription>({
            type: DragType.RAW_SAMPLE,
            id: "-1",
            payload: {
                sampleFile: sample
            }
        })
    });

    return (<div ref={setNodeRef} {...attributes} className="flex flex-row items-center h-10" >
        <Handle {...listeners} />
        <p className="text-base">{sample.stemType}</p>
        <div className="grow" />
        <button
            className="mr-6"
            onClick={() => trackActions.downloadSample(sample)}>
            <Download />
        </button>

        <AudionButton sample={sample} />
    </div>)
}

export default SampleCardUser;
