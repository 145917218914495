

import * as Tone from 'tone'
import { Note, Ratios, Track } from "src/store/sound/Track";
import { TransportTime } from "tone/build/esm/core/type/Units";
import { Disposable, combineDisposables, emptyDisposable } from "src/utils/disposable";
import { TransportContext } from "../audio/TransportContext";
import { IdentifierFactory, TrackID } from 'src/store/identifiers/identifiers';
import { PartialMap, assertNotNull, isNotNull, rangeFromTo } from 'src/utils';

export interface AnimationTrackTick {
    lerp: number //[0,1]
}

interface PlaybackItem {
    track: Track,
    dispose: Disposable,
    value: () => number
}

interface NotePlayed {
    time: Tone.Unit.Time,
    note: string,
    velocity(): number
}

class AnimationSequence implements Disposable {

    private playbackItem?: PlaybackItem
    private envelope?: Tone.Envelope
    private part?: Tone.Part
    private notesPlaying: NotePlayed[] = []
    private id = 0

    static count = 0

    constructor(
        private transport: TransportContext,
        private track: Track) {

        this.id = AnimationSequence.count

        AnimationSequence.count = AnimationSequence.count + 1

    }

    get trackID(): TrackID {
        return IdentifierFactory.makeTrack(this.track)
    }

    async onBegin(): Promise<void> {


        this.playbackItem = {
            track: this.track,
            dispose: emptyDisposable,
            value: () => {
                assertNotNull(this.envelope)
                return this.envelope.getValueAtTime(Tone.immediate())
            }
        }

        this.updatePlaybackItem()

        return Promise.resolve()
    }

    private updatePlaybackItem() {
        assertNotNull(this.playbackItem)


        this.playbackItem.dispose.dispose()

        if (Object.values(this.track.modulationLayer.notes).filter(isNotNull).findIndex(x => x.enabled) === -1) {
            return
        }

        const { dispose, envelope, part } = this.queueAnimationLayerEnvelope(
            this.track,
            this.track.modulationLayer.envelope)

        this.envelope = envelope
        this.part = part

        this.playbackItem.dispose = dispose
    }

    private clear() {
        this.playbackItem?.dispose.dispose()
        this.playbackItem = undefined
    }

    onEnd() {
        this.clear()
    }

    dispose() {
        this.clear()
        this.updatePlaybackItem
    }

    invalidate() {
        this.updatePlaybackItem()
    }

    private queueAnimationLayerEnvelope(
        track: Track,
        envelopeData: Ratios): {
            dispose: Disposable,
            envelope: Tone.Envelope
            part: Tone.Part
        } {


        // const timeEvents = track.notes.reduce((acc, note, index) => {
        //     if (!note.enabled) {
        //         return acc
        //     }
        //     const bar = Math.floor(index / 16)
        //     const quarter = Math.floor((index - bar * 16) / 4)
        //     const sixteenth = index - bar * 16 - quarter * 4
        //     const time = `${bar}:${quarter}:${sixteenth}`
        //     acc.push(time)
        //     return acc
        // }, new Array<TransportTime>())

        // const partTimeEvents: NotePlayed[] = timeEvents.map(x => { return { time: x, note: "C5", velocity: 1 } })
        let envelope: Tone.Envelope
        const bps = (this.transport.bpm() / 60)

        switch (envelopeData.kind) {
            default:
            case "ar":
                envelope = new Tone.Envelope({
                    //undefined, "2n", 0
                    attack: bps * envelopeData.xa,
                    decay: bps * envelopeData.xr,
                    sustain: 0,
                    decayCurve: "linear"
                })
        }

        this.notesPlaying = AnimationSequence.calculateNotes(track.beats16ths, track.modulationLayer.notes)

        const part = new Tone.Part(((time, note) => {
            //console.log(`Starting attack: ${time}`)
            envelope.triggerAttack(time, note.velocity())
        }), this.notesPlaying).start(0)

        const numberOfBars = (track.beats16ths / 16)
        part.loop = true
        part.loopEnd = `${numberOfBars}:0`

        return {
            dispose: combineDisposables(envelope, part),
            envelope,
            part
        }
    }

    private static calculateNotes(length: number, notes: PartialMap<number, Note>): NotePlayed[] {

        const timeEvents = rangeFromTo(0, length).reduce((acc, _0, index) => {
            const note: Note | undefined = notes[index]
            if (note === undefined) {
                return acc
            }
            const time = AnimationSequence.BeatToTime(index);
            acc.push({ time, velocity: () => note.velocity })
            return acc
        }, new Array<{ time: Tone.Unit.Time, velocity(): number }>())


        const partTimeEvents: NotePlayed[] = timeEvents.map(({ time, velocity }) => {
            return {
                time,
                note: "C5",
                velocity
            }
        })
        return partTimeEvents
    }

    private static BeatToTime(index: number): TransportTime {
        const bar = Math.floor(index / 16);
        const quarter = Math.floor((index - bar * 16) / 4);
        const sixteenth = index - bar * 16 - quarter * 4;
        const time = `${bar}:${quarter}:${sixteenth}`;
        return time;
    }

    private static applyDelta(part: Tone.Part, playing: NotePlayed[], queued: NotePlayed[]) {
        const missingReducer = (list: NotePlayed[]) => (acc: NotePlayed[], next: NotePlayed): NotePlayed[] => {
            const found = list.find(x => x.time === next.time)
            if (!found) {
                acc.push(next)
            }
            return acc
        }

        const queuedItemShouldStart = queued.reduce(missingReducer(playing), [])
        const playingItemShouldStop = playing.reduce(missingReducer(queued), [])

        playingItemShouldStop.forEach(({ time }) => {
            if (!part.at(time)) {
                console.warn("Removing Time at :" + time + " that wasn't registered")
                return
            }

            part.remove(time)
        })

        queuedItemShouldStart.forEach((value) => {
            part.at(value.time, value.note)
        })

    }

    onRenderTick(): AnimationTrackTick {
        //console.log(this.playbackItem?.value() ?? 0)
        return { lerp: this.playbackItem?.value() ?? 0 }
    }


    editEnvelope(ratios: Ratios) {
        const bps = (this.transport.bpm() / 60)

        if (!this.envelope) {
            return
        }
        switch (ratios.kind) {
            default:
            case "ar":
                this.envelope.attack = bps * ratios.xa
                this.envelope.decay = bps * ratios.xr
        }
    }
}

export default AnimationSequence
