
export interface Success<R> {
    kind: "Success"
    value: R
}

export const success = <R>(r: R): Success<R> => {
    return {
        kind: "Success",
        value: r
    }
}

export interface Failure {
    kind: "Failure"
    error?: Error
}

export const failure = (): Failure => {
    return { "kind": "Failure" }
}

