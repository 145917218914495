import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { Button, IconButton, Stack, ThemeProvider, createTheme } from "@mui/material";
import { Box } from "@mui/system";
import classNames from "classnames";

import React, { useEffect, useState } from "react"
//import { Handle } from "..";
import { Track } from "src/store/sound/Track";
import styles from './SampleRow.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { observer } from "mobx-react-lite";
import { useAppContext } from "src/store/hooks";
import { useLoopActions } from "src/render/hooks";
import { WaveGrid } from "./WaveGrid/WaveGrid";
import VolumeMeter from "../VolumeMeter";
import VolumeSlider from "../VolumeSlider";
import { action } from "mobx";
import { Handle } from "../DragCore/Item/components";



type Props = {
    trackInfo: Track
    dragProps?: DragProps
    removeItem?(trackId: string): void
}


export type DragProps = {
    listeners: DraggableSyntheticListeners;
}



const SampleRow: React.FC<Props> = observer(({ trackInfo, dragProps }) => {

    const trackIndex = trackInfo.trackIndex
    const state = useAppContext()

    const trackPlaybackState = state.audition.getTrackPlaybackState(trackInfo.trackId)

    const { trackActions: actions } = useLoopActions()

    const [expanded, setIsExpanded] = useState(false)
    const [barToShow, setBarToShow] = useState(0)

    const onClickRemove = () => {
        actions.removeTrack(trackInfo, state)
    }

    return (<div className="flex flex-row">
        <div className="flex flex-col">
            <Stack direction="row">
                <Box className={classNames(styles.Handle)} {...dragProps?.listeners}>
                    <Handle />
                </Box>
                <WaveGrid barToShow={barToShow} trackInfo={trackInfo} />
            </Stack>

            <SampleFooter
                onChangeExpanded={(expanded) => {
                    setIsExpanded(expanded)
                }}

                trackIndex={trackIndex}
                trackInfo={trackInfo}
                expanded={expanded}
            />
        </div >

        <div className={
            classNames(styles.RowEnd,
                "flex",
                "grow",
                "w-fit",
                "pb-1",
                "border-r-2",
                "border-black",
                "items-start",
            )}>
            <VolumeMeter
                volume={(trackPlaybackState?.levelMeter ?? 1) * 100}
                style={{
                    backgroundColor: "white",
                }}
            />
            <VolumeSlider
                volume={trackInfo.audioLayer.volume * 100}
                onChange={action((volume: number) => {
                    trackInfo.audioLayer.volume = volume / 100
                })}
            />
            <IconButton aria-label="play/pause" sx={{ padding: "0", margin: "0px" }} onClick={onClickRemove} >
                <CloseIcon sx={{ margin: "5px", width: "20px", height: "20px", padding: "0" }} />
            </IconButton>
        </div>
    </div>
    );
})

export default SampleRow

const normalButton = createTheme({ palette: { primary: { main: '#CCC' } } })
const soloButton = createTheme({ palette: { primary: { main: '#0F0' } } })
const muteButton = createTheme({ palette: { primary: { main: '#F00' } } })

const disabledButton = createTheme({
    palette: {
        action: {
            disabledBackground: '#999',
            //disabled: 'set color of text here'
        }
    }
});

interface SampleFooterProps {
    trackInfo: Track,
    trackIndex: number,

    expanded: boolean
    onChangeExpanded(expanded: boolean): void

}

// const styles = createTheme({
//     disabledButton: {
//         backgroundColor: 'red'
//     }
// })

const SampleFooter: React.FC<SampleFooterProps> = observer(({
    trackInfo,
    expanded: _expanded,
}) => {

    const [_, setExpanded] = useState(_expanded)
    useEffect(() => {
        setExpanded(_expanded)
    }, [_expanded])


    const { audition } = useAppContext()

    const onSolo = () => {
        audition.toggleSolo(trackInfo.trackId)
    }

    const onMute = () => {
        audition.toggleMute(trackInfo.trackId)
    }

    const isMutedState = audition.getMutedState(trackInfo.trackId)
    const isSoloed = audition.isSoloed(trackInfo.trackId)

    const [stream, setStream] = React.useState<MediaStream | undefined>(undefined)

    return (<div className={classNames(styles.SampleRowFooter)} >
        <ThemeProvider theme={isMutedState === "muted" ? muteButton :
            isMutedState === "implicitlyMuted" || isSoloed ? disabledButton :
                normalButton}>
            {/* <ThemeProvider theme={disabled}> */}
            <Button
                variant="contained" size='small'
                disabled={isMutedState === "implicitlyMuted" || isSoloed}
                onClick={onMute}
            > M</Button>
        </ThemeProvider>
        <ThemeProvider theme={isSoloed ? soloButton : normalButton} >
            <Button
                disableRipple={true}
                variant="contained" size='small'
                onClick={onSolo}

            >S</Button>
        </ThemeProvider>
        <p
            //sx={{ fontSize: 14, textAlign: "left", marginLeft: "10px", width: "100%" }} variant="body1"
            className="text-sm text-left ml-2 w-full"
        >
            {`${trackInfo.sampleFile.name}`}
        </p>
    </div >)
})


