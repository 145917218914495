import React from 'react';
import { TrackWrappedItems, WrappedItemsProps } from './TrackWrappedItems';
import { TransportUI } from '../TransportUI/TransportUI';
import { Droppable } from './Droppable';

export const AudioWindow: React.FC<{ wrappedProps: WrappedItemsProps }> =
  ({ wrappedProps }) => {
    return <div className="flex flex-col-reverse h-full w-full">
      <TransportUI />
      <div style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        // backgroundColor: "green",
        display: "flex",
        flexDirection: "row"
      }}>
        <Droppable dragging={false} id="window3">
          <TrackWrappedItems {...wrappedProps}></TrackWrappedItems>
        </Droppable>
        {/* <BindableLane /> */}
      </div>
    </div>;
  }
