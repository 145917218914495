import React from 'react';
import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';

import styles from './Droppable.module.scss';

interface Props {
  children: React.ReactNode;
  dragging: boolean;
  id: UniqueIdentifier;
}

export function Droppable({ children, id, dragging }: Props) {
  const { isOver, setNodeRef, rect, over } = useDroppable({
    id,
  });

  let isOverContainer = isOver

  if (over?.data?.current?.sortable?.containerId === "SortableTracks") {
    isOverContainer = true
  }

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        styles.Droppable,
        isOverContainer && styles.over,
        dragging && styles.dragging,
        //children && styles.dropped
      )}
      aria-label="Droppable region"
    >
      {children}
    </div>
  );
}
