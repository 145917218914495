import { makeAutoObservable } from "mobx";


export class MeterNode extends AudioWorkletNode {

    data: MeterNodeData = new MeterNodeData()

    constructor(audioContext: AudioContext,
    ) {
        const context = audioContext as any
        super(context._nativeAudioContext as unknown as BaseAudioContext, "vumeter")
        this.port.onmessage = this.onMessage;
    }

    onMessage(event: any) {
        console.log(event)
        this.data.volume = 1.0
    }



}


export class MeterNodeData {
    constructor() {
        makeAutoObservable(this)
    }

    volume = 0

}

export async function registerMeterWorklet(audioContext: AudioContext) {
    await audioContext.audioWorklet.addModule('./worklet/vumeter.js');
}