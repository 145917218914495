import { makeAutoObservable, runInAction } from "mobx";
import { assertNotNull } from "src/utils";
import { AuditionStatePlaying, AuditionStateQueued, AuditionStateStopped } from "./audition";
import { SampleFileID } from "./identifiers/identifiers";
import { getLoopContext } from "./middleware/cinemaMiddleware";
import { TrackPlaybackState } from "./store";

class TransportState {
    constructor() {
        makeAutoObservable(this);
        this.progressIn16ths = 0
    }

    progressIn16ths: number
}

export class AuditionState {

    constructor() {
        makeAutoObservable(this);
        this.sampleAudition = { "kind": "stopped" };
    }


    isPlaying = false;

    transportState: TransportState = new TransportState()

    trackPlaybackState: Map<string, TrackPlaybackState> = new Map();

    sampleAudition: AuditionStateQueued | AuditionStatePlaying | AuditionStateStopped;

    stopAuditionPlaying() {
        this.sampleAudition = {
            kind: "stopped"
        };

        getLoopContext().transport.auditioner.clear();
    }

    *queueAudition(sampleFileID: SampleFileID) {
        this.sampleAudition = {
            kind: "queued",
            currentAudition: sampleFileID
        };

        const auditioner = getLoopContext().transport.auditioner;

        try {
            yield auditioner.load(sampleFileID)
        } catch (e) {
            console.error(e);
            throw e
        }

        // RACE CONDITION
        if (this.sampleAudition.kind !== "queued" ||
            this.sampleAudition.currentAudition.compositeKey !== sampleFileID.compositeKey) {
            return;
        }

        runInAction(() => {
            auditioner.startAudition();
            this.sampleAudition = {
                kind: "playing",
                currentAudition: sampleFileID
            };
        });
    }

    // verifies the existing tracks, and possibly removes stale ones.
    setExistingTracks(existingTracks: string[]) {
        Array.from(this.trackPlaybackState.keys())
            .forEach((trackID) => {
                if (!existingTracks.includes(trackID)) {
                    this.trackPlaybackState.delete(trackID);
                }
            });

        existingTracks.forEach(existingTrack => {
            if (!this.trackPlaybackState.has(existingTrack)) {
                this.trackPlaybackState.set(existingTrack, new TrackPlaybackState())
            }
        })

    }

    toggleSolo(trackId: string) {
        const state = this.getTrackPlaybackState(trackId)
        assertNotNull(state)
        state.solo = state.solo === undefined ? true : !state.solo;
        if (state.solo) {
            state.mute = false;
        }
        this.trackPlaybackState.set(trackId, state);

    }
    toggleMute(trackId: string) {
        const state = this.trackPlaybackState.get(trackId) || new TrackPlaybackState();
        state.mute = state.mute === undefined ? true : !state.mute;
        if (state.mute) {
            state.solo = false;
        }

        this.trackPlaybackState.set(trackId, state);
    }

    getTrackPlaybackState(trackId: string): TrackPlaybackState | undefined {
        const state = this.trackPlaybackState.get(trackId);
        return state
    }



    // If I'm solod, Im not muted..
    // If something else is solo'd, then I'm implicitly muted.
    // Otherwise, am I simply muted.
    getMutedState(trackId: string): "muted" | "implicitlyMuted" | "notMuted" {
        if (this.isSoloed(trackId)) {
            return "notMuted";
        }

        const isAnythingSoloed = Array.from(this.trackPlaybackState.entries()).some(v => v[1].solo);

        if (isAnythingSoloed) {
            return "implicitlyMuted";
        }

        const state = this.trackPlaybackState.get(trackId);
        return (state?.mute ?? false) ? "muted" : "notMuted";
    }

    isSoloed(trackId: string) {
        const state = this.trackPlaybackState.get(trackId);
        return state?.solo ?? false;
    }
}
