import { graphQLCLient } from "src/core/client"
import { ConnectionStatus } from "./types"
import * as GraphQL from "src/generated/graphqlTypes";
import base58 from "bs58"
import { ConnectedState } from "../store";


const SIGNED_MESSAGE = "I want to connect to this site!"

export async function pingConnection(connectedState: ConnectedState): Promise<ConnectionStatus> {
    try {
        const props: any = await (window as any).solana.connect({ onlyIfTrusted: true })
        const { publicKey } = props

        const connectionState = connectedState.state
        if (connectionState === undefined || connectionState.kind === "NotConnected") {
            return {
                kind: "NotConnected"
            }
        }

        if (connectionState.publicKey !== publicKey.toString()) {
            return {
                kind: "NotConnected"
            }
        }

        return await authorize(connectionState.publicKey, connectionState.signature)
    } catch {
        return {
            kind: "NotConnected"
        }
    }
}

export function disconnect() {
    const anyWindow = window as any
    if (anyWindow.solana === undefined) {
        return
    }

    (window as any).solana.disconnect();
}


async function authorize(publicKey: any, signature: string): Promise<ConnectionStatus> {
    const authenticationResult = await graphQLCLient
        .mutation<GraphQL.AuthenticateMutation, GraphQL.AuthenticateMutationVariables>(GraphQL.AuthenticateDocument, { publicKey, signature })
        .toPromise()

    const authResult = authenticationResult.data?.auth?.authorize
    switch (authResult?.__typename) {
        case "AuthorizeSuccess":
            return {
                kind: "Connected",
                publicKey: publicKey.toString(),
                signature
            }

        default:
        case "AuthorizeFailure":
            return {
                kind: "NotConnected",
            }
    }
}


export async function establishConnection(): Promise<ConnectionStatus> {
    try {

        const anyWindow = window as any
        if (anyWindow.solana === undefined) {
            alert("Please install the Solana Phantom wallet")
            return {
                kind: "NotConnected"
            }
        }

        const resp = await (window as any).solana.connect();
        const publicKey = resp.publicKey.toString()

        const encodedMessage = new TextEncoder().encode(SIGNED_MESSAGE);

        const { signature } = await (window as any).solana.signMessage(encodedMessage);

        const encodedSig = base58.encode(signature);
        return authorize(publicKey, encodedSig)
    } catch (err) {
        console.error(err)
        return {
            kind: "NotConnected"
        }
    }
}