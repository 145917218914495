import { IconButton, Popover, TextField } from "@mui/material";
import React from "react";
import { BindableItemProps } from "./BindableItem";
import SettingsIcon from '@mui/icons-material/Settings';
import classNames from "classnames";
import styles from "../Cinema.module.scss"


export const PopupOverSettingsButton = (props: BindableItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const openSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    };

    const closeSettings = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <>
        <IconButton
            color="inherit"
            size="small"
            onClick={(event) => {
                openSettings(event)
            }}
        >
            <SettingsIcon sx={{ fontSize: "medium" }} />

        </IconButton>
        <PopupOverSetting {...props}
            anchor={anchorEl}
            open={open}
            onClose={() => {
                closeSettings()
            }}
            onChangeRange={props.onChangeRange}
        />
    </>
}



export const PopupOverSetting = (props: BindingSettings) => {
    const { open, anchor, onClose } = props
    const id = open ? 'simple-popover' : undefined;

    return (<Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}>
        <UniformSettings {...props} />
    </Popover>)
}


type BindingSettings = {
    open: boolean
    anchor: HTMLButtonElement | null
    onClose: () => void
    onChangeRange?: (type: "min" | "max", value: any) => void
} & BindableItemProps


const UniformSettings = (props: BindingSettings) => {
    return (
        <div className={classNames(styles.PopupUniformSettings)}>
            <TextField
                size='small'
                id="outlined-number"
                label="Range min"
                type="number"
                value={props.rangeMin}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event) => {
                    props.onChangeRange?.("min", Number.parseFloat(event.target.value))
                }} />

            <TextField
                size='small'
                id="outlined-number"
                label="Range max"
                value={props.rangeMax}
                type="number"
                sx={{ marginTop: "10px" }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event) => {
                    props.onChangeRange?.("max", Number.parseFloat(event.target.value))
                }} />
        </div>
    )
}
