import React, { useEffect, useRef, useState } from 'react';

import {
  DndContext,
  DragOverlay,
  DropAnimation,
  MeasuringStrategy,
  UniqueIdentifier,
  defaultDropAnimation,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  NewIndexGetter,
  arrayMove,
} from '@dnd-kit/sortable';

import { NFTItem, RenderItemArgs } from './NFTItem';
import { WrappedItemsProps } from './TrackWrappedItems';
import { DragDescriptions, DragSampleDescription, DragTrackDescription, DragType } from './dragTypes';
import styles from './DndContext.module.scss';
import classNames from 'classnames';
import AnimationWindow from '../hack/AnimationWindow';
import { ShaderMain as MaterialMain } from '../CinemaEditors/Shaders/ShaderMain';
import { AudioWindow } from './AudioWindow';
import { SelectedUIStates } from 'src/store/ui/types';

import { ModelMain } from '../CinemaEditors/Model/ModelMain';
import { LayerElementType } from 'src/store/cinema';
import { NodeEditor } from '../NodeEditor';
import { SampleCardPackFileRow } from '../SampleCard/SampleCardPackFileRow';
import { observer } from 'mobx-react-lite';
import { PagesState } from 'src/store/store';
import { useAppContext } from 'src/store/hooks';
import { CinemaMain } from '../Cinema/CinemaMain';
import SampleMain from '../SampleCard/SampleMain';

export interface Props {
  //activationConstraint?: PointerActivationConstraint;
  animateLayoutChanges?: AnimateLayoutChanges;
  //adjustScale?: boolean;
  //collisionDetection?: CollisionDetection;
  //Container?: any; // To-do: Fix me
  dropAnimation?: DropAnimation | null;
  getNewIndex?: NewIndexGetter;
  handle?: boolean;
  //measuring?: MeasuringConfiguration;
  //modifiers?: Modifiers;
  renderItem?: (args: RenderItemArgs) => React.ReactElement;
  reorderItems?: typeof arrayMove;
  //strategy?: SortingStrategy;
  getItemStyles?(args: {
    id: UniqueIdentifier;
    index: number;
    isSorting: boolean;
    isDragOverlay: boolean;
    overIndex: number;
    isDragging: boolean;
  }): React.CSSProperties;
  // wrapperStyle?(args: {
  //   index: number;
  //   isDragging: boolean;
  //   id: string;
  // }): React.CSSProperties;
  isDisabled?(id: UniqueIdentifier): boolean;

  // Loop NFT's
  sampleProps: SampleProps

}

const defaultDropAnimationConfig: DropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
};

interface SampleProps {
  postNewOrder(ids: string[]): void
  addTrack(info: DragSampleDescription): void

  items: DragTrackDescription[]

  onSongDragging(enabled: boolean): void
}


type AllFooterUIStates = LayerElementType | "audio" | "none"



const aciveUISelector = (state: PagesState) => {
  const primaryUIState = state.uiState.selectedState
  const selectedCinemaElementKind = state.uiState.selectedElements.selectedElement?.kind


  const footerUIState: AllFooterUIStates = (() => {
    switch (primaryUIState) {
      case "audio":
        return "audio"
      case "wiring":
        return "none"
      case "cinema":
        switch (selectedCinemaElementKind) {
          case "LayerMaterialIdentifier":
            return "material"
          case "LayerModelIdentifier":
            return "model"
          case "LayerShaderIdentifier":
          default:
            return "shader"
        }
    }
  })()

  return {
    primaryUIState,
    footerUIState
  }
}


export const LoopDndContext: React.FC<Props> = observer(({
  animateLayoutChanges,
  dropAnimation = defaultDropAnimationConfig,
  getItemStyles = () => ({}),
  getNewIndex,
  handle = false,
  isDisabled = (): boolean => false,
  renderItem,
  reorderItems = arrayMove,
  //////
  sampleProps
}) => {

  // const [items2, setItems] = useState<string[]>(
  //   () =>
  //     initialItems ??
  //     createRange<string>(itemCount, (index) => (index + 1).toString())
  // );

  const [samplePropsItems, setSamplePropsItems] = useState(sampleProps.items)
  // const samplePropsItems = sampleProps.items
  // const setSamplePropsItems = (any) => { return }

  useEffect(() => {
    setSamplePropsItems(sampleProps.items)
  }, [sampleProps.items]); // Only re-run the effect if count changes

  const handleRemove = (trackId: string) => {
    setSamplePropsItems(samplePropsItems.filter(x => x.id !== trackId))
  }

  const [activeDrag, setActiveDrag] = useState<DragDescriptions | null>(null);

  const isFirstAnnouncement = useRef(true);
  const getIndex = (itemId: string): number => {
    const items: DragTrackDescription[] = samplePropsItems

    return items.findIndex(desc => desc.id === itemId)
  }
  const activeIndex = activeDrag ? getIndex(activeDrag.id) : -1;

  useEffect(() => {
    if (!activeDrag) {
      isFirstAnnouncement.current = true;
    }
  }, [activeDrag]);


  const state = useAppContext()

  const { primaryUIState, footerUIState } = aciveUISelector(state)

  const wrappedProps: WrappedItemsProps = {
    items: samplePropsItems,
    handle,
    renderItem,
    isDisabled,
    animateLayoutChanges,
    getNewIndex,
    dropAnimation,
    removeItem: handleRemove,
  }

  return (
    <DndContext
      //announcements={announcements}
      //screenReaderInstructions={screenReaderInstructions}
      // sensors={sensors}
      //collisionDetection={collisionDetection}
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }

        if (active.data.current?.type === DragType.RAW_SAMPLE) {
          setActiveDrag(active.data.current as DragSampleDescription);
          sampleProps.onSongDragging(true)
        } else {
          setActiveDrag({
            type: DragType.TRACK,
            id: active.id
          });
        }

      }}
      onDragEnd={({ over }) => {
        sampleProps.onSongDragging(false)
        setActiveDrag(null);

        if (over && activeDrag) {
          const overIndex = getIndex(over.id);

          switch (activeDrag.type) {
            case DragType.TRACK:
              if (activeIndex != -1) {
                if (activeIndex !== overIndex) {
                  sampleProps.postNewOrder(reorderItems(samplePropsItems.map(x => x.id), activeIndex, overIndex));
                }
              }
              return
            case DragType.RAW_SAMPLE:
              sampleProps.addTrack(activeDrag)
          }
        }
      }
      }
      onDragOver={({ over }) => {
        if (over && over.id) {
          //  console.log("over2:" + over.id)
        }
      }}
      // onDragCancel={() => setActiveId(null)}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
    // modifiers={modifiers}
    >
      <div className={classNames(styles.MainContainer)}>
        <div className="h-1/3 w-full">
          <FooterView footerUIState={footerUIState} />
        </div>
        <div className={classNames(styles.topHalfWindow)}>
          <div className={classNames(styles.topLeftWindow)}>
            <PrimaryWindow wrappedProps={wrappedProps} uiState={primaryUIState} />
          </div>
          <div className={classNames(styles.topRightWindow)}>
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <AnimationWindow />
            </div>
            {/* <TabBar tabs={[
              {
                tabName: "Render",
                children: (
                  // <AnimationWindow style={{ width: "500px", height: "100%" }} />
                  <div style={{ display: "flex", height: "100%", width: "100%" }}>
                    <AnimationWindow style={{ width: "100%", height: "100%" }} />
                  </div>
                )
              },
              {
                tabName: "Samples",
                children: <SelectedSamplePack />
              }
            ]} /> */}
            {/* <div style={{ display: "flex", height: "100%", width: "100%", backgroundColor: "green" }}>
              <AnimationWindow style={{ width: "100%", height: "100%" }} />
            </div> */}
          </div>
        </div>
      </div>

      <DragOverlay>
        {activeDrag ? 
          (activeDrag.type === DragType.RAW_SAMPLE ? (
            <SampleCardPackFileRow samplePackFile={activeDrag.payload.sampleFile} />
          ) :
            (
              <NFTItem
                value={activeIndex != -1 ? samplePropsItems[activeIndex].id : null}
                renderItem={renderItem}
                payload={activeIndex != -1 ? samplePropsItems[activeIndex] : null}
                style={getItemStyles({
                  id: activeIndex != -1 ? samplePropsItems[activeIndex].id : "",
                  index: activeIndex,
                  isSorting: activeDrag !== null,
                  isDragging: true,
                  overIndex: -1,
                  isDragOverlay: true,
                })}
                dragOverlay
                dragData={activeDrag}
              />
            ))

          : null}
      </DragOverlay>
    </DndContext >
  );
})


const PrimaryWindow: React.FC<{
  uiState: SelectedUIStates,
  wrappedProps: WrappedItemsProps
}> = ({ uiState, wrappedProps }) => {

  switch (uiState) {
    case "audio": return (<AudioWindow wrappedProps={wrappedProps} />)
    case "cinema": return (<CinemaMain />)
    case "wiring": return (<NodeEditor />)
  }
}

export interface SortableItemProps {
  animateLayoutChanges?: AnimateLayoutChanges;
  disabled?: boolean;
  getNewIndex?: NewIndexGetter;
  id: string;
  index: number;
  handle?: boolean;
  useDragOverlay?: boolean;
  onRemove?(id: string): void;
  style?: (values: any) => React.CSSProperties;
  renderItem?(args: any): React.ReactElement;
  wrapperStyle?: ({
    index,
    isDragging,
    id,
  }: {
    index: number;
    isDragging: boolean;
    id: string;
  }) => React.CSSProperties;
}





const FooterView: React.FC<{ footerUIState: AllFooterUIStates }> = ({ footerUIState }) => {

  const { uiState } = useAppContext()

  switch (footerUIState) {
    case "audio":
      // return <SampleSearchView />
      return <SampleMain />
    // return <SamplePackList />
    case "material":
      return <MaterialMain shaderType={footerUIState} shaderViewState={uiState.shaderViewState} />
    case "shader":
      return <MaterialMain shaderType={footerUIState} shaderViewState={uiState.shaderViewState} />
    case "model":
      return <ModelMain />
    case "none": return null
  }
}

