import React from 'react';
import './App.css';
import MyApp from './old_pages/MyApp';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       
        {/* <Home categories={getNavigation()}/> */}
        <MyApp/>
      </header>
    </div>
  );
}

export default App;
