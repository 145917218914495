import { action, flowResult, isFlowCancellationError, runInAction, toJS } from "mobx"
import { MiddleWareContext } from "src/render/context"
import { MeshTypeFromString } from "src/render/visual/factory/meshFactory"
import { assert } from "src/utils"
import { IdentifierFactory, LayerIdentifiable, LayerMaterialID, LayerShaderID } from "../identifiers/identifiers"
import { PagesState } from "../store"
import { Visit } from "../visit"
import { search } from "./search"
import { BackgroundLayer, ForgroundModelLayer, LayerNameKeys, LayerShader, LayerType, PostProcessLayer, ShaderSummary, UniformTypes } from "./types"

// eslint-disable-next-line @typescript-eslint/no-namespace
export class CinemaActions {


    constructor(
        private context: MiddleWareContext,
        private state: PagesState
    ) {

    }

    clearModulationForLayer = action((layerName: LayerNameKeys) => {
        this.state.modulationState
            .modulations
            .filter((mod) => mod.target.layerName === layerName)
            .forEach((mod) => {
                this.state.modulationState.removeModulation(mod)
            })
    })

    clearModulatiorShader = action((shader: LayerShaderID | LayerMaterialID) => {
        this.state.modulationState
            .modulations
            .filter((mod) => mod.target.layerShaderID.compositeKey === shader.compositeKey)
            .forEach((mod) => {
                this.state.modulationState.removeModulation(mod)
            })
    })

    removeLayer = action((layerName: LayerNameKeys) => {
        const layer = this.state.cinemaState.removeLayer(layerName)

        this.clearModulationForLayer(layerName)
        Visit.allShaders([layer]).forEach(shader => {
            removeShaderFromBindings(layer, shader, this.context)
        })
    })

    assignModelToSelectedLayer = action((model: string) => {
        const state = this.state
        if (!state.uiState.selectedElements.selectedElement) {
            return
        }
        const selectedElement = state.uiState.selectedElements.selectedElement

        if (selectedElement.kind === "LayerModelIdentifier") {

            const layer = state.cinemaState.getLayer(selectedElement.layerName) ?? this.makeNewLayer(selectedElement)
            assert(layer instanceof ForgroundModelLayer, "Expected ForgroundModelLayer")

            runInAction(async () => {
                layer.model = {
                    meshType: MeshTypeFromString(model)
                }
                state.renderCore.queueReloadLayer(layer.info.layerName)
            })
        }
    })

    makeNewLayer = action(
        (selectedelement: LayerIdentifiable): LayerType => {
            const state = this.state
            if (search.findLayerWithName(selectedelement.layerName, state.cinemaState) !== undefined) {
                throw new Error(`Layer name already exists ${selectedelement.layerName}`)
            }

            let layer: LayerType
            switch (selectedelement.layerKind) {
                case "backgroundLayer":
                    layer = new BackgroundLayer({
                        layerKind: "backgroundLayer",
                        id: `${selectedelement.layerName}_id`,
                        layerName: selectedelement.layerName
                    })
                    break
                case "postProcessLayer":
                    layer = new PostProcessLayer({
                        layerKind: "postProcessLayer",
                        id: `${selectedelement.layerName}_id`,
                        layerName: selectedelement.layerName
                    })
                    break

                case "forgroundModelLayer":
                    layer = new ForgroundModelLayer({
                        id: `${selectedelement.layerName}_id`,
                        layerKind: "forgroundModelLayer",
                        layerName: selectedelement.layerName,
                    })
                    break

            }

            state.cinemaState.addLayer(selectedelement.layerName, layer)
            return layer
        })


    removeShader = action((layer: LayerType) => {

        switch (layer.kind) {
            case "backgroundLayer":
            case "postProcessLayer":
                if (layer.shader) {
                    this.clearModulatiorShader(IdentifierFactory.makeLayerShaderIdentifier(layer.info))
                    removeShaderFromBindings(layer, layer.shader, this.context)
                }
                layer.shader = undefined
                break
            case "forgroundModelLayer":
                if (layer.materialShader) {
                    this.clearModulatiorShader(IdentifierFactory.makeLayerMaterialIdentifier(layer))
                    removeShaderFromBindings(layer, layer.materialShader, this.context)
                }
                layer.materialShader = undefined
        }
    })


    assignShaderToLayer = action((layer: LayerType, shader: ShaderSummary) => {
        this.state.uiState.cancelPendingShaderAssignment()

        this.removeShader(layer)

        const flow = flowResult(layer.assignShader(toJS(shader)))

        flow.catch(error => {
            if (isFlowCancellationError(error)) {
                console.log(`Canceled Shader Assigned: ${error}`)
            } else {
                console.log(`Failed to assign Shader: ${error}`)
            }
        }).then(() => {
            this.state.renderCore.queueReloadLayer(layer.info.layerName)
        })
        this.state.uiState.pendingShaderAssignment = flow
    })
}


function removeShaderFromBindings(layer: LayerType, shader: LayerShader, context: MiddleWareContext) {


    Object.values(shader.uniforms).map((uniform: UniformTypes) => {

        let shaderID: LayerShaderID | LayerMaterialID
        switch (layer.kind) {
            case "backgroundLayer":
            case "postProcessLayer":
                shaderID = IdentifierFactory.makeLayerShaderIdentifier(layer)
                break

            case "forgroundModelLayer":
                shaderID = IdentifierFactory.makeLayerMaterialIdentifier(layer)
        }

        const uniformID = IdentifierFactory.makeUniformIdentifier(shaderID, uniform.name)
        return uniformID
    }).forEach((uniformID) => context.binding.removePreset(uniformID))
}
