
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from "../Cinema.module.scss";
import { IconButton, Tooltip } from '@mui/material';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { GizmosType } from 'src/store/ui/types';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

type GizmosRowProps = {
    enabled: boolean
    onToggleGizmos: (type: GizmosType) => void
    selectedGizmo?: GizmosType
};



export const GizmosRowView: React.FC<GizmosRowProps> = ({
    enabled,
    onToggleGizmos,
    selectedGizmo: _selectedGizmo,
}) => {

    const gizmos: GizmosType[] = [
        // "reset",
        // "position",
        // "scale",
        "camera",
        "rotate"
    ]


    const [enabledGizmos, setEnabledGizmos] = useState<GizmosType | undefined>(undefined)


    useEffect(() => {
        setEnabledGizmos(_selectedGizmo)
    }, [_selectedGizmo])


    return (<div className={classNames(styles.GizmosRowView, styles.enabled)}>
        {gizmos.map(g => {
            const isSelected = g === enabledGizmos
            return (<Tooltip key={g}
                title={labelName(g)}>
                <IconButton
                    //color={isSelected ? "ff0" : "primary"}
                    component="span"
                    disabled={!enabled}
                    size='small'
                    style={{ color: isSelected && enabled ? "#CC5" : undefined }}
                    onClick={() => onToggleGizmos(g)}
                >
                    {pickIcon(g)}
                </IconButton>
            </Tooltip>)
        })}

        {/* <IconButton color="primary" component="span" size='small'
            onClick={() => onSelect("camera")}
        >
            <PhotoCameraBackIcon />
        </IconButton> */}

    </div >);
};


const pickIcon = (gizmo: GizmosType): ReactJSXElement => {
    switch (gizmo) {
        case "reset":
            return (<CenterFocusWeakIcon />)
        case "position":
            return <ThreeSixtyIcon />
        case "scale":
            return <MoveDownIcon />
        case "rotate":
            //   return <AspectRatioIcon />
            return <ThreeSixtyIcon />
        case "camera":
            return <DocumentScannerIcon />

    }
}

const labelName = (gizmos: GizmosType): string => {
    switch (gizmos) {
        case "camera":
            return "pan"
        case "rotate":
            return "rotate"
        default:
            return "none"
    }
}