

import LRUCache from 'lru-cache';
import {  AsyncStateLoader } from 'src/utils/async';
import { fetchAudioBuffer } from './Transport';
import * as GraphQL from "src/generated/graphqlTypes"
import { IdentifierFactory } from 'src/store/identifiers/identifiers';


type AudioLoaderState = AsyncStateLoader<Promise<AudioBuffer>, AudioBuffer>

export class AudioLoader {

    private _cache: LRUCache<string, AudioLoaderState> = new LRUCache({
        max: 50,
    })


    async load(sampleFile: GraphQL.SampleFileFragment): Promise<AudioBuffer> {

        const samplePackEntryId = IdentifierFactory.makeSampleFileID(sampleFile)
        const cacheState = this._cache.get(samplePackEntryId.compositeKey)

        if (cacheState === undefined) {

            const url = sampleFile.audioURL

            const promise = fetchAudioBuffer(url)
            this._cache.set(url, {
                kind: "loading",
                props: promise
            })

            const buffer = await promise
            this._cache.set(url, {
                kind: "loaded",
                props: buffer
            })
            return buffer

        } else {

            switch (cacheState.kind) {
                case "none":
                    throw new Error("Invalid loading state. Should not be none")
                case "loaded":
                case "loading":
                    return cacheState.props
                case "failed":
                    return Promise.reject(cacheState.error)
            }
        }


    }
}


const gAudioLoader = new AudioLoader()

export default gAudioLoader
