import { IconButton, Link, ListItemText } from '@mui/material';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import * as GraphQL from 'src/generated/graphqlTypes';
import { useAppContext } from 'src/store/hooks';
import { deleteRemoteSafeFile, loadRemoteSaveFile } from 'src/store/serialization/serialization';
import { ExpandableList, Props as NavigationProps } from '../ExpandableList';
import CloseIcon from '@mui/icons-material/Close';
import { S } from '../Utilities/CommonDivs';

export const SaveFilesNavigation: React.FC<{ toggle: () => void }> = observer(({ toggle }) => {

    const isConnected = useAppContext().connect.isConnected

    if (!isConnected) {
        return <NotConnected />
    }
    else return <SafeFilesList toggle={toggle} />
})

const NotConnected: React.FC = () => {

    return <div>Not Connected</div>
}

const SafeFilesList = observer((prop: { toggle: () => void }) => {

    const [fetch, fetchAgain] = GraphQL.useGetSaveFilesQuery()
    const [expanded, setExpanded] = React.useState(true)
    const [isBusy, setIsBusy] = React.useState(false)

    const state = useAppContext()

    React.useEffect(() => {
        fetchAgain({ requestPolicy: "network-only" })
    }, [state.session.identifier])

    const isFetching = isBusy || fetch.fetching

    if (isFetching) {
        return <div>loading...</div>
    }

    const saveFiles = fetch.data?.serialization?.getSaveFiles?.saveFiles

    if (saveFiles === undefined) {
        return <div>Error. No Files...</div>
    }

    interface Item {
        onDelete: () => void
        onClick: () => void
        name: string,
        key: string
    }

    const props: NavigationProps<Item> = {
        expanded,
        display: "Recent Savefiles",
        items: saveFiles.map(saveFile => {
            return {
                name: saveFile.name,
                key: saveFile.identifier,
                onClick: () => {
                    loadRemoteSaveFile(saveFile.identifier, state)
                    prop.toggle()
                },
                onDelete: async () => {
                    setIsBusy(true)
                    await deleteRemoteSafeFile(saveFile.identifier, state)
                    setIsBusy(false)
                    fetchAgain({ requestPolicy: "network-only" })
                }
            }
        }),
        render: (item, index) => {
            return (
                <S.HoriztonalFlex
                    key={`${item.key}_${index}`}

                    style={{ justifyContent: "space-between", paddingLeft: 16 }}
                // button
                // onClick={item.onClick}
                >
                    <Link onClick={item.onClick} >
                        <ListItemText secondary={item.name} />
                    </Link>
                    <IconButton onClick={item.onDelete} >
                        <CloseIcon />
                    </IconButton>
                </S.HoriztonalFlex>
            )
        }

    }

    return (
        <ExpandableList key="Save Files"
            {...props} />
    )
})