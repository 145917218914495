import { Fab } from "@mui/material";
import React, { useEffect } from "react";
import { useAppContext } from "src/store/hooks";
import { observer } from "mobx-react-lite";
import { AlertDialog, AlertDialogProps } from "../Alert";
import { ConnectionActions } from "src/store/connect/actions";

export const ConnectionIcon: React.FC = observer(() => {
    const connection = useAppContext().connect
    const pageState = useAppContext()

    const isConnected = connection.state.kind === "Connected"



    const [openDialog, setOpenDialog] = React.useState<AlertDialogProps | undefined>(undefined)

    useEffect(() => {
        connection.pingConnection()
    }, [])

    return <Fab variant="extended" size="small"
        onClick={
            async () => {
                if (!isConnected) {
                    await connection.establishConnection()
                } else {
                    setOpenDialog({
                        title: "Disconnect",
                        body: "Are you sure you want to disconnect?",
                        close() {
                            setOpenDialog(undefined)
                        },
                        okay() {
                            const connectionActions = new ConnectionActions(pageState)
                            connectionActions.disconnect()
                            setOpenDialog(undefined)
                        },
                        open: true,
                    })
                }
            }}>
        <img
            style={{
                marginRight: "8px",
                textAlign: 'center',
                height: '80%'
            }}
            src={"/icons/phantom-icon-purple.svg"} />
        {isConnected ? "Connected" : "Connect"}
        {openDialog ?
            <AlertDialog
                {...openDialog}
            /> : null}
    </Fab >;
})
