import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid"


export interface Props<Item> {
    expanded: boolean
    display: string,
    items: Item[]
    render: (item: Item, index: number) => React.ReactElement
}

// normal function
export function ExpandableList<T>(props: Props<T>): React.ReactElement<Props<T>> {

    const {
        display,
        expanded: _expanded,
        items,
        render
    } = props

    const [expanded, setExpanded] = useState(_expanded)
    const handleClick = () => {
        setExpanded(!expanded);
    }

    return (
        <div className="flex flex-col items-start">
            <button className="hover:bg-slate-100 py-2 w-full" onClick={handleClick}>
                <div className="flex flex-row ">
                    <span className="text-base underline">{display}</span>
                    {expanded ? <ChevronUpIcon className="h-6" /> : <ChevronDownIcon className="h-6" />}
                </div>
            </button>
            {
                expanded && (
                    <div>
                        {items.map(render)}
                    </div>
                )
            }
        </div>
    )
}
