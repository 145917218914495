import { BindableSourceIdentifiers, BindableTargetIdentifier } from "./NodeEditorContext"
import { LoopBasePortModel } from "./factories/PortModels"

export function sortPorts(first: LoopBasePortModel, second: LoopBasePortModel): {
    source: BindableSourceIdentifiers,
    target: BindableTargetIdentifier
} {

    const source = first.isInput === false ? first.identifier : second.identifier
    const target = first.isInput === true ? first.identifier : second.identifier

    return {
        source,
        target
    } as any
}