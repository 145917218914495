import classNames from 'classnames';
import React from 'react';
import { ShaderSummary } from 'src/store/cinema';
import styles from "../Cinema.module.scss"
import { ShaderBodyView } from './Shader/ShaderBodyView';
import { Header } from './LayerHeaderView';
import { CinemaEmptyLayerProperty } from './CinemaEmptyLayerProperty';
import { IdentifierFactory, LayerID } from 'src/store/identifiers/identifiers';
import { useAppContext } from 'src/store/hooks';
import { observer } from 'mobx-react-lite';
import { action } from "mobx"
import { useLoopActions } from 'src/render/hooks';
type Props = {
    layerID: LayerID,
    shader?: ShaderSummary
    close: () => void
}

export const BackgroundProcessLayerView: React.FC<Props> = observer(({ layerID, shader, close }) => {


    const state = useAppContext()
    const { uiActions } = useLoopActions()
    let selectedElement = undefined
    {
        const _selectedElement = state.uiState.selectedElements.selectedElement
        if (_selectedElement && _selectedElement.layerName === layerID.layerName) {
            switch (_selectedElement.kind) {
                case "LayerShaderIdentifier":
                    selectedElement = "shader"
                    break
                default:
                    throw new Error(`Unexpected Type ${_selectedElement.kind}`)
            }
        }
    }

    const modelSelected = selectedElement === "shader"

    const onSelect = action(() => {
        uiActions.selectElement(IdentifierFactory.makeLayerShaderIdentifier(layerID))
        //state.uiState.selectedElements.selectedElement = 
    })

    return (
        <div className={classNames(styles.LayerCard)}>
            <Header layerName={layerID.layerName} close={close} />

            {shader ?
                <ShaderBodyView shader={shader} selected={modelSelected} onSelect={onSelect} /> :
                <CinemaEmptyLayerProperty name={"Material"} selected={modelSelected} onSelect={onSelect}
                    onEdit={onSelect} />
            }
        </div>)
})


