import { assert } from "chai"
import { observe, reaction, runInAction } from "mobx"
import { search } from "./cinema/search"
import { ForgroundModelLayer } from "./cinema/types"
import { getLoopContext } from "./middleware/cinemaMiddleware"
import { MiddleWareContext } from "../render/context"
import { failure, success } from "./mobxExtensions"
import { PagesState } from "./store"


export function registerRenderEngine(state: PagesState, context: MiddleWareContext) {

    const { renderManager } = context

    observe(renderManager.loadingState, "state", (change) => {
        runInAction(() => {
            console.log(`Render Engine State: ${JSON.stringify(change.newValue)}`)
            state.renderCore.renderEngineState = change.newValue as any
        })
    })

    reaction(() => {
        return {
            isRenderEngineLoading: renderManager.loadingState.isLoadingState,
            queueState: state.renderCore.nextAction
        }
    },
        ({ isRenderEngineLoading, queueState }) => {
            if (isRenderEngineLoading) {
                return
            }

            if (queueState === "none") {
                return
            }

            console.log(`QueueState: ${queueState}`)

            if (queueState === "reloadSession") {
                runInAction(() => {
                    state.renderCore.clearNextAction()
                })
                renderManager.startSession(state.cinemaState.allLayers)
                return
            }

            const action = state.renderCore.popNextAction()
            const layer = search.findLayerWithNameRequired(action.layerName, state.cinemaState)
            renderManager.replaceLayer(action.layerName, layer)
        }, {
        fireImmediately: true
    })

    runInAction(() => state.renderCore.queueReloadSession())

    reaction(() => {
        return {
            layers: state.cinemaState.allLayers,
            initialized: state.renderCore.engineInitialized,
        }
    },
        ({ initialized }) => {
            if (initialized) {
                state.renderCore.queueReloadSession()
            }
        }, {
        fireImmediately: true
    })

    reaction(() => {
        return {
            layers: state.cinemaState.allLayers.length,
            initialized: state.renderCore.engineInitialized,
        }
    },
        ({ initialized }) => {
            if (initialized) {
                state.renderCore.queueReloadSession()
            }
        }, {
        fireImmediately: true
    })

    // Change Gizmos on Select
    reaction(() => {
        const element = state.uiState.selectedElements?.selectedElement
        const isRenderEngineLoading = state.renderCore.isRenderEngineLoading

        if (!isRenderEngineLoading && element?.kind === "LayerModelIdentifier") {
            const layer = search.findLayerFromID(element, state.cinemaState)
            if (!layer) {
                return failure()
            }
            assert(layer instanceof ForgroundModelLayer, "Should be foreground layer")

            return success({
                layer,
                gizmosState: state.uiState.selectedElements.gizmosState
            })
        }
        return failure()
    }, (result) => {
        switch (result.kind) {
            case "Failure":
                getLoopContext().renderManager.performAction().clearGizmos()
                return
            case "Success":
                const { layer: modelLayer, gizmosState } = result.value
                getLoopContext().renderManager.performAction()
                    .enableGizmos(modelLayer.info.layerName, gizmosState)
                    .add((gizmoState) => {
                        if (gizmoState.camera) {
                            const serializedData = JSON.stringify(gizmoState.camera.serialize())

                            runInAction(() => {
                                modelLayer.camera.serializedData = serializedData
                            })
                        }

                        if (gizmoState.meshWorldViewMatrix) {
                            const meshWorldViewMatrix = gizmoState.meshWorldViewMatrix
                            runInAction(() => {
                                modelLayer.meshParameters = meshWorldViewMatrix
                            })
                        }
                    })
        }
    })
}
