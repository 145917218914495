import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ShaderSummary } from 'src/store/cinema';
import styles from "../../Cinema.module.scss";
import { Typography } from '@mui/material';
import { useFetchShaderSummaryQuery } from 'src/generated/graphqlTypes';


type MaterialViewProps = {
    onSelect: () => void
    selected: boolean
    shader: ShaderSummary;
};
export const MaterialView: React.FC<MaterialViewProps> = ({ shader, selected, onSelect }) => {
    const [screenshot, setScreenshot] = useState<string | undefined>(undefined);

    const [result] = useFetchShaderSummaryQuery({
        variables: {
            type: shader.type,
            shaderID: shader.id,

        }
    })

    useEffect(() => {
        setScreenshot(result.data?.shader?.fetchShader?.summary?.screenshotURL)
    }, [result.data]);

    return (
        <div className={classNames(styles.ShaderSmallView, selected && styles.Selected)} onClick={onSelect}>
            <Typography>Shader</Typography>
            <div className={classNames(styles.ShaderImageEdit)}>
                <img style={{ width: "80px", height: "60px", objectFit: "cover" }} src={screenshot} />
                {/* <IconButton color="primary" component="span" size='small'
                    onClick={() => {
                        //
                    }}>
                    <EditIcon />
                </IconButton> */}
            </div>
        </div>);
};

