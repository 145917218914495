
import styled from '@emotion/styled';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace S {
	export const HoriztonalFlex = styled.div`
		display: flex;
		margin-top: 1px;
		align-items: center;
	`;

	export const VerticalFlex = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1px;
	align-items: center;
	`;
}