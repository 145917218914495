
import { RenderManager } from "src/render/visual/RenderManager";
import { BindingContext } from "src/render/sequencing/binding";
import { makeBindingLoader } from "../bindings";
import { AnimationSequencer } from "src/render/sequencing/AnimationSequencer";
//import { loadUserDataFromStorageAction } from "../root/actions";
import { PagesState } from "../store";
import { gState } from "..";
import * as BABYLON from "@babylonjs/core";
import { NFTTransport } from "src/render/audio/Transport";
import { MiddleWareContext } from "../../render/context";


let gContext: MiddleWareContext | undefined

export function getLoopContext(): MiddleWareContext {
    if (!gContext) {
        const msg = "Context is not ready."
        console.error(msg)
        throw new Error(msg)
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return gContext
}

export function startup(engine: BABYLON.Engine) {
    gContext = gContext || makeContext()

    // this may need to move.
    gContext.renderManager.initialize(engine)
}

export function debugSetContext(context: MiddleWareContext) {
    gContext = context
}

function makeContext(getState: () => PagesState = () => gState): MiddleWareContext {

    const binding = new BindingContext(makeBindingLoader(getState))
    const sequencer = new AnimationSequencer()
    const transport = new NFTTransport(binding, sequencer, getState())
    const renderManager = new RenderManager({
        binding,
        transport: transport.makeTransportContext()
    })
    return {
        binding,
        renderManager,
        transport,
        animationSequencer: sequencer
    }
}


