import { autorun, runInAction } from "mobx";
import { graphQLCLient } from "src/core/client";
import { PagesState } from "./store";

import * as GraphQL from 'src/generated/graphqlTypes';




export function registerConnectionSideEffects(state: PagesState) {

    autorun(async () => {
        switch (state.connect.isConnected) {
            case true:
                const saveFile = await graphQLCLient
                    .query<
                        GraphQL.UserDataQuery,
                        GraphQL.UserDataQueryVariables>(GraphQL.UserDataDocument)
                    .toPromise()

                runInAction(() => {
                    state.userState.bookmarkedShaders.clear()
                    state.userState.reportedShaders.clear()
                    state.userState.pendingSampleSplitStatus.clear()

                    const bookmarks = saveFile.data?.user?.userSaveData?.bookmarks?.shaders ?? []
                    const reportedShaders = saveFile.data?.user?.userSaveData?.reportedShaders ?? []
                    const pendingSampleSplitStatus = saveFile.data?.samples.allSplitStatus ?? []
                    bookmarks.forEach((b) => state.userState.bookmarkedShaders.add(b))
                    reportedShaders.forEach((b) => state.userState.reportedShaders.add(b))
                    pendingSampleSplitStatus.forEach((b) => state.userState.pendingSampleSplitStatus.set(b.sampleID, b))
                })

                break;
        }
    })
}