import _ from "lodash";
import { when } from "mobx";
import { deepObserve } from "mobx-utils";
import { serialize } from "serializr";
import { Disposable, combineDisposables } from "src/utils/disposable";
import { createNewUser } from "./newUser";
import { deserializeSong } from "./serialization/serialization";
import { PagesState } from "./store";

export { }
// import { GraphQLFetch } from "src/core/client"
// import { gStore } from "."
// import { CinemaState } from "./cinema"
// import { ConnectedState } from "./connect"
// import { Track } from "./sound/Track"

// type STORAGE_VERSION_TYPE = "2.0"// Make these the same
// const STORAGE_VERSION = "2.0" // Make these the same

// type StoredTrack = Omit<Track, "samplePackEntry">

// interface StorageState {
//     version: STORAGE_VERSION_TYPE
//     tracks: StoredTrack[]
//     cinemaState: CinemaState
//     connect?: ConnectedState
// }

// export interface UsersCreativeState {
//     tracks: Track[]
//     cinemaState: CinemaState
// }
// export function makeDefaultUserCreateState(): UsersCreativeState {
//     return {
//         cinemaState: {
//             layers: {}
//         },
//         tracks: [],
//     }
// }


const LOCAL_STORE_KEY = "mobxState"

export function registerLocalStoreEvents(pageState: PagesState, localStoreKey: string = LOCAL_STORE_KEY): Disposable {

    const writeToStorage = _.debounce((_state) => {
        const json = serialize(_state)
        localStorage.setItem(localStoreKey, JSON.stringify(json))
    }, 200)


    const dispose = deepObserve(pageState, (change, path, root) => {
        writeToStorage(root)
    })

    const dispse2 = when(
        () => (pageState.renderCore.engineInitialized && localStorage.getItem(localStoreKey) !== undefined),
        async () => {
            const jsonString = localStorage.getItem(localStoreKey)
            if (!jsonString) {
                await createNewUser(pageState)
                return
            }

            await deserializeSong(jsonString, pageState, { connect: true })
        })

    return combineDisposables({ dispose }, { dispose: dispse2 })
}

