import React from "react"
import { action } from "mobx"
import { observer } from "mobx-react-lite"
import gAudioLoader from "src/render/audio/AudioLoader"
import { useLoopActions } from "src/render/hooks"
import { Track } from "src/store/sound/Track"
import { rangeFromTo } from "src/utils"
import WaveformRender from "../../WaveformRender"
import { SampleNode } from "../SampleNode"
import { PlaybackMarker } from "./PlaybackMarker"
import { BarToShow } from "./BarToShow"




type WaveGridProps = {
    barToShow?: number
    trackInfo: Track
}

export const WaveGrid: React.FC<WaveGridProps> = observer(({ barToShow: _barToShow = 0, trackInfo }) => {
    const { trackActions: actions } = useLoopActions()

    const [barToShow, setBarToShow] = React.useState(_barToShow)

    return (
        <div className="flex flex-row w-fill h-fit">
            <div className="wave relative" >
                <div className="flex flex-row">
                    {rangeFromTo(barToShow * 16, 16).map(noteIndex => {
                        const last = noteIndex == trackInfo.beats16ths - 1;
                        const noteInfo = trackInfo.modulationLayer.notes[noteIndex];

                        return (
                            <div key={noteIndex}>
                                <SampleNode
                                    index={noteIndex}
                                    last={last}
                                    enabled={noteInfo?.enabled ?? false}
                                    value={noteInfo?.velocity ?? 1.0}

                                    onValueChanged={action((newValue) => {
                                        actions.updateNote(trackInfo, noteIndex, {
                                            velocity: newValue
                                        });
                                    })}

                                    onEnabledChanged={(newEnabled) => actions.updateNote(trackInfo, noteIndex, {
                                        enabled: newEnabled,
                                        velocity: 1.0
                                    })} />

                            </div>);
                    })}


                </div>
                <WaveformRender
                    barToShow={barToShow}
                    style={{
                        // zIndex: 2,

                        position: "absolute",
                        left: "0px", width: "100%", height: "100%", top: "0px", bottom: 0, right: 0,
                        opacity: 1.,
                        pointerEvents: "none"
                    }}
                    fetchPeaks={async () => {

                        return gAudioLoader
                            .load(trackInfo.sampleFile)
                            .then(buffer => {
                                const channelArray = buffer.getChannelData(0);
                                return {
                                    data: channelArray,
                                    numberOfBars: (trackInfo.sampleFile.beats16ths ?? 32) / 16 //TODO: fix this
                                };
                            });
                    }}
                    color="#FFFFFF"
                />
                <PlaybackMarker
                    barToShow={barToShow}
                    numberOf16thNotes={trackInfo.beats16ths}
                />
            </div >
            <BarToShow barToShow={barToShow} numberOfBars={trackInfo.beats16ths / 16} onChange={
                (newBarToShow) => {
                    setBarToShow(newBarToShow)
                }} />
        </div>)
})
