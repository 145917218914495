import React from "react"
import { GraphQL } from "src/generated";
import { useLoopActions } from "src/render/hooks";

type EditSamplePropertiesProps = {
  sampleFile: GraphQL.SampleFileFragment
}


const EditSampleProperties: React.FC<EditSamplePropertiesProps> = ({ sampleFile }) => {

  const numberOfBars = sampleFile.beats16ths / 16
  const [localNumberOfBars, setLocalNumberOfBars] = React.useState(numberOfBars)
  const [localName, setLocalName] = React.useState(sampleFile.name)

  const applyDisabled = numberOfBars === localNumberOfBars && localName === sampleFile.name

  const { trackActions, uiActions } = useLoopActions()

  return (
    <div className="bg-gray-50 p-4 h-fit flex flex-col">
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Name
      </label>

      <input
        type="text"
        className="block w-full rounded-md bg-transparent border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-base sm:text-sm"
        value={localName}
        onChange={(e) => {
          const val = e.target.value

          if (val !== localName) {
            setLocalName(val)
          }
        }}
      />

      <label htmlFor="email" className="mt-2 block text-sm font-medium text-gray-700">
        Number Of Bars
      </label>
      <div className="mt-1 h-fit">
        <input
          type="number"
          step={localNumberOfBars}
          className="block w-fit rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={localNumberOfBars}
          onChange={(e) => {
            const val = parseInt(e.target.value)

            if (val !== localNumberOfBars) {
              setLocalNumberOfBars(val)
            }
          }} />
      </div>
      <button className="btn-blue mt-1 w-fit mr-3 self-end" disabled={applyDisabled} onClick={() => {
        trackActions.updateTrack(sampleFile, {
          numberOfBeatsIn16ths: localNumberOfBars * 16,
          name: localName
        })
        close()
      }} >Apply</button>
      <button className="btn-blue mt-1 w-fit mr-3 self-end" onClick={() => {

        uiActions.postAlert("Are you sure you want to delete this sample?", "Delete Sample", () => {
          //
          trackActions.deleteSample(sampleFile)
          close()
        })
      }}>Delete</button>
 
    </div>
  )
};

export default EditSampleProperties;
