
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useLoopActions } from 'src/render/hooks';
import { search } from 'src/store/cinema/search';
import { useAppContext } from 'src/store/hooks';
import { LayerID } from 'src/store/identifiers/identifiers';
import styles from "../Cinema.module.scss"
import { BackgroundProcessLayerView } from './BackroundProcessLayerView';
import { EditorLayerDetails } from './CinemaLayerLane';
import { ForegroundModelLayerView } from './ForegroundModelLayerView';

interface Props {
    layer: EditorLayerDetails
    currentID: string

}

enum SelectedState {
    none,
    entered,
    selected
}

// const selectCinemaLayer = createSelector(
//     (state: PagesState, layer: LayerID) => {
//         const l = findLayerFromIDRequired(layer, state.cinemaState)
//         return l
//     },
//     (layer) => {
//         layer
//         return "10"
//     }
// )



export const CinemaLayer: React.FC<Props> = observer(({
    layer,

}) => {

    const { cinemaActions } = useLoopActions()

    const [selectedState, setSelectedState] = useState(SelectedState.none)

    const actions: Actions = {
        close: () => cinemaActions.removeLayer(layer.layerID.layerName)
    }


    return (<div
        className={classNames(styles.CinemaLayer,
            selectedState === SelectedState.entered && styles.hover,
            selectedState === SelectedState.selected && styles.selected
        )}
        // onMouseEnter={() => changeState("enter")}
        // onMouseLeave={() => changeState("leave")}
        onClick={(evt) => {
            evt.stopPropagation()
            // changeState("click")
            // onSelected()
        }}
    >
        {factory(layer.layerID, actions)}
    </div>)
})


interface Actions {
    close: () => void
}

const factory = (layerID: LayerID, actions: Actions) => {

    const state = useAppContext()
    const layer = search.findLayerFromID(layerID, state.cinemaState)

    switch (layerID.layerKind) {

        // case "postProcessLayer":
        //     return (<PostProcessLayerView layer={layerInfo} />)
        case "backgroundLayer":

            if (layer !== undefined && layer.kind !== "backgroundLayer") {
                throw new Error("Expected backgroundLayer")
            }

            return (<BackgroundProcessLayerView
                layerID={layerID}
                shader={layer?.shader?.summary}
                close={actions.close}
            />)
        case "forgroundModelLayer": {

            if (layer !== undefined && layer.kind !== "forgroundModelLayer") {
                throw new Error("Expected forgroundModelLayer")
            }

            return (<ForegroundModelLayerView
                layerID={layerID}
                materialShader={layer?.materialShader?.summary}
                model={layer?.model}
                close={actions.close}

            />)
        }
        default:
            return null

    }
}
