import { makeAutoObservable } from "mobx";
import { SelectedUIStates } from "./types";
import { SelectedElementState } from "../store";
import * as GraphQL from 'src/generated/graphqlTypes';
import { Client } from "urql";
import { assertNotNull } from "src/utils";
import { UploaderState } from "./uploader";


export class ShaderViewState {


    constructor() {
        makeAutoObservable(this);
    }

    pendingShaderLoad?: { cancel: () => void; };

    private _shader: GraphQL.ShaderSummaryFragment[] = []
    private _nextPage?: GraphQL.PageInfo
    get allShaders(): GraphQL.ShaderSummaryFragment[] {
        return this._shader
    }

    get isLoading() {
        return this.pendingShaderLoad !== undefined
    }


    get hasNextPage() {
        return this._nextPage?.hasNextPage ?? false
    }
    get nextPage() {
        return this._nextPage?.endCursor
    }

    clearShaders() {
        this._shader = []
    }

    cancelFetch() {
        this.pendingShaderLoad?.cancel()
        this.pendingShaderLoad = undefined
    }

    *loadShaders(client: Client,
        variables: GraphQL.FetchShaderSummariesQueryVariables) {

        const results: GraphQL.FetchShaderSummariesQuery = yield fetchShaderSummaries(client, variables)

        const summaries = results.shader?.fetchSummary?.summaries ?? []
        const nextPage = results.shader?.fetchSummary?.pageInfo
        assertNotNull(nextPage)
        this._nextPage = nextPage

        if (variables.afterPage) {
            this._shader = this._shader.concat(summaries)
        } else {
            this._shader = summaries
        }
    }


}

export type PendingAlert = {
    title: string
    body: string
    okay?: () => void
    cancel: () => void
}


async function fetchShaderSummaries(client: Client,
    variables: GraphQL.FetchShaderSummariesQueryVariables): Promise<GraphQL.FetchShaderSummariesQuery> {

    const useCache = variables?.search?.type !== GraphQL.ShaderSearchType.Random

    const result = await client.query<
        GraphQL.FetchShaderSummariesQuery,
        GraphQL.FetchShaderSummariesQueryVariables>(GraphQL.FetchShaderSummariesDocument,
            variables,
            {
                requestPolicy: useCache ? "cache-first" : "network-only"
            }
        )
        .toPromise()

    assertNotNull(result.data, "Expected to find shader results")
    return result.data
}

export class UIState {
    constructor() {
        makeAutoObservable(this);
        this.selectedState = "audio";
        this.selectedElements = new SelectedElementState();

    }

    draggableId?: string;
    selectedState: SelectedUIStates;
    selectedElements: SelectedElementState;
    shaderViewState: ShaderViewState = new ShaderViewState()

    pendingShaderAssignment?: { cancel: () => void; };
    cancelPendingShaderAssignment() {
        if (this.pendingShaderAssignment) {
            try {
                this.pendingShaderAssignment.cancel();
            } catch {
                console.log("error");
            }
            this.pendingShaderAssignment = undefined;
        }
    }


    pendingAlerts: PendingAlert[] = []



    mySampleDirty = false
    uploadSample?: UploaderState
}
