import React, { useEffect, useRef } from "react";
import { useAppContext } from "src/store/hooks";
import { observer } from "mobx-react-lite";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SceneComponentProps {
    //
    id: string,
    style?: React.CSSProperties
}


// const selectAnimations = createSelector(
//     (state: PagesState) => {
//         return Object.values(state.cinemaState.layers)
//             .filter((x): x is LayerType => x !== undefined)
//             .map(x => x?.id)
//     },
//     (animationLayers) => {
//         return animationLayers
//     }
// )


const SceneComponent: React.FC<SceneComponentProps> = observer((props) => {
    const reactCanvas: React.MutableRefObject<null> = useRef(null);
    const state = useAppContext()

    useEffect(() => {
        if (reactCanvas.current) {

            const engine = state.renderCore.startup(reactCanvas)

            const resize = () => {
                engine.resize();
                //  const size =
                //  return size
            };

            if (window) {
                window.addEventListener("resize", resize);
            }

            return () => {
                engine.dispose();

                if (window) {
                    window.removeEventListener("resize", resize);
                }
            };
        } 
        return void 0;
    }, [reactCanvas]);

    return <canvas
        ref={reactCanvas}
        id={props.id}
        style={{
            height: "100%", width: "100%",
        }}
    />;
})

export default SceneComponent

