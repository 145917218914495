import * as React from 'react';
import { D3DragEvent, DragBehavior, drag } from "d3-drag"
import { Selection, event, select } from "d3-selection"
import _ from 'lodash';


type Position = {
  x: number,
  y: number
}

type Event = React.MouseEvent<HTMLDivElement, MouseEvent>
type DragEvent = D3DragEvent<any, unknown, unknown>

interface Props {
  index: number,
  last: boolean,
  enabled: boolean,
  value: number // Shows the note value [0,1]

  onEnabledChanged: (enabled: boolean) => void
  onValueChanged: (value: number) => void
}

type DragState = {
  envelopeDrag: boolean
  enabled: boolean,
  downPos?: {
    x: number,
    y: number,
    value: number
  }
}

export const SampleNode: React.FC<Props> = ({
  index,
  last,
  enabled: _enabled,
  value,
  onValueChanged,
  onEnabledChanged,



  // onDragStart,
  // onDragMove,
  // onDragEnd
}) => {


  // if (index == 15) {
  //   console.log(`SampleNode ${index} : ${_enabled}`)
  // }
  const ref = React.useRef(null)

  type State = {
    enabled: boolean,
    value: number
  }

  const [state, setState] = React.useState<State>({
    enabled: _enabled,
    value
  })

  // if (index == 15) {
  //   console.log(`SampleNode:state.enable ${index} : ${state.enabled}`)
  // }


  const [dragState, setDragState] = React.useState<DragState>({
    enabled: false,
    envelopeDrag: false
  })

  const onDragMove = (event: DragEvent) => {

    if (dragState.downPos) {
      if (!dragState.envelopeDrag) {
        if (Math.abs(event.y - dragState.downPos.y) > 5) {
          console.log(`onDragMove envelopeDrag=true`)
          dragState.envelopeDrag = true
          setDragState(dragState)
        }
      } else {
        const delta = _.clamp((dragState.downPos.y - event.y) / 100, -1, 1)
        const newValue = _.clamp(delta + dragState.downPos.value, 0, 1)

        //console.log(`newValue: ${newValue} delta: ${delta} screen:${(dragState.downPos.y - event.y)}`)
        //dispatch(modifyNote(note, { value: newValue }))
        onValueChanged(newValue)
      }
    }
  }


  const onDragStart = (evt: DragEvent) => {
    //    console.log(`onDragStart ${state.enabled}`)

    dragState.downPos = {
      value: value,
      x: evt.x,
      y: evt.y
    }

    setDragState(dragState)

    //dispatch(modifyNote(note, !noteInfo.enabled))
    // setMouseDownState({
    //     draw: true,
    //     enabled: !noteInfo.enabled
  }


  const onDragEnd = (event: DragEvent) => {
    console.log(`onDragEnd envelopeDrag=${dragState.envelopeDrag}`)
    setDragState({
      enabled: false,
      envelopeDrag: false,
      downPos: undefined
    })

    if (!dragState.envelopeDrag) {
      //   console.log(`modify: ${state.enabled} ${_enabled}`)
      setState({
        ...state,
        enabled: !state.enabled
      })
      //dispatch(modifyNote(note, { enabled: !noteInfo.enabled, value: 1.0 }))
      onEnabledChanged(!state.enabled)
    }
  }


  React.useLayoutEffect(() => {
    setState({
      enabled: _enabled,
      value
    })
    if (ref.current) {

      const started = () => {
        const evt = event
        onDragStart(evt)

        const dragged = () => {
          onDragMove(event)
        }
        const ended = () => {
          onDragEnd(event)
        }
        event.on("drag", dragged).on("end", ended);
      }

      const dragInstance = drag();

      const elem: Selection<any, unknown, HTMLElement, any> = select(ref.current)
      const on: DragBehavior<Element, unknown, unknown> = dragInstance.on("start", started)
      elem.call(on);
    }
  }, [dragState, _enabled])

  return (

    <div
      ref={ref}
      className="relative bg-green-800 w-[30px] h-[40px] border-r-2 border-black"
    >
      {
        state.enabled ?
          <div
            className="absolute w-full bg-green-600 bottom-0"
            style={{ height: `${value * 100}%` }} />
          : null
      }
    </div >
  );
}

