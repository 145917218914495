
import { assert } from "src/utils"
import { CinemaState, LayerType } from "."
import { ModulationState, PrimitiveModulation } from "../bindings"
import { IdentifierFactory, LayerIdentifiable, LayerMaterialID, LayerShaderID, LayerUniformID, PrimitiveModulationID, TrackEnvelopeID, TrackID } from "../identifiers/identifiers"
import { Track } from "../sound/Track"
import { SoundState } from "../store"
import { LayerShader, UniformTypes } from "./types"

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace search {

    export function findLayerWithName(layerName: string, cinemaState: CinemaState): LayerType | undefined {
        return cinemaState.allLayers.find(x => x?.info.layerName === layerName)
    }

    export function findLayerFromID(layerID: LayerIdentifiable, cinemaState: CinemaState): LayerType | undefined {
        return findLayerWithName(layerID.layerName, cinemaState)
    }

    export function findLayerFromIDRequired(layerID: LayerIdentifiable, cinemaState: CinemaState): LayerType {
        return findLayerWithNameRequired(layerID.layerName, cinemaState)
    }

    export function findLayerWithNameRequired(layerName: string, cinemaState: CinemaState): LayerType {
        const layer = findLayerWithName(layerName, cinemaState)
        if (layer === undefined) {
            throw new Error(`Failed to find layerName: ${layer} in cinema state`)
        }
        return layer
    }

    export function findShaderOnLayerRequired(
        layerID: LayerShaderID | LayerMaterialID,
        cinemaState: CinemaState): LayerShader {
        const find = findShaderOnLayer(layerID, cinemaState)
        if (!find) {
            throw new Error(`Failed to find shader on layer of ${layerID.compositeKey}`)
        }
        return find
    }

    export function findShaderOnLayer(
        layerID: LayerShaderID | LayerMaterialID,
        cinemaState: CinemaState): LayerShader | undefined {
        const layer = findLayerWithName(layerID.layerName, cinemaState)
        if (layer === undefined) {
            return undefined
        }

        switch (layer.kind) {
            case "backgroundLayer":
            case "postProcessLayer":
                return layer.shader
            case "forgroundModelLayer":
                return layer.materialShader
        }
        return undefined
    }

    export function findUniformRequired(
        uniformID: LayerUniformID,
        cinemaState: CinemaState): UniformTypes {
        const layer = findLayerFromIDRequired(uniformID.layerShaderID, cinemaState)

        let shaderID: LayerShaderID | LayerMaterialID
        let shader: LayerShader | undefined
        switch (layer.kind) {
            case "backgroundLayer":
            case 'postProcessLayer':
                shader = layer.shader
                shaderID = IdentifierFactory.makeLayerShaderIdentifier(layer)
                break
            case "forgroundModelLayer":
                shader = layer.materialShader
                shaderID = IdentifierFactory.makeLayerMaterialIdentifier(layer)
        }

        if (!shader) {
            throw new Error("Failed to find expected Shader")
        }

        const found = Object.values(shader.uniforms).find(uniform => {
            const searchUniformID = IdentifierFactory.makeUniformIdentifier(shaderID, uniform.name)
            return searchUniformID.compositeKey === uniformID.compositeKey
        })

        if (found === undefined) {
            throw new Error("Failed to find Uniform")
        }
        return found
    }

    export function findTrackRequired(trackID: TrackID | TrackEnvelopeID, sound: SoundState): Track {
        const track = sound.tracks.find(x => x.trackId === trackID.trackId)
        assert(track !== undefined, `Cannot find track ${trackID.trackId}`)
        return track
    }


    export function findPrimitiveModulation(
        modulationID: PrimitiveModulationID,
        modulationState: ModulationState): PrimitiveModulation | undefined {

        const mod = modulationState.modulations?.find(x =>
            x.source.compositeKey === modulationID.source.compositeKey &&
            x.target.compositeKey === modulationID.target.compositeKey)

        if (mod) {
            assert(mod.kind === "PrimitiveBinding", "Expected it to be PrimitiveBinding.")
        }
        return mod
    }

    export function findPrimitiveModulationRequired(
        modulationID: PrimitiveModulationID,
        modulationState: ModulationState): PrimitiveModulation {

        const mod = findPrimitiveModulation(modulationID, modulationState)
        assert(mod !== undefined, "Expected it to be undefined.")
        return mod
    }

    export function findPrimitiveModulationToUniform(
        uniform: LayerUniformID,
        modulationState: ModulationState): PrimitiveModulation | undefined {

        const mod = modulationState.modulations?.find(x => x.target.compositeKey === uniform.compositeKey)
        if (mod && mod.kind !== "PrimitiveBinding") {
            return undefined
        }
        return mod
    }


}
