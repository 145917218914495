import React, { useEffect } from "react"
import tw from "tailwind-styled-components"

type BarToShowProps = {
    barToShow: number
    numberOfBars: number
    onChange(bar: number): void
}

const Container = tw.div`
    w-5
    h-1/3
    min-h-0
    shrink
    bg-slate-400
    flex
    items-center 
    place-content-center
`

const Cheveron = tw.img`h-1/3 w-4`


export const BarToShow: React.FC<BarToShowProps> = ({
    barToShow,
    numberOfBars,
    onChange
}) => {

    useEffect(() => {
        //
    }, [barToShow])

    const increment = () => {
        if (barToShow < numberOfBars - 1) {
            onChange(barToShow + 1)
        }
    }

    const decrement = () => {
        if (barToShow > 0) {
            onChange(barToShow - 1)
        }
    }

    return (
        <div className="flex flex-col h-fill bg-slate-300 items-center">
            {/* <Container > */}
            <Cheveron src="/icons/chevron_right.svg" onClick={increment} />
            {/* <img className="h-1/3 w-3" src="/icons/chevron_left.svg" alt="React Logo" /> */}
            {/* </Container> */}
            {/* <Container className="bg-slate-200"> */}
            <p className="leading-none h-1/3 w-6  text-[12px] font-bold text-center self-center text-control" >{barToShow + 1}/{numberOfBars}</p>
            {/*     </Container> */}
            {/* <Container onClick={decrement}> */}
            <Cheveron src="/icons/chevron_left.svg" onClick={decrement} />
            {/* </Container> */}
        </div>
    )
};
