import { CircularProgress, IconButton, TextField, ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import SaveIcon from '@mui/icons-material/Save';

import * as GraphQL from 'src/generated/graphqlTypes';
import { useAppContext } from "src/store/hooks";
import { observer } from "mobx-react-lite";
import { assertNotNull } from "src/utils";
import { action, runInAction } from "mobx";
import { AlertDialog, AlertDialogProps } from "../Alert";

const normalButton = createTheme({
    palette: {
        primary: {
            main: '#19857b',
        },
        secondary: { //Copy paste
            main: '#777',
            dark: '#0C3D38'
        },
        text: {
            primary: '#AAA',
        },
    },
})

export const SaveFileControl: React.FC = observer(() => {
    const [result, executeMutation] = GraphQL.useSaveFileMutation()
    const state = useAppContext()

    const fileName = state.session.fileName
    console.log(`Loading: ${fileName}`)
    const [openDialog, setOpenDialog] = React.useState<AlertDialogProps | undefined>(undefined)

    const onSave = React.useCallback(() => {
        console.log("Saving...")
        const saveFileName = state.session.fileName

        if (saveFileName === undefined) {
            setOpenDialog({
                title: "Save file name",
                body: "Please enter a file name",
                close: () => {
                    openDialog ? (openDialog.open = false) : undefined
                    setOpenDialog(openDialog)
                },
                open: true,
            })
            return
        }

        if (state.connect.state.kind === "NotConnected") {
            setOpenDialog({
                title: "Login with Phantom",
                body: "Please login using Phantom browser wallet.",
                close: () => {
                    openDialog ? (openDialog.open = false) : undefined
                    setOpenDialog(openDialog)
                },
                okay: () => {
                    const win = window.open("https://phantom.app/download", "_blank")
                    win?.focus()
                },

                open: true,
            })
            return
        }

        executeMutation({
            saveFile: {
                clientFileVersion: "1.0.0",
                name: saveFileName ?? "test",
                contents: JSON.stringify(state.saveData(), null, 2),
                identifier: state.session.identifier
            }
        }).then(result => {
            const pushSaveResult = result.data?.serialization.pushSave
            if (!pushSaveResult) {
                if (result.error) {
                    throw result.error
                } else {
                    throw new Error("No serialization result")
                }
            }

            runInAction(() => {
                assertNotNull(result.data)
                switch (pushSaveResult.__typename) {
                    case "ErrorResponse":
                        console.error(pushSaveResult)
                        throw new Error(pushSaveResult.errorMessage)
                    case "PushSaveFileSuccessResponse":
                        state.session.identifier = pushSaveResult.identifier
                }
            })
        }).catch(error => {
            console.error(error)
        })
        console.log("Saving")
    }, [executeMutation])

    const isFetching = result.fetching

    return (
        <ThemeProvider theme={normalButton}>
            <TextField
                color="primary"
                variant="outlined"
                placeholder="Save File Name"
                size="small"
                sx={{ marginLeft: "10px" }}
                value={fileName || ''}
                onChange={action((e) => {
                    state.session.updateSaveFilename(e.target.value)
                })}
            />
            {isFetching
                ? <CircularProgress />
                :
                <IconButton
                    color="primary"
                    size="large"
                    onClick={onSave}
                //disabled={state.session.fileName === undefined}
                >
                    <SaveIcon />
                </IconButton>
            }

            {openDialog ?
                <AlertDialog
                    {...openDialog}
                /> : null}

        </ThemeProvider >
    );
})