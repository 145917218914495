import React from 'react';
import "./index.css";
import Slider from 'rc-slider';
import { handleRender } from './TooltipSlider';

type VolumeSliderProps = {
    volume: number,
    onChange: (value: number) => void,
}


const VolumeSlider: React.FC<VolumeSliderProps> = ({ volume, onChange }) => {

    return (
        <div className='flex flex-col items-center h-full pt-2 pb-1 px-1'>
            <Slider
                vertical
                value={volume}
                max={100}
                min={0}
                handleRender={handleRender}
                onChange={(v) => onChange(v as number)}
            />
        </div >
    );
}
export default VolumeSlider