import React from "react"
import { LoopDndContext } from "./DragCore/DndContext";
import { DragType } from "./DragCore/dragTypes";
import { renderSampleRow } from "./SampleRow";

import { useAppContext } from "src/store/hooks";
import { observer } from "mobx-react-lite";

const Main: React.FC = observer(({ }) => {
    //const dispatch = storeMain.dispatch
    //const [songIsDragging, setSongIsDragging] = useState(false)

    const sounds = useAppContext().sounds
    const items = sounds.tracks.map(x => x.trackId)
    //const items: DragTrackDescription[] = []

    console.log("Render: Main")
    return (

        <LoopDndContext
            //strategy={verticalListSortingStrategy}
            sampleProps={
                {
                    items: items.map(i => {
                        return {
                            type: DragType.TRACK,
                            id: i,
                        }
                    }),
                    //ids: sounds.tracks.map(x => x.trackId),
                    postNewOrder: (ids) => {
                        sounds.reorder(ids)
                    },
                    addTrack: (info) => {
                        sounds.addTrack(info)
                    },
                    onSongDragging: () => {
                        //
                    }

                }
            }

            renderItem={renderSampleRow}
        />);
})


export default Main

/**
 * Move an array item to a different position. Returns a new array with the item moved to the new position.
 */
export function arrayMove<T>(array: T[], from: number, to: number): T[] {
    const newArray = array.slice();
    newArray.splice(
        to < 0 ? newArray.length + to : to,
        0,
        newArray.splice(from, 1)[0]
    );

    return newArray;
}


