
import classNames from 'classnames';
import React from 'react';
import styles from "../Cinema.module.scss"

interface Props {
    name: string
    selected: boolean
    onSelect: () => void
    onEdit: () => void
}

export const CinemaEmptyLayerProperty: React.FC<Props> = ({ name, onEdit, selected, onSelect }) => {
    return (
        <div className={classNames(styles.HeaderStyle, selected && styles.Selected)} onClick={onSelect}>
            <p className='text-base'>{name}</p>
            {/* <Button
                onClick={onEdit}>
                <EditIcon />
            </Button> */}
        </div>)
}
