import * as BABYLON from "@babylonjs/core";
import { Disposable } from "src/utils/disposable";
import { TransportPlaybackListener } from "../audio/Transport";
import { LayerNameKeys, LayerType } from "src/store/cinema";
import { FactoryResult, construct } from "./factory";
import { GizmosState } from "./GizmosState";
import { GlobalRenderContext } from "./GlobalRenderContext";
import { GizmosUpdateState, LiveLayerActions, RenderLayerOptions } from "./RenderManager";

export class RenderLayer implements Disposable, TransportPlaybackListener {

    scene: BABYLON.Scene;
    camera: BABYLON.UniversalCamera;
    globalContext: GlobalRenderContext;

    gizmoState?: GizmosState;
    sceneReady = false;

    private sceneState?: FactoryResult;

    private frames = 0;
    //private animationSequencer?: AnimationSequencer
    layerID: string;
    layerName: LayerNameKeys;
    private listenerHandle: Disposable;


    constructor(
        engine: BABYLON.Engine,
        context: GlobalRenderContext,
        layerData: LayerType,
        options: RenderLayerOptions = {}) {


        this.layerID = layerData.info.id;
        this.layerName = layerData.info.layerName;
        this.globalContext = context;
        const sceneOptions = {};
        const { autoClear = false } = options;

        const scene = new BABYLON.Scene(engine, sceneOptions);

        scene.autoClear = false; //TODO Understand this
        this.scene = scene;
        this.camera = RenderLayer.loadCamera(layerData, scene);
        this.listenerHandle = context.transport.registerPlaybackListener({
            onAudioBegin: () => this.onAudioBegin(),
            onAudioEnd: () => this.onAudioEnd
        });
    }

    // Its important the the later keep local track of 
    // the frame count so it is reset to Zero.
    makeContext(): GlobalRenderContext {
        return {
            ...this.globalContext,
            update: {
                get: () => {
                    const updateTime = this.globalContext.update.get()
                    return {
                        ...updateTime,
                        frame: this.frames,
                    }
                }
            }
        };
    }

    private static loadCamera(layer: LayerType, scene: BABYLON.Scene): BABYLON.UniversalCamera {
        switch (layer.kind) {
            case "forgroundModelLayer":
                if (layer.camera.serializedData) {
                    const camera = BABYLON.Camera.Parse(JSON.parse(layer.camera.serializedData), scene);
                    if (camera instanceof BABYLON.UniversalCamera) {
                        return camera;
                    }
                }
        }

        const camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(-0.1, 10, 20), scene);
        camera.setTarget(BABYLON.Vector3.Zero());

        return camera;

    }

    async waitForSceneReady(layer: LayerType): Promise<void> {

        await this.scene.whenReadyAsync()

        await this.onSceneReady(
            this.scene,
            this.camera,
            layer);
    }

    async onAudioBegin(): Promise<void> {
        // const player = this.transport.getAudioPlayer("debug-track-id-0")!
        // this.frequencyAnalyser = new FrequencyAnalyser(
        //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        //     player.player!.source!.analyser as unknown as AnalyserNode,
        //     this.scene
        // )
        // const track = findTrack(gStore.getState(), this.layerID)
        // if (!track) {
        //     return
        // }
        // this.setupAudio(track)
        // await this.animationSequencer?.onBegin()
    }

    onAudioEnd(): void {
        // this.animationSequencer?.dispose()
        // this.animationSequencer = undefined
    }

    dispose() {
        this.scene.dispose();
        this.sceneState?.dispose?.();
        // this.animationSequencer?.dispose()
        this.listenerHandle.dispose();
        this.gizmoState?.dispose();
    }


    async onSceneReady(scene: BABYLON.Scene,
        camera: BABYLON.UniversalCamera,
        layer: LayerType) {

        const factory = await construct(layer);
        if (factory === undefined) {
            return;
        }

        const context = this.makeContext()
        // const c2 = camera as TargetCamera
        // c2.cameraDirection = new Vector3(100.5, 100.5, 100.5)
        // scene.createDefaultCameraOrLight(true, true, true);
        // This creates and positions a free camera (non-mesh)
        //const camera = new FreeCamera("camera1", new Vector3(-0.1, 0.7, 1.3), scene);
        //const camera = new UniversalCamera("UniversalCamera", new Vector3(-0.1, 10, 20), scene);
        //const camera = new FreeCamera("camera1", new Vector3(0, 5, -10), scene);
        // This targets the camera to scene origin
        camera.inputs.addMouseWheel();


        // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
        const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 1), scene);

        // Default intensity is 1. Let's dim the light a small amount
        light.intensity = 0.7;
        //scene.createDefaultEnvironment();
        try {
            this.sceneState = await factory.make({
                scene,
                camera,
                context
            });
        } catch (error) {
            console.error(error);
        }

        this.sceneReady = true;
    }

    setupAudio(layer: LayerType) {
        // this.animationSequencer = new AnimationSequencer(gTransport.makeTransportState(),
        //     track)
    }

    /**
     * Will run on every frame render.  We are spinning the box on y-axis.
     */
    onRender(params: {
        time: number;
        deltaTime: number;
        frame: number;
    }) {

        if (this.sceneState !== undefined) {
            const { onRender } = this.sceneState;

            // if (this.frequencyAnalyser) {
            //     //?.bindBuffer()
            //     const postProcessResult = this.sceneState as PostProcessFactoryResult
            //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            //     this.frequencyAnalyser.bindBuffer(
            //         postProcessResult.postProcesses["Image"].getEffect()!,
            //         "iChannel0",
            //         params
            //     )
            // }
            // let lerp: number =
            // if (this.animationSequencer) {
            //     const tickResults = this.animationSequencer.onRenderTick()
            //     lerp = tickResults.lerp
            // } else {
            //     lerp = 0;
            // }
            const lerp = 0;

            if (onRender) {
                onRender({
                    ...params,
                    frame: this.frames,
                    lerp: lerp,
                    scene: this.scene,
                });
            }
            this.frames += 1;

        }
    }

    getActions(): LiveLayerActions {
        return {
            enableGizmos: (gizmosType): BABYLON.Observable<GizmosUpdateState> => {

                this.gizmoState?.dispose();
                this.gizmoState = undefined;

                let observer: BABYLON.Observable<GizmosUpdateState>;

                switch (gizmosType) {
                    case "camera":
                        const canvas = this.scene.getEngine().getRenderingCanvas();
                        this.camera.attachControl(canvas, false);

                        this.gizmoState = new GizmosState();
                        observer = this.gizmoState.observable;


                        const observable = this.camera.onViewMatrixChangedObservable.add((camera, state) => {
                            observer.notifyObservers({
                                camera
                            });
                        });

                        this.gizmoState.gizmoDisposable = {
                            dispose: () => {
                                this.camera.onViewMatrixChangedObservable.remove(observable)
                            }
                        }

                        break;
                    case "position":
                    case "rotate":
                        this.gizmoState = new GizmosState();
                        observer = this.gizmoState.observable;

                        const gizmoDisposable = this.sceneState?.onBindGizmos?.(
                            "rotation",
                            (meshWorldViewMatrix) => {
                                observer.notifyObservers({
                                    meshWorldViewMatrix
                                });
                            });

                        this.gizmoState.gizmoDisposable = gizmoDisposable;
                        break;
                    default:
                        throw new Error("not implemented");
                }
                return observer;
            },
            clearGizmos: () => {
                this.camera.detachControl();
                this.gizmoState?.dispose();
                this.gizmoState = undefined;

            }
        };
    }
}

