
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { search } from 'src/store/cinema/search';
import { useAppContext } from 'src/store/hooks';
import { IdentifierFactory } from 'src/store/identifiers/identifiers';
import styles from "./Cinema.module.scss"
import { ShaderFocusLane } from './Focus/ShaderFocusLane';
import { CinemaLayerLane } from './Layers/CinemaLayerLane';
import { action } from "mobx"
// eslint-disable-next-line @typescript-eslint/no-empty-interface


const _CinemaMain: React.FC = () => {

    const uiState = useAppContext().uiState

    return (
        <div className={classNames(styles.CinemaWindow)} onClick={
            action(() => {
                uiState.selectedElements.selectedElement = undefined
            })}>
            <CinemaLayerLane />
            <FocusLane />
        </div >
    )
}

export const CinemaMain = observer(_CinemaMain)

const _FocusLane: React.FC = () => {
    const state = useAppContext()
    const layerID = state.uiState.selectedElements.selectedElement

    if (layerID === undefined) {
        return null
    }

    const layer = search.findLayerWithName(layerID.layerName, state.cinemaState)
    if (layer === undefined) {
        return null
    }

    switch (layer.kind) {
        case "backgroundLayer":
            switch (layerID.kind) {
                case "LayerMaterialIdentifier":
                case "LayerShaderIdentifier":
                    if (layer.shader !== undefined) {
                        return (<ShaderFocusLane
                            layerID={IdentifierFactory.makeLayerShaderIdentifier(layer)}
                            uniforms={Object.values(layer.shader.uniforms)}
                        />)
                    }
            }
            break
        case "forgroundModelLayer":
            switch (layerID.kind) {
                case "LayerMaterialIdentifier":
                case "LayerShaderIdentifier":
                    if (layer.materialShader) {
                        return (<ShaderFocusLane
                            layerID={IdentifierFactory.makeLayerMaterialIdentifier(layer)}
                            uniforms={Object.values(layer.materialShader.uniforms)}
                        />)
                    }

            }
    }

    return null
}

export const FocusLane = observer(_FocusLane)


