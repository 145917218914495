import { v4 as uuidv4 } from 'uuid';
import { SampleFileID } from "src/store/identifiers/identifiers";
import { GraphQLFetch } from "src/core/client";
import { AsyncStateLoader, asyncStateNone } from "src/utils/async";
import { PlatState, loadPlayers } from "./Transport";
import { TransportContext } from "./TransportContext";
import { Player } from "./Player";
import { assert } from 'src/utils';
export interface AuditionStateLoadedProps {
    player: Player
    samplePackFile: SampleFileID
}


export interface AuditionStateLoadingProps {
    samplePackFile: SampleFileID
    uuid: string
}


export class Auditioner {

    //The state of the loaded sample.
    dataState: AsyncStateLoader<AuditionStateLoadingProps, AuditionStateLoadedProps> = asyncStateNone;

    //What does the user want the state to be.
    //userState: PlayingState = "on"

    constructor(private transportState: TransportContext) { }

    clear() {
        switch (this.dataState.kind) {
            case "none": return;
            case "loading":
                this.dataState = asyncStateNone;
                return;
            case "loaded":
                this.dataState.props.player.dispose();
                this.dataState = asyncStateNone;
        }
    }


    stop() {
        switch (this.dataState.kind) {
            case "none": return;
            case "loading":
                return;
            case "loaded":
                this.dataState.props.player.reset()
        }
    }

    transportStartIfActive() {
        // if (this.playbackState == "off") {
        //     return;
        // }    

        switch (this.dataState.kind) {
            case "none": return;
            case "loading":
                return; // It'll start when ready.
            case "loaded":
                if (this.dataState.props.player.playingState === "on") {
                    this.dataState.props.player.reset()
                }

                this.startAudition();
        }
    }

    async load(sampleFileID: SampleFileID) {
        this.clear();
        const uuid = uuidv4();
        this.dataState = {
            kind: "loading",
            props: {
                samplePackFile: sampleFileID,
                uuid,
            }
        };

        const samplePackEntry = await GraphQLFetch.sampleFile(sampleFileID);

        const [tonePlayer] = await loadPlayers([samplePackEntry]);

        const player = new Player("audition",
            {},
            samplePackEntry,
            tonePlayer, this.transportState);

        this.dataState = {
            kind: "loaded",
            props: {
                player,
                samplePackFile: sampleFileID
            }
        };
    }

    startAudition() {
        assert(this.dataState.kind === "loaded", "Expected it to be loaded to start")

        const synced = this.transportState.playState() === PlatState.STOPPED ? false : true;

        this.dataState.props.player.start({ synced });
    }
}
