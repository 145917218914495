import React from 'react'
import { useState } from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'
import classNames from 'classnames'


const Switch: React.FC<{
    checked: boolean
    onChanged(checked: boolean): void
    className?: string
}> = ({ className, children, checked, onChanged }) => {
    const [enabled, setEnabled] = useState(checked)

    return (
        <HeadlessSwitch.Group as="div" className={`flex flex-col-reverse justify-center ${className}`}>
            <HeadlessSwitch
                checked={enabled}
                onChange={(_checked: boolean) => {
                    setEnabled(_checked)
                    onChanged(_checked)
                }}
                className={classNames(
                    enabled ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                />
            </HeadlessSwitch>
            <HeadlessSwitch.Label as="span" className="">
                {children}
            </HeadlessSwitch.Label>
        </HeadlessSwitch.Group>
    )
}


export default Switch;