import { ShaderSource } from "src/generated/graphqlTypes"
import { ForgroundModelLayer, ShaderSummary } from "src/store/cinema"
import { IdentifierFactory } from "src/store/identifiers/identifiers"
import { MaterialFactory } from "."
import { loadShaderFrogMaterial } from "../shaders/shaderFrog"
import { loadShadeToyMaterial } from "../shaders/shaderToy"



export default (layer: ForgroundModelLayer, shader: ShaderSummary): MaterialFactory => {
    switch (shader.source) {
        case ShaderSource.St:
            return loadShadeToyMaterial(shader.id)
        case ShaderSource.Sf:
            return loadShaderFrogMaterial(IdentifierFactory.makeLayerMaterialIdentifier(layer), shader.id)
        default:
            throw new Error(`Have not implemented layer support for ${shader.type}`)
    }
}
