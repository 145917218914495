

import React, { CSSProperties, useRef } from "react"


import InfiniteScroll from "./InfiniteScroll";

interface Props {

    loadMore: (page: number) => void
    hasMore: boolean
    style?: CSSProperties
}

const InfiniteScrollContainer: React.FC<Props> = ({
    style,
    children,
    loadMore,
    hasMore

}) => {
    const ref = useRef(null)

    return (
        <div
            ref={ref}
            style={{
                ...style,
                overflow: "auto"
            }}
        >
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                // loader={<div className="loader" key={0}>Loading ...</div>}
                useWindow={false}
                getScrollParent={() => ref.current}
            >
                {children}
                {/* {items.map((_, i) => (
                        <div
                            key={i}
                            style={{ height: 30, margin: 4, border: '1px solid hotpink' }}>
                            #{i + 1} row
                        </div>
                    ))} */}
            </InfiniteScroll>
        </div>)
}
export default InfiniteScrollContainer