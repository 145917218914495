import React, { useEffect, useRef, useState } from 'react';
import { ADSRRatios, Ratios } from 'src/store/sound/Track';



interface Props {
    mode: "adsr"
    ratio: Ratios
    style?: React.CSSProperties;

    onChange?: (ratio: Ratios) => void
}


const styles = {
    line: {
        fill: "none",
        stroke: "rgb(221, 226, 232)",
        strokeWidth: "2"
    },
    dndBox: {
        fill: "none",
        stroke: "white",
        strokeWidth: 0.1,
        height: 0.75,
        width: 0.75
    },
    dndBoxActive: {
        fill: "none",
        stroke: "white",
        strokeWidth: 0.1
    },
    corners: {
        strokeWidth: 0.25,
        length: 1,
        stroke: "white"
    },
    box: {
        light: "#666",
        dark: "#444"
    }
};

interface DragState {
    drag: DragType
    lastPos: {
        x: number,
        y: number
    }
}
interface State {
    xp: number
    xa: number
    xd: number
    xr: number
    ys: number

    minWidthSector: number

    svgRatio: {
        width: number,
        height: number
    }

    // state = Object.assign(state, {
    //     xa: props.defaultXa * viewBox.width * state.ratio.xa,
    //     xd: props.defaultXd * viewBox.width * state.ratio.xd,
    //     xr: props.defaultXr * viewBox.width * state.ratio.xr,
    //     // NOTE: Dragging attack in y direction is currently not implemented.
    //     ya: props.defaultYa,
    //     ys: props.defaultYs,

    //     drag: null,

    //     svgRatio: 0
    // });
}

type DragType = "attack" | "decaysustain" | "release"



const Envelope: React.FC<Props> = (props) => {

    const ref = useRef<SVGSVGElement>(null)

    interface ViewBox {
        width: number,
        height: number
    }
    const [viewBox, setViewBox] = useState<ViewBox>({ width: 0, height: 0 })
    const [ratios, setRatios] = useState(props.ratio)
    const [state, setState] = useState<State>({
        xp: 0,
        xa: 0,
        xr: 0,
        xd: 0,
        ys: 0.5,
        minWidthSector: 20,
        svgRatio: {
            width: 1,
            height: 1
        }
    })

    const [dragState, setDragState] = useState<DragState | null>(null)

    const compareRatios = <R extends Ratios>(l: R, r: R): boolean => {
        if (l.kind != r.kind) {
            return false
        }

        if (l.xp != r.xp ||
            l.xa != r.xa ||
            l.xr != r.xr
        ) {
            return false
        }

        if (l.kind == "adsr" && l.kind == r.kind &&
            (
                l.xd == r.xd ||
                l.ys == r.ys
            )) {
            return false
        }

        return true
    }

    useEffect(() => {
        if (viewBox.height == 0 || viewBox.width == 0) {
            return
        }

        // If they're the same, early out.
        if (compareRatios(props.ratio, ratios)) {
            return
        }

        //console.log(`ratio: ${props.ratio.xa} : ${ratios.xa}`)

        setRatios(props.ratio)
        const newState = calculateState(viewBox, props.ratio)
        setState(newState)
        //notifyChanges(state, newState)
    }, [props.ratio, viewBox])

    useEffect(() => {

        function handleResize() {
            if (ref.current) {
                const viewBox = {
                    width: ref.current.width.baseVal.value,
                    height: ref.current.height.baseVal.value
                }

                setViewBox(viewBox)
                setState(calculateState(viewBox, ratios))
            }
        }

        handleResize()
        window.addEventListener('resize', handleResize)

        const onDrag = () => setDragState(null)

        window.addEventListener("mouseup", onDrag);


        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('mouseup', onDrag);
        }

    }, [])


    const moveDnDRect = () => {

        return (event: { stopPropagation: () => void; clientX: number; clientY: number; }) => {

            if (!dragState) {
                return
            }
            const { drag, lastPos } = dragState

            //event.stopPropagation();

            const [
                prependWidth,
                attackWidth,
                decayWidth,
                releaseWidth
            ] = getPhaseLengths();



            // const {  xa, xd, xr, ratio, svgRatio } = state;
            // const { drag, xa, xd, xr, ratio, svgRatio } = props

            const svgRatio = state.svgRatio

            let finalNewState = state;

            type StateKeys = Pick<State, "xa" | "xd" | "xp" | "xr">
            const nudgeBoxX = <K extends keyof ADSRRatios, S extends keyof StateKeys>(k: K, s: S, state: State, ratios: any) => {
                const deltaX = (event.clientX - lastPos.x)
                const x = state[s]
                let scaledRatioX = (deltaX + x) / x
                if (scaledRatioX < 0) {
                    scaledRatioX = 0
                }

                if (ratios[k] == 0) {
                    ratios[k] = 0.01
                } else {
                    try {
                        ratios[k] *= scaledRatioX
                    } catch (error) {
                        throw error
                    }
                }

                if (ratios[k] > 1.0) {
                    ratios[k] = 1
                }


            }

            if (ref.current) {
                //const rect = refs.box.getBoundingClientRect();
                const rect = ref.current.getBoundingClientRect()
                if (drag === "attack") {

                    nudgeBoxX("xa", "xa", state, ratios)

                    // finalNewState = calculateState(viewBox, ratios)
                    // setState(finalNewState);

                } else if (drag === "decaysustain" && ratios.kind == "adsr") {
                    nudgeBoxX("xd", "xd", state, ratios)
                    const deltaY = (event.clientY - lastPos.y)
                    const y = state.ys

                    if (state.ys == 0) {
                        if (deltaY <= 0) {
                            ratios.ys = 0
                        } else {
                            ratios.ys = 0.01
                        }
                    } else {
                        const scaledRatioY = (deltaY + y) / y
                        ratios.ys *= scaledRatioY
                    }

                    //                    console.log(`scaledRatioY:${scaledRatioY} ${state.ys} : ${deltaY}`)


                    ratios.ys = Math.min(ratios.ys, 1)
                    ratios.ys = Math.max(ratios.ys, 0)



                    // const ysNew =
                    //     event.clientY - rect.top;

                    // const newState: any = {};
                    // if (ysNew >= 0 && ysNew <= rect.top) {
                    //     newState.ys = ysNew;
                    // }

                    // const xdNew =
                    //     (event.clientX -
                    //         rect.left -
                    //         attackWidth * svgRatio.width) /
                    //     svgRatio.width;

                    // if (xdNew >= 0 && xdNew <= viewBox.width) {
                    //     newState.xd = xdNew;
                    // }

                    // finalNewState = { ...state, ...newState }
                    // setState(finalNewState);
                } else if (drag === "release") {

                    nudgeBoxX("xr", "xr", state, ratios)

                    // const xrNew =
                    //     (event.clientX -

                    //         rect.left -
                    //         (prependWidth + attackWidth + decayWidth) * svgRatio.width) /
                    //     svgRatio.width;
                    // if (xrNew >= 0 && xrNew <= viewBox.width) {
                    //     finalNewState = { ...state, ...{ xr: xrNew } }
                    //     setState(finalNewState);
                    // }
                }
            }

            setRatios(ratios)
            finalNewState = calculateState(viewBox, ratios)
            setState(Object.assign(state, finalNewState));
            dragState.lastPos = { x: event.clientX, y: event.clientY }
            setDragState(dragState)

            notifyChanges(ratios)
        };
    }

    useEffect(() => {
        const onDragMove = (event: MouseEvent) => {
            if (!dragState) {
                return
            }
            //console.log(`window.onDragMove : ${dragState?.drag}`)
            moveDnDRect()(event)
        }
        window.addEventListener("mousemove", onDragMove);

        return () => {

            window.removeEventListener('mousemove', onDragMove);
        }
    }, [dragState])


    /**
     * Returns a string to be used as 'd' attribute on an svg path that resembles
     * an envelope shape given its parameters
     * @return {String}
     */
    const generatePath = () => {
        const { ys } = state;

        const [
            prependWidth,
            attackWidth,
            decayWidth,
            releaseWidth
        ] = getPhaseLengths();


        const strokes = [];
        strokes.push(`M ${prependWidth} ` + viewBox.height);
        strokes.push(exponentialStrokeTo(attackWidth, -viewBox.height));
        strokes.push(
            exponentialStrokeTo(decayWidth, ys)
        );
        strokes.push(exponentialStrokeTo(releaseWidth, viewBox.height - ys));

        return strokes.join(" ");
    }

    /**
     * Constructs a command for an svg path that resembles an exponential curve
     * @param {Number} dx
     * @param {Number} dy
     * @return {String} command
     */
    const exponentialStrokeTo = (dx: number, dy: number) => {
        return ["c", dx / 5, dy / 2, dx / 2, dy, dx, dy].join(" ");
    }

    /**
     * Constructs a line command for an svg path
     * @param {Number} dx
     * @param {Number} dy
     * @return {String} command
     */
    const linearStrokeTo = (dx: any, dy: number) => {
        return `l ${dx} ${dy}`;
    }

    const getPhaseLengths = () => {
        const { xp, xa, xd, xr } = state;

        // NOTE: We're subtracting 1/4 of the width to reserve space for release.

        return [xp, xa, xd, xr];
    }

    const renderDnDRect = (type: DragType) => {
        const [
            prependWidth,
            attackWidth,
            decayWidth,
            releaseWidth
        ] = getPhaseLengths();

        const { ys } = state;
        //const { drag } = dragState ?? {}
        const rHeight = 4;
        const rWidth = 10;

        let x, y, width;
        let color;
        if (type === "attack") {
            x = 0,
                y = 0;
            width = prependWidth + attackWidth;
            color = styles.box.dark

        } else if (type === "decaysustain") {
            x = prependWidth + attackWidth;
            y = ys - rHeight / 2;
            width = decayWidth;
            color = styles.box.light
        } else if (type === "release") {
            x =
                prependWidth +
                attackWidth +
                decayWidth
            y = + viewBox.height - rHeight;
            width = releaseWidth
            color = styles.box.dark
        } else {
            throw new Error("Invalid type for DnDRect");
        }

        return (
            <rect
                onMouseDown={(event) => {
                    setDragState({ drag: type, lastPos: { x: event.clientX, y: event.clientY } })
                    //console.log("mouseDown: " + type)
                }}
                x={x}
                y={0}
                width={width}
                height={viewBox.height}
                style={{
                    pointerEvents: "all",
                    fill: color,
                }}
            />
        );
    }


    const calculateState = (_viewBox: ViewBox, _ratios: Ratios): State => {

        const xd = _ratios.kind == "adsr" ? _ratios.xd : 0

        const sum = _ratios.xp + _ratios.xa + xd + _ratios.xr;


        // X% of the width is reserved for min width, spread over 3 quadrants. So you can always click and drag
        const MinWidthPercentage = 0.05
        const minWidthPerSector = _viewBox.width * MinWidthPercentage
        const minWidthTotalUsed = minWidthPerSector * (_ratios.kind === "adsr" ? 3 : 2)
        const widthAvailableForRatios = _viewBox.width - minWidthTotalUsed

        return {
            xp: widthAvailableForRatios * (_ratios.xp / sum),
            xa: minWidthPerSector + widthAvailableForRatios * (_ratios.xa / sum),
            xd: _ratios.kind == "adsr" ? minWidthPerSector + widthAvailableForRatios * (xd / sum) : 0,
            xr: minWidthPerSector + widthAvailableForRatios * (_ratios.xr / sum),
            ys: _ratios.kind == "adsr" ? _viewBox.height * _ratios.ys : 0,
            minWidthSector: minWidthPerSector,
            svgRatio: {
                width: 1,
                height: 1
            }
        }
    }

    const notifyChanges = (ratios: Ratios) => {
        if (props.onChange) {
            props.onChange(ratios);
        }
    }

    // const notifyChanges = (prevState: State, currentState: State) => {
    //     const { xp, xa, xd, ys, xr } = currentState;

    //     if (viewBox.width == 0 || viewBox.height == 0) {
    //         return
    //     }

    //     if (
    //         prevState.xa !== xa ||
    //         prevState.xd !== xd ||
    //         prevState.ys !== ys ||
    //         (prevState.xr !== xr)
    //     ) {
    //         // const relationXa = ((xa / viewBox.width) * 1) / ratios.xa;
    //         // const relationXd = ((xd / viewBox.width) * 1) / ratios.xd;
    //         // const relationXr = ((xr / viewBox.width) * 1) / ratios.xr;
    //         const relationXp = ((xp / viewBox.width) * 1);
    //         const relationXa = ((xa / viewBox.width) * 1);
    //         const relationXd = ((xd / viewBox.width) * 1);
    //         const relationXr = ((xr / viewBox.width) * 1);
    //         const relationYs = ((ys / viewBox.height) * 1);
    //         const newRatios: Ratios = {
    //             xp: relationXp,
    //             xa: relationXa,
    //             //ya,
    //             xd: relationXd,
    //             ys: relationYs,
    //             xr: relationXr
    //         }


    //         if (props.onChange) {
    //             props.onChange(newRatios);
    //         }
    //     }
    // }



    const w = viewBox.width;
    const h = viewBox.height;
    const vb = `0 0 ${w} ${h}`;

    return (
        <svg style={{ ...props.style }}
            onDragStart={() => false}
            viewBox={vb}
            ref={ref}
        //onMouseMove={moveDnDRect()}

        >
            {renderDnDRect("attack")}
            {renderDnDRect("decaysustain")}
            {renderDnDRect("release")}
            <path
                //transform={`translate(${ marginLeft }, ${ marginTop })`}
                d={generatePath()}
                style={Object.assign({}, styles.line)}
                vectorEffect="non-scaling-stroke"
            />

            {/* {corners ? renderCorners() : null} */}
            {/* {renderDnDRect("attack")}
            {renderDnDRect("decaysustain")}
            {renderDnDRect("release")} */}

        </svg>
    )
}


export default Envelope