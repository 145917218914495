import React from "react";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { IconButton } from "@mui/material";


type BookmarksProps = {
    bookmarked: boolean,
    onBookmarked?: () => void,
    reported: boolean,
    onReported?: () => void
}


export const BookmarksControl: React.FC<BookmarksProps> = ({
    bookmarked,
    onBookmarked,
    onReported,
    reported
}) => {

    return <div className="flex items-center h-8">
        <IconButton component="span" size='small' disabled={onBookmarked === undefined} onClick={() => {
            onBookmarked?.()
        }} >
            {bookmarked ? <BookmarkIcon color="secondary" /> : <BookmarkBorderIcon />}
        </IconButton>
        <IconButton component="span" size='small' disabled={onReported === undefined} onClick={() => {
            onReported?.()
        }} >
            <ReportProblemIcon fontSize='small' color={reported ? "secondary" : "primary"} />
        </IconButton>
    </div >
}
