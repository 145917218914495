import {
	DefaultLinkFactory,
	DefaultLinkModel
} from '@projectstorm/react-diagrams';
import { PortModel, PortModelGenerics } from '@projectstorm/react-diagrams';
import { ModulationTypes } from 'src/store/bindings';
import { isNotNull, requireExists } from 'src/utils';
import { NodeEditorContext } from '../NodeEditorContext';
import { sortPorts } from '../utils';
import { LoopBasePortModel } from './PortModels';

export class AudioLinkModel extends DefaultLinkModel {

	constructor(private context: NodeEditorContext) {
		super({
			type: 'audio',
			width: 4
		});
	}

	public modulation?: ModulationTypes


	getTargetPort(): PortModel<PortModelGenerics> {
		const port = super.getTargetPort()
		return port
	}

	remove(): void {
		if (!isNotNull(this.sourcePort) || !isNotNull(this.targetPort)) {
			super.remove()
			return
		}

		const { source, target } = sortPorts(
			requireExists(this.sourcePort) as LoopBasePortModel,
			requireExists(this.targetPort) as LoopBasePortModel)

		super.remove()
		this.context.onLinkRemoved(source, target)
	}
}


export class AudioLinkFactory extends DefaultLinkFactory {
	constructor(private context: NodeEditorContext) {
		super('audio');
	}

}	
