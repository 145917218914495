import { makeAutoObservable } from "mobx";
import { serializeAll } from "serializr";


@serializeAll
export class SessionState {

    constructor() {
        makeAutoObservable(this)
    }

    updateSaveFilename(newSaveFileName: string) {
        if (this.fileName === newSaveFileName) {
            return
        }

        this.fileName = newSaveFileName
        this.identifier = undefined
    }


    fileName?: string = undefined;

    identifier?: string = undefined;
}