
import * as GraphQL from "src/generated/graphqlTypes";
import { Track } from "src/store/sound/Track";



export enum DragType {
    TRACK = "dragtype-track",
    RAW_SAMPLE = "dragtype-sample"
}

export interface DragSampleDescription {
    id: string,
    type: DragType.RAW_SAMPLE
    payload: {
        sampleFile: GraphQL.SampleFileFragment
    }
}

export interface DragTrackDescription {
    id: string,
    track?: Track
    type: DragType.TRACK
}

export type DragDescriptions = DragTrackDescription | DragSampleDescription