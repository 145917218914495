import { Track } from "src/store/sound/Track";
//import "log-timestamp"
import * as Tone from 'tone'
import { assertNotNull, ensure } from "src/utils";
import gAudioLoader from "./AudioLoader";
import { Disposable } from "src/utils/disposable";
import { BindingContext } from "../sequencing/binding";
import { IdentifierFactory } from "src/store/identifiers/identifiers";
import AnimationSequence from "../sequencing/AnimationSequence";
import { AnimationSequencer } from "../sequencing/AnimationSequencer";
import * as GraphQL from "src/generated/graphqlTypes"
import { GraphQLFetch } from "src/core/client";
import { Auditioner } from "./Auditioner";
import { Player } from "./Player";
import { TransportContext } from "./TransportContext";
import { Metronome } from "./Metronome";
import { registerMeterWorklet } from "./Meter";
import { AuditionState } from "src/store/AuditionState";
import { Lambda, observe, runInAction } from "mobx";
import { PagesState } from "src/store/store";

export enum PlatState {
    STOPPED,
    LOADING,
    PLAYING
}

export const getAudioContext = (): AudioContext => {
    return Tone.context.rawContext as unknown as AudioContext
}


export interface ActivePlayer extends Disposable {
    track: Track,
    player: Player
    disposables: Disposable[]
}

export interface TransportPlaybackListener {
    onAudioBegin(): Promise<void>
    onAudioEnd(): void
}

class ActiveSession implements Disposable {
    constructor(
        public players: ActivePlayer[]) { }

    public disposables: Disposable[] = []

    dispose() {
        this.disposables.forEach(x => x.dispose())
        this.players.forEach(player => player.dispose())
    }
}

export interface ITransport {
    play(tracks: Track[]): void
    stop(): void
    setMute(mutedTrackIDs: string[]): void
    auditioner: Auditioner
}

export class NFTTransport implements ITransport {

    private activeSession?: ActiveSession

    public metronomeEnabled = false
    public bpm = 125
    public auditioner: Auditioner


    constructor(
        private bindingContext: BindingContext,
        private sequencer: AnimationSequencer,
        private pageState: PagesState) {
        this.auditioner = new Auditioner(this.makeTransportContext())
    }

    private playbackListeners: Record<number, TransportPlaybackListener> = {}
    private playbackListenerCount = 0;

    public makeTransportContext(): TransportContext {
        return {
            bpm: () => this.bpm,
            playState: () => this.playState,
            metronomeEnabled: () => this.metronomeEnabled,
            registerPlaybackListener: (listener: TransportPlaybackListener) => {
                this.playbackListenerCount++
                this.playbackListeners[this.playbackListenerCount] = listener
                return {
                    dispose: () => {
                        delete this.playbackListeners[this.playbackListenerCount]
                    }
                }
            },
            getAudioPlayer: (trackID?: string) => {
                throw new Error("not implemented")
                //return this.activeSession?.players[0]
            }
        }
    }

    public isInitialied = false
    private _playState: PlatState = PlatState.STOPPED
    private _metronome?: Metronome

    public get playState(): PlatState {
        return this._playState;
    }

    async init() {
        if (this.isInitialied) {
            return
        }

        await Tone.start();

        await registerMeterWorklet(getAudioContext())

        this.isInitialied = true
        this._metronome = new Metronome(this.makeTransportContext())


        console.log("Transport:init()")
    }

    // setModel(tracks: Track[]) {
    //     this.tracks = tracks
    // }

    setBPM(_bpm: number) {
        this.bpm = _bpm
    }

    async loadSamples(tracks: Track[]) {
        //     return Promise.resolve()
        // }

        //async loadSamplesInternal() {
        await this.init()

        this._playState = PlatState.LOADING

        console.log("Transport: Tone.start()")

        const transportContext = this.makeTransportContext()


        const activePlayers: ActivePlayer[] = await loadTracks(tracks, transportContext)
        this.activeSession = new ActiveSession(activePlayers)

        bindActivePlayersToSequencer(activePlayers, this.sequencer, transportContext, this.bindingContext)
        const disposePlaybackState = bindActionPlayerToPlaybackState(activePlayers, this.pageState.audition)
        this.activeSession.disposables.push(disposePlaybackState)

        console.log("Transport: loadPlayers:" + activePlayers.length)

        activePlayers.forEach(({ player }) => player.start())

        // TODO Fix force
        let bars = activePlayers.reduce((max, next) => Math.max(next.player.sampleFile.beats16ths!, max), 0) / 16
        bars = Math.max(bars, 1)

        //Tone.Transport.loopEnd = "1:0:0"// bpmToBar(this.bpm)
        Tone.Transport.setLoopPoints("0:0:0", `${bars}:0:0`)
        Tone.Transport.bpm.value = this.bpm
        Tone.Transport.loop = true

        console.log("Transport: Tone.loading()")
        await Tone.loaded();

    }

    async play(tracks: Track[]) {
        await this.loadSamples(tracks)
        await this.sequencer.onBegin()

        const listeners = Object.values(this.playbackListeners).map(x => x.onAudioBegin())

        const transportState = this.pageState.audition.transportState
        this.queueProgressListenter((processIn16ths) => {
            runInAction(() => {
                transportState.progressIn16ths = processIn16ths
            })

        })



        await Promise.all(listeners)
        await Tone.start()

        this._playState = PlatState.PLAYING

        this.auditioner.transportStartIfActive()
        Tone.Transport.start()
        console.log("Transport: PLAYING")
    }

    private queueProgressListenter(callBack: (progressIn16ths: number) => void) {
        assertNotNull(this.activeSession)

        let count = -1
        const loop = new Tone.Loop(function (time) {
            //triggered every eighth note. 
            count++
            callBack(count)
        }, "16n").start(0);


        const dispoable: Disposable = {
            dispose: () => {
                loop.dispose()
                callBack(0)
            }
        }

        this.activeSession.disposables.push(dispoable)
    }

    setMute(mutedTrackIDs: string[]) {
        this.activeSession?.players.forEach((player) => {
            if (mutedTrackIDs.includes(player.track.trackId)) {
                player.player.mute = true
            } else {
                player.player.mute = false
            }
        })
    }

    stop() {
        this.sequencer.clearAll()
        this.activeSession?.dispose()
        this.activeSession = undefined
        this.auditioner.stop()
        Tone.Transport.stop()
        this._playState = PlatState.STOPPED
    }
}

function bindActionPlayerToPlaybackState(
    activePlayers: ActivePlayer[],
    audition: AuditionState): Disposable {

    const dispoables: Lambda[] = []

    activePlayers.forEach(player => {
        const state = audition.getTrackPlaybackState(player.track.trackId)
        assertNotNull(state)
        {
            const dispose = observe(player.player.data, "playbackLevel", (change) => {
                runInAction(() => {
                    state.levelMeter = change.newValue as number
                })
            })
            dispoables.push(dispose)
        }

        {
            const dispose = observe(player.track.audioLayer, "volume", (change) => {
                player.player.volume = change.newValue as number
            })
            dispoables.push(dispose)


            dispoables.push(() => {
                runInAction(() => {
                    state.levelMeter = 0
                })

            })
        }
    })

    return ensure<Disposable>({
        dispose: () => {
            dispoables.forEach(x => x())
        }
    })

}

function bindActivePlayersToSequencer(
    activePlayers: ActivePlayer[],
    sequence: AnimationSequencer,
    transportContext: TransportContext,
    bindingContext: BindingContext) {
    if (!bindingContext) {
        console.warn("Binding failed due to null")
        return undefined
    }

    activePlayers
        .map(player => {


            bindingContext.bindSource<GainNode>({
                compositeID: IdentifierFactory.makeTrack(player.track).compositeKey,
                prepare: () => {

                    if (!player.player.source?.gain) {
                        throw new Error("Gain")
                    }
                    return player.player.source.gain
                }
            })

            bindingContext.bindSource<AnimationSequence>({
                compositeID: IdentifierFactory.makeEnvelope(player.track).compositeKey,

                prepare() {
                    return sequence.getOrMakeAnimationSequnce(transportContext, player.track)
                }

                // provideData: (sequencer) => {
                //     return sequencer.onRenderTick().lerp
                // }
            })
        })

    return
}

async function loadTracks(tracks: Track[], transport: TransportContext): Promise<ActivePlayer[]> {

    const samplePackEntries = await Promise.all(tracks
        .map(t => IdentifierFactory.makeSampleFileID(t.sampleFile))
        .map(GraphQLFetch.sampleFile))

    const tonePlayers = await loadPlayers(samplePackEntries)
    const activePlayers = tonePlayers.map((tonePlayer, i) => {
        const track = tracks[i]

        return ensure<ActivePlayer>({
            player: new Player(track.trackId, {
                volume: track.audioLayer.volume,
            }, samplePackEntries[i], tonePlayer, transport),
            track: tracks[i],
            disposables: [],
            dispose() {
                this.disposables.forEach(x => x.dispose)
                this.player.dispose()
            }

        })
    })

    return activePlayers
}

export async function loadPlayers(samples: GraphQL.SampleFileFragment[]): Promise<Tone.Player[]> {

    const createPlayer = async (samples: GraphQL.SampleFileFragment) => {
        const buffer = await gAudioLoader.load(samples)
        const player = new Tone.Player(buffer).toDestination()
        return player
    }

    const pendingPlayers = samples.map(createPlayer)

    const players = Promise.all(pendingPlayers)

    return Promise.resolve(players)
}

export async function fetchAudioBuffer(url: string): Promise<AudioBuffer> {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`could not load url: ${url}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await Tone.getContext().decodeAudioData(arrayBuffer);
    return audioBuffer
}


