import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react"
import { TabBarProps, Tabs } from "../Tailwind/Tabs";
import { SampleSearchView } from "./SampleSearchView";
import SamplesUser from "./SamplesUser";


type SampleMainProps = {
    // 
}


type PageType = "sample" | "user"
type PageSelector = { type: PageType }


const SampleMain: React.FC<SampleMainProps> = observer((props) => {

    const [tabs] = React.useState<TabBarProps["tabs"]>(
        [{
            name: "Sample Packs",
            id: "sample",
            current: true
        }, {
            name: "User Samples",
            id: "user",
            current: false
        }]
    )

    const typeSelector: PageSelector = useLocalObservable<PageSelector>(() => { return { type: "sample" } })

    return (
        <div className="pt-2 h-full flex flex-col">
            <Tabs
                tabs={tabs}
                onChange={(id) => {
                    typeSelector.type = id as PageType
                }}
            />
            <SampleTabSelector {...typeSelector} />
            {/* <div className="w-full h-full overflow-clip">
                <div className="h-full w-1/2 bg-slate-100 overflow-auto">
                    {[...Array(10)].map((_, i) => {
                        return (<div key={i}>{i}</div>)
                    })}
                </div>
            </div> */}
        </div >)
});

const SampleTabSelector: React.FC<PageSelector> = observer(({ type }) => {
    switch (type) {
        case "sample": return (<SampleSearchView />)
        case "user": return (<SamplesUser />)
    }
})

export default SampleMain;


