import { graphQLCLient } from "src/core/client";
import * as GraphQL from "src/generated/graphqlTypes";

export async function fetchShader(shaderID: string, shaderType: GraphQL.ShaderType): Promise<GraphQL.ShaderFragment> {
    console.log(`Fetching shader: ${shaderID}`);

    const doc = await graphQLCLient
        .query<GraphQL.FetchShaderQuery, GraphQL.FetchShaderQueryVariables>(GraphQL.FetchShaderDocument, { shaderID, type: shaderType })
        .toPromise();

    const shader = doc.data?.shader.fetchShader;
    if (!shader) {
        throw new Error(`Failed to download shader ${shaderID}:st`);
    }

    return shader;
}
