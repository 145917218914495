import classNames from 'classnames'
import React from 'react'

const _tabs = [
    { name: 'My Account', href: '#accounts', current: false },
    { name: 'Company', href: '#company', current: false },
    { name: 'Team Members', href: '#', current: true },
    { name: 'Billing', href: '#', current: false },
]

type Tab = {
    name: string
    id: string,
    current: boolean
}

export type TabBarProps = {
    tabs: Tab[]
    onChange: (id: string) => void
}

export const Tabs: React.FC<TabBarProps> = ({ tabs: _tabs, onChange }) => {
    const [tabs, setTabs] = React.useState(_tabs)

    return (
        <div className='flex w-full h-fit'>
            <nav className="flex space-x-4" aria-label="Tabs">
                {tabs.map((tab) => (
                    <a
                        key={tab.name}
                        href={"#"}
                        className={classNames(
                            tab.current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                            'px-3 py-2 font-medium text-sm rounded-md'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                        onClick={() => {
                            tabs.forEach(t => t.current = false)
                            tab.current = true
                            setTabs([...tabs])
                            onChange(tab.id)
                        }}
                    >
                        {tab.name}
                    </a>
                ))}
            </nav>
        </div>
    )
}
