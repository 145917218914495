
import React from "react";
import AlertHandler from "src/components/Alert/AlertHandler";
import Main from "src/components/Main";
import URQLProvider from "src/core/client";
import { PagesProvider } from "src/store";
import Layout from "../components/layout";
import { getNavigation } from "../components/SampleRow/lib/content";

export default function Home({ categories }: { categories: ReturnType<typeof getNavigation> }) {
  return (
    <PagesProvider>
      <URQLProvider>
        {/* <Provider store={storeMain}> */}
        <Layout title="Next Sample" categories={categories}>
          <Main />
          {/* <TestNodeLines /> */}
          {/* <TestNodeEditor /> */}
          <AlertHandler />
        </Layout >
      </URQLProvider>
      {/* </Provider> */}
    </PagesProvider>
  )
}

