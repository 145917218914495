import { isNotNull } from "src/utils"



export interface Disposable {
    dispose: () => void
}


export const emptyDisposable: Disposable = {
    dispose: () => {
        //
    }
}

export class CompositeDisposable implements Disposable {

    private _disposables: Disposable[]
    constructor(disposalbes?: Disposable[]) {
        const dis = disposalbes ?? []
        this._disposables = [...dis]
    }

    dispose() {
        this._disposables.forEach(x => x.dispose())
    }

    add(dispoable: Disposable) {
        this._disposables.push(dispoable)
    }

    addAll(arg0: Disposable[]) {
        this._disposables.concat(arg0)
    }
}

export interface MaybeDispoable {
    dispose?: () => void
}

export function combineAllDisposables(args: Disposable[]): CompositeDisposable {
    return new CompositeDisposable(args)
}

export function combineDisposables(...args: (Disposable | undefined)[]): Disposable {
    return new CompositeDisposable(args.filter(isNotNull))
}
