import { Clazz, custom, deserialize, getDefaultModelSchema, serialize, serializeAll } from "serializr"

const modelInfoByName: {
    [name: string]: Clazz<any>
} = {}


export function serializeAllNamed(name: string): <T>(clazz: Clazz<T>) => Clazz<T> {
    return function <T>(clazz: Clazz<T>) {
        modelInfoByName[name] = clazz
        return serializeAll(clazz)
    }
}

export function union<T, K extends keyof T>(k: K) {
    return custom(
        (sourcePropertyValue: T, key, sourceObject) => {

            const kind = sourcePropertyValue[k]
            if (typeof kind !== "string") {
                throw new Error("key must be a string")
            }

            const schema = getDefaultModelSchema(sourcePropertyValue)
            if (schema === undefined) {
                return {
                    kind,
                    data: sourcePropertyValue
                }
            } else {
                if (modelInfoByName[kind] === undefined) {
                    throw new Error(`Expected to find registry for ${(sourcePropertyValue as any).constructor.name} registered under name "${kind}". Be sure to use @serializeAllNamed(${kind})`)
                }
                return {
                    kind,
                    data: serialize(schema, sourcePropertyValue)
                }
            }
        },
        (jsonValue) => {
            const kind = jsonValue.kind
            if (modelInfoByName[kind] === undefined) {
                return jsonValue.data
            }
            else {
                return deserialize(modelInfoByName[jsonValue.kind], jsonValue.data)
            }
        }
    )
}