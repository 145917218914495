
import { isObservableObject, makeAutoObservable, runInAction, toJS } from "mobx"
import { CinemaState, LayerNameKeys, ShaderSummary } from "./cinema"
import { ConnectedStatus, NotConnectedStatus } from "./connect"
import { disconnect as disconnectWindow, establishConnection, pingConnection } from "./connect/connections"
import { SamplePackID } from "./identifiers/identifiers"
import { Track, TrackSerializationFixup } from "./sound/Track"
import { ModelIdentifier } from "./ui/actions"
import { GizmosType, SelectableLayerIDTypes } from "./ui/types"
import { v4 as uuidv4 } from 'uuid';
import { DragSampleDescription } from "src/components/DragCore/dragTypes"
import { startup } from "./middleware/cinemaMiddleware"
import * as BABYLON from "@babylonjs/core";
import { list, object, raw, serializable, serialize, serializeAll } from "serializr"
import { AsyncStateLoader, asyncStateNone } from "src/utils/async"
import { assert, assertNotNull } from "src/utils"
import { SessionState } from "./session"
import { ModulationState } from "./bindings"
import { AuditionState } from "./AuditionState"
import { UIState } from "./ui/state"
import { search } from "./cinema/search"
import { UserState } from "./userState"


@serializeAll
export class SoundState {


    constructor() {
        makeAutoObservable(this)
    }

    bpm = 125

    @serializable(list(object(Track, TrackSerializationFixup)))
    tracks: Track[] = []

    addTrack(info: DragSampleDescription) {
        //action.payload.id
        const dragInfo = info
        const sampleFile = dragInfo.payload.sampleFile


        const newTrack = new Track({
            trackIndex: this.tracks.length,
            trackId: uuidv4(),
            beats16ths: sampleFile.beats16ths ?? 16, // TODO FIx
            sampleFileID: sampleFile.id,
            sampleFile,
        })

        this.tracks.push(newTrack)
    }

    removeTrack(trackInfo: Track) {
        const index = this.tracks.indexOf(trackInfo)
        if (index == -1) {
            throw new Error(`Failed to find track ${trackInfo.trackId}`)
        }
        const track = this.tracks[index]
        this.tracks.splice(index, 1)
        return track
    }

    reorder(ids: string[]) {
        const reorderedTracks = ids.reduce((acc, trackId, index) => {
            const track = this.tracks.find((v) => v.trackId === trackId)
            if (track === undefined) {
                throw new Error("Failed to find trackId:" + trackId)
            }

            track.trackIndex = index
            acc.push(track)
            return acc
        }, [] as SoundState['tracks'])

        this.tracks = reorderedTracks
    }

    findTrack(identifier: string | { trackId: string }): Track {

        let trackId: string
        if (typeof identifier === "string") {
            trackId = identifier
        } else {
            trackId = identifier.trackId
        }
        const track = this.tracks.find(x => x.trackId === trackId)
        assertNotNull(track)
        return track
    }
}



@serializeAll
export class ConnectedState {

    constructor() {
        makeAutoObservable(this)
        this.state = {
            "kind": "NotConnected"
        }
    }

    @serializable(raw())
    state: NotConnectedStatus | ConnectedStatus

    async pingConnection() {
        const result = await pingConnection(this)
        runInAction(() => {
            this.state = result
        })
    }

    async establishConnection() {
        const result = await establishConnection()
        runInAction(() => {
            this.state = result
        })
    }

    get isConnected(): boolean {
        return this.state.kind === "Connected"
    }

    disconnect() {
        disconnectWindow()
        this.state = {
            "kind": "NotConnected"
        }
    }

}



export class PagesState {


    constructor() {
        makeAutoObservable(this)
    }

    @serializable(object(SessionState))
    session: SessionState = new SessionState()

    @serializable(object(SoundState))
    sounds = new SoundState()

    @serializable(object(CinemaState))
    cinemaState = new CinemaState()

    @serializable(object(ConnectedState))
    connect = new ConnectedState()

    @serializable(object(ModulationState))
    modulationState = new ModulationState()

    // Volatile state. Not serialized.
    audition = new AuditionState()
    uiState = new UIState()
    renderCore = new RenderCoreState()
    userState = new UserState()

    reset() {
        this.session = new SessionState()
        this.sounds = new SoundState()
        this.cinemaState = new CinemaState()
        this.audition = new AuditionState()
        this.modulationState = new ModulationState()

        this.uiState = new UIState()
        this.renderCore = new RenderCoreState()

        this.renderCore.queueReloadSession()
    }

    saveData(): string {
        const data = serialize(this)
        delete data["connect"]
        return data as any
    }





    merge(store: PagesState,
        options: { connect: boolean }) {
        this.cinemaState = store.cinemaState
        this.sounds = store.sounds
        this.session = store.session
        this.modulationState = store.modulationState
        if (options.connect) {
            this.connect = store.connect
        }
    }

    validate() {
        const modulations = this.modulationState.modulations
        modulations.forEach(mod => {

            try {


                try {
                    search.findUniformRequired(mod.target, this.cinemaState)
                } catch (error) {
                    console.error(`VALIDATE: Failed to find uniform on target: ${mod.target.compositeKey}`)
                }

                try {
                    search.findTrackRequired(mod.source, this.sounds)
                } catch (error) {
                    console.error(`VALIDATE: Failed to find track on source: ${mod.source.compositeKey}`)

                }
            } catch {
                this.modulationState.removeModulation(mod)
            }

        })
    }
}

export interface ReloadAction {
    layerName: LayerNameKeys
    kind: 'reload'
}

export class RenderCoreState {

    constructor() {
        makeAutoObservable(this)
    }

    startup(reactCanvas: React.MutableRefObject<null>) {
        assertNotNull(reactCanvas)

        const engineOptions: BABYLON.EngineOptions = {
            //limitDeviceRatio: 2,
        }
        const adaptToDeviceRatio = false
        const antialias = true

        const engine = new BABYLON.Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio);

        startup(engine)
        BABYLON.Logger.Error = (message) => {
            console.error(message)
        }
        this.engineInitialized = true;
        this.queueReloadSession()
        return engine

    }

    engineInitialized = false

    renderEngineState: AsyncStateLoader = asyncStateNone

    get isRenderEngineLoading(): boolean {

        if (this.nextAction !== "none") {
            return true
        }

        const kind = this.renderEngineState.kind
        if (kind === "loading") {
            return true
        }

        return false

    }

    private _nextAction: "none" | "reloadSession" | ReloadAction[] = "none"

    get nextAction(): RenderCoreState["_nextAction"] {
        return this._nextAction
    }

    popNextAction(): ReloadAction {
        assert(typeof this._nextAction === "object", "")
        const actions = this._nextAction as ReloadAction[]
        assert(actions.length > 0, "shoudl be greater than 0")
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const action = actions.pop()!
        if (actions.length === 0) {
            this._nextAction = "none"
        } else {
            this._nextAction = actions
        }
        return action
    }

    clearNextAction() {
        this._nextAction = "none"
    }

    queueReloadSession() {
        this._nextAction = "reloadSession"
    }

    queueReloadLayer(layerName: LayerNameKeys) {
        if (this.nextAction === "reloadSession") {
            return
        }
        const action = this.nextAction == "none" ? [] : this.nextAction
        action.push({
            kind: "reload",
            layerName
        })
        this._nextAction = action
    }
}

export class TrackPlaybackState {
    // muted by the user clicking the button
    constructor() {
        makeAutoObservable(this)
        this.mute = false
        this.solo = false
        this.levelMeter = 0
    }
    mute: boolean
    solo: boolean
    levelMeter: number

}

export class SelectedElementState {
    constructor() {
        makeAutoObservable(this)
    }


    private _selectShaderForPreview?: ShaderSummary
    previewShader(shaderSummay: ShaderSummary) {
        this._selectShaderForPreview = isObservableObject(shaderSummay) ? toJS(shaderSummay) : shaderSummay
    }
    clearShaderPreview() {
        this._selectShaderForPreview = undefined
    }

    get selectShaderForPreview(): ShaderSummary | undefined {
        return this._selectShaderForPreview
    }


    selectedModelForPreview?: ModelIdentifier
    selectedElement?: SelectableLayerIDTypes
    selectedSamplePack?: SamplePackID

    gizmosState: GizmosType = "camera"
}


