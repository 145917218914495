import { action, runInAction } from 'mobx'
import { GraphQL } from 'src/generated'
import { MiddleWareContext } from 'src/render/context'
import { ID } from 'src/types/common'
import { Client } from 'urql'
import { IdentifierFactory, TrackID } from '../identifiers/identifiers'
import { PagesState } from '../store'
import { Note, Ratios, Track } from './Track'

export interface NoteReference {
  trackID: TrackID,
  note: number
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export class TrackActions {



  constructor(
    private context: MiddleWareContext,
    private client: Client,
    private state: PagesState
  ) {

  }

  updateNote = action((track: Track,
    noteIndex: number,
    note: Partial<Note>,

  ) => {

    if (track.modulationLayer.notes[noteIndex] === undefined) {
      track.modulationLayer.addNote(noteIndex)
    }
    track.modulationLayer.updateNote(noteIndex, note)

    console.log(`Update Note: enabled:${note.enabled}`)

    this.context.animationSequencer.invalidateTrack(IdentifierFactory.makeTrack(track))
  })

  updateRatios = action((track: Track, ratios: Ratios) => {
    track.modulationLayer.envelope = ratios
    this.context.animationSequencer.invalidateTrack(IdentifierFactory.makeTrack(track))
  })

  removeTrack = action((trackInfo: Track, state: PagesState) => {

    const track = state.sounds.removeTrack(trackInfo)

    state.modulationState.modulations
      .filter((mod) => mod.source.trackId === track.trackId)
      .forEach((mod) => {
        state.modulationState.removeModulation(mod)
      })
  })

  waitForSplit = action(async (sample: GraphQL.SampleFileFragment) => {

    const result = await this.client.query<
      GraphQL.WaitForSampleToSplitQuery,
      GraphQL.WaitForSampleToSplitQueryVariables>(GraphQL.WaitForSampleToSplitDocument, {
        sampleId: sample.id
      }).toPromise()

    if (result.error) {
      throw result.error
    }

    const splitResult = result.data?.samples.waitForSplit
    if (!splitResult) {
      throw new Error("Expected to find split samples")
    }
    return splitResult
  })
  
  splitTrack = action(async (sample: GraphQL.SampleFileFragment, force?: boolean) => {

    this.state.userState.pendingSampleSplitStatus.delete(sample.id)

    const result = await this.client.mutation<GraphQL.SplitSampleMutation,
      GraphQL.SplitSampleMutationVariables>(GraphQL.SplitSampleDocument, {
        sampleID: sample.id,
        force
      }).toPromise()

    if (result.error) {
      throw result.error
    }

    const splitResult = result.data?.samples.split
    if (!splitResult) {
      throw new Error("Expected to find split samples")
    }

    return splitResult
  })

  updateTrack = action(async (sample: GraphQL.SampleFileFragment, info: { name?: string, numberOfBeatsIn16ths: number }) => {

    const result = await this.client.mutation<GraphQL.UpdateSampleMutation,
      GraphQL.UpdateSampleMutationVariables>(GraphQL.UpdateSampleDocument, {
        sampleId: sample.id,
        info
      }).toPromise()

    if (result.error) {
      throw result.error
    }


    runInAction(() => {
      this.state.uiState.mySampleDirty = true
    })

    return
  })

  updateAllPendingSplits = action(async () => {
    const result = await this.client.query<GraphQL.AllPendingSplitsQuery>(GraphQL.AllPendingSplitsDocument).toPromise()

    if (result.error) {
      throw result.error
    }

    const pendingSplits = result.data?.samples.allSplitStatus ?? []
    runInAction(() => {
      this.state.userState.pendingSampleSplitStatus.clear()
      pendingSplits.forEach((b) => this.state.userState.pendingSampleSplitStatus.set(b.sampleID, b))
    })
  })

  deleteSample = action(async (sampleFile: GraphQL.SampleFileFragment) => {
    const result = await this.client.mutation<
      GraphQL.DeleteSamplesMutation,
      GraphQL.DeleteSamplesMutationVariables>(GraphQL.DeleteSamplesDocument, {
        samples: [sampleFile.id, ...sampleFile.stemsIDs ?? []]
      }).toPromise()

    runInAction(() => {
      this.state.uiState.mySampleDirty = true
    })
  })

  async downloadSample(sampleFile: GraphQL.SampleFileFragment): Promise<void> {
    const result = await this.client.query<
      GraphQL.DownloadSampleQuery,
      GraphQL.DownloadSampleQueryVariables>(GraphQL.DownloadSampleDocument, {
        sampleId: sampleFile.id
      }, {
        requestPolicy: "network-only"
      }).toPromise()


    if (result.error) {
      throw result.error
    }

    const downloadURL = result.data?.samples.downloadSample?.oneTimeURL
    if (!downloadURL) {
      throw new Error("Expected to find download URL")
    }

    try {
      const res = await fetch(downloadURL)
      const blob = await res.blob()
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${sampleFile.name}.wav`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    } catch (e) {
      console.error(e)
      throw e
    }
  }
}


export async function fetchSample(client: Client, samplehashID: ID): Promise<GraphQL.SampleFileFragment> {
  const result = await client.query<GraphQL.FetchSampleQuery, GraphQL.FetchSampleQueryVariables>(GraphQL.FetchSampleDocument, {
    sampleHashID: samplehashID
  }).toPromise()

  if (result.error) {
    throw result.error
  }

  const sample = result.data?.samples.fetchSample
  if (!sample) {
    throw new Error("Expected to find sample")
  }

  return sample
}
