import classNames from 'classnames';
import React from 'react';
import styles from "../Material.module.scss";
import { Box, Tab, Tabs } from '@mui/material';
import { ShaderPreview } from './ShaderPreview';
import { SearchView } from './ShaderSearchView';
import { ShaderViewState } from 'src/store/ui/state';

export type ShaderMainTypes = "material" | "shader"

type Props = {
    shaderType: ShaderMainTypes
    shaderViewState: ShaderViewState
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            className={classNames(styles.TabPanel)}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}


export const ShaderMain: React.FC<Props> = ({ shaderType, shaderViewState }) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (<div className={classNames(styles.ShaderMain)}>
        <ShaderPreview />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                orientation='vertical'
            >
                <Tab label="Search" {...a11yProps(0)} />
                <Tab label="Import" {...a11yProps(1)} />
            </Tabs>
        </Box>
        <TabPanel value={value} index={0} >
            <SearchView shaderType={shaderType} shaderViewState={shaderViewState} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <SearchView shaderType={shaderType} shaderViewState={shaderViewState} />
        </TabPanel>

    </div>);
};
