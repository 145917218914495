import { CinemaState, LayerShader, LayerType } from "src/store/cinema";
import { ensure } from "src/utils";
import { IdentifierFactory, LayerMaterialID, LayerShaderID, LayerUniformID } from "src/store/identifiers/identifiers";

export interface BindableTargetData {
    kind: "target"
    layer: LayerType
    uniforms: BindableUniformData[]
}

export interface BindableUniformData {
    kind: "audio" | "unfirom" | "texture"
    uniformName: string,
    layerUniform: LayerUniformID
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EdgeData {
    kind: "edge"
    //
}

export const fetchDataForLayer = (layer: LayerType): BindableTargetData | undefined => {

    const mapShaderToBindableRowData = (
        shaderID: LayerShaderID | LayerMaterialID,
        shader: LayerShader): BindableUniformData[] => {
        return Object.values(shader.uniforms).reduce((acc, uniform) => {
            switch (uniform.kind) {
                case "AudioUniform":
                    acc.push({
                        kind: "audio",
                        uniformName: uniform.name,
                        layerUniform: IdentifierFactory.makeUniformIdentifier(shaderID, uniform.name)
                    })
                    break

                case "PrimitiveUniform":
                    if (uniform.modulatable) {
                        acc.push({
                            kind: "unfirom",
                            uniformName: uniform.getFriendlyName(),
                            layerUniform: IdentifierFactory.makeUniformIdentifier(shaderID, uniform.name)
                        })
                    }
            }

            return acc
        }, [] as BindableUniformData[])
    }

    switch (layer.kind) {
        case "backgroundLayer":
        case "postProcessLayer": {
            const shaderID = IdentifierFactory.makeLayerShaderIdentifier(layer)

            const layerShader = layer.shader
            if (layerShader === undefined) {
                return undefined
            }

            return ensure<BindableTargetData>({
                kind: "target",
                layer,
                uniforms: mapShaderToBindableRowData(shaderID, layerShader)
            })
        }
        case "forgroundModelLayer": {
            const shaderID = IdentifierFactory.makeLayerMaterialIdentifier(layer)

            const layerShader = layer.materialShader
            if (layerShader === undefined) {
                return undefined
            }

            return ensure<BindableTargetData>({
                kind: "target",
                layer,
                uniforms: mapShaderToBindableRowData(shaderID, layerShader)
            })
        }
    }
    return undefined
}


export const generateBindableTargets = (cinemaState: CinemaState): BindableTargetData[] => {

    const layers = cinemaState.allLayers

    const rows = layers.map(layer => {
        return fetchDataForLayer(layer)
    })

    return rows
        .filter((x): x is BindableTargetData => x !== undefined)
        .map((row) => {
            return row
        })
}


