
import { DefaultNodeModel, DefaultPortModel, DiagramModel } from "@projectstorm/react-diagrams";
import { Identifier } from "src/store/identifiers/identifiers";
import { findFirstWhere, requireExists, requireType } from "src/utils";
import { NodeEditorContext } from "../NodeEditorContext";
import { AudioLinkModel } from "../factories/AudioLinkModel";
import { AudioModSourcePortModel, AudioSourcePortModel, LoopBasePortModel } from "../factories/PortModels";
import { TrackNodeModel } from "../factories/TrackNode";
import { ModulationState } from "src/store/bindings";


export function makeLinks(
    model: DiagramModel,
    modulationState: ModulationState,
    context: NodeEditorContext): AudioLinkModel[] {
    const modulations = modulationState.modulations

    const trackNodes = model.getNodes().filter((n): n is TrackNodeModel => {
        return n instanceof TrackNodeModel
    })

    const cinemaNodes = model.getNodes().filter((n): n is DefaultNodeModel => {
        return !(n instanceof TrackNodeModel)
    })

    if (cinemaNodes.length + trackNodes.length !== model.getNodes().length) {
        throw new Error("Invalid number of node count.")
    }

    const links = modulations.map(mod => {
        let outPort: LoopBasePortModel
        let inPort: LoopBasePortModel
        switch (mod.kind) {
            case "AudioBinding": {
                outPort = requireExists(findPortOnNodes(false, trackNodes, mod.source))
                inPort = requireExists(findPortOnNodes(true, cinemaNodes, mod.target))
                break
            }
            case "PrimitiveBinding": {
                outPort = requireExists(findPortOnNodes(false, trackNodes, mod.source))
                inPort = requireExists(findPortOnNodes(true, cinemaNodes, mod.target))
                break
            }
        }

        if (!outPort || !inPort) {
            throw new Error(`Failed to find inPort or outPort`)
        }
        const port = requireType<AudioLinkModel>(outPort.link(inPort), (t) => t instanceof AudioLinkModel)
        
        port.modulation = mod
        return port
    })

    return links
}

function findPortOnNode(ports: DefaultPortModel[], identifier: Identifier): LoopBasePortModel | undefined {
    return ports.find(x => {
        const y: AudioSourcePortModel | AudioModSourcePortModel = x as any
        return y.identifier.compositeKey === identifier.compositeKey
    }) as any
}


function findPortOnNodes(isIn: boolean, nodes: DefaultNodeModel[], identifier: Identifier): LoopBasePortModel | undefined {

    return findFirstWhere(nodes, (next) => {
        const ports = isIn ? next.getInPorts() : next.getOutPorts()
        return findPortOnNode(ports, identifier)
    })
}