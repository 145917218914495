import React from 'react';
import classNames from "classnames"
import { AudioUniform } from "src/store/cinema"
import styles from "../Cinema.module.scss"

interface Props {
    item: AudioUniform
}

export const AudioUniformItem: React.FC<Props> = (props) => {

    return (<div className={classNames(styles.BindableItem, styles.pinned)} >
        <div className={classNames(styles.BindableItemTopRow)}>
            <p className='text-sm'>
                AUDIO INPUT
            </p>
        </div>
        <div className={styles.BindableItemBody}>
            <p  >
                {props.item.getFriendlyName()}
            </p>
        </div>
    </div>)
}