import { observer } from "mobx-react-lite";
import React, { useEffect } from "react"
import { useAppContext } from "src/store/hooks";
import { AlertDialog, AlertDialogProps } from ".";

const AlertHandler: React.FC = observer(() => {

    const alerts = useAppContext().uiState.pendingAlerts
    // const [openDialog, setOpenDialog] = React.useState<AlertDialogProps | undefined>({
    //     open: true,
    //     body: "body",
    //     title: "title",
    //     close() {
    //         setOpenDialog(undefined)
    //     }
    // })

    const [openDialog, setOpenDialog] = React.useState<AlertDialogProps | undefined>(undefined)


    useEffect(() => {
        const alert = alerts[0]
        if (alert !== undefined) {

            const props: AlertDialogProps = {
                open: true,
                title: alert.title,
                body: alert.body,
                close() {
                    alert.cancel()
                    setOpenDialog(undefined)
                }
            }

            if (alert.okay) {
                props.okay = () => {
                    alert.okay?.()
                    setOpenDialog(undefined)
                }
            }

            setOpenDialog(props)
        }
    }, [alerts[0]])


    return (
        <div>
            {openDialog ?
                <AlertDialog
                    {...openDialog}
                /> : null}
        </div>
    )
})

export default AlertHandler;
