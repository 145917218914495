import classNames from 'classnames';
import React from 'react';
import styles from "../Cinema.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';

type HeaderProps = {
    layerName: string;
    close: () => void;
};
export const Header: React.FC<HeaderProps> = ({ layerName, close }) => {
    return (<div className={classNames(styles.HeaderStyle)}>
        <Typography>
            {layerName}
        </Typography>
        <IconButton color="primary" component="span" size='small' onClick={close}>
            <CloseIcon />
        </IconButton>
    </div>);
};
