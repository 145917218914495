import * as BABYLON from "@babylonjs/core";
import { LayerTypeKeys } from ".";
import { LayerKind, LayerNameKeys, LayerType, SimpleVector3, allCinemaLayerNames } from "./types";


export function isTime(uniformName: string): boolean {
    return uniformName === "time" || uniformName === "iTime"
}

export function serializeVector3(vector: BABYLON.Vector3): SimpleVector3 {
    return {
        x: vector.x,
        y: vector._y,
        z: vector._z
    }
}

export function deserializeVector3(vector: SimpleVector3): BABYLON.Vector3 {
    return new BABYLON.Vector3(
        vector.x,
        vector.y,
        vector.z
    )
}


export const LayerNameToTypeMap: Record<LayerNameKeys, LayerTypeKeys> = {
    "background": "postprocess",
    "secondary": "model",
    "primary": "model",
    "postprocess": "postprocess"
}

export const LayerNameToKindMap: Record<LayerNameKeys, LayerKind> = {
    "background": "backgroundLayer",
    "secondary": "forgroundModelLayer",
    "primary": "forgroundModelLayer",
    "postprocess": "postProcessLayer"
}

export const SortLayers = (a: LayerType, b: LayerType): number => {
    try {
        return allCinemaLayerNames.indexOf(a.info.layerName) > allCinemaLayerNames.indexOf(b.info.layerName) ? 1 : -1
    } catch (error) {
        throw new Error("Failed to sort Layers")
    }
}