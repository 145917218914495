import React from "react";
import { graphQLCLient } from "src/core/client";
import { gState } from "src/store";
import { CinemaActions } from "src/store/cinema/actions";
import { ConnectionActions } from "src/store/connect/actions";
import { getLoopContext } from "src/store/middleware/cinemaMiddleware";
import { TrackActions } from "src/store/sound";
import { PagesState } from "src/store/store";
import { UIStateActions } from "src/store/ui/actions";
import { Client } from "urql";
import { MiddleWareContext } from "./context";



export function makeFactories(
    state: PagesState,
    client: Client,
    middleware: MiddleWareContext): ActionFactories {
    return {
        trackActions: new TrackActions(middleware, client, state),
        cinemaActions: new CinemaActions(middleware, state),
        uiActions: new UIStateActions(
            client,
            middleware,
            state,
            () => { return makeFactories(state, client, middleware).cinemaActions }
        ),
        connectionActions: new ConnectionActions(state)
    }
}


export interface ActionFactories {
    trackActions: TrackActions
    cinemaActions: CinemaActions
    uiActions: UIStateActions
    connectionActions: ConnectionActions

}

export interface LoopProviderContext {
    getLoopContext(): MiddleWareContext
    actionsFactories(): ActionFactories
}

const loopContext: LoopProviderContext = {
    getLoopContext,
    actionsFactories() {
        return makeFactories(gState, graphQLCLient, getLoopContext())
    }
}

export const LoopContext = React.createContext(loopContext)

export const LoopProvider = ({ children }: any) => {
    return (
        <LoopContext.Provider value={loopContext}>
            {children}
        </LoopContext.Provider>
    )
}
