import * as React from 'react';
import Card from '@mui/material/Card';
import { CardActionArea, CardMedia, Theme } from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import * as GraphQL from 'src/generated/graphqlTypes';
import { IdentifierFactory } from 'src/store/identifiers/identifiers';
import { useAppContext } from 'src/store/hooks';
import { observer } from 'mobx-react-lite';
import { action } from "mobx"

type Props = {
  samplePack: GraphQL.SamplePackFragment
}

type StyleProps = {
  selected: boolean
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  SampleCardPreview: props => ({ // props = { width: string; height: string }
    borderRadius: "5px",
    //border: props.selected ? "1px solid green" : "1px solid black",
    boxShadow: props.selected ?
      "inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px"
      :
      "inset rgba(201, 211, 219, 0.5) 0 0 0 2px, rgba(255, 255, 255, 0) 0 0 0 1px, rgba(201, 211, 219, 0.25) 10px 7px 12px",
    boxSizing: "border-box",
    transition: "box-shadow 250ms ease",
    padding: "3px",
    width: "200px"
  }),
}));

export const SamplePackPreview: React.FC<Props> = observer(({ samplePack }) => {

  const samplePackID = IdentifierFactory.makeSamplePackID(samplePack.id)

  const uiState = useAppContext().uiState
  const selectedPack = uiState.selectedElements.selectedSamplePack

  const classes = useStyles({ selected: selectedPack?.compositeKey === samplePackID.compositeKey });

  return (
    <div className={classNames(classes.SampleCardPreview)}>

      <Card>
        <CardActionArea onClick={action(() => {
          uiState.selectedElements.selectedSamplePack = samplePackID
        })}>
          <CardMedia
            className='h-[80px]'
            component="img"
            image={samplePack.imageURL}
            sx={{
              objectFit: "cover"
            }}
          />
          <div >
            <p className='text-base'>{samplePack.name}</p>
            <div className="flex p-1 justify-start">
              {samplePack.bpm ? (
                <p className='text-sm'>{`${samplePack.bpm} BPM`}&nbsp;</p>
              ) : null}
              <p className='text-sm'>
                {samplePack.samples.length} stems
              </p>
            </div>
          </div>
        </CardActionArea>
      </Card >
    </div >
  )
})

export type SampleCardPackFileRowProps = {
  samplePackFile: GraphQL.SampleFileFragment,
  listeners?: any,
  attributes?: any,
  setNodeRef?: (element: HTMLElement) => void
}


