import { observer } from "mobx-react-lite"
import React from "react"
import { useAppContext } from "src/store/hooks"
import { SampleCardLoader } from "./SampleCard"



export const SelectedSamplePack: React.FC = observer(() => {
    const selectedSamplePack = useAppContext().uiState.selectedElements.selectedSamplePack
    return (
        <div className="flex flex-col w-[275] h-full overflow-auto" style={{
            minWidth: "275px"
        }}>
            {selectedSamplePack ? (<SampleCardLoader samplePackID={selectedSamplePack} />) : null}
        </div >)
})