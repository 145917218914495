import React, { LegacyRef } from "react"

import styles from '../DragCore/Item/Item.module.scss';
import classNames from 'classnames';
import SampleRow from "./SampleRow";
import { NFTRenderItemArgs } from "../DragCore/NFTItem/Item";
import { useAppContext } from "src/store/hooks";

export * from "./SampleRow"

export const renderSampleRow = ({ index,
    ref,
    listeners,
    transform,
    transition,
    fadeIn,
    sorting,
    dragOverlay,
    dragging,
    removeItem,
    payload }: NFTRenderItemArgs) => {

    //const track = payload.track  //sounds.tracks.find((track) => track.trackId === value)

    // if (track == undefined) {
    //     return (<div />)
    // }

    const track = useAppContext().sounds.tracks.find((track) => track.trackId === payload.id)

    if (track === undefined) {
        return (<div />)
    }


    return (
        <div
            // Ive had to do this because otherwise the drag from song over track doesn't
            // detect properly.
            //ref={songIsDragging ? (ref) : null}
            ref={ref as unknown as LegacyRef<HTMLDivElement>}
            className={classNames(
                styles.Wrapper,
                fadeIn && styles.fadeIn,
                sorting && styles.sorting,
                //dragOverlay && styles.dragOverlay
            )}
            style={
                {
                    // ...wrapperStyle,
                    transition,
                    '--translate-x': transform
                        ? `${Math.round(transform.x)}px`
                        : undefined,
                    '--translate-y': transform
                        ? `${Math.round(transform.y)}px`
                        : undefined,
                    '--scale-x': transform?.scaleX
                        ? `${transform.scaleX}`
                        : undefined,
                    '--scale-y': transform?.scaleY
                        ? `${transform.scaleY}`
                        : undefined,
                    '--index': index,
                    //'--color': color,
                } as React.CSSProperties
            }
        >


            {/* <div style={{ width: "200px", height: "20px", backgroundColor: "blueviolet" }}{...listeners} /> */}
            <div
                className={classNames(
                    styles.SampleRowWrapper,
                    dragging && styles.dragging,
                    //handle && styles.withHandle,
                    //dragOverlay && styles.dragOverlay,
                    //disabled && styles.disabled,
                    //color && styles.color
                )}>
                    
                <SampleRow
                    trackInfo={track}
                    dragProps={{ listeners: listeners }}
                    removeItem={removeItem} />
            </div>
        </div>
    )
}
