import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { SamplePackPreview } from "./SamplePackPreview";
import * as GraphQL from 'src/generated/graphqlTypes';
import _ from "lodash";
import InfiniteScroll from "../InfiniteScroll";

export type DragListEntry = {
    id: string
}

export const SamplePackList: React.FC = () => {

    const [packList, setPackList] = useState<GraphQL.SamplePackFragment[]>([])
    const [variables, setVariables] = useState<GraphQL.FetchSamplePacksQueryVariables>({
    })

    const [result] = GraphQL.useFetchSamplePacksQuery({
        variables
    })

    useEffect(() => {
        if (!result.data?.samples.fetchSamplePacks) {
            return
        }

        const { pageInfo, packs } = result.data?.samples.fetchSamplePacks

        if (!packs || packs.length === 0) {
            return
        }

        if (packList.length === 0 ||
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            _.last(packList)!.id !== _.last(packs)!.id) {
            setPackList([...packList, ...packs])
        }
    }, [result.data])

    const fetchData = () => {
        const _nextPage = result.data?.samples.fetchSamplePacks?.pageInfo.endCursor
        setVariables({
            ...variables,
            after: _nextPage
        })
    }

    const hasNextPage = result.data?.samples.fetchSamplePacks?.pageInfo.hasNextPage ?? false
    console.log(`Pack: ${packList.length}:${hasNextPage}`)

    return (<div className="flex flex-col h-full overflow-auto px-2">
        <InfiniteScroll
            loadMore={fetchData}
            hasMore={!result.fetching && hasNextPage}
            style={{
                height: "100%",
                width: "100%"
            }}>
            <Grid container spacing={3} sx={{ backgroundColor: "" }} >
                {packList.map((item, index) =>
                    <Grid item key={index} >
                        <SamplePackPreview samplePack={item} />
                    </Grid>
                )}
            </Grid>
        </InfiniteScroll>
    </div>)
}
