import React, { CSSProperties, useState } from "react";
import { PlayCircleIcon, StopCircleIcon } from "@heroicons/react/20/solid"
import { useAppContext } from "src/store/hooks";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { Headphones, Spinner } from "src/svg";
import TextFieldInset from "../Controls/TextField";

export const TransportUI: React.FC = observer(() => {
    //const { sounds } = useContext(PagesContext);
    const { sounds, audition } = useAppContext()

    const [uistate, setUIState] = useState(
        {
            playingEnabled: !audition.isPlaying,
            stopEnabled: audition.isPlaying
        }
    )

    const [transportBPM, setTransportBPM] = useState(sounds.bpm)
    const [metronomeIsOn, setMetronomeIsOn] = useState(false)


    const onPlay = action(() => {
        setUIState({
            playingEnabled: false,
            stopEnabled: true
        })
        audition.isPlaying = true
    })


    const onStop = action(() => {
        setUIState({
            playingEnabled: true,
            stopEnabled: false,
        })
        audition.isPlaying = false

    })


    return (<div className="flex flex-row items-center bg-white w-fit rounded p-1">
        <button onClick={onPlay} disabled={!uistate.playingEnabled}>
            <PlayCircleIcon className="h-6 w-6 " />
        </button >
        <button onClick={onStop} disabled={!uistate.stopEnabled}>
            <StopCircleIcon className="h-6 w-6 " />
        </button >
        <button
            onClick={() => {
                const newMetronomeState = !metronomeIsOn
                setMetronomeIsOn(newMetronomeState)
            }}
            className={`px-2 py-1 mr-1 rounded border border-black ${metronomeIsOn ? "bg-blue-500" : "bg-gray-100"}`}
        >
            <img
                className="h-5 w-5"
                src={"/icons/metronome.svg"} />
        </button>

        <TextFieldInset
            label={"BPM"}
            type={"number"}
            value={transportBPM.toString()}
            onChanged={function (text: string): void {
                const bpm = Number.parseFloat(text)
                setTransportBPM(bpm)
            }} />


        <HeadphoneAudition style={{}} />

    </div >
    )
})

interface HeadphoneProps {
    style?: CSSProperties | undefined;
}

const HeadphoneAudition: React.FC<HeadphoneProps> = observer(({ }) => {

    const audition = useAppContext().audition
    const auditionState = audition.sampleAudition
    let isQueued = false
    let isAuditioning = false

    if (auditionState.kind !== "stopped") {
        isAuditioning = auditionState.kind === "playing"
        isQueued = auditionState.kind === "queued"
    }

    return (
        <button className="btn-blue self-center disabled:bg-slate-400" onClick={() => {
            audition.stopAuditionPlaying()
        }}
            disabled={auditionState.kind === "stopped"}
        >
            {isQueued ? <Spinner /> :
                !isAuditioning ? <Headphones className="text-gray-500 h-4 w-4"></Headphones> :
                    <Headphones className="text-white h-4 w-4 animate-ping"></Headphones>}
        </button >

    )
})