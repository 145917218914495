import { Popover, Transition } from "@headlessui/react"
import React, { Fragment } from "react"


type PopupButtonProps = {
    button: React.ReactNode
    children?: React.ReactNode
}


const PopupButton: React.FC<PopupButtonProps> = (props) => {
    return (
        <Popover className="relative flex justify-center">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={`
${open ? '' : 'text-opacity-90'}
group flex items-center rounded-md text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                        {props.button}
                    </Popover.Button>
                    <Transition

                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-fit max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
                            {props.children}
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>)
}

export default PopupButton;
