import React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DefaultNodeModel, DefaultNodeWidget, DiagramEngine } from '@projectstorm/react-diagrams';
import { DefaultPortLabel } from './DefaultPortLabelWidget';
import ModulationOutputPortLabel from './ModulationOutputPortLabel';
import { AudioModSourcePortModel, AudioSourcePortModel } from './PortModels';



class TrackNodeWidget extends DefaultNodeWidget {

    generatePort = (port: AudioModSourcePortModel | AudioSourcePortModel) => {
        if (port instanceof AudioModSourcePortModel) {
            return <ModulationOutputPortLabel
                engine={this.props.engine}
                portID={port.identifier as any}
                port={port}
                key={port.getID()}
            />
        } else {
            return <DefaultPortLabel
                engine={this.props.engine}
                port={port}
                color={"#340"}
                key={port.getID()} />
        }
    }
}

export class TrackNodeModel extends DefaultNodeModel {
    constructor(name?: string, color?: string) {
        super({
            color,
            name,
            type: "track"
        })
    }
}

export class TrackNodeFactory extends AbstractReactFactory<TrackNodeModel, DiagramEngine> {
    constructor() {
        super('track');
    }

    generateReactWidget(event: any): JSX.Element {
        return <TrackNodeWidget engine={this.engine} node={event.model} />;
    }

    generateModel(event: any) {
        return new TrackNodeModel();
    }
}
