import { makeAutoObservable } from "mobx"
import { GraphQL } from "src/generated"




export class UserState {
    constructor() {
        makeAutoObservable(this)
    }

    bookmarkedShaders = new Set<string>()
    reportedShaders = new Set<string>()

    pendingSampleSplitStatus = new Map<string, GraphQL.SplitSampleResponseFragment>()
}