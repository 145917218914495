import React from 'react'

const TextFieldInset: React.FC<{
    label: string,
    type?: "text" | "number"
    value?: string,
    placeHolder?: string,
    onChanged(text: string): void
}> = ({ label: name, type = "text", value, onChanged, placeHolder }) => {
    return (<div
        className="items-start flex flex-col  gap-0 rounded-md border border-gray-300  px-3 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
        <label htmlFor="name" className="block text-xs font-light text-gray-900 -translate-y-1">
            {name}
        </label>
        <input
            type={type}
            className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0  text-base"
            placeholder={placeHolder}
            value={value}
            onChange={(e) => onChanged(e.target.value)}
        />
    </div>
    )
}

export default TextFieldInset
