import { AppBar, CssBaseline, Drawer, IconButton, Tab, Tabs, Toolbar } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import clsx from "clsx";
import { Theme } from '@mui/material/styles';
import { getNavigation } from "src/components/SampleRow/lib/content";
import { SelectedUIStates } from "src/store/ui/types";
import { ConnectionIcon } from "./Connection/ConnectionIcon";
import { useAppContext } from "src/store/hooks";
import { action } from "mobx"
import { observer } from "mobx-react-lite";
import { XMarkIcon } from "@heroicons/react/20/solid"
import { SaveFileControl } from "./FileMenu/SaveFileControl";
import { SaveFilesNavigation as SaveFilesNavigation } from "./FileMenu/SaveFileList";

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    type DefaultTheme = Theme
}

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            position: "absolute",
            top: "10px", /* Header Height */
            bottom: "0px", /* Footer Height */
            width: "100%",
        },
        appBar: {
            zIndex: 2000,
        },
        title: {

        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        main: {
            flexGrow: 1,
            //  backgroundColor:"green",
            padding: theme.spacing(1),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
            marginTop: theme.spacing(6),

            //backgroundColor:"coal"

        },
        mainShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        }
    })
)

const Layout = observer(({ children, categories }:
    {
        children: any,
        title: string,
        categories: ReturnType<typeof getNavigation>
    }) => {


    const classes = useStyles()
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const nav: Record<string, boolean> = {};

    categories.forEach((item, index) => { nav[item.display] = index == 0 });
    const toggleDrawerOpen = () => {
        setDrawerOpen(!drawerOpen);
    }

    const state = useAppContext()

    const [uiState, setUIState] = useState<SelectedUIStates>("audio")

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar className={classes.appBar}>
                <Toolbar variant="dense" >
                    <IconButton color="inherit" onClick={toggleDrawerOpen} size="large">
                        <MenuIcon />
                    </IconButton>
                    <Tabs
                        value={uiState}
                        onChange={action((_, newValue) => {
                            setUIState(newValue)
                            state.uiState.selectedState = newValue
                        })}
                        textColor="secondary"
                        indicatorColor="secondary"
                    >
                        <Tab value={"audio"} label="Audio" />
                        <Tab value={"wiring"} label="Wiring" />
                        <Tab value={"cinema"} label="Cinema" />
                    </Tabs>
                    <SaveFileControl />
                    <div style={{ flexGrow: 1 }} />
                    <ConnectionIcon />
                </Toolbar>
            </AppBar>
            <Drawer variant="persistent" className={classes.drawer} classes={{ paper: classes.drawerPaper }} anchor="left" open={drawerOpen}>
                <div className="flex flex-col pl-1">
                    <button className="" onClick={toggleDrawerOpen}>
                        <div className="flex flex-row items-center justify-center">
                            <span className="text-lg">Close</span>
                            <XMarkIcon className="h-6" />
                        </div>
                    </button>
                    <button className="text-lg text-left mt-1 hover:bg-slate-50" onClick={action(() => {
                        state.reset()
                    })}>Reset</button>
                    < SaveFilesNavigation toggle={toggleDrawerOpen} />
                </div>
            </Drawer>
            <main className={clsx(classes.main, { [classes.mainShift]: drawerOpen, })}>

                {children}
                {/* <div style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                }}>
                    {children}


                </div> */}

            </main >
        </div >
    );
})


export default Layout
