
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLoopActions } from 'src/render/hooks';
import { Ratios, Track } from "src/store/sound/Track"
import { ADSREnvelope } from "../Envelope/ADSREnvelope"


interface Props {
    trackInfo: Track

}

const TrackADSRWindow: React.FC<Props> = observer(({ trackInfo }) => {

    const { trackActions: action } = useLoopActions()
    const _onChangeRatios = (ratios: Ratios) => action.updateRatios(trackInfo, ratios)

    const onChangeRatios = _.debounce(_onChangeRatios, 500)

    return <ADSREnvelope
        ratios={trackInfo.modulationLayer.envelope}
        onChange={onChangeRatios}
    />
})

export default TrackADSRWindow