
import * as BABYLON from "@babylonjs/core";
import { Disposable } from "src/utils/disposable";

import { TransportContext } from "../audio/TransportContext";
import { LayerNameKeys, LayerType, MeshParameters } from "src/store/cinema";
import { GizmosType } from "src/store/ui/types";
import { GlobalRenderContext } from "./GlobalRenderContext";

import { BindingContext } from "../sequencing/binding";
import { TimeFrame } from "./UpdateContext";
import { RenderLayer } from "./RenderLayer";
import { LoadingStateMonitor, asyncStateFailed, asyncStateLoaded, asyncStateLoading, makeAsyncStateLoader } from "src/utils/async";
import { assert } from "src/utils";


type RenderLayerMap = Partial<Record<LayerNameKeys, RenderLayer>>



export class RenderManager implements Disposable {

    private engine?: BABYLON.Engine = undefined
    private layers: RenderLayerMap = {}
    private sceneReady = false
    private binding: BindingContext
    public loadingState: LoadingStateMonitor = makeAsyncStateLoader()

    transport: TransportContext;

    currentTimeframe: TimeFrame

    constructor(params: {
        binding: BindingContext,
        transport: TransportContext
    }) {
        this.binding = params.binding
        this.transport = params.transport
        this.currentTimeframe = {
            frame: 0,
            time: 0,
            deltaTime: 0.01
        }
    }


    private makeContext(): GlobalRenderContext {
        return {
            binding: this.binding,
            transport: this.transport,
            update: {
                get: () => this.currentTimeframe
            }
        }
    }


    dispose() {
        this.engine?.dispose();
        this.clearAllLayers()
    }

    initialize(engine: BABYLON.Engine) {
        this.engine = engine

        let time = 0
        let frame = 0;
        engine.runRenderLoop(() => {
            if (!this.sceneReady) {
                return
            }

            const deltaTimeInMillis = engine.getDeltaTime();
            time += (deltaTimeInMillis / 1000)
            const deltaTime = deltaTimeInMillis / 1000
            frame += 1

            this.currentTimeframe = {
                time,
                frame,
                deltaTime
            }
            try {

                Object.values(this.layers).forEach(l => {
                    l.onRender({
                        time,
                        deltaTime,
                        frame,
                    })
                    l.scene.render()
                })

            } catch (error) {
                console.error(error)
                this.sceneReady = false

            }

        });
    }

    private clearAllLayers() {
        Object.values(this.layers).forEach(l => l.dispose())
        this.layers = {}
        this.engine?.clear(new BABYLON.Color4(0, 0, 0, 0), true, false)
    }

    async startSession(layers: LayerType[]): Promise<void> {
        assert(!this.loadingState.isLoadingState, "In Loading State")
        this.loadingState.setState(asyncStateLoading)

        try {
            this.clearAllLayers()

            if (this.engine == undefined) {
                throw new Error("Missing key deps to start rendering")
            }
            const engine = this.engine

            // interface Payload {
            //     layer: Lay
            //     animationLayer: AnimationLayer
            //     last: boolean
            // }
            // const renderPaylods = layers
            //     .map(layer => {
            //         return {
            //             track: t,
            //             animationLayer: t.animationLayer,
            //             last: false
            //         }
            //     })
            //     .filter((pair): pair is Payload => pair.animationLayer !== undefined)

            // if (renderPaylods.length > 0) {
            //     renderPaylods[renderPaylods.length - 1].last = true
            // }


            const _layersArray = await Promise.all(layers.map((layer: LayerType, index) => {
                const last = layers.length - 1 === index
                const r = new RenderLayer(
                    engine,
                    this.makeContext(),
                    layer,
                    {
                        autoClear: last ? false : true
                    })

                return r.waitForSceneReady(layer).then(() => r)
            }))

            this.layers = _layersArray.reduce((layers, next) => {
                layers[next.layerName] = next
                return layers
            }, {} as RenderLayerMap)

            this.sceneReady = true
            this.loadingState.setState(asyncStateLoaded)
        } catch (error) {
            this.loadingState.setState(asyncStateFailed(error))
            throw error
        }

    }

    async removeLayer(layerName: LayerNameKeys) {
        this.layers[layerName]?.dispose()
        delete this.layers[layerName]
    }

    async replaceLayer(layerName: LayerNameKeys, layer: LayerType) {
        assert(!this.loadingState.isLoadingState, "In Loading State")
        this.loadingState.setState(asyncStateLoading)
        try {
            const engine = this.engine
            if (!engine) {
                throw new Error("No engine")
            }


            this.layers[layerName]?.dispose()

            const r = new RenderLayer(
                engine,
                this.makeContext(),
                layer,
                {
                    autoClear: false
                })

            await r.waitForSceneReady(layer)
            this.layers[layerName] = r
            this.sceneReady = true
            this.loadingState.setState(asyncStateLoaded)
        } catch (error) {
            this.loadingState.setState(asyncStateFailed(error))
            throw error
        }
    }

    performActionOnLayerID(layerID: string): LiveLayerActions | undefined {
        return Object.values(this.layers).find(x => x.layerID == layerID)?.getActions()
    }

    performActionOnLayerName(layerName: LayerNameKeys): LiveLayerActions | undefined {
        return this.layers[layerName]?.getActions()
    }



    performAction(): LiveActions {
        return {
            clearGizmos: () => {
                Object.values(this.layers).forEach(x => x.getActions().clearGizmos())
            },
            enableGizmos: (layerName, gizmosType) => {

                const foundLayer = this.layers[layerName]
                if (foundLayer === undefined) {
                    throw new Error('Failed to find layer Name ' + layerName)
                }

                Object.values(this.layers).forEach(layer => {
                    if (layer.layerName !== layerName) {
                        layer.getActions().clearGizmos()
                    }
                })

                return foundLayer.getActions().enableGizmos(gizmosType)
            }
        }
    }

    layerExists(layerName: LayerNameKeys): boolean {
        return this.layers[layerName] !== undefined
    }


}

export interface LiveActions {

    // Gizmos let you modify the scene elements.
    // https://doc.babylonjs.com/divingDeeper/mesh/gizmo
    clearGizmos: () => void,
    enableGizmos: (layerName: LayerNameKeys, gizmos: GizmosType) => BABYLON.Observable<GizmosUpdateState>
}
export interface LiveLayerActions {
    enableGizmos: (gizmos: GizmosType) => BABYLON.Observable<GizmosUpdateState>
    clearGizmos: () => void
}

export interface RenderLayerOptions {
    autoClear?: boolean
}

export interface GizmosUpdateState {
    camera?: BABYLON.Camera
    meshWorldViewMatrix?: MeshParameters
}


