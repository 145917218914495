
import { DefaultNodeModel } from '@projectstorm/react-diagrams';
import { IdentifierFactory } from 'src/store/identifiers/identifiers';
import { Track } from 'src/store/sound/Track';
import { NodeEditorContext } from '../NodeEditorContext';
import { AudioModSourcePortModel, AudioSourcePortModel } from '../factories/PortModels';
import { TrackNodeModel } from '../factories/TrackNode';



export function makeSources(tracks: Track[], context: NodeEditorContext): DefaultNodeModel[] {

    const sources = tracks.map((track, index): DefaultNodeModel => {
        const audioID = IdentifierFactory.makeTrack(track);
        const envelopeID = IdentifierFactory.makeEnvelope(track);

        const node = new TrackNodeModel(track.sampleTitle, '#390');
        //const port2 = node2.addInPort('In');
        const audioOutput = node.addPort(new AudioSourcePortModel(audioID, 'Audio', context))
        
        const modulationOutput = node.addPort(new AudioModSourcePortModel(envelopeID, 'Mod', context))

        node.setPosition(25, 25 + 220 * index);
        return node

    });

    return sources

}

