import * as BABYLON from "@babylonjs/core";
import { getAudioContext } from "../audio/Transport";
import { BindingTargetResult } from "./types";

export class FrequencyAnalyserTexture
    extends BABYLON.RawTexture
    implements BindingTargetResult<FrequencyAnalyserTexture> {

    public static NUMBER_OF_BINS = 512


    frame = -1
    timeBuffer: Uint8Array = new Uint8Array(FrequencyAnalyserTexture.NUMBER_OF_BINS)
    public analyserNode: AnalyserNode
    // audioTexture: BABYLON.RawTexture

    //renderTexture: BABYLON.Texture

    time = 0

    constructor(scene: BABYLON.Scene,
        private freqBuffer: Uint8Array = new Uint8Array(FrequencyAnalyserTexture.NUMBER_OF_BINS * 2)
    ) {
        // // Frquency is in the first layer 0.
        //const buffer = new Uint8Array(FrequencyAnalyserTexture.NUMBER_OF_BINS * 2);
        super(freqBuffer, FrequencyAnalyserTexture.NUMBER_OF_BINS, 2, 1, scene)

        this.analyserNode = getAudioContext().createAnalyser()


        // const makeTexture = (buffer: ArrayBufferView) => {
        //     return new BABYLON.RawTexture(buffer, FrequencyAnalyserTexture.NUMBER_OF_BINS, 2, 1, scene)
        // }

        // this.audioTexture = makeTexture(this.freqBuffer)
    }

    bindToSource(source: GainNode) {
        source.connect(this.analyserNode)
    }

    updateAnalyser(frame: number) {
        if (this.frame == frame) {
            return
        }

        this.frame = frame

        this.analyserNode.getByteFrequencyData(this.freqBuffer)
        this.analyserNode.getByteTimeDomainData(this.timeBuffer)

        // SLOW COPY!!!!
        for (let i = 0; i < this.analyserNode.frequencyBinCount; i++) {
            this.freqBuffer[i + 512] = this.timeBuffer[i]
        }

        // for (let i = 0; i < this.analyser.frequencyBinCount * 2; i++) {
        //     this.freqBuffer[i] = Math.floor(Math.random() * 255)
        // }

        // if (!this.audioTexture) {
        //     throw new Error("Audio Texture is not prepared")
        // }
        this.update(this.freqBuffer)
    }

    getData() {
        return this
    }
}

