
import classNames from 'classnames';
import React from 'react';
import { PrimitiveUniform, UniformTypes } from 'src/store/cinema';
import { BindableItem } from "./BindableItem"
import styles from "../Cinema.module.scss"

import { IdentifierFactory, LayerMaterialID, LayerShaderID } from 'src/store/identifiers/identifiers';
import { assert, isNotNull } from 'src/utils';
import { ColorPicker } from './ColorPicker';

import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { action } from "mobx"
import { BindableTypes, isModulatableType } from 'src/store/cinema/primitives';
import { AudioUniformItem } from './AudioItem';

interface Props {

    layerID: LayerShaderID | LayerMaterialID
    uniforms: UniformTypes[]
    // shaderID: string
    // shaderType: GraphQL.ShaderType
}

export const ShaderFocusLane: React.FC<Props> = (props) => {

    return (
        <div
            className={classNames(styles.FocusLane)}
            onClick={(event) => {
                event.stopPropagation()
            }}>
            {
                props.uniforms
                    //   .sort(uniformIsPinned)
                    .map((uniform) => {

                        switch (uniform.kind) {
                            case "AudioUniform": {
                                assert(uniform.kind === "AudioUniform", "Expected it to be audio Uniform")
                                const uniformID = IdentifierFactory.makeUniformIdentifier(props.layerID, uniform.name).compositeKey
                                return <AudioUniformItem item={uniform} key={uniformID} />
                            }
                            case "PrimitiveUniform": {
                                const uniformID = IdentifierFactory.makeUniformIdentifier(props.layerID, uniform.name).compositeKey
                                switch (uniform.type) {
                                    case "Color":
                                        return <UniformColorPicker uniform={uniform} key={uniformID} />
                                    case "F":
                                        return <UniformBindable uniform={uniform} key={uniformID} />
                                    default:
                                        return null
                                }
                            }
                            default:
                                throw new Error("Unexpected")
                        }
                    }).filter(isNotNull)
            }
        </div>)
}

const UniformColorPicker: React.FC<{ uniform: PrimitiveUniform }> = observer(({ uniform }) => {
    const _onChange = (execute: () => void) => {
        execute()
    }
    const onChange = _.throttle(_onChange, 100)

    return <ColorPicker
        name={uniform.name}
        typeName="Color"
        value={uniform.value as any}
        onChange={(newValue) => {
            onChange(action(() => {
                uniform.value = newValue
            }))
        }}
    />
})

const UniformBindable: React.FC<{ uniform: PrimitiveUniform }> = observer(({ uniform }) => {
    const _onChange = (execute: () => void) => {
        execute()
    }
    const onChange = _.throttle(_onChange, 100)

    assert(isModulatableType(uniform.type), "Expected this to be bindableType")
    const bindableType: BindableTypes = uniform.type as BindableTypes

    return <BindableItem
        //pinned={true}
        modulatable={(uniform.modulatable === true ?? false)}
        requiredModulatable={uniform.modulatable === "required"}
        name={uniform.getFriendlyName()}
        typeName={bindableType}
        rangeMax={uniform.rangeMax}
        rangeMin={uniform.rangeMin}
        value={uniform.value as any}
        onChange={(newValue) => {
            ///
            onChange(action(() => { uniform.value = newValue }))
        }}
        onChangeModulatable={(newValue) => {
            onChange(action(() => { uniform.modulatable = newValue }))
        }}
        onChangeRange={action((type, newValue) => {
            onChange(() => {
                switch (type) {
                    case "min":
                        uniform.rangeMin = newValue
                        break
                    case "max":
                        uniform.rangeMax = newValue
                        break
                }
            })
        })}
    />
})