import { IdentifierFactory, TrackID } from "src/store/identifiers/identifiers";
import { Track } from "src/store/sound/Track";
import { TransportContext } from "../audio/TransportContext";
import AnimationSequence from "./AnimationSequence";



export class AnimationSequencer {
    findSequence(trackID: TrackID): AnimationSequence[] {
        return this.sequences
            .filter(x => x.trackID.compositeKey === trackID.compositeKey)
    }

    sequences: AnimationSequence[] = []
    constructor() {
        //
    }

    clearAll() {
        this.sequences.forEach(x => x.dispose())
        this.sequences = []
    }

    async onBegin() {
        await Promise.all(this.sequences.map(x => x.onBegin()))
    }

    private findAnimationSequence(track: TrackID | Track) {
        let trackID: TrackID
        if (track instanceof Track) {
            trackID = IdentifierFactory.makeTrack(track)
        } else {
            trackID = track
        }

        const seq = this.sequences.find(x => x.trackID.compositeKey === trackID.compositeKey)
        if (seq) {
            return seq
        }
        return undefined
    }

    getOrMakeAnimationSequnce(transport: TransportContext, track: Track): AnimationSequence {
        let seq = this.findAnimationSequence(track)
        if (seq) {
            return seq
        }
        seq = new AnimationSequence(transport, track)
        this.sequences.push(seq)
        return seq
    }

    invalidateTrack(trackID: TrackID): void {
        this.findAnimationSequence(trackID)?.invalidate()
    }

}