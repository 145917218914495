import * as Tone from 'tone';
import { TransportContext } from "./TransportContext";

export class Metronome {

    synth: Tone.Synth | undefined;
    part: Tone.Part | undefined;

    constructor(private transport: TransportContext) {
        this.createMetronome();
    }

    createMetronome() {
        const synth = new Tone.Synth({
            envelope: {
                decay: 0.05,
                release: 0.05
            }
        }).toDestination();

        const transport = this.transport;
        // use an array of objects as long as the object has a "time" attribute
        const part = new Tone.Part(((time, value) => {

            //console.log(ctx)
            // the value is an object which contains both the note and the velocity
            if (transport.metronomeEnabled()) {
                // console.log("Metronome:tick:" + JSON.stringify(value) + " time:" + time.toFixed(3))
                synth.triggerAttackRelease(value.note, 0.05, time, value.velocity);
            }
        }), [
            { time: "0:0", note: "C5", velocity: 0.9 },
            { time: "0:1", note: "C4", velocity: 0.5 },
            { time: "0:2", note: "C4", velocity: 0.5 },
            { time: "0:3", note: "C4", velocity: 0.5 }
        ]).start(0);

        part.loop = true;
        part.loopEnd = "1:0";

        return { part, synth };
    }
}
