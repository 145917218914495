import classNames from 'classnames';
import React, { ChangeEventHandler, useState } from 'react';
import styles from "../Material.module.scss";
import { Grid, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ShaderPeekView } from './ShaderPeekView';
import * as GraphQL from 'src/generated/graphqlTypes';
import { useEffect } from 'react';
import InfiniteScroll from '../../InfiniteScroll';
import { debounce } from "lodash";
import { ShaderMainTypes } from './ShaderMain';
import { action } from "mobx"
import { useLoopActions } from 'src/render/hooks';
import { S } from 'src/components/Utilities/CommonDivs';
import { ShaderViewState } from 'src/store/ui/state';
import { observer } from 'mobx-react-lite';

type Props = {
    shaderType: ShaderMainTypes
    shaderViewState: ShaderViewState
};


export const SearchView: React.FC<Props> = observer(({ shaderType, shaderViewState }) => {
    const { uiActions } = useLoopActions()

    const hasNextPage = shaderViewState.hasNextPage

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
    const [searchType, setSearchType] = useState<"random" | "bookmarked" | "reported" | undefined>(undefined)

    const constructVariables = (): GraphQL.FetchShaderSummariesQueryVariables => {
        return {
            type: convertShaderType(shaderType),
            search: {
                term: searchTerm,
                type: (() => {
                    switch (searchType) {
                        case "random": return GraphQL.ShaderSearchType.Random
                        case "bookmarked": return GraphQL.ShaderSearchType.Bookmarked
                        case "reported": return GraphQL.ShaderSearchType.Reported
                        default: return undefined
                    }
                })()
            }
        }
    }

    useEffect(() => {
        uiActions.loadShaderForSearch(shaderViewState,
            { ...constructVariables() }, true)
    }, [searchTerm, searchType, shaderType])


    const fetchNextPage = async (page: number) => {
        const variables = constructVariables()
        variables.afterPage = shaderViewState.nextPage

        uiActions.loadShaderForSearch(shaderViewState, variables)
    }

    const onSelected = action((summary: GraphQL.ShaderSummaryFragment) => {

        uiActions.previewShader(summary)

    })

    const _onSearchTextChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
        let _searchTerm = event.target.value
        _searchTerm = _searchTerm.trim()

        if (_searchTerm === searchTerm) {
            return
        } else if (_searchTerm === "") {
            setSearchTerm(undefined)
        } else {
            setSearchTerm(_searchTerm)
        }
    }

    const onSearchTextChanged = debounce(_onSearchTextChanged, 500)
    const isFetching = shaderViewState.isLoading

    console.log("isFetching: " + isFetching + " hasNextPage: " + hasNextPage)

    return (
        <div className={classNames(styles.FooterView)}>
            <S.HoriztonalFlex style={{ gap: "10px" }}>
                <TextField
                    id="outlined-basic"
                    label="Search for id or tags"
                    variant="outlined"
                    size='small'
                    onChange={onSearchTextChanged}
                />
                <ToggleButtonGroup
                    color="secondary"
                    value={searchType}
                    exclusive
                    onChange={(event, newAlignment) => {
                        console.log(`Change Search Type: ${newAlignment}`)
                        setSearchType(newAlignment)

                        if (newAlignment === null) {
                            setSearchType(undefined)
                        } else {
                            setSearchType(newAlignment)
                        }
                    }}
                >
                    <ToggleButton value="random">Random</ToggleButton>
                    <ToggleButton value="bookmarked">Bookemarked</ToggleButton>
                    <ToggleButton value="reported">Reported</ToggleButton>
                </ToggleButtonGroup>
            </S.HoriztonalFlex>

            <InfiniteScroll
                loadMore={(page) => { fetchNextPage(page) }}
                hasMore={!isFetching && hasNextPage}

                style={{
                    height: "100%",
                    width: "100%"
                }}>
                <Grid container spacing={3} sx={{ backgroundColor: "" }} >
                    {shaderViewState.allShaders.map((summary, index) => (
                        <Grid item key={index} >
                            <ShaderPeekView
                                name={summary?.name ?? ""}
                                id={summary.id}
                                author={"@dixtra"}
                                mint={"30"}
                                views={"12k"}
                                screenshotURL={summary?.screenshotURL}
                                onSelected={() => onSelected(summary)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </InfiniteScroll>
            {isFetching ?
                (<div className='absolute left-0 h-6 bottom-0 w-fit rounded bg-selected bg-opacity-70'>
                    <p className='text-lg'>Loading more...</p>
                </div>) : null
            }
        </div >);
})

function convertShaderType(shaderType: ShaderMainTypes): GraphQL.ShaderType {
    switch (shaderType) {
        case "material":
            return GraphQL.ShaderType.Material
        case "shader":
            return GraphQL.ShaderType.Shader
    }
}