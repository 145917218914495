import { DefaultLinkModelListener, DefaultPortModel } from '@projectstorm/react-diagrams';
import { PortModel, PortModelGenerics } from '@projectstorm/react-diagrams';
import { LayerUniformID, TrackEnvelopeID, TrackID } from 'src/store/identifiers/identifiers';
import { BindableSourceIdentifiers, BindableTargetIdentifier, NodeEditorContext } from '../NodeEditorContext';
import { sortPorts } from '../utils';

import { AudioLinkModel } from './AudioLinkModel';

export class LoopBasePortModel extends DefaultPortModel {

	constructor(
		public identifier: BindableSourceIdentifiers | BindableTargetIdentifier,
		private isIn: boolean,
		name: string,
		public context: NodeEditorContext) {
		super(isIn, name, name)
	}

	get isInput(): boolean {
		return this.isIn
	}

	get isOutput(): boolean {
		return !this.isInput
	}

	protected makeLinkListener(): DefaultLinkModelListener {
		return {
			sourcePortChanged: (event: any) => {
				if (this === event.port) {
					console.log("same");
				}
			},
			targetPortChanged: (event: any) => {
				const targetPort = event.port;

				if (targetPort instanceof LoopBasePortModel) {
					const { source, target } = sortPorts(this, targetPort)
					this.context.onLinkCreated(source, target);
				}
			}
		};
	}

	createLinkModel(): AudioLinkModel {
		const link = new AudioLinkModel(this.context);

		link.registerListener(this.makeLinkListener());
		return link;
	}

	canLinkToPort(port: PortModel<PortModelGenerics>): boolean {
		if (!(port instanceof LoopBasePortModel)) {
			return false;
		}
		if (this === port) {
			return false;
		}
		return true;
	}
}

export class AudioSourcePortModel extends LoopBasePortModel {

	constructor(
		public identifier: TrackID,
		name: string,
		context: NodeEditorContext) {

		//		super(false, name, con);
		super(identifier, false, name, context)
	}


	override canLinkToPort(port: PortModel<PortModelGenerics>): boolean {
		if (!super.canLinkToPort(port)) {
			return false
		}

		if (!(port instanceof AudioTargetPortModel)) {
			return false
		}

		return true
	}

}


export class AudioModSourcePortModel extends LoopBasePortModel {
	constructor(
		public identifier: TrackEnvelopeID,
		name: string,
		context: NodeEditorContext) {

		super(identifier, false, name, context)
	}


	override canLinkToPort(port: PortModel<PortModelGenerics>): boolean {
		if (!super.canLinkToPort(port)) {
			return false
		}

		if (!(port instanceof UniformTargetPortModel)) {
			return false
		}

		return true
	}

}

export class UniformTargetPortModel extends LoopBasePortModel {
	constructor(
		public identifier: LayerUniformID,
		name: string,
		context: NodeEditorContext) {

		//		super(false, name, con);
		super(identifier, true, name, context)
	}


	override canLinkToPort(port: PortModel<PortModelGenerics>): boolean {
		if (!super.canLinkToPort(port)) {
			return false
		}

		if (!(port instanceof AudioModSourcePortModel)) {
			return false
		}

		return true
	}
}

export class AudioTargetPortModel extends LoopBasePortModel {
	constructor(
		public identifier: LayerUniformID,
		name: string,
		context: NodeEditorContext) {

		//		super(false, name, con);
		super(identifier, true, name, context)
	}


	override canLinkToPort(port: PortModel<PortModelGenerics>): boolean {
		if (!super.canLinkToPort(port)) {
			return false
		}

		if (!(port instanceof AudioSourcePortModel)) {
			return false
		}

		return true
	}
}