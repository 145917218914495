import React from 'react';
import { IconButton } from "@mui/material"
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { BindableItemProps } from './BindableItem';
import Tooltip from 'rc-tooltip';


export const BindableTopRow: React.FC<BindableItemProps> = (props) => {
    return <div className="flex flex-row justify-between">
        <p className='font-mono text-sm'>{props.typeName}</p>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Tooltip overlay="Pin to use in Wiring">
                <div className='flex p-0 m-0 h-fit w-fit'>
                    <IconButton
                        disabled={props.requiredModulatable ?? false}
                        size='small'
                        onClick={() => {
                            console.log("x");
                            props.onChangeModulatable?.(!props.modulatable ?? true);
                        }}>
                        {props.modulatable ?
                            <PushPinIcon sx={{ fontSize: "medium" }} /> :
                            <PushPinOutlinedIcon sx={{ fontSize: "medium" }} />}
                    </IconButton>
                </div>
            </Tooltip>

        </div>
    </div >;
}
