import { autorun, reaction, when } from "mobx"
import { MiddleWareContext } from "src/render/context"
import { audioSideEffects } from "./audioSideEffects"
import { registerRenderEngine } from "./cinemaSideEffect"
import { registerConnectionSideEffects } from "./connectionSideEffect"
import { getLoopContext } from "./middleware/cinemaMiddleware"
import { PagesState } from "./store"


export function registerSideEffects(state: PagesState) {
    when(() => state.renderCore.engineInitialized,
        () => {
            getLoopContext().binding.load(state.modulationState.modulations)
            _register(state, getLoopContext())
            registerRenderEngine(state, getLoopContext())
            audioSideEffects(state, getLoopContext())
            registerConnectionSideEffects(state)
        })
}

function _register(state: PagesState, context: MiddleWareContext) {
    // reaction(
    //     () => state.uiState.selectedElements.selectedElement,
    //     (selectedLayer) => {
    //         if (selectedLayer !== undefined)
    //             if (selectedLayer.kind === "LayerShaderIdentifier" ||
    //                 selectedLayer.kind === "LayerMaterialIdentifier") {
    //                 const found = search.findShaderOnLayer(selectedLayer, state.cinemaState)
    //                 if (found) {
    //                     runInAction(() => {
    //                         state.uiState.selectedElements.previewShader(found.summary)
    //                     })
    //                 }
    //             }
    //     }
    // )

    //let assignShaderPromise: { cancel(): void } | undefined = undefined
    reaction(
        () => state.uiState.selectedElements.selectShaderForPreview,
        (selectShaderForPreview) => {
            if (!selectShaderForPreview) {
                return
            }
            if (!state.uiState.selectedElements.selectedElement) {
                return
            }

            // assignShaderPromise?.cancel()

            // const selectedElement = state.uiState.selectedElements.selectedElement

            // if (selectedElement.kind === "LayerMaterialIdentifier" ||
            //     selectedElement.kind === "LayerShaderIdentifier") {


            //     const cinemaActions = new CinemaActions(context, state)
            //     const layer = state.cinemaState.getLayer(selectedElement.layerName) ?? cinemaActions.makeNewLayer(selectedElement)
            //     cinemaActions.removeShader(layer)

            //     const flow = flowResult(layer.assignShader(selectShaderForPreview))

            //     flow.catch(error => {
            //         if (isFlowCancellationError(error)) {
            //             console.log(`Canceled Shader Assigned: ${error}`)
            //         } else {
            //             console.log(`Failed to assign Shader: ${error}`)
            //         }
            //     }).then(() => {
            //         state.renderCore.queueReloadLayer(layer.info.layerName)
            //     })
            //     assignShaderPromise = flow
            // }
        })


    // autorun(() => {

    //     for (let shaders in Visit.allShaders(state.cinemaState.allLayers)) {
    //         getLoopContext().binding.
    //     }
    // })


    autorun(() => {
        if (state.audition.isPlaying) {
            getLoopContext().binding.load(state.modulationState.modulations ?? [])
            getLoopContext().transport.play(state.sounds.tracks)
        }
    })

    autorun(() => {
        if (!state.audition.isPlaying) {
            getLoopContext().transport.stop()
        }
    })

}

