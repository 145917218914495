import { Identifier } from "./store/identifiers/identifiers";

export function assert(condition: unknown, message: string): asserts condition {
    if (!condition) {
         throw new Error(message);
    }
}

export function assertNotNull<T>(obj: T | null | undefined, message?: string): asserts obj is T {
    if (!obj)
        throw new Error(message ?? "Expected obj to be no-nonull");
}


export function ensure<T>(t: T): T {
    return t
}

export function isNotNull<T>(t: T | undefined | null): t is T {
    if (t === undefined || t === null) return false
    return true
}

export function mapMaybe<T, TResult>(t: T[], _map: (it: T) => TResult | undefined): TResult[] {
    return t.map(_map).filter(isNotNull)
}

export function requireType<TResult>(t: any, test: (_t: any) => boolean): TResult {

    if (!test(t)) {
        throw new Error('Value is not expceted type')
    }
    return t
}

export function requireExists<T>(t: T | undefined | null): T {
    if (isNotNull(t)) {
        return t
    }
    throw new Error("required object is null | undefined")
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function enumValues(t: any): string[] {
    return Object.values(t)
}

export function makeCompositeKey(...args: (string | Identifier)[]): string {
    if (args.length === 0) {
        throw new Error("Can't make key from nothing")
    }
    const stringArgs: string[] = args
        .map(a => {
            if (typeof a === "string") {
                return a
            } else {
                return a.compositeKey
            }
        })

    return stringArgs
        .slice(1)
        .reduce((acc, next) => { return `${acc}|${next}` }, stringArgs[0])
}


export function findFirstWhere<T, U>(array: T[], where: (next: T) => U | undefined): U | undefined {
    return array.reduce((acc, next) => {
        if (acc !== undefined) {
            return acc
        }

        acc = where(next)
        return acc
    }, undefined as U | undefined)
}


export function rangeFromTo(from: number, count: number): number[] {
    return Array.from({ length: count }, (v, k) => k).map(x => x + from)
}


export type PartialMap<Key extends string | number, Value> = Partial<Record<Key, Value>>