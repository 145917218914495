import classNames from 'classnames';
import React from 'react';
import { Typography } from '@mui/material';
import { useAppContext } from 'src/store/hooks';
import { observer } from 'mobx-react-lite';
import { BookmarksControl } from './Bookmarks';
export type Props = {
    //
    name: string,
    id: string,
    author?: string,
    mint?: string,
    views?: string,
    screenshotURL?: string
    onSelected: () => void
};

export const ShaderPeekView: React.FC<Props> = observer(({
    name,
    id,
    author,
    mint,
    views,
    screenshotURL,
    onSelected
}) => {


    const { uiState, userState } = useAppContext()

    const selectedPreview = uiState.selectedElements.selectShaderForPreview
    const isSelected = selectedPreview?.id === id

    const isBookmarked = userState.bookmarkedShaders.has(id)
    const isReported = userState.reportedShaders.has(id)


    return (
        <div className={
            classNames("overflow-hidden w-48 h-48",
                isSelected ?
                    "border-solid border-selected border-4" :
                    "border-solid border-black border"
            )}

            onClick={onSelected}>
            <BookmarksControl
                bookmarked={isBookmarked}
                reported={isReported} />
            <img
                src={screenshotURL}
                style={{
                    width: "100%",
                    height: "120px",
                    objectFit: "cover"
                }}
            />
            <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                align='left'
            >
                {name}
            </Typography>
        </div>
    );
})
