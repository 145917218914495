import classNames from 'classnames';
import React from 'react';
import styles from "../Material.module.scss";
import { Grid } from '@mui/material';
import { ModelPeakPreview } from './ModelPeakView';
import { useAppContext } from 'src/store/hooks';
import { useLoopActions } from 'src/render/hooks';

type Props = {
    //
};


export const ModelMain: React.FC<Props> = ({ }) => {
    const [summaryList, setSummaryList] = React.useState(createModelList());

    const state = useAppContext()

    const { cinemaActions } = useLoopActions()

    return (<div className={classNames(styles.FooterView)}>
        <Grid container spacing={3} sx={{ backgroundColor: "" }} >
            {summaryList.map((summary, index) => (
                <Grid item key={index} >
                    <ModelPeakPreview
                        name={summary.name}
                        onSelected={() => cinemaActions.assignModelToSelectedLayer(summary.name)}
                    />
                </Grid>
            ))}
        </Grid>

    </div>);
}

interface ModelPeekProps {
    // Temp
    name: string

}

function createModelList(): ModelPeekProps[] {
    return [
        "SPHERE",
        "TORUS",
        "TORUS_KNOT",
        "GROUND",
        "BOX",
        "PLANE",
    ].map(name => {
        return {
            name
        }
    })
}


