import React, { useEffect, useRef, useState } from "react"
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams';

import NFTKnob from 'src/components/Envelope/NFTKnob';
import { LoopBasePortModel } from './PortModels';
import { NodeEditorContext } from "../NodeEditorContext";
import { IdentifierFactory, PrimitiveModulationID } from "src/store/identifiers/identifiers";
import { PrimitiveUniform } from "src/store/cinema";
import classNames from "classnames";
import styles from "./NodeEditor.module.scss"
import { assert } from "src/utils";
import _ from "lodash";
import { search } from "src/store/cinema/search";
import { BindableTargetIdentifier } from "../NodeEditorContext"
import { PrimitiveModulation } from "src/store/bindings";
import { useAppContext } from "src/store/hooks";
import { observer } from "mobx-react-lite";
import { action } from "mobx";
import { getLoopContext } from "src/store/middleware/cinemaMiddleware";
import { isTime } from "src/store/cinema/utils";
import { S } from "./CommonCSS"
import Switch from "src/components/Controls/Switch";

export interface DefaultPortLabelProps {
	port: LoopBasePortModel;
	portID: BindableTargetIdentifier
	engine: DiagramEngine;
	color?: string
}


const CinemaPortLabel: React.FC<DefaultPortLabelProps> = observer(({ ...props }) => {

	const state = useAppContext()
	const modulation = search.findPrimitiveModulationToUniform(props.portID, state.modulationState)
	const modulationID = modulation ? IdentifierFactory.makePrimitiveModulationID(modulation) : undefined


	const _color = props.color || `rgba(0,100,180, 0.7)`
	const port = (
		<PortWidget engine={props.engine} port={props.port}>
			<S.Port color={_color} />
		</PortWidget>
	);

	const label = <S.Label>{props.port.getOptions().label}</S.Label>;

	return (
		<S.HoriztonalFlex>
			{port}
			<S.VerticalFlex style={{ paddingBottom: "5px" }}>
				{label}
				{modulationID &&
					<ModulationControls
						context={props.port.context}
						modulationID={modulationID} />}
			</S.VerticalFlex>
		</S.HoriztonalFlex>
	);
})

type ModulationControlsProps = {
	context: NodeEditorContext
	modulationID: PrimitiveModulationID
}

const ModulationControls = observer(({ context, modulationID }: ModulationControlsProps) => {

	const state = useAppContext()
	const { primitiveData, modulation } = {
		primitiveData: search.findUniformRequired(modulationID.target, state.cinemaState) as PrimitiveUniform,
		modulation: search.findPrimitiveModulationRequired(modulationID, state.modulationState)
	}

	assert(modulation.kind === "PrimitiveBinding", "Kind is PrimitiveBinding")
	assert(primitiveData.kind === "PrimitiveUniform", "Should be PrimitiveUniform")

	const _isTime = isTime(primitiveData.name)

	const v: number = primitiveData.value as any
	const x = 3
	console.log(x.toPrecision(3))

	const progressProps: ValueProgressBarProps = {
		value: v,
		rangeMin: primitiveData.rangeMin,
		rangeMax: primitiveData.rangeMax,
		modulationValue: modulation.modScale,
		//centerValue: isTime ? 0 : undefined
	}

	const rangeDelta = primitiveData.rangeMax - primitiveData.rangeMin
	const modulationPercentage = 100 * modulation.modScale / rangeDelta

	const _onChange = (execute: () => void) => {
		execute()
	}
	const onChange = _.throttle(_onChange, 100)

	console.log(`A ${modulationPercentage} | ${modulation.modScale}`)

	return (<S.VerticalFlex>
		<div className="flex flex-row items-start ml-1 gap-1">
			{_isTime &&
				// <FormControlLabel
				// 	sx={{ padding: 0, margin: 0 }}
				// 	value="top"
				// 	control={
				// 		<Switch
				// 			color="primary"
				// 			size="small"
				// 			checked={timeIsChecked(modulation.behavior)}
				// 			onChange={(evt) => {
				// 				onChange(() => {
				// 					modulation.behavior = timeCheckedToBehavior(evt.target.checked)

				// 				})
				// 			}}
				// 		/>}
				// 	label={<Typography sx={{ fontSize: 10 }}>{timeLabel(modulation.behavior)}</Typography>}
				// 	labelPlacement="top"
				// />
				<Switch
					checked={timeIsChecked(modulation.behavior)}
					onChanged={(checked) => {
						onChange(() => {
							modulation.behavior = timeCheckedToBehavior(checked)

						})
					}}
				>
					<span>{timeLabel(modulation.behavior)}</span>
				</Switch>
			}
			<NFTKnob
				label="val"
				value={progressProps.value}
				max={progressProps.rangeMax}
				min={progressProps.rangeMin}
				skin={"small"}
				rotateDegrees={-120}
				onChange={(newValue) => {
					onChange(action(() => {
						primitiveData.value = newValue
					}))
				}}
				valueLabel={true}
				valueFormat={value => value.toPrecision(3)}
			/>
			<NFTKnob
				label="mod"
				value={modulationPercentage}
				max={100}
				min={-100}
				skin={"small"}
				rotateDegrees={180}
				onChange={(newValue) => {
					const newValueAbsolute = _.clamp(rangeDelta * newValue / 100, primitiveData.rangeMin - v, primitiveData.rangeMax - v)
					console.log(`B ${modulationPercentage} | ${newValueAbsolute}`)
					onChange(action(() => {
						modulation.modScale = newValueAbsolute
						getLoopContext().binding.updateUniformPreset(
							modulationID.compositeKey,
							newValueAbsolute)
					}))
				}}
				valueLabel={true}
				valueFormat={value => value.toPrecision(3)}
			/>
		</div>
		<ValueProgressBar {...progressProps} />
	</S.VerticalFlex>)
})

type ValueProgressBarProps = {
	value: number
	rangeMin: number
	rangeMax: number
	modulationValue?: number
	centerValue?: number
}


const timeIsChecked = (behavior: PrimitiveModulation["behavior"]) => {
	if (behavior === undefined) {
		return true
	}
	switch (behavior) {
		case "offset":
			return true
		case "scale":
			return false
	}
}

const timeLabel = (behavior: PrimitiveModulation["behavior"]) => {

	if (behavior === undefined) {
		return "Offset"
	}
	switch (behavior) {
		case "offset":
			return "Offset"
		case "scale":
			return "Scale"
	}
}


const timeCheckedToBehavior = (isChecked: boolean): PrimitiveModulation["behavior"] => {

	return isChecked ? "offset" : "scale"
}




const ValueProgressBar = ({ value, rangeMin, rangeMax, centerValue = rangeMin, modulationValue = 0 }: ValueProgressBarProps) => {

	const rangeDelta = rangeMax - rangeMin
	const valueWidthPercentage = (value - centerValue) / rangeDelta
	const valueXPercentage = (centerValue - rangeMin) / rangeDelta
	const modulationWidthPercentage = modulationValue / rangeDelta

	const ref = useRef<SVGSVGElement>(null)

	interface ViewBox {
		width: number,
		height: number
	}
	const [viewBox, setViewBox] = useState<ViewBox>({ width: 0, height: 0 })


	useEffect(() => {
		if (ref.current) {
			const viewBox = {
				width: ref.current.width.baseVal.value,
				height: ref.current.height.baseVal.value
			}

			setViewBox(viewBox)
		}
	}, [])

	const valueX = viewBox.width * valueXPercentage
	const valueWidth = viewBox.width * valueWidthPercentage

	const modulationX = Math.min(modulationWidthPercentage * viewBox.width + valueWidth, valueWidth)
	const modulationWidth = Math.abs(modulationWidthPercentage * viewBox.width)

	return <div className={classNames(styles.ValueProgressBar)}>
		<div className={classNames(styles.ValueProgressRender)}>
			<svg ref={ref}
				width="100%"
				height="100%" >
				<rect
					x={valueX}
					width={valueWidth}
					height="100%"
					fill="purple" />
				<rect
					x={modulationX}
					width={modulationWidth}
					height="100%"
					fill="green" />
			</svg>
		</div>
	</div >
}

export default CinemaPortLabel