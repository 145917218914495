import { runInAction } from 'mobx';
import { deserialize } from 'serializr';
import { graphQLCLient } from 'src/core/client';
import * as GraphQL from 'src/generated/graphqlTypes';
import { ensure } from 'src/utils';
import { PagesState } from '../store';
import { IDeserializeContext } from './IDeserializeContext';

export async function loadRemoteSaveFile(
    identifier: string,
    state: PagesState) {

    const saveFile = await graphQLCLient.query<
        GraphQL.GetSaveFileQuery,
        GraphQL.GetSaveFileQueryVariables>(GraphQL.GetSaveFileDocument, {
            identifier
        }).toPromise()

    // Version Update if necessary

    if (saveFile.error) {
        throw `Failed to load save file: ${saveFile.error}`
    }

    const contents = saveFile.data?.serialization?.getSaveFile?.contents as string | undefined
    if (contents === undefined) {
        throw new Error("Save file is missing contents")
    }

    deserializeSong(contents, state, { connect: false })
}

export async function deleteRemoteSafeFile(identifier: string, state: PagesState) {
    const deleteFile = await graphQLCLient.mutation<
        GraphQL.DeleteSaveFileMutation,
        GraphQL.DeleteSaveFileMutationVariables>(GraphQL.DeleteSaveFileDocument, {
            identifier
        }).toPromise()

    // Version Update if necessary

    if (deleteFile.error) {
        throw `Failed to load save file: ${deleteFile.error}`
    }
    const result = deleteFile.data?.serialization?.deleteSave

    if (result === undefined) {
        throw new Error("Delete file result missing")
    }

    switch (result?.__typename) {
        case 'DeleteSaveFileSuccessResponse':
            if (state.session.identifier === result.identifier) {
                // Clear the identifer. This is now an unsaved file.
                runInAction(() => state.session.identifier = undefined)
            }
            return
        case "ErrorResponse":
            throw new Error(result.errorMessage)
    }
}

export async function deserializeSong(
    jsonString: string,
    pageState: PagesState,
    options: { connect: boolean }) {
    const storeValue: any = JSON.parse(jsonString)
    const store = await (new Promise<PagesState>((resolve, reject) => {
        // Verboce callback because `deserialize` is not typed correctly.
        const callback = (err: any, result: PagesState) => {
            if (err) {
                reject(err)
            } else {
                resolve(result)
            }
        }

        deserialize(PagesState, storeValue, callback, ensure<IDeserializeContext>({
            client: graphQLCLient
        }))
    }))

    runInAction(() => {
        pageState.merge(store, options)
        pageState.validate()
    })

    pageState.renderCore.queueReloadSession()
}
