import { GraphQL } from "src/generated";
import { Client } from "urql";




export class UploaderState {

    constructor(private file: File) {

    }

    async beginUpload(client: Client) {

        const fileName = this.file.name

        const pendingUploadResponse = await client.mutation<
            GraphQL.CreatePendingUploadMutation,
            GraphQL.CreatePendingUploadMutationVariables>(GraphQL.CreatePendingUploadDocument, {
                filename: fileName
            })
            .toPromise()

        if (pendingUploadResponse.error) {
            throw pendingUploadResponse.error
        }

        const pendingUpload = pendingUploadResponse.data?.samples.pendingUpload
        if (!pendingUpload) {
            throw new Error("Expected to find signed URL")
        }

        const { confirmationKey, signedURL } = pendingUpload

        const uploadSampleComplete = await fetch(signedURL,
            {
                method: "PUT",
                body: this.file.stream(),
                headers: {
                    "Content-Type": "audio/wav"
                },
                ...{
                    duplex: 'half'
                }
            })

        if (uploadSampleComplete.status !== 200) {
            throw new Error("Upload failed")
        }

        const confirmPendingUpload = await client.mutation<
            GraphQL.ConfirmSampleMutation,
            GraphQL.ConfirmSampleMutationVariables>(GraphQL.ConfirmSampleDocument, {
                confirmationKey
            })
            .toPromise()

        if (confirmPendingUpload.error) {
            throw confirmPendingUpload.error
        }

        const confirmSample = confirmPendingUpload.data?.samples.confirmUpload?.sample

        if (!confirmSample) {
            throw new Error("Expected to find sample")
        }

        return

    }
}
