import _ from "lodash"
import { GraphQL } from "src/generated"
import { assert } from "src/utils"



export type SampleModes = SampleSingle | SampleSplit

export type SampleSingle = {
    kind: "SampleSingle"
    root: GraphQL.SampleFileFragment
}

export type SampleSplit = {
    kind: "SampleSplit"
    root: GraphQL.SampleFileFragment
    children: GraphQL.SampleFileFragment[]
}



export function flattenSamples(samples: GraphQL.SampleFileFragment[]) {

    type SampleMapType = { [hash: string]: SampleModes }

    // First find all the stem parent samples
    const sampleModes: SampleMapType = samples.filter(s => !_.isNil(s.stemsIDs))
        .reduce((map, s) => {
            map[s.id] = {
                kind: "SampleSplit",
                root: s,
                children: []
            }
            return map
        }, {} as SampleMapType)


    // Then find all the rest.
    samples
        .filter(s => sampleModes[s.id] === undefined).forEach(s => {
            if (s.stemParentID && sampleModes[s.stemParentID] !== undefined) {
                const mode = sampleModes[s.stemParentID]
                assert(mode.kind === "SampleSplit", "Expected kind to be SampleSplit")
                mode.children.push(s)
            } else {
                sampleModes[s.id] = {
                    kind: "SampleSingle",
                    root: s
                }
            }
        })

    return Object.values(sampleModes)
}
