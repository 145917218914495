import * as Tone from 'tone';
import { assert } from "src/utils";
import * as GraphQL from "src/generated/graphqlTypes";
import { PlayingState } from "./types";
import { getAudioContext } from "./Transport";
import { TransportContext } from "./TransportContext";
import { Disposable } from 'src/utils/disposable';
import { makeAutoObservable } from 'mobx';



interface PlayerSource extends Disposable {
    audioBufferSourceNode: AudioBufferSourceNode
    meter: AudioWorkletNode
    gain: GainNode
}

type CreateContext = {
    audioBuffer: AudioBuffer,
    bpm: number,
    bpmSample: number
    setPlaybackLevel(v: number): void
}

class WatchPlayerData {
    constructor() {
        makeAutoObservable(this)
    }

    playbackLevel = 0
}


interface PlayerOptions { 
    volume?: number
}

export class Player {

    private static gCount = 0;
    private index: number;

    buffer: AudioBuffer;
    source: PlayerSource;
    scheduledEvent?: number;
    private _volume = 1.0;
    private _playingState: PlayingState = "off";

    data: WatchPlayerData = new WatchPlayerData()

    constructor(
        public ident: string,
        public options: PlayerOptions = {},
        public sampleFile: GraphQL.SampleFileFragment,
        public tonePlayer: Tone.Player,
        private transport: TransportContext) {
        const _buffer = this.tonePlayer.buffer.get();

        this.index = Player.gCount;
        Player.gCount = Player.gCount + 1;

        if (_buffer === undefined) {
            throw new Error("Buffer not initifialed in player");
        }
        this.buffer = _buffer;
        

        this.source = Player.makeSample(getAudioContext(),
            {
                audioBuffer: this.buffer,
                bpm: this.transport.bpm(),
                bpmSample: this.sampleFile.bpm ?? 125, // TODO: Fix this
                setPlaybackLevel: this.onPlaybackLevelUpdated.bind(this)
            }
        );

        this.volume = options.volume ?? 1.0;
    }

    get playingState() {
        return this._playingState;
    }

    public reset() {
        this.stopAll();

        this.source = Player.makeSample(getAudioContext(), {
            audioBuffer: this.buffer,
            bpm: this.transport.bpm(),
            bpmSample: this.sampleFile.bpm ?? 125, // TODO: Fix this
            setPlaybackLevel: this.onPlaybackLevelUpdated

        });
    }

    public start(options: { synced: boolean; } = { synced: true }) {

        assert(this._playingState === "off", "Should be off to start");
        if (this.buffer === undefined) {
            throw new Error("Buffer not loaded");
        }

        this._playingState = "on";

        if (options.synced) {
            this.scheduledEvent = Tone.Transport.scheduleOnce((time) => {
                try {
                    this.source.audioBufferSourceNode.start(time);
                } catch (error) {
                    throw error;
                }

            }, "0:0");
        } else {
            this.source.audioBufferSourceNode.start();
        }
    }



    private static makeSample(
        audioContext: AudioContext,
        createContext: CreateContext
    ): PlayerSource {

        const {
            audioBuffer,
            bpm,
            bpmSample,
            setPlaybackLevel
        } = createContext

        const sampleSource = audioContext.createBufferSource();
        sampleSource.buffer = audioBuffer;
        sampleSource.playbackRate.value = bpm / bpmSample;

        // Source -> Gain -> Meter -> Output

        //const meterNode = new MeterNode(audioContext)


        const meterNode = Tone.context.createAudioWorkletNode('vumeter')
        meterNode.port.onmessage = (event: any) => {
            if (event.data && event.data.volume) {
                setPlaybackLevel(event.data.volume)
            }

        }


        const gainNode: GainNode = audioContext.createGain();
        gainNode.connect(audioContext.destination);
        gainNode.connect(meterNode);
        // const analyser = audioContext.createAnalyser()
        // analyser.fftSize = 512 * 2
        // gainNode.connect(analyser)
        // analyser.connect(audioContext.destination)
        sampleSource.connect(gainNode);
        sampleSource.loop = true;
        return {
            audioBufferSourceNode: sampleSource,
            gain: gainNode,
            meter: meterNode,
            dispose() {
                this.audioBufferSourceNode.disconnect();
                this.gain.disconnect();
                this.meter.disconnect()
            },
        };
    }

    set mute(value: boolean) {
        const gain = this.source?.gain.gain;
        if (gain) {
            gain.value = value ? 0.0 : this._volume;
        }
    }

    set volume(value: number) {
        this._volume = value;
        const gain = this.source?.gain.gain;
        if (gain) {
            gain.value = value;
        }
    }

    private onPlaybackLevelUpdated(v: number) {
        this.data.playbackLevel = v
    }


    private stopAll() {
        this._playingState = "off";
        if (this.scheduledEvent !== undefined) {
            Tone.Transport.clear(this.scheduledEvent);
            this.scheduledEvent = undefined;
        }

        this.source.dispose();
    }

    dispose() {
        this.stopAll();
    }

}
