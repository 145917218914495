import * as GraphQL from "src/generated/graphqlTypes"
import { makeAutoObservable } from "mobx"
import { AdditionalPropArgs, map, object, raw, serializable, serializeAll } from 'serializr';
import { PartialMap, assertNotNull } from 'src/utils';
import { ID } from 'src/types/common';
import { fetchSample } from './actions';
import { IDeserializeContext } from '../serialization/IDeserializeContext';

@serializeAll
export class Note {

    constructor(
        public enabled: boolean = true,
        public velocity: number = 1.0) {
        makeAutoObservable(this)
    }
}


@serializeAll
export class AudioLayer {
    constructor() {
        makeAutoObservable(this)
        this.volume = 1.0
    }

    volume: number
}


@serializeAll
export class ModulationLayer {
    constructor() {
        this.envelope = makeDefaultAR()
        makeAutoObservable(this)
    }

    @serializable(raw())
    envelope: Ratios

    @serializable(map(object(Note)))
    notes: PartialMap<number, Note> = {}

    addNote(index: number) {
        const newNote = new Note()
        this.notes[index] = newNote


    }

    updateNote(index: number, note: Partial<Note>) {
        const newNote = this.notes[index]
        assertNotNull(newNote)
        newNote.enabled = note.enabled ?? newNote.enabled
        newNote.velocity = note.velocity ?? newNote.velocity
    }

    removeNote(index: number) {
        delete this.notes[index]
    }
}

type TrackProps = {
    trackIndex: number,
    trackId: string,
    beats16ths: number,
    sampleFileID: ID,
    sampleFile: GraphQL.SampleFileFragment
}

@serializeAll
export class Track {
    constructor(props: TrackProps) {
        makeAutoObservable(this)

        const { trackIndex, trackId, beats16ths, sampleFileID, sampleFile } = (props ?? {})

        this.trackIndex = trackIndex
        this.trackId = trackId
        this.beats16ths = beats16ths
        this.modulationLayer = new ModulationLayer()
        this.audioLayer = new AudioLayer()
        this.sampleFileID = sampleFileID
        this.sampleFile = sampleFile
    }

    trackIndex: number;
    readonly trackId: string;
    readonly beats16ths: number
    readonly sampleFileID: ID

    @serializable(raw())
    sampleFile: GraphQL.SampleFileFragment

    @serializable(object(AudioLayer))
    readonly audioLayer: AudioLayer

    @serializable(object(ModulationLayer))
    readonly modulationLayer: ModulationLayer

    get sampleTitle(): string {
        return `${this.sampleFile.name}`
    }
}

export const TrackSerializationFixup: AdditionalPropArgs = {
    beforeDeserialize(callback, json, jsonParent, paramName, context, props) {
        const deserializeContext = context.args as IDeserializeContext
        // This fixes up old broken files. 
        // serializr doesn't like existing JSON data in the payload that is essentially unused.
        // So delete or migrate.

        delete json["notes"]

        const p = new Promise(async (resolve, reject) => {
            // Load SampleFileID from JSON
            const sampleFileID = json["sampleFileID"]
            try {
                if (sampleFileID !== undefined) {
                    json["sampleFile"] = await fetchSample(deserializeContext.client, sampleFileID)
                }
            } catch (e) {
                console.error(e)
                reject(e)
                return
            }

            resolve(json)
        })

        p.then(updateJSON => {
            callback(null, updateJSON)
        })

        return
    },

    // beforeSerialize(callback, json, jsonParent, paramName, context, props) {

    //     delete json["_sampleFile"]

    //     return callback(null, json)
    // }


}



export interface ADSRRatios {
    kind: "adsr"
    xp: number
    xa: number
    xd: number
    ys: number
    xr: number
}

export interface ARRatios {
    kind: "ar"
    xp: number
    xa: number
    xr: number
}

export type Ratios = ADSRRatios | ARRatios

function makeDefaultAR(): ARRatios {
    return {
        kind: "ar",
        xp: 0.0,
        xa: 0.5,
        xr: 0.5,
    }
}

