import { action, flowResult, isFlowCancellationError, runInAction } from "mobx";
import { MiddleWareContext } from "src/render/context";
import { isNotNull } from "src/utils";
import { Client } from "urql";
import { ShaderSummary } from "../cinema";
import { CinemaActions } from "../cinema/actions";
import { search } from "../cinema/search";
import { PagesState } from "../store";
import { PendingAlert, ShaderViewState } from "./state";
import { SelectableLayerIDTypes } from "./types";
import * as GraphQL from 'src/generated/graphqlTypes';
import { UploaderState } from "./uploader";

export type ModelIdentifier = string

export class UIStateActions {

    constructor(
        private client: Client,
        private context: MiddleWareContext,
        private state: PagesState,
        private getCinemaActions: () => CinemaActions
    ) {

    }

    selectElement = action((selectedElement: SelectableLayerIDTypes) => {
        const uiState = this.state.uiState
        uiState.selectedElements.selectedElement = selectedElement

        if (selectedElement.kind === "LayerShaderIdentifier" ||
            selectedElement.kind === "LayerMaterialIdentifier") {
            const found = search.findShaderOnLayer(selectedElement, this.state.cinemaState)
            if (found) {
                uiState.selectedElements.previewShader(found.summary)
            }
        }
    })

    previewShader = action((summary: ShaderSummary) => {
        this.state.uiState.selectedElements.previewShader(summary)

        // automatically assign shader to a layer.
        const selectedElement = this.state.uiState.selectedElements.selectedElement
        if (isNotNull(selectedElement) &&
            (selectedElement.kind === "LayerMaterialIdentifier" ||
                selectedElement.kind === "LayerShaderIdentifier")
        ) {
            const cinemaActions = this.getCinemaActions()
            const layer = this.state.cinemaState.getLayer(selectedElement.layerName) ?? cinemaActions.makeNewLayer(selectedElement)
            cinemaActions.assignShaderToLayer(layer, summary)
        }
    })

    loadShaderForSearch = action((
        shaderViewState: ShaderViewState,
        variables: GraphQL.FetchShaderSummariesQueryVariables,
        clear?: boolean) => {
        shaderViewState.cancelFetch()
        if (clear) {
            shaderViewState.clearShaders()
        }

        const flow = flowResult(shaderViewState.loadShaders(this.client, variables))

        flow.catch(error => {
            if (isFlowCancellationError(error)) {
                console.log(`Canceled Shader Assigned: ${error}`)
            } else {
                console.log(`Failed to assign Shader: ${error}`)
            }
            throw error
        }).then(() => {
            console.log(`Loaded Shaders: ${JSON.stringify(variables)}`)
            runInAction(() => {
                shaderViewState.pendingShaderLoad = undefined
            })

        })
        shaderViewState.pendingShaderLoad = flow
    })

    async uploadSamples(files: File[]) {
        for (const file of files) {
            await this.uploadSample(file)
        }
    }



    postAlert(title: string, message: string, okay?: () => void, cancel?: () => void) {
        const state = this.state.uiState

        const clear = () => {
            runInAction(() => {
                const index = state.pendingAlerts.indexOf(alert)
                state.pendingAlerts.splice(index, 1)

            })
        }
        const alert: PendingAlert = {
            title,
            body: message,
            cancel: () => {
                clear()
                cancel?.()
            }
        }

        if (okay !== undefined) {
            alert.okay = () => {
                clear()
                okay()
            }
        }

        runInAction(() => {
            state.pendingAlerts.push(alert)
        })
    }

    async uploadSample(file: File) {
        try {
            if (this.state.uiState.uploadSample !== undefined) {
                throw new Error("Upload already in progress")
            }

            const uploader = new UploaderState(file)
            runInAction(() => {
                this.state.uiState.uploadSample = uploader
            })

            await uploader.beginUpload(this.client)

            runInAction(() => {
                this.state.uiState.mySampleDirty = true
            })
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            runInAction(() => {
                this.state.uiState.uploadSample = undefined
            })
        }
    }
}