import * as GraphQL from "src/generated/graphqlTypes"
import { MeshType } from "src/render/visual/factory/meshFactory"
import { FrogValueTypes } from "src/render/visual/shaders/shaderFrog/model"
import { IdentifierFactory, LayerIdentifiable, LayerMaterialID, LayerShaderID, LayerUniformID } from "../identifiers/identifiers"
import { makeAutoObservable, observe, onBecomeObserved, onBecomeUnobserved } from "mobx"
import { SortLayers } from "./utils"
import { fetchShader } from "src/render/visual/shaders/shaderToy/fetchShader"
import { readUniformsFromShader } from "./shader"
import { getLoopContext } from "../middleware/cinemaMiddleware"
import { map, object, raw, serializable, serializeAll } from "serializr"
import { serializeAllNamed, union } from "../utils/serializrExt"
import { PrimitiveTypes } from "./primitives"
import { assertNotNull } from "src/utils"

export type LayerNameKeys = "background" | "secondary" | "primary" | "postprocess"
export type LayerTypeKeys = "postprocess" | "model"
export type LayerElementType = "material" | "model" | "shader"


@serializeAll
export class CinemaState {
    @serializable(map(union<LayerType, "kind">("kind")))
    layers: Partial<Record<LayerNameKeys, LayerType>> = {}

    constructor() {
        makeAutoObservable(this)
    }

    public getLayer(layerName: LayerNameKeys): LayerType | undefined {
        return this.layers[layerName]
    }

    public addLayer(layerName: LayerNameKeys, layer: LayerType) {
        this.layers[layerName] = layer
    }


    // Use StoreActions
    removeLayer(layerName: LayerNameKeys) {
        const layer = this.layers[layerName]
        assertNotNull(layer)
        delete this.layers[layerName]

        return layer
    }


    get allLayers(): LayerType[] {
        return Object.values(this.layers)
            .filter((x): x is LayerType => x !== undefined)
            .sort(SortLayers)
    }

}

export const allCinemaLayerNames: LayerNameKeys[] = [
    "background",
    "secondary",
    "primary",
    "postprocess"
]

export type LayerType =
    BackgroundLayer |
    ForgroundModelLayer |
    PostProcessLayer

export type LayerKind = LayerType["kind"]

export interface LayerInfo extends LayerIdentifiable {
    id: string
    layerName: LayerNameKeys
}

export interface CharacterModel {
    meshType: MeshType
}

export type SimpleVector3 = { x: number, y: number, z: number }

export interface MeshParameters {
    position: SimpleVector3
    rotation: SimpleVector3
    scaling: SimpleVector3
}

export interface CameraModel {
    //projectionMatrix: Array<number>
    serializedData?: string
}

@serializeAll
export class LayerShader {
    constructor(
        summary: ShaderSummary,
        uniforms: UniformTypeMap) {
        makeAutoObservable(this)
        this.summary = summary
        this.uniforms = uniforms
    }

    @serializable(raw())
    summary: ShaderSummary

    @serializable(map(union<UniformTypes, "kind">("kind")))
    uniforms: UniformTypeMap
}

@serializeAllNamed("forgroundModelLayer")
export class ForgroundModelLayer {
    constructor(info: LayerInfo) {
        makeAutoObservable(this)

        this.meshParameters = {
            position: { x: 0, y: 0, z: 0 },
            scaling: { x: 1, y: 1, z: 1 },
            rotation: { x: 1, y: 1, z: 1 }
        }
        this.camera = {}
        this.info = info
    }

    kind = "forgroundModelLayer" as const;

    @serializable(raw())
    info: LayerInfo

    @serializable(raw())
    model?: CharacterModel

    @serializable(raw())
    camera: CameraModel

    @serializable(object(LayerShader))
    materialShader?: LayerShader

    @serializable(raw())
    meshParameters: MeshParameters

    *assignShader(summary: ShaderSummary) {
        const materialID = IdentifierFactory.makeLayerMaterialIdentifier(this)

        if (this.materialShader?.summary.id === summary.id) {
            return
        }

        this.materialShader = yield fetchSummaryForAssignment(materialID, summary)

    }
}

@serializeAllNamed("postProcessLayer")
export class PostProcessLayer {
    constructor(info: LayerInfo) {
        makeAutoObservable(this)
        this.info = info
    }

    kind = "postProcessLayer" as const

    @serializable(raw())
    info: LayerInfo

    @serializable(object(LayerShader))
    shader?: LayerShader

    *assignShader(summary: ShaderSummary) {
        const shaderID = IdentifierFactory.makeLayerShaderIdentifier(this)
        if (this.shader?.summary.id === summary.id) {
            return
        }

        this.shader = yield fetchSummaryForAssignment(shaderID, summary)
    }
}

@serializeAllNamed("backgroundLayer")
export class BackgroundLayer {
    constructor(info: LayerInfo) {
        makeAutoObservable(this)
        this.info = info
    }

    kind = "backgroundLayer" as const

    @serializable(raw())
    info: LayerInfo

    @serializable(object(LayerShader))
    shader?: LayerShader

    *assignShader(summary: ShaderSummary) {
        const shaderID = IdentifierFactory.makeLayerShaderIdentifier(this)
        if (this.shader?.summary.id === summary.id) {
            return
        }
        this.shader = yield fetchSummaryForAssignment(shaderID, summary)
    }
}

export interface ShaderSummary {
    name: string,
    type: GraphQL.ShaderType
    source: GraphQL.ShaderSource
    id: string
    screenshotURL?: string
}

export type UniformTypes = PrimitiveUniform | TextureUniform | AudioUniform
export type UniformTypeMap = Record<string, UniformTypes>

@serializeAllNamed("AudioUniform")
export class AudioUniform {

    constructor(name: string) {
        this.name = name
    }
    readonly kind = "AudioUniform"
    name: string

    getFriendlyName() {
        if (this.name.startsWith('iChannel')) {
            const endWith = this.name.substring(this.name.length - 1)
            return `Channel ${endWith}`
        }
        return this.name
    }
}


@serializeAllNamed("PrimitiveUniform")
export class PrimitiveUniform {
    constructor(
        parent: LayerMaterialID | LayerShaderID,
        options: {
            name: string
            friendlyName?: string
            type: PrimitiveTypes
            value: FrogValueTypes
            rangeMin: any
            rangeMax: any
        }) {
        makeAutoObservable(this)
        Object.assign(this, options)
        this.parent = parent

        const trackValue = <K extends keyof PrimitiveUniform>(key: K) => {
            // When someone starts observing value (UI component)
            onBecomeObserved(this, key, () => {
                this.valueListenerDisposable?.()
                this.valueListenerDisposable = undefined

                // Then we start listening for changes.
                // Oddly, this doesn't ALSO increment the observe count
                const dispose = observe(this, "value", (args) => {
                    const id = IdentifierFactory.makeUniformIdentifier(this.parent, this.name)
                    updateUniformValue(id, args.newValue)
                })

                this.valueListenerDisposable = dispose

            })

            // When the UI unbindes, stop listening for updates to the preset
            onBecomeUnobserved(this, key, () => {
                this.valueListenerDisposable?.()
                this.valueListenerDisposable = undefined
            })
        }

        trackValue("value")
    }

    readonly kind = "PrimitiveUniform"

    @serializable(raw())
    parent: LayerMaterialID | LayerShaderID

    name!: string

    friendlyName?: string
    getFriendlyName(): string {
        return this.friendlyName ?? this.name
    }

    @serializable(raw())
    type!: PrimitiveTypes

    @serializable(raw())
    value!: FrogValueTypes

    @serializable(raw())
    rangeMin: any

    @serializable(raw())
    rangeMax: any

    @serializable(raw())
    modulatable: true | false | "required" = false

    private valueListenerDisposable?: () => void

    get isTime() {
        return this.name === "time" || this.name === "iTime"
    }
}

function updateUniformValue(
    uniformID: LayerUniformID,
    value: any
) {
    getLoopContext().binding.updateUniformPreset(
        uniformID.compositeKey,
        value)
}



export type TextureUniform = {
    kind: "TextureUniform"
    name: string
    url: string
}


// Binding

export interface ModulationInfo {
    sourceID: string,
    sourceOutputID: string
    target: LayerUniformID
}


async function fetchSummaryForAssignment(
    layerID: LayerMaterialID | LayerShaderID,
    summary: ShaderSummary) {
    const fragment = await fetchShader(summary.id, summary.type)

    const shader: LayerShader = new LayerShader(
        summary,
        readUniformsFromShader(layerID, fragment))
    return shader
}





