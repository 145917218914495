import styled from '@emotion/styled';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace S {
    export const HoriztonalFlex = styled.div`
		display: flex;
		margin-top: 1px;
		align-items: center;
	`;

    export const VerticalFlex = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1px;
	align-items: center;
	`;

    export const Label = styled.div`
		padding: 0 5px;
		flex-grow: 1;
		text-align: left
	`;

    export const Port = styled.div<{ color: string }>`
		width: 15px;
		height: 15px;
		background: ${(p) => p.color};

		&:hover {
			background: rgb(192, 255, 0);
		}
	`

}