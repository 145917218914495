import React from 'react';
import { DropAnimation } from '@dnd-kit/core';
import {
    AnimateLayoutChanges,
    NewIndexGetter,
    SortableContext,
    defaultAnimateLayoutChanges,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
// import { List, Wrapper } from '..';

import { NFTSortableItem } from './NFTSortableItem';
import { DragTrackDescription } from './dragTypes';
import { NFTRenderItemArgs } from './NFTItem/Item';
import { Wrapper } from './Wrapper';
import { List } from './List';

export type WrappedItemsProps = {
    animateLayoutChanges?: AnimateLayoutChanges;
    dropAnimation?: DropAnimation | null;
    getNewIndex?: NewIndexGetter;
    handle?: boolean;
    items: DragTrackDescription[];
    renderItem?(args: NFTRenderItemArgs): React.ReactElement;
    useDragOverlay?: boolean;
    isDisabled: (value: any) => boolean;
    removeItem?(trackID: string): void;
};


export const TrackWrappedItems: React.FC<WrappedItemsProps> = ({
    items,
    handle,
    renderItem,
    isDisabled,
    //animateLayoutChanges,
    useDragOverlay,
    getNewIndex,
    removeItem
}) => {


    const animateLayoutChanges: AnimateLayoutChanges = (args) =>
        args.isSorting || args.wasDragging
            ? defaultAnimateLayoutChanges(args)
            : true;

    return (<div>
        <Wrapper >
            <SortableContext
                items={items.map(x => x.id)}
                strategy={verticalListSortingStrategy}
                id={"SortableTracks"}
            >

                <List >
                    {items.map((payload, index) => (
                        <NFTSortableItem
                            key={payload.id}
                            id={payload.id}
                            payload={payload}
                            handle={handle}
                            index={index}
                            data={undefined}
                            // style={getItemStyles}
                            // wrapperStyle={wrapperStyle}
                            disabled={isDisabled(payload.id)}
                            renderItem={renderItem}
                            // onRemove={handleRemove}
                            animateLayoutChanges={animateLayoutChanges}
                            useDragOverlay={useDragOverlay}
                            getNewIndex={getNewIndex}
                            removeItem={removeItem}
                        />
                        // <div key={payload.id}>
                        //     <SortableItem
                        //         key={payload.id}
                        //         id={payload.id}
                        //         handle={true}
                        //         index={index}
                        //         style={() => ({})}
                        //         wrapperStyle={() => ({})}
                        //         //disabled={isDisabled(value)}
                        //         //renderItem={renderItem}
                        //         //onRemove={removeItem}
                        //         animateLayoutChanges={animateLayoutChanges}
                        //         useDragOverlay={useDragOverlay}
                        //         getNewIndex={getNewIndex}
                        //     />
                        //     <Remove className={styles.Remove} onClick={() => { removeItem(payload.id) }} />
                        // </div>
                    ))}
                </List>
            </SortableContext>
        </Wrapper>
    </div >)
}
