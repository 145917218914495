import React from 'react';
import './index.css';

type VolumeMeterProps = {
    
    volume: number,
    max?: number,
    style?: React.CSSProperties
};

const VolumeMeter: React.FC<VolumeMeterProps> = ({
    volume,
    max = 10,
    style = {} }) => {

    const dividerList = React.useMemo(() => {
        const list: any[] = [];
        const width = 100 / (max * 2);
        for (let i = 0; i < max; i += 1) {
            list.push(
                <div
                    key={i}
                    className="volumeMeterDivider"
                    style={{ top: `${(2 * i + 1) * width}%`, height: `${width}%` }}
                />
            );
        }
        return list;
    }, [max]);

    return (<div className="volumeMeter" style={style} >
        <div className="volumeMeterBg" />
        <div
            className="volumeMeterInner"
            style={{
                transform: `scaleY(${Math.ceil((volume * max) / 100) / max})`,
            }} />
        {dividerList}
    </div>
    );
};


export default VolumeMeter