import { observer } from "mobx-react-lite";
import React from "react";
import { useAppContext } from "src/store/hooks";


type PlaybackMarkerProps = {
    numberOf16thNotes: number
    barToShow: number
}

export const PlaybackMarker: React.FC<PlaybackMarkerProps> = observer(({ numberOf16thNotes, barToShow }) => {

    const { audition } = useAppContext()

    const progressIn16ths = audition.transportState.progressIn16ths % numberOf16thNotes
    const barPlaying = Math.floor(progressIn16ths / 16)

    let leftPercent = 0
    if (barPlaying === barToShow) {
        const progressIntoThisBar = progressIn16ths % 16
        leftPercent = Math.max(100 * progressIntoThisBar / 16, 0)
    }

    return (<div className="absolute top-0 w-1 bg-gray-300 h-full"
        style={{
            left: `${leftPercent}%`
        }}
    >
    </div>)
})  