import { LayerType, MeshParameters } from "src/store/cinema";
import postProcessFactory from "./postProcessFactory"

import * as BABYLON from "@babylonjs/core";
import { meshFactory } from "./meshFactory";
import { Disposable } from "src/utils/disposable";
import { ShaderPassMap } from "../shaders/shaderToy";
import { GlobalRenderContext } from "src/render/visual/GlobalRenderContext";

export async function construct(layer: LayerType): Promise<SceneFactory | undefined> {
    switch (layer.kind) {
        case "backgroundLayer":
        case "postProcessLayer":
            return postProcessFactory(layer)
        case "forgroundModelLayer":

            return meshFactory(layer)
    }
}

export interface SceneProps {
    scene: BABYLON.Scene
    camera: BABYLON.UniversalCamera,
    
    context: GlobalRenderContext
}

export interface RenderParams {
    scene: BABYLON.Scene,
    deltaTime: number
    time: number
    lerp: number
    frame: number
}

export type FactoryResult<Params extends RenderParams = RenderParams> = {
    onRender?: OnRenderClosure<Params>
    onBindGizmos?: (
        gizmos: "rotation" | "none",
        callback: (meshWorlViewMatric: MeshParameters) => void
    ) => Disposable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties?: any
} & Disposable

export type OnRenderClosure<Params extends RenderParams = RenderParams> = (params: Params) => void

export interface SceneFactory<
    Props extends SceneProps = SceneProps,
    Params extends RenderParams = RenderParams,
    Result extends FactoryResult<Params> = FactoryResult<Params>> {
    make: (props: Props) => Promise<Result>
}

// MeshRender

export type MeshRenderParams = { mesh: BABYLON.Mesh } & RenderParams

export type MeshFactory = SceneFactory<
    SceneProps>

// PostProcess
export type PostProcessRenderParams = { postProcess: BABYLON.PostProcess } & RenderParams

export type PostProcessFactory = SceneFactory<
    SceneProps,
    RenderParams,
    PostProcessFactoryResult>

export type PostProcessFactoryResult = { postProcesses:  ShaderPassMap } & FactoryResult<RenderParams>

// Material Factory
export type MaterialRenderParams = { mesh: BABYLON.Mesh } & RenderParams

export type MaterialFactory = SceneFactory<
    SceneProps,
    MaterialRenderParams,
    { material: BABYLON.Material } & FactoryResult<MaterialRenderParams>
>


