import * as GraphQL from "src/generated/graphqlTypes"
import { makeCompositeKey } from "src/utils"
import { ModulationTypes, PrimitiveModulation } from "../bindings"
import { LayerKind, LayerNameKeys } from "../cinema"
import { Track } from "../sound/Track"


export interface Identifier {
    compositeKey: string
}

export interface LayerIdentifiable {
    layerName: LayerNameKeys
    layerKind: LayerKind
}

////// Modulation Identifiers

export interface AudioModulationID extends Identifier {
    kind: "AudioModulationID",

    source: TrackID,
    target: LayerUniformID
}

export interface PrimitiveModulationID extends Identifier {
    kind: "PrimitiveModulationID",

    source: TrackEnvelopeID,
    target: LayerUniformID,
}

export type ModulationIdentifier = AudioModulationID | PrimitiveModulationID

////// Audio Identifiers

export interface TrackID extends Identifier {
    kind: "TrackID",
    trackId: string,
}

export interface TrackEnvelopeID extends Identifier {
    kind: "TrackEnvelopeID",
    trackId: string,
}

export interface SamplePackID extends Identifier {
    kind: "SamplePackID",
    packID: string,
}

export interface SampleFileID extends Identifier {
    kind: "SamplePackEntryID",
    sampleID: string
}

export type TrackIdentifers =
    | TrackID
    | TrackEnvelopeID



//////// Cinema Identifiers

export interface LayerID extends LayerIdentifiable, Identifier {
    kind: "LayerIdentifier"
}

export interface LayerShaderID extends LayerIdentifiable, Identifier {
    kind: "LayerShaderIdentifier"
}

export interface LayerMaterialID extends LayerIdentifiable, Identifier {
    kind: "LayerMaterialIdentifier"
}

export interface LayerModelID extends LayerIdentifiable, Identifier {
    kind: "LayerModelIdentifier"
}

export interface LayerUniformID extends LayerIdentifiable, Identifier {
    kind: "UniformID"
    uniformName: string,
    layerShaderID: LayerShaderID | LayerMaterialID
}


export type LayerIdentifiers =
    | LayerID
    | LayerShaderID
    | LayerMaterialID
    | LayerModelID
    | LayerUniformID


export type AllIdentifiers = TrackIdentifers | LayerIdentifiers

export type LayerIdentifierKinds = LayerIdentifiers["kind"]

type _LayerIdentifiable = { info: LayerIdentifiable } | LayerIdentifiable
const extractID = (ident: _LayerIdentifiable): LayerIdentifiable => {

    if ((ident as LayerIdentifiable).layerKind !== undefined) {
        return ident as LayerIdentifiable
    }

    type IndirectIdentifiable = { info: LayerIdentifiable }
    if ((ident as IndirectIdentifiable).info !== undefined) {
        return (ident as IndirectIdentifiable).info
    }

    throw new Error("Incomplete")
}

export class IdentifierFactory {

    public static makeTrack(track: Track): TrackID {
        return {
            kind: "TrackID",
            trackId: track.trackId,
            compositeKey: makeCompositeKey("track", track.trackId)
        }
    }

    public static makeSamplePackID(packID: string): SamplePackID {
        return {
            "kind": "SamplePackID",
            packID,
            compositeKey: makeCompositeKey("sample_pack", packID)
        }
    }

    public static makeSampleFileIDFromRaw(entry: { id: string }): SampleFileID {
        const { id } = entry

        return {
            "kind": "SamplePackEntryID",
            sampleID: id,
            compositeKey: makeCompositeKey("sample_pack_entr", id)
        }
    }

    public static makeSampleFileID(sampleFile: GraphQL.SampleFileFragment): SampleFileID {

        return {
            "kind": "SamplePackEntryID",
            sampleID: sampleFile.id,
            compositeKey: makeCompositeKey("sample_pack_entr", sampleFile.id)
        }
    }

    public static makeEnvelope(track: Track): TrackEnvelopeID {
        return {
            kind: "TrackEnvelopeID",
            trackId: track.trackId,
            compositeKey: makeCompositeKey("envelope", track.trackId)
        }
    }

    public static makeLayerID(_layer: _LayerIdentifiable): LayerID {
        const layer = extractID(_layer)
        return {
            kind: "LayerIdentifier",
            layerName: layer.layerName,
            layerKind: layer.layerKind,
            compositeKey: makeCompositeKey("layer", layer.layerName)
        }
    }

    public static makeLayerShaderIdentifier(_layer: _LayerIdentifiable): LayerShaderID {
        const layer = extractID(_layer)
        if (layer.layerKind !== "backgroundLayer" &&
            layer.layerKind !== "postProcessLayer") {
            throw new Error(`Expected layerKind to be foreground. Found ${layer.layerKind}`)
        }
        return {
            kind: "LayerShaderIdentifier",
            layerName: layer.layerName,
            layerKind: layer.layerKind,
            compositeKey: makeCompositeKey("shader", layer.layerName)
        }
    }

    public static makeLayerMaterialIdentifier(_layer: _LayerIdentifiable): LayerMaterialID {
        const layer = extractID(_layer)

        if (layer.layerKind !== "forgroundModelLayer") {
            throw new Error(`Expected layerKind to be foreground. Found ${layer.layerKind}`)
        }
        return {
            kind: "LayerMaterialIdentifier",
            layerName: layer.layerName,
            layerKind: layer.layerKind,
            compositeKey: makeCompositeKey("material", layer.layerName)
        }
    }


    public static makeLayerModelIdentifier(_layer: _LayerIdentifiable): LayerModelID {
        const layer = extractID(_layer)
        return {
            kind: "LayerModelIdentifier",
            layerName: layer.layerName,
            layerKind: layer.layerKind,
            compositeKey: makeCompositeKey("model", layer.layerName)
        }
    }

    public static makeUniformIdentifier(layerID: LayerShaderID | LayerMaterialID, uniformName: string): LayerUniformID {
        return {
            kind: "UniformID",
            layerName: layerID.layerName,
            layerKind: layerID.layerKind,
            layerShaderID: layerID,
            uniformName,
            compositeKey: makeCompositeKey("uniform", uniformName, layerID.compositeKey)
        }
    }

    public static makeModulationID(p: ModulationTypes): ModulationIdentifier {
        switch (p.kind) {
            case "PrimitiveBinding":
                return {
                    kind: "PrimitiveModulationID",
                    source: p.source,
                    target: p.target,
                    compositeKey: makeCompositeKey("primitive_mod", p.source, p.target)
                }
            case "AudioBinding":
                return {
                    kind: "AudioModulationID",
                    source: p.source,
                    target: p.target,
                    compositeKey: makeCompositeKey("audio_mod", p.source, p.target)
                }
        }

    }

    public static makePrimitiveModulationID(p: PrimitiveModulation): PrimitiveModulationID {
        return {
            kind: "PrimitiveModulationID",
            source: p.source,
            target: p.target,
            compositeKey: makeCompositeKey("primitive_mod", p.source, p.target)
        }
    }
}