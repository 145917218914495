import classNames from 'classnames';
import React from 'react';
import { CharacterModel, ShaderSummary } from 'src/store/cinema';
import styles from "../Cinema.module.scss"
import { Header } from './LayerHeaderView';
import { ModelBodyView } from './Model/ModelBodyView';
import { CinemaEmptyLayerProperty } from "./CinemaEmptyLayerProperty"
import { MaterialView } from './Material/MaterialView';
import { IdentifierFactory, LayerID } from 'src/store/identifiers/identifiers';
import { SelectableLayerIDTypes } from 'src/store/ui/types';
import { observer } from 'mobx-react-lite';
import { useAppContext } from 'src/store/hooks';
import { action } from 'mobx';
import { useLoopActions } from 'src/render/hooks';


type Props = {
    layerID: LayerID
    model?: CharacterModel
    materialShader?: ShaderSummary
    close: () => void
}

type SelectableElementTypes = "material" | "model"

export const ForegroundModelLayerView: React.FC<Props> = observer(({
    layerID,
    model,
    materialShader,
    close }) => {


    const state = useAppContext()
    const { uiActions } = useLoopActions()

    const selectedElement: SelectableElementTypes | undefined = (() => {
        const selectedElement = state.uiState.selectedElements.selectedElement
        if (selectedElement && selectedElement.layerName === layerID.layerName) {
            switch (selectedElement.kind) {
                case "LayerMaterialIdentifier": return "material"
                case "LayerModelIdentifier": return "model"
                default:
                    throw new Error(`Unexpected Type ${selectedElement.kind}`)
            }
        } else {
            return undefined
        }
    })()

    const modelSelected = selectedElement === "model"
    const materialSelected = selectedElement === "material"

    const onSelect = action((type: "model" | "material") => {
        let selectedElement: SelectableLayerIDTypes
        switch (type) {
            case "material":
                selectedElement = IdentifierFactory.makeLayerMaterialIdentifier(layerID)
                break
            case "model":
                selectedElement = IdentifierFactory.makeLayerModelIdentifier(layerID)
                break
        }

        uiActions.selectElement(selectedElement)
    })

    return (
        <div className={classNames(styles.LayerCard)}>
            <Header layerName={layerID.layerName} close={close} />
            {model ?
                <ModelBodyView model={model} selected={modelSelected} onSelect={() => onSelect("model")} /> :
                <CinemaEmptyLayerProperty name={"Model"} selected={modelSelected} onSelect={() => onSelect("model")}
                    onEdit={() => {
                        onSelect("model")
                    }} />
            }
            {materialShader ?
                <MaterialView shader={materialShader} selected={materialSelected} onSelect={() => onSelect("material")} /> :
                <CinemaEmptyLayerProperty name={"Material"} selected={materialSelected} onSelect={() => onSelect("material")}
                    onEdit={() => {
                        onSelect("material")
                    }} />
            }

        </div>)
})


