import { makeAutoObservable } from "mobx"


export interface AsyncStateNone {
    kind: "none"
}
export const asyncStateNone: AsyncStateNone = { kind: "none" }

export const asyncStateLoading: AsyncStateLoading<any> = {
    kind: "loading",
    props: {}
}

export interface AsyncStateLoading<T> {
    kind: "loading"
    props: T
}


export const asyncStateLoaded: AsyncStateLoaded<any> = {
    kind: "loaded",
    props: {}
}

export interface AsyncStateLoaded<T> {
    kind: "loaded"
    props: T
}

export const asyncStateFailed = (error: Error): AsyncStateFailed => {
    return {
        kind: "failed",
        error: error
    }
}



export interface AsyncStateFailed {
    kind: "failed"
    error: Error
}


export type AsyncStateLoader<Loading = any, Loaded = any> =
    AsyncStateNone |
    AsyncStateLoading<Loading> |
    AsyncStateLoaded<Loaded> |
    AsyncStateFailed





export interface LoadingStateMonitor<Loading = any, Loaded = any> {
    readonly state: AsyncStateLoader<Loading, Loaded>
    setState(state: AsyncStateLoader<Loading, Loaded>): void
    isLoadingState: boolean
}

export const makeAsyncStateLoader = <Loading = any, Loaded = any>(): LoadingStateMonitor<Loading, Loaded> => {
    return new LoadingStateMonitorImpl<Loading, Loaded>()
}

class LoadingStateMonitorImpl<Loading, Loaded> implements LoadingStateMonitor<Loading, Loaded>{
    constructor() {
        makeAutoObservable(this)
    }

    public state: AsyncStateLoader<Loading, Loaded> = asyncStateNone

    setState(state: AsyncStateLoader<Loading, Loaded>) {
        this.state = state
    }

    get isLoadingState(): boolean {
        return this.state.kind === "loading"
    }
}


// export interface SideEffect<A extends Action, Loaded, State = PagesState> {
//     properties: {
//         atomic?: boolean
//     }
//     actionKey: string
//     matches: (action: AnyAction) => boolean
//     isEqual?: (action: A, other: A) => boolean

//     thenFetch: (action: A) => Promise<Loaded>

//     finish: (action: A, data: Loaded, state: State, dispatch: Dispatch<any>) => void
// }

// interface RunningEffect {
//     action: AnyAction,
//     effect: SideEffect<AnyAction, any>
// }

// export class SideEffectState {
//     constructor(private dispatch: Dispatch<AnyAction>, private getState: () => any) {
//         //
//     }

//     private registeredEffects: Partial<{ [actionKey: string]: SideEffect<AnyAction, any>[] }> = {}


//     private runningEffects: { [index: string]: RunningEffect } = {}
//     private index = 0

//     receiveAction(action: Action) {
//         this.registeredEffects[action.type]?.forEach(s => {
//             if (s.matches(action)) {
//                 if (s.properties.atomic) {
//                     // Cancel currently running
//                     const remain = Object.entries(this.runningEffects).filter(([k, v]) => {

//                         if (v.effect.matches(action) && v.effect.isEqual?.(action, v.action)) {
//                             return false
//                         }
//                         return true
//                     })

//                     this.runningEffects = Object.fromEntries(remain)
//                 }


//                 const id = `${this.index}`
//                 this.index++

//                 s.thenFetch(action).then((data) => {
//                     if (this.runningEffects[id]) {
//                         delete this.runningEffects[id]
//                         s.finish(action, data, this.getState(), this.dispatch)
//                     } else {
//                         console.log("Cacneled running side effect")
//                     }
//                 })
//                 this.runningEffects[id] = {
//                     action,
//                     effect: s
//                 }

//             }
//         })
//     }

//     addSideEffect<A extends Action, Loaded>(sideEffect: SideEffect<A, Loaded>) {
//         const sideEffects = this.registeredEffects[sideEffect.actionKey] ?? []
//         sideEffects.push(sideEffect as any)
//         this.registeredEffects[sideEffect.actionKey] = sideEffects
//     }
// }