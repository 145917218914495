import { reaction } from "mobx";
import { deepObserve } from "mobx-utils";
import { MiddleWareContext } from "../render/context";
import { PagesState } from "./store";



export function audioSideEffects(pageState: PagesState, context: MiddleWareContext) {
    const { transport } = context
    const updateMute = () => {
        const mutedIndecies = pageState.sounds.tracks.reduce((acc, track) => {
            const isMuted = pageState.audition.getMutedState(track.trackId) !== 'notMuted'
            if (isMuted) {
                acc.push(track.trackId)
            }
            return acc
        }, new Array<string>())

        transport.setMute(mutedIndecies)
    }

    deepObserve(pageState.audition.trackPlaybackState, updateMute)

    reaction(
        () => pageState.sounds.tracks.map(x => x.trackId),
        (existingTracks) => {
            pageState.audition.setExistingTracks(existingTracks)
        })

}