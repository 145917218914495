import { ShaderType } from "src/generated/graphqlTypes"
import { BackgroundLayer, LayerShader, PostProcessLayer } from "src/store/cinema"
import { IdentifierFactory } from "src/store/identifiers/identifiers"
import { ensure } from "src/utils"
import { PostProcessFactory, SceneFactory } from "."
import { loadShaderToyPostProcess } from "../shaders/shaderToy"

type SupportedLayerTypes = PostProcessLayer | BackgroundLayer

export default (layer: SupportedLayerTypes): SceneFactory | undefined => {
    
    if(layer.shader === undefined) {
        return undefined
    }

    switch (layer.shader.summary.type) {
        case ShaderType.Material:
        case ShaderType.Shader:
            return shaderToyPostProcessFactory(layer, layer.shader)
        // case ShaderType.Sf:
        //     return shaderFrogPostProcessFactory(layer.shader)
        default:
            throw new Error(`Have not implemented layer support for ${layer.shader.summary.type}`)
    }
}


const shaderToyPostProcessFactory = (layer: SupportedLayerTypes, shader: LayerShader) => ensure<SceneFactory>({
    make: async (props) => {
        const factory: PostProcessFactory = await loadShaderToyPostProcess(
            shader.summary.id,
            shader.summary.type,
            IdentifierFactory.makeLayerShaderIdentifier(layer))

        return await factory.make(props)
    }
})

// const shaderFrogPostProcessFactory = (shader: ShaderSummary) => ensure<SceneFactory>({
//     make: async (props) => {
//         let shaderID: number
//         try {
//             shaderID = Number.parseInt(shader.id)
//         } catch (error) {
//             throw new Error(`Failed to parse Shader ID to int ${shader.id}`)
//         }
//         const factory = await loadShaderFrogPostProcess(shaderID)
//         return await factory.make(props)

//     }
// })